import React, { useState } from 'react';
import { Button, Accordion, AccordionDetails, AccordionSummary, ToggleButtonGroup, ToggleButton, Skeleton, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from '../../constants/colors';
import { PieChart } from "@mui/x-charts";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import formatKeyToTitle from '../../utils/formatKeytitle';

const PMSAssetAllocationMobile = ({ DataBYID, DataBYiSIN }: { DataBYID: any, DataBYiSIN: any }) => {
  const [currentTab, setCurrentTab] = useState(1);

  const tabs = [
    { label: 'Assets', value: 1 },
    { label: 'Sectors', value: 2 },
    { label: 'Securities', value: 3 },
  ];


  const AllAssetChartValue = () => {
    const data = DataBYID?.Asset;
  
    return Object.entries(data).map(([key, value]) => ({
      id: key,
      value: value as number,
      label: formatKeyToTitle(key),
    }));
  };
  

  const AssetItem = ({ title, value }: { title: string; value: number }) => {
    return (
      <>
        <Box
            key={title}
            sx={{
              width:"100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr", // Equal halves
              alignItems: "center",
              gap: 2,
              mb: 2,
            }}
          >
            {/* Label Section - Left Half */}
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, textAlign: "left" }}
            >
              {title}
            </Typography>

            <LinearProgressWithLabel value={value} />
          </Box>
      </>
    );
  };

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', flexDirection:"column"}}>
      <Box sx={{ width: "100%",display:'flex',justifyContent:"end", alignItems:'end' }}>
          <Typography variant="body2" color="text.secondary">{`${(props.value).toFixed(2)}%`}</Typography>
        </Box>
        
        <Box sx={{ width: '100%'}}>
          <LinearProgress variant="determinate" {...props} sx={{
                  width: "100%", // Make sure the progress bar stretches
                  height: 5,
                  borderRadius: 5,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    backgroundColor: "#4caf50",
                  },
                }} />
        </Box>
      </Box>
    );
  }

  const AssetList = ({ data }: { data: any }) => {
    const assetArray = Object.entries(data.assetAllocation).map(([key, value]) => ({ key, value: parseFloat(value as string) }));
    const filteredData = assetArray.filter((item) => item.value >= 0);
  
    return (
      <div className="flex flex-wrap">
        {filteredData.map(({ key, value }, index) => (
          <div key={index} className="my-4 w-full px-8">
            <AssetItem title={formatKeyToTitle(key)} value={value} />
          </div>
        ))}
      </div>
    );
  };
  




  const AssetAllocationTab = () => {
    const pieChart = AllAssetChartValue();
    // console.log('pieChart', pieChart);

    return (
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-center mx-auto px-auto">
          <PieChart
            series={[
              {
                data: pieChart,
                innerRadius: 120,
                outerRadius: 150,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{
              '& .css-ecs132-MuiChartsSurface-root': {
                backgroundColor: '#ff0000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            width={400}
            height={300}  // You can keep this height or adjust as needed
            margin={{ top: 4, right: 5, bottom: 4, left: 5 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </div>
        <AssetList data={{ assetAllocation: DataBYID?.Asset }} />
      </div>
    )
  };

  const ListingComponent = ({ data }: { data: any }) => {
    const [showAll, setShowAll] = useState(false);

    if (!Array.isArray(data) || data.length === 0) {
      return (
        <Skeleton
          variant="rectangular"
          height={200}
          animation="wave"
          sx={{ bgcolor: colors.lightBg }}
        />
      );
    }

    const filteredData = data;
    if (filteredData.length === 0) {
      return <p>No data found for the specified types.</p>;
    }

    const dataToShow = showAll ? filteredData : filteredData.slice(0, 10);

    const handleClickAway = () => {
      setShowAll(false);
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div className="flex flex-wrap">
            {dataToShow.map((item, index) => (
              <div key={index} className="my-4 w-full px-8">
                <AssetItem title={item?.Security || item?.Sector} value={parseFloat(item?.Percentage)} />
              </div>
            ))}
          </div>
          <div className="w-full text-center mt-4">
            {!showAll && filteredData.length > 10 && (
              <div
                onClick={() => setShowAll(true)}
                className="px-4 py-2 text-primary font-bold underline underline-offset-8"
              >
                Load More
              </div>
            )}
            {showAll && (
              <div
                onClick={() => setShowAll(false)}
                className="px-4 py-2 text-primary font-bold underline underline-offset-8 rounded"
              >
                Hide Assets
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  };

  const Securities = () => {
    //const pieChart = ChartValue();
    return (
      <div className="md:w-full items-center justify-center">
        <div className="w-3/4">
          {/* <PieChart
            series={[
              {
                data:{},
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 2,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{ width: "100%" }}
            height={300}
          /> */}
        </div>
        <ListingComponent data={DataBYiSIN?.Securities} />
      </div>
    );
  };

  const Sectors = () => {
    //const pieChart = ChartValue();

    return (
      <div className="md:w-full items-center justify-center">
        <div className="w-3/4">
          {/* <PieChart
            series={[
              {
                data:{},
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 2,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{ width: "100%" }}
            height={300}
          /> */}
        </div>
        <ListingComponent data={DataBYiSIN?.Sectors} />
      </div>
    );
  };


  // const handleAlignmentss = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string | null,
  // ) => {
  //   if (newAlignment !== null) {
  //     setAlignment(newAlignment);
  //   }
  // };

  return (
    <>
     {(DataBYiSIN?.Sectors?.every((obj:object) => Object?.keys(obj)?.length === 0) && DataBYiSIN?.Securities?.every((obj:object) => Object?.keys(obj)?.length === 0))?<>
     <h3 className="w-full text-lg text-[darkGrey] text-center">No assets data found for this PMS.</h3>
     </>:( <div style={{ padding: '0', margin :'1rem 1rem'}}>
        <div className="border border-lightGrey rounded-md">
          <div className='border-b-2 border-primary'>
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={currentTab}
                        onChange={(event, value) => setCurrentTab(value)}
                        aria-label="Platform"
                        sx={{
                          width: '100%',
                          justifyContent: 'center',
                          '& .css-1xhpvac-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                            backgroundColor: colors.primary,
                          },
                          '& .css-233i2o-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                            color: '#fff',
                            backgroundColor: colors.primary
                          },
                          '& .css-1vwtnbs-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                            color: '#fff',
                            backgroundColor: colors.primary
                          }
                        }}
                      >
                        {tabs.map((tab) => (
                          <ToggleButton
                            key={tab.value}
                            value={tab.value}
                            sx={{
                              fontWeight: 'bold',
                              fontSize:"12px",
                              color: tab.value ? colors.primary : '#fff',
                              width: tab.value === 1 ? '33.33%' : '33.33%', // Adjust width based on your requirements
                              '&:hover': {
                                backgroundColor: currentTab === tab.value ? 'primary' : 'transparent',
                              }
                            }}
                          >
                            {tab.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </div>
          <div className="my-5 flex flex-col md:flex-row gap-5 justify-between p-4 w-full mx-auto">
            {currentTab === 1 ? <AssetAllocationTab /> : currentTab === 2 ? <Sectors />: <Securities />  }
          </div>
        </div>
      </div>)}
    </>
  );
};

export default PMSAssetAllocationMobile;
