import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getImpersonateToken,
  getRMUser,
  loginAsUser,
  verifyRMOTP,
} from "../../redux/user/userSlice";
import { useUserData } from "../../hooks/useUserData";
import VerifyOTPModal from "../../components/modals/VerifyOTPModal";
import Loader from "../../components/Loader/Loader";
import toast from "react-hot-toast";

// Define the user interface
interface User {
  id: number;
  userId: number;
  firstName: string;
  email: string;
  mobile: string;
  onboardStatus: number;
  createdAt: string;
}

const UserList = () => {
  const [RMUser, setRMUser] = useState<User[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch<any>();
  const [userId, setUserId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const user = useUserData();
  const [showSummary, setShowSummary] = useState(false);
  const [otp, setOtp] = useState("");
  const [InvestorID, setInvestorID] = useState<number | null>(null);

  // Set userId only once when the user object is available
  useEffect(() => {
    if (user?.id) {
      setUserId(user.id);
    }
  }, [user?.id]);

  useEffect(() => {
    if (!userId) return; // Ensure userId exists before making API call

    const fetchUsers = async () => {
      try {
        const response = await dispatch(
          getRMUser({
            pageIndex,
            pageSize,
            rmId: userId,
            forRm: user?.userType === 2 ? 1 : 0,
          })
        );

        if (response?.payload?.data) {
          const { data, totalPages } = response.payload.data;
          setRMUser(data);
          setTotalPages(totalPages);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [pageIndex, pageSize, userId]);
  useEffect(() => {
    console.log("Rendered Users:", RMUser);
  }, [RMUser]);

  // Handle page change
  const handlePageChange = (newPageIndex: number) => {
    if (newPageIndex > 0 && newPageIndex <= totalPages) {
      setPageIndex(newPageIndex);
    }
  };

  // Handle page size change
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPageSize(Number(event.target.value));
    setPageIndex(1); // Reset to first page when page size changes
  };

  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    if (names.length > 1) {
      return names[0][0] + names[1][0];
    }
    return names[0][0];
  };

  const handleImpersonate = async (userId: number) => {
    try {
      setInvestorID(userId);
      setShowSummary(true);
      const impersonationResponse = await dispatch(
        getImpersonateToken({ userId, otpFor: 2 })
      );
    } catch (error) {
      console.error("Error impersonating user:", error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const Response = await dispatch(verifyRMOTP({ userId: InvestorID, otp }));
      if (Response.payload.statusCode === 200) {
        const impersonationToken = Response?.payload?.data?.data?.token;

        const loginResponse = await dispatch(
          loginAsUser({ token: impersonationToken })
        );
        const accessToken = loginResponse.payload.data.accessToken;

        // Open dedicated new window
        const newWindow = window.open(
          "/",
          `_user_${userId}`,
          "width=1200,height=800"
        );

        if (newWindow) {
          // Inject Access Token into new window's sessionStorage using window.opener
          newWindow.onload = () => {
            // newWindow.localStorage.setItem("isImpersonated", "true");
            newWindow.sessionStorage.setItem("accessToken", accessToken);
            newWindow.location.reload(); // Important to reload the new window to use the token immediately
          };

          // Listen for Parent Window Logout
          window.addEventListener("storage", (e) => {
            if (e.key === "accessToken" && !e.newValue) {
              newWindow.close(); // Close Child Window
            }
          });

          window.addEventListener("sessionExpired", (e: any) => {
            if (e.detail === "accessToken") {
              console.log("Child Window Closing...");
              newWindow.close();
            }
          });

          // Listen for Child Window Close Event 🔥
          newWindow.onbeforeunload = () => {
            sessionStorage.removeItem("accessToken"); // Clear Token in Parent Window
            window.dispatchEvent(new Event("storage")); // Trigger Logout Event
          };
        }
      }
      else{
        toast.error("Invalid OTP");
      }
    } catch (error) {
      toast.error("Something went wrong! Please try after some time.");
      console.error("Error impersonating user:", error);
    } finally {
      setLoading(false);
      setShowSummary(false);
      setOtp("");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="p-4 mb-48 mx-auto" style={{ width: "90%" }}>
        {/* Header */}
        <div className="flex justify-between items-center mb-20">
          <h1 className="text-2xl font-bold text-primary">User List</h1>

          {/* Page size selector */}
          <div className="flex items-center">
            <label className="mr-2">Page Size:</label>
            <select
              value={pageSize}
              onChange={handlePageSizeChange}
              className="p-2 border border-gray-300 rounded"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>

        <div className="flex flex-wrap gap-10 justify-start mb-10">
          {RMUser && RMUser.length > 0 ? (
            <div className="flex flex-wrap gap-10 justify-start mb-10">
              {RMUser.map((user: User) => (
                <div
                  key={user.id}
                  className="flex flex-row items-center p-4 border border-gray-300 rounded-lg w-64"
                >
                  <div className="bg-primary rounded-full w-12 h-12 flex items-center justify-center text-white text-xl mr-4">
                    {getInitials(user.firstName)}
                  </div>
                  <div className="flex flex-col">
                    <span className="font-bold text-sm">{user.firstName}</span>
                    <span className="text-xs">{user.email}</span>
                    <span className="text-xs">
                      Onboarding Status: {user.onboardStatus}
                    </span>
                    <span className="text-xs">
                      Created At:{" "}
                      {new Date(user.createdAt).toLocaleDateString()}
                    </span>
                    <button
                      onClick={() => handleImpersonate(user.userId)}
                      className="mt-2 w-32 px-4 py-2 bg-primary text-white rounded"
                    >
                      Open
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No users found.</p>
          )}
        </div>

        {/* Pagination controls */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-24">
            <button
              onClick={() => handlePageChange(pageIndex - 1)}
              disabled={pageIndex <= 1}
              className={`px-4 py-2 rounded-l-md ${
                pageIndex <= 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-primary text-white"
              }`}
            >
              Previous
            </button>
            <span className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">
              Page {pageIndex} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(pageIndex + 1)}
              disabled={pageIndex >= totalPages}
              className={`px-4 py-2 rounded-r-md ${
                pageIndex >= totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-primary text-white"
              }`}
            >
              Next
            </button>
          </div>
        )}
      </div>
      <VerifyOTPModal
        title="Verify OTP sent to your mobile"
        showModal={showSummary}
        setShowModal={setShowSummary}
        otp={otp}
        setOtp={setOtp}
        onClick={handleSubmit}
      />
    </>
  );
};

export default UserList;
