import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Delete,
  Edit,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from "../../constants/colors";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import {
  deleteHoldingsData,
  editHoldingsData,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";

const PPFTableRowComponent = ({ data, getInitials, modalMenuClick, fetchDataPPF }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);
  function convertPercentage(value: any) {
    return parseFloat(value?.toFixed(2));
  }
  const handleOpen = (
    id: any,
    interestRate: any,
    amount: any,
    maturityDate: any,
    lockInPeriod: any,
    uanNo: any
  ) => {
    setFields([
      { name: "interestRate", label: "Interest Rate", type: "number" },
      { name: "amount", label: "Amount", type: "number" },
      { name: "maturityDate", label: "Maturity Date", type: "text" },
      { name: "lockInPeriod", label: "Lock In Period", type: "number" },
      { name: "uanNo", label: "UAN No", type: "number" },
    ]);
    setValues({
      interestRate: Number(interestRate),
      amount: Number(amount),
      maturityDate: maturityDate,
      lockInPeriod: Number(lockInPeriod),
      uanNo: Number(uanNo),
    });
    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData({
          id: id,
          interestRate: formData?.interestRate,
          amount: formData?.amount,
          maturityDate: formData?.maturityDate,
          lockInPeriod: formData?.lockInPeriod,
          uanNo: formData?.uanNo,
          holdingType: holdingType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`PPF/EPS/KWP edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "PPF",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`PPF/EPS/KWP deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchDataPPF();
    setOpen(false);
    setMessage("");
  };
  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize:{
                      xs:"12px",
                      sm:"12px",
                      md:"16px"
                    }
                  }}
                  align="left"
                  className="min-w-[100px]"
                >
                  {toCamelCase(item?.assetName || "-")}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {Number(item?.interestRate) + "%"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.amount)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.maturityDate ? DateUtils.formatDate(item?.maturityDate) : "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.currentAmount)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{    
                    height: "100%",    
                    verticalAlign: "middle",            
                    borderBottom: "2px solid #ccc",
                  }}
                >
                   <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                  <IconButton>
                    <Edit
                      onClick={() =>
                        handleOpen(
                          item?.id,
                          item?.interestRate,
                          item?.amount,
                          item?.maturityDate,
                          item?.lockInPeriod,
                          item?.uanNo
                        )
                      }
                    />
                  </IconButton>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleConfirmModalOpen(item?.id, toCamelCase(item?.assetName))
                      }
                    />
                  </IconButton>
                  </div>
                </TableCell>
              </TableRow>
              {/* <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow> */}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="PPF"
          heading="PPF/EPS/KWP Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default PPFTableRowComponent;
