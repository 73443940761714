import api from "../../config/apiConfig";

const sendPartnerOtp = async (data: any) => {
  try {
    const res = await api.post("partner/sendOTP", data);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const verifyPartnerOtp = async (data: any) => {
  try {
    const res = await api.post("partner/verifyOTP", data);
    return res.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const partnerRequest = async (data: any) => {
  try {
    const response = await api.post("partner/request", data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const PartnerService = { sendPartnerOtp, verifyPartnerOtp, partnerRequest };
export default PartnerService;
