import React, { useState, useEffect } from "react";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Add, Close, FilterAlt, Search } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import dropdown from "../../assets/icons/dropdown.png";
import TableComponent from "../Tables/TableRow";
import { LineChart } from "@mui/x-charts";
import CloseIcon from "@mui/icons-material/Close";
import PmsTable from "../PMSListingTable/PmsTable";
import ListingCardComponent from "../Mobile_Components/ListingCardComponent";

// Define the type for a pms entry
interface PMSEntry {
  assetName: string;
  promoter: string;
  category: string;
  // Add other fields as needed
}

function PmsListingComponent({
  title,
  breadcrumb,
  filterOps,
  totalEnteries,
  dataEnteries,
  controlBarObj,
  summary,
  setDataEnteries,
  SmallChart,
  loaderData,
}: {
  title: string;
  breadcrumb: any;
  filterOps: any;
  totalEnteries: any;
  dataEnteries: any;
  controlBarObj: any[];
  summary: string;
  setDataEnteries: any;
  SmallChart?: boolean;
  QuickFilterOption?: any[];
  quickFilterOptionFn?: any;
  clearFilterOptionFn?: any;
  inputData?: any;
  setInputData?: any;
  subcategory?: any[];
  subCategoryTable?: boolean;
  initials?: boolean;
  loaderData?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [filterOptions, setFilterOptions] = useState(filterOps);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [filteredData, setFilteredData] = useState<PMSEntry[]>(
    dataEnteries || []
  );

  useEffect(() => {
    if (searchInput === "") {
      setFilteredData(dataEnteries);
    } else {
      const lowercasedInput = searchInput.toLowerCase();
      const newFilteredData = dataEnteries.filter(
        (entry: PMSEntry) =>
          entry.assetName.toLowerCase()?.includes(lowercasedInput) ||
          entry.promoter.toLowerCase()?.includes(lowercasedInput) ||
          entry.category.toLowerCase()?.includes(lowercasedInput)
      );
      setFilteredData(newFilteredData);
    }
  }, [searchInput, dataEnteries]);

  const isSmallScreen = useMediaQuery("(max-width:1024px)");

  return (
    <div>
      <div className="bg-lightBg">
        <div className="md:py-[30px] px-4 py-2 md:px-[30px] max-w-[1440px] justify-center items-center mx-auto">
          <div className="pb-4 border-b border-lightGrey md:mb-8 mb-2 sm:pt-0 pt-2">
            <DynamicBreadcrumbs items={breadcrumb} />
          </div>
          <div className="w-full flex md:flex-row gap-x-6 ">
            <div className="md:w-[300px] md:h-[150px] md:bg-white bg-red-950 items-center justify-center align-middle relative rounded-full md:flex hidden">
              {/* Center image */}
              <div className="flex items-center justify-center w-full h-full">
                <img className="w-[80px]" src={dropdown} alt="center-image" />
              </div>
            </div>
            <div className="flex flex-col md:gap-y-3">
              <div className="flex gap-x-8 items-center border-b-2">
                <div className="flex flex-row w-full md:justify-between justify-start sm:pb-4 pb-2">
                  <div className="bg-white w-16 h-18 items-center justify-center flex align-middle relative rounded-full md:hidden mr-4 my-0 py-0">
                    {/* Center image */}
                    <div className="flex items-center justify-center w-full h-full">
                      <img
                        className="w-full p-3"
                        src={dropdown}
                        alt="center-image"
                      />
                    </div>
                  </div>
                  <div className="my-auto md:my-0">
                    <h3 className="text-xl sm:text-3xl font-semibold sm:font-medium text-primary">
                      {title}
                    </h3>
                  </div>
                  {/* <div className="justify-center px-3 py-2 text-md border border-solid rounded-[35px] max-md:max-w-full bg-gray-700_01 text-white">
                  Total found: {totalFound}
                </div> */}
                </div>
              </div>
              <p className="mt-1 text-md sm:text-lg text-stone-500 max-md:max-w-full">
                {summary}
              </p>
            </div>
          </div>
          {SmallChart && (
            <div className="flex justify-between mt-8">
              <div className="w-[46%] flex flex-row border-[1px] rounded-xl border-lightGrey items-center justify-center bg-white p-2">
                <Box>
                  <Typography
                    variant="h5"
                    className="text-primary font-extrabold "
                  >
                    NIFTY 50
                  </Typography>
                  <Typography variant="h6" className="text-darkGrey ">
                    21,862.82
                  </Typography>
                  <div className="flex gap-x-4">
                    <span className="text-red-600">-36.21%</span>
                    <span className="text-red-600">-36.21%</span>
                  </div>
                </Box>
                <Box>
                  <LineChart
                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                    series={[
                      {
                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                      },
                    ]}
                    width={300}
                    margin={{
                      bottom: 0,
                      left: 0,
                      right: 0,
                      top: 0,
                    }}
                    height={150}
                    axisHighlight={{
                      y: "none",
                      x: "none",
                    }}
                    slotProps={{
                      mark: {
                        display: "none",
                      },
                      axisLine: {
                        display: "none",
                      },
                      axisTickLabel: {
                        display: "none",
                      },
                      axisTick: {
                        display: "none",
                      },
                    }}
                    tooltip={{
                      trigger: "none",
                    }}
                  />
                </Box>
              </div>
              <div className="w-[46%] flex flex-row border-[1px] rounded-xl border-lightGrey items-center justify-center bg-white p-2">
                <Box>
                  <Typography variant="h5" className="text-primary font-bold">
                    Sensex
                  </Typography>
                  <Typography variant="h6" className="text-darkGrey ">
                    21,862.82
                  </Typography>
                  <div className="flex gap-x-4">
                    <span className="text-red-600">-36.21%</span>
                    <span className="text-red-600">-36.21%</span>
                  </div>
                </Box>
                <Box>
                  <LineChart
                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                    series={[
                      {
                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                      },
                    ]}
                    width={300}
                    margin={{
                      bottom: 0,
                      left: 0,
                      right: 0,
                      top: 0,
                    }}
                    height={150}
                    axisHighlight={{
                      y: "none",
                      x: "none",
                    }}
                    slotProps={{
                      mark: {
                        display: "none",
                      },
                      axisLine: {
                        display: "none",
                      },
                      axisTickLabel: {
                        display: "none",
                      },
                      axisTick: {
                        display: "none",
                      },
                    }}
                    tooltip={{
                      trigger: "none",
                    }}
                  />
                </Box>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-4 px-2 sm:p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
        <div className="flex justify-between sm:gap-x-3">
          <TextField
            className="w-full"
            sx={{ padding: "1re" }}
            placeholder="Search PMS"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            variant="outlined"
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {isSmallScreen ? (
          <>
            {Array.isArray(filteredData) ? (
              filteredData?.map((fund: any, index: any) => (
                <ListingCardComponent
                  key={index}
                  schemeName={fund.assetName || ""}
                  data={fund}
                  details={[
                    { label: "Annual Return", value:(fund.ann1Year==="-" || fund.ann1Year==="") ? "-" : fund.ann1Year + "%" },
                    { label: "AUM", value:(fund.aumInCr==="-" || fund.aumInCr==="") ? "-" : fund.aumInCr + " Cr" },
                    {
                      label: "Return Inception",
                      value: (fund.returnSinceInception==="-" || fund.returnSinceInception==="") ? "-" : fund.returnSinceInception + "%",
                    },
                    // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
                  ]}
                  // onEdit={() => handleEdit(fund.schemeName)}
                  // onDelete={() => handleDelete(fund.schemeName)}
                  assetType="PMS"
                  loading={loaderData}
                  subInfo={fund.fundManager}
                  subInfoTitle="Fund Manager"
                />
              ))
            ) : (
              <ListingCardComponent details={[]} />
            )}
          </>
        ) : (
          <div className="mt-8">
            {filteredData &&
              controlBarObj &&
              setDataEnteries &&
              (filteredData.length > 0 ? (
                <div>
                  <PmsTable data={filteredData} loader={loaderData} />
                  {/* <TableComponent bodyData={filteredData} headData={controlBarObj} unSortdata={setDataEnteries} subcategory={subcategory} subCategoryTable={subCategoryTable} initials={initials} /> */}
                </div>
              ) : (
                <div className="mt-8">
                  <Typography variant="subtitle2">No data Found</Typography>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PmsListingComponent;
