import axios from "axios";
import authService from "../redux/auth/authService";
import { auth } from "./firebaseConfig";
import { useEffect } from "react";

const api = axios.create({
  // baseURL: "https://mmk7r2qidh.ap-south-1.awsapprunner.com/api/v1/",
  baseURL: process.env.REACT_APP_API_URL || "https://vuwycnpnem.ap-south-1.awsapprunner.com/api/v1/",
});

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void; 
  reject: (error: any) => void;
}> = [];

// Function to process the queue of failed requests
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token!);
    }
  });
  failedQueue = [];
};

// Request interceptor to handle token expiration and refresh logic
api.interceptors.request.use(async (req) => {
  try {
    const { refreshToken, accessToken } = authService.getToken();

    // If there's no refresh token, log out the user if access token is expired
    if (!refreshToken) {
      if (accessToken && authService.isTokenExpired()) {
        authService.logout();
      }
      return req;
    }

    // Skip refresh token endpoint to prevent infinite loop
    if (req.url === "/user/refreshToken") return req;

     // Check if the access token needs refreshing
     if (authService.isTokenExpired()) {
      await authService.refreshTokenIfNeeded();

      // If the user was logged out due to inactivity, stop further processing
      if (!authService.getToken().accessToken) {
        throw new Error("User inactive. Logged out.");
      }
    }

    // Only refresh the token if the accessToken is expired
    if (authService.isTokenExpired()) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await authService.refreshToken(); // Refresh the token
          processQueue(null, authService.getToken().accessToken);
        } catch (error) {
          processQueue(error, null);
          authService.logout();
        } finally {
          isRefreshing = false;
        }
      }

      // Queue the request until the token refresh is completed
      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve: (token: string) => {
            req.headers['Authorization'] = token;
            resolve(req);
          },
          reject: (err: any) => {
            reject(err);
          }
        });
      });
    }

     // Attach the (possibly refreshed) access token to the request
     const updatedAccessToken = authService.getToken().accessToken;
     if (updatedAccessToken) {
       req.headers['Authorization'] = updatedAccessToken;
     }

    return req;
  } catch (error) {
    authService.logout();
    return req;
  }
});



api.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = error;

    if (response?.status === 401 && !config._retry) {
      config._retry = true;

      try {
        const newAccessToken = await authService.refreshToken();
        config.headers["Authorization"] = `${newAccessToken}`;
        return api(config);
      } catch (err) {
        authService.logout();
      }
    }

    return Promise.reject(error);
  }
);

// api.interceptors.response.use((response) => {
//   return response;
// }, async (error) => {
//   const { config, response: { status, data } } = error;
//   const originalRequest = config;

//   // if (status === 401 && data.message === "You are forcefully logged out, Your token is not valid.") {
//   //   console.log('====================================');
//   //   console.log('failedQueue by reacted', failedQueue);
//   //   console.log('====================================');
//   //   authService.logout();
//   //   return Promise.reject(error);
//   // }

//   if (status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;

//     try {
//       await authService.refreshToken();
//       const { accessToken } = authService.getToken();
//       originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

//       // Add delay before retrying the original request
//       await new Promise((resolve) => setTimeout(resolve, 2000));

//       return api(originalRequest);
//     } catch (err) {
//       authService.logout();
//       return Promise.reject(err);
//     }
//   }

//   return Promise.reject(error);
// });

export default api;
