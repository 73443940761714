import { useState } from "react";
import { Card, useMediaQuery} from "@material-ui/core";
import { Collapse, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { useUserData } from "../../hooks/useUserData";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { colors } from "../../constants/colors";

interface UpdateItem {
  updateColumn: string;
  updateValue: any; // Adjust type as necessary
  oldValue: any; // Adjust type as necessary
}

interface UpdateData {
  KYC: Record<string, UpdateItem[]>;
  USER: Record<string, UpdateItem[]>;
  NOMINEE: Record<string, UpdateItem[]>;
  BANK: Record<string, UpdateItem[]>;
}

const NomineeForm = ({ nominee }: any) => {
  const [openIndex, setOpenIndex] = useState(0); // Default open index is 0 (Nominee 1)
  const user = useUserData();
  const nomineeData:any = user?.nominee;
  // const nomineeData:any = [
  //   {
  //     id: 262,
  //     name: "Amit Kumar",
  //     mobileNumber: "9876543210",
  //     email: "amit.kumar@example.com",
  //     relation: 3,
  //     dateOfBirth: 398294400000,
  //     percentage: "50",
  //     nomineeAddress: "123 Street, City A",
  //     pincode: "110001",
  //     isMinor: 0,
  //     guardianDetails: '{"name":"N/A","pancardNumber":"N/A","relation":"N/A"}',
  //     aadharNumber: "123456789012",
  //     aadharCard: "path/to/aadharCardA",
  //     pancardNumber: "ABCDE1234F",
  //     pancard: "path/to/panCardA",
  //   },
  //   {
  //     id: 263,
  //     name: "Rohini Sharma",
  //     mobileNumber: "8765432109",
  //     email: "rohini.sharma@example.com",
  //     relation: 5,
  //     dateOfBirth: 457833600000,
  //     percentage: "30",
  //     nomineeAddress: "456 Lane, City B",
  //     pincode: "560002",
  //     isMinor: 1,
  //     guardianDetails:
  //       '{"name":"Suresh Sharma","pancardNumber":"WXYZ1234H","relation":20}',
  //     aadharNumber: "234567890123",
  //     aadharCard: "path/to/aadharCardB",
  //     pancardNumber: "WXYZ1234H",
  //     pancard: "path/to/panCardB",
  //   },
  //   {
  //     id: 264,
  //     name: "Sneha Roy",
  //     mobileNumber: "7654321098",
  //     email: "sneha.roy@example.com",
  //     relation: 4,
  //     dateOfBirth: 488376000000,
  //     percentage: "20",
  //     nomineeAddress: "789 Avenue, City C",
  //     pincode: "700001",
  //     isMinor: 0,
  //     guardianDetails: '{"name":"N/A","pancardNumber":"N/A","relation":"N/A"}',
  //     aadharNumber: "345678901234",
  //     aadharCard: "path/to/aadharCardC",
  //     pancardNumber: "FGHI5678J",
  //     pancard: "path/to/panCardC",
  //   },
  // ];

  // const nomineeData:any = [];

  // console.log(nomineeData, "data");

  const NomineeAge = ({ value, onChange, isEditable }: any) => {
    return (
      <div>
        <Typography variant="subtitle2">Is the nominee a minor?</Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="isMinor"
          value={value} // Bind to the current nominee's state
          onChange={(e) => {
            onChange(Number(e.target.value)); // Ensure `value` updates with `1` or `0`
          }}
        >
          <FormControlLabel
            value={1}
            control={<Radio disabled={!isEditable} />} // Disable the radio button if not editable
            label="Yes"
          />
          <FormControlLabel
            value={0}
            control={<Radio disabled={!isEditable} />} // Disable the radio button if not editable
            label="No"
          />
        </RadioGroup>
      </div>
    );
  };

  const relationshipOptions = [
    { label: "AUNT", value: 1 },
    { label: "BROTHER-IN-LAW", value: 2 },
    { label: "BROTHER", value: 3 },
    { label: "DAUGHTER", value: 4 },
    { label: "DAUGHTER-IN-LAW", value: 5 },
    { label: "FATHER", value: 6 },
    { label: "FATHER-IN-LAW", value: 7 },
    { label: "GRAND DAUGHTER", value: 8 },
    { label: "GRAND FATHER", value: 9 },
    { label: "GRAND MOTHER", value: 10 },
    { label: "GRAND SON", value: 11 },
    { label: "MOTHER-IN-LAW", value: 12 },
    { label: "MOTHER", value: 13 },
    { label: "NEPHEW", value: 14 },
    { label: "NIECE", value: 15 },
    { label: "SISTER", value: 16 },
    { label: "SISTER-IN-LAW", value: 17 },
    { label: "SON", value: 18 },
    { label: "SON-IN-LAW", value: 19 },
    { label: "SPOUSE", value: 20 },
    { label: "UNCLE", value: 21 },
    { label: "OTHERS", value: 22 },
    { label: "COURT APPOINTED LEGAL GUARDIAN", value: 23 },
  ];

  const boxStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxWidth: 500,
    width: "100%",
  };

  const ulStyles = {
    listStyleType: "disc", // Removes default bullet points
    paddingLeft: "0",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    "& li": {
      position: "relative",
      paddingLeft: "2rem", // Space for custom bullet points
      marginBottom: "0.5rem", // Space between items
      color: "text-gray-700", // Tailwind's text-gray-700
    },
    "& li::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#f39a45", // Custom color for bullets
    },
  };

  const validateTotalPercentage = (nominees: any) => {
    // Ensure nominees is an array before calling .reduce()
    if (!Array.isArray(nominees)) {
      return false; // or handle the case where nominees is not an array
    }

    const total = nominees.reduce(
      (sum: number, nominee: any) =>
        sum + (parseFloat(nominee.percentage) || 0),
      0
    );

    return total <= 100;
  };
      const isSmallScreen = useMediaQuery("(max-width:640px)");
  return (
    <>
      <div className="mb-6 border-2 border-lightGrey rounded-md ">
        <Card
          style={{
            backgroundColor: colors.lightBg,
            minHeight: "60px",
            display: "flex",
            alignItems: "center",
            padding: "0",
          }}
          className="rounded-md h-8 w-full mb-5"
        >
          <Typography
           variant={isSmallScreen?"subtitle1":"h5"}
            marginLeft={2}
            color={colors.darkGrey}
            fontWeight="bold"
          >
            Nominee Details
          </Typography>
        </Card>
        <>
          {nomineeData?.length >0 ?(nomineeData?.map((nominee: any, index: number) => (
            <div
              key={index}
              className={`bg-white p-4 mb-4 shadow-md border border-lightGrey rounded-lg`}
            >
              <div className="flex justify-between items-center">
                <Typography
                   variant={isSmallScreen?"subtitle1":"h5"}
                  fontWeight="bold"
                  color={colors.darkGrey}
                >
                  Nominee {index + 1}
                </Typography>
                <IconButton
                  onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
                >
                  {openIndex === index ? (
                     <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
              <div className="flex flex-wrap justify-between">
                {/* Full Name */}
                <div className="flex flex-grow w-2/4 md:w-full lg:w-1/3 p-2 mb-4">
                  <div>
                    <span className="text-textGrey text-sm md:text-base">
                      Full Name 
                    </span>
                    <Typography
                      variant={isSmallScreen?"subtitle2":"h6"}
                      color={colors.darkGrey}
                      className="font-bold w-full md:w-48 lg:w-40 xl:w-32"
                    >
                      {nominee.name}
                    </Typography>
                  </div>
                </div>

                {/* Relationship */}
                {/* Relationship */}
                <div className="flex flex-grow w-2/4 md:w-full lg:w-1/3 p-2 mb-4">
                  <div>
                    <span className="text-textGrey text-sm md:text-base">
                      Relationship
                    </span>
                    <Typography
                      variant={isSmallScreen?"subtitle2":"h6"}
                      color={colors.darkGrey}
                      className="font-bold text-sm md:text-base w-full md:w-48 lg:w-40 xl:w-32" // Apply consistent width
                    >
                      {relationshipOptions.find(
                        (option) => option.value === nominee.relation
                      )?.label || "N/A"}
                    </Typography>
                  </div>
                </div>
                <div className="flex flex-grow w-2/4 md:w-full lg:w-1/3 p-2 mb-4">
                  <div>
                    <span className="text-textGrey text-sm md:text-base">
                      Date Of Birth
                    </span>
                    <Typography
                      variant={isSmallScreen?"subtitle2":"h6"}
                      color={colors.darkGrey}
                      className="font-bold text-sm md:text-base w-full md:w-48 lg:w-40 xl:w-32"
                    >
                      {nominee.dateOfBirth
                        ? dayjs(nominee.dateOfBirth).format("DD/MM/YYYY")
                        : "N/A"}
                    </Typography>
                  </div>
                </div>

                {/* Share Percentage */}
                <div className="flex flex-col w-2/4 md:w-full lg:w-1/3 p-2 mb-4">
                  <div>
                    <span className="text-textGrey text-sm md:text-base">
                      Share (in Percentage)
                    </span>
                    <Typography
                      variant={isSmallScreen?"subtitle2":"h6"}
                      color={colors.darkGrey}
                      className="font-bold text-sm md:text-base"
                    >
                      {nominee.percentage}%
                    </Typography>
                  </div>
                </div>
              </div>
              {/* Is Minor */}
              <div className="flex flex-grow w-full md:w-full lg:w-1/3 p-2 mb-4">
                <NomineeAge value={nominee.isMinor} />
              </div>
              {nominee.isMinor === 1 && (
                <>
                  <div className="flex flex-wrap justify-between">
                    {/* Name Input */}
                    <div className="flex flex-grow w-2/4 md:w-full lg:w-1/4 p-2 mb-4">
                      <div>
                        <span className="text-textGrey text-sm md:text-base">
                          Guardian Name
                        </span>
                        <Typography
                          variant={isSmallScreen?"subtitle2":"h6"}
                          color={colors.darkGrey}
                          className="font-bold text-sm md:text-base"
                        >
                          {nominee.guardianDetails
                            ? JSON.parse(nominee.guardianDetails).name
                            : ""}
                        </Typography>
                      </div>
                    </div>

                    {/* Guardian Mobile Number Input */}
                    {/* <div className="flex flex-grow w-full md:w-full lg:w-1/4 p-2 mb-4">
                      <div>
                        <span className="text-textGrey text-sm md:text-base">
                          Guardian Mobile Number
                        </span>
                        <Typography
                          variant="h6"
                          color={colors.darkGrey}
                          className="font-bold text-sm md:text-base"
                        >
                          {nominee.guardianDetails
                            ? JSON.parse(nominee.guardianDetails).mobileNumber
                            : ""}
                        </Typography>
                      </div>
                    </div> */}
                    <div className="flex flex-grow w-2/4 md:w-full lg:w-1/4 p-2 mb-4">
                      <div>
                        <span className="text-textGrey text-sm md:text-base">
                          Guardian Relationship
                        </span>
                        <Typography
                         variant={isSmallScreen?"subtitle2":"h6"}
                          color={colors.darkGrey}
                          className="font-bold text-sm md:text-base"
                        >
                          {nominee.guardianDetails
                            ? typeof nominee.guardianDetails === "string"
                              ? relationshipOptions.find(
                                  (option) =>
                                    option.value ===
                                    Number(
                                      JSON.parse(nominee.guardianDetails)
                                        .relation
                                    )
                                )?.label || "N/A"
                              : relationshipOptions.find(
                                  (option) =>
                                    option.value ===
                                    nominee.guardianDetails.relation
                                )?.label || "N/A"
                            : "N/A"}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap justify-between">
                    {/* <div className="flex flex-grow w-full md:w-full lg:w-1/4 p-2 mb-4">
                      <div>
                        <span className="text-textGrey text-sm md:text-base">
                          Guardian Relationship
                        </span>
                        <Typography
                          variant="h6"
                          color={colors.darkGrey}
                          className="font-bold text-sm md:text-base"
                        >
                          {nominee.guardianDetails
                            ? typeof nominee.guardianDetails === "string"
                              ? relationshipOptions.find(
                                  (option) =>
                                    option.value ===
                                    Number(
                                      JSON.parse(nominee.guardianDetails)
                                        .relation
                                    )
                                )?.label || "N/A"
                              : relationshipOptions.find(
                                  (option) =>
                                    option.value ===
                                    nominee.guardianDetails.relation
                                )?.label || "N/A"
                            : "N/A"}
                        </Typography>
                      </div>
                    </div> */}
                    {/* Pancard Number Input */}
                    <div className="flex flex-grow w-2/4 md:w-full lg:w-1/4 p-2 mb-4">
                      <div>
                        <span className="text-textGrey text-sm md:text-base">
                          Pancard Number
                        </span>
                        <Typography
                         variant={isSmallScreen?"subtitle2":"h6"}
                          color={colors.darkGrey}
                          className="font-bold text-sm md:text-base"
                        >
                          {nominee.guardianDetails
                            ? JSON.parse(nominee.guardianDetails).pancardNumber
                            : ""}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              )}
              </Collapse>
            </div>
          ))):(
            <div className="flex justify-center items-center">
            <Typography
              variant={isSmallScreen?"subtitle2":"h6"}
              fontWeight="bold"
              color={colors.darkGrey}
            >
              No nominee details available.
            </Typography>
          </div>
          )}
        </>
      </div>
    </>
  );
};

export default NomineeForm;
