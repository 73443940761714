import React, { useState } from 'react';
import { Button, Accordion, AccordionDetails, AccordionSummary, ToggleButtonGroup, ToggleButton, Skeleton, ClickAwayListener, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from '../../constants/colors';
import { PieChart } from "@mui/x-charts";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import formatKeyToTitle from '../../utils/formatKeytitle';

const AssetAllocationForMobile = ({ DataBYID, DataBYiSIN, DataBYiSIN2 }: { DataBYID: any, DataBYiSIN: any, DataBYiSIN2:any }) => {
  const [currentTab, setCurrentTab] = useState(1);
  console.log(DataBYiSIN,"DataBYiSIN MF");
  const isSmallScreen = useMediaQuery('(max-width:1024px)');

  const tabs = [
    // { label: 'Asset Allocation', value: 1 },
    { label: 'Equity', value: 1 },
    { label: 'Debt ', value: 2 },
    { label: 'Others', value: 3 }
  ];

  const ChartValue = () => {
    const { indianMarket } = DataBYiSIN || {};
    const labels = ['IndiaLargeCapNet', 'IndiaMidCapNet', 'IndiaSmallCapNet'];

    if (!indianMarket) {
      return [
        { id: 'Investment', value: 50, label: 'Investment' },
        { id: 'Earnings', value: 50, label: 'Earnings' }
      ];
    }

    return labels.map(label => ({
      id: label,
      value: parseFloat(indianMarket[label]) || 50,
      label: formatKeyToTitle(label)
    }));
  };


  const AllAssetChartValue = (allAsset: any) => {
    // console.log('pieChart', allAsset);
    if (!allAsset || Object.keys(allAsset).length === 0) {
      return [
        { id: 'Investment', value: 50, label: 'Investment' },
        { id: 'Earnings', value: 50, label: 'Earnings' }
      ];
    }
    const FilteredPieChartData = Object.entries(allAsset)
      .filter(([key, value]) => Number(value) > 0 && !key.toLowerCase()?.includes('net'));

    // console.log('pieChart after filter', FilteredPieChartData);

    return FilteredPieChartData.map(([key, value]) => ({
      id: key,
      value: parseFloat(value as string) || 50,
      label: formatKeyToTitle(key)
    }));
  };

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', flexDirection:"column"}}>
      <Box sx={{ width: "100%",display:'flex',justifyContent:"end", alignItems:'end' }}>
          <Typography variant="body2" color="text.secondary">{`${(props.value).toFixed(2)}%`}</Typography>
        </Box>
        
        <Box sx={{ width: '100%'}}>
          <LinearProgress variant="determinate" {...props} sx={{
                  width: "100%", // Make sure the progress bar stretches
                  height: 5,
                  borderRadius: 5,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    backgroundColor: "#4caf50",
                  },
                }} />
        </Box>
      </Box>
    );
  }

  const AssetList = ({ data }: { data: any }) => {
    const [showAll, setShowAll] = useState(false);

    // console.log('data by All assets', data.assetAllocation);
    const obj = data.assetAllocation;

    if (Object.keys(obj)?.length === 0) {
      return (
        <Skeleton
          variant="rectangular"
          height={200}
          animation="wave"
          sx={{ bgcolor: colors.lightBg }}
        />
      );
    }

    const assetArray = Object?.entries(obj)?.map(([key, value]) => ({ key, value: parseFloat(value as string) }));

    // AllAssetChartValue(FilteredPieChartData)
    const filteredData = assetArray.filter((item) => item.value > 0);

    if (filteredData.length === 0) {
      return <p>No data found for the specified type.</p>;
    }

    const handleClickAway = () => {
      setShowAll(false);
    };

    // setPieChartforAssetAll(FilteredPieChartData);


    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div className="flex flex-wrap">
            {filteredData?.map(({ key, value }, index) => (
              <div key={index} className="my-4 w-1/2 px-8">
                <AssetItem title={formatKeyToTitle(key)} value={value} />
              </div>
            ))}
          </div>
          <div className="w-full text-center mt-4">
            {!showAll && filteredData?.length > 10 && (
              <div
                onClick={() => setShowAll(true)}
                className="px-4 py-2 text-primary font-bold underline underline-offset-8"
              >
                Load More
              </div>
            )}
            {showAll && (
              <div
                onClick={() => setShowAll(false)}
                className="px-4 py-2 text-primary font-bold underline underline-offset-8 rounded"
              >
                Hide Assets
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  };


  const ListingComponent = ({ data, types }: { data: any, types: string[] }) => {
    const [showAll, setShowAll] = useState(false);

    if (!Array.isArray(data) || data.length === 0) {
      return (
        <Skeleton
          variant="rectangular"
          height={200}
          animation="wave"
          sx={{ bgcolor: colors.lightBg }}
        />
      );
    }

    const filteredData = data.filter((item) => types?.includes(item.Category));
    if (filteredData.length === 0) {
      return <p>No data found for the specified types.</p>;
    }

    const dataToShow = showAll ? filteredData : filteredData.slice(0, 10);

    const handleClickAway = () => {
      setShowAll(false);
    };

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div className="flex flex-wrap">
            {dataToShow.map((item, index) => (
              <div key={index} className="my-4 w-full px-2">
                <AssetItem title={item.Name} value={parseFloat(item?.Weighting || 0)} />
              </div>
            ))}
          </div>
          <div className="w-full text-center mt-4">
            {!showAll && filteredData.length > 10 && (
              <div
                onClick={() => setShowAll(true)}
                className="px-4 py-2 text-primary font-bold underline underline-offset-8"
              >
                Load More
              </div>
            )}
            {showAll && (
              <div
                onClick={() => setShowAll(false)}
                className="px-4 py-2 text-primary font-bold underline underline-offset-8 rounded"
              >
                Hide Assets
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  };

  const AssetItem = ({ title, value }: { title: string; value: number }) => {
    return (
      <>
        <Box
            key={title}
            sx={{
              width:"100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr", // Equal halves
              alignItems: "center",
              gap: 2,
              mb: 2,
            }}
          >
            {/* Label Section - Left Half */}
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, textAlign: "left" }}
            >
              {title}
            </Typography>

            <LinearProgressWithLabel value={value} />
          </Box>
      </>
    );
  };


  const AssetAllocationTab = () => {
    const pieChart = AllAssetChartValue(DataBYiSIN2.assetAllocation);
    console.log('pieChart', pieChart,DataBYiSIN2);

    return (
      <div className="md:w-full items-center justify-center">
        <div className="w-3/4">
          <PieChart
            series={[
              {
                data: pieChart,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{ width: "100%" }}
            height={300}
          />
        </div>
        <AssetList data={DataBYiSIN2} />
      </div>
    )
  };



  const Equity = () => {
    const formatLabel = (key: any) => {
      return key.replace("Net", "").replace(/([A-Z])/g, " $1").trim();
    };
  
    const getNetFields = (indianMarket: Record<string, string | number>) => {
      return Object.entries(indianMarket)
        .filter(([key]) => key.includes("Net"))
        .map(([key, value]) => ({
          label: formatLabel(key),
          value: typeof value === "string" ? parseFloat(value) : value, // Ensure value is a number
        }));
    };
  
    const filteredData = getNetFields(DataBYiSIN2?.indianMarket);
  
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-center mx-auto px-auto">
          <PieChart
            series={[
              {
                data: filteredData?.map((item, index) => ({
                  id: index,
                  value: item.value,
                  label: item.label,
                })),
                innerRadius: 110,
                outerRadius: 140,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              },
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{
              '& .css-ecs132-MuiChartsSurface-root': {
                backgroundColor: '#ff0000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            width={400}
            height={300}  // You can keep this height or adjust as needed
            margin={{ top: 4, right: 5, bottom: 4, left: 5 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </div>
        <ListingComponent data={DataBYiSIN.fundholdings} types={['Stock', 'Preferred']} />
      </div>
    );
  };
  

  const Debt = () => {
    const pieChart = AllAssetChartValue(DataBYiSIN2.assetAllocation);
    console.log(pieChart,"Pie Chart");
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-center mx-auto px-auto">
          <PieChart
            series={[
              {
                data: pieChart,
                innerRadius: 110,
                outerRadius: 140,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{
              '& .css-ecs132-MuiChartsSurface-root': {
                backgroundColor: '#ff0000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            width={400}
            height={300}  // You can keep this height or adjust as needed
            margin={{ top: 4, right: 5, bottom: 4, left: 5 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </div>
        <ListingComponent data={DataBYiSIN.fundholdings} types={['Bond']} />
      </div>
    )
  };

  const Others = () => {
    const pieChart = AllAssetChartValue(DataBYiSIN2.assetAllocation);
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-center mx-auto px-auto">
          <PieChart
            series={[
              {
                data: pieChart,
                innerRadius: 110,
                outerRadius: 140,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{
              '& .css-ecs132-MuiChartsSurface-root': {
                backgroundColor: '#ff0000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            width={400}
            height={300}  // You can keep this height or adjust as needed
            margin={{ top: 4, right: 5, bottom: 4, left: 5 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </div>
        <ListingComponent data={DataBYiSIN.fundholdings} types={['Cash', 'Other']} />
      </div>
    )
  };

  // const handleAlignmentss = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string | null,
  // ) => {
  //   if (newAlignment !== null) {
  //     setAlignment(newAlignment);
  //   }
  // };

  return (    
      <div style={{ padding: 0 , margin :isSmallScreen? "1rem 1rem" : '2rem 0'}}>
        <div className="border border-lightGrey rounded-md">
          <div className='border-b-2 border-primary'>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={currentTab}
              onChange={(event, value) => setCurrentTab(value)}
              aria-label="Platform"
              sx={{
                width: '100%',
                justifyContent: 'center',
                '& .css-1xhpvac-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                  backgroundColor: colors.primary,
                },
                '& .css-233i2o-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                  color: '#fff',
                  backgroundColor: colors.primary
                },
                '& .css-1vwtnbs-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                  color: '#fff',
                  backgroundColor: colors.primary
                }
              }}
            >
              {tabs.map((tab) => (
                <ToggleButton
                  key={tab.value}
                  value={tab.value}
                  sx={{
                    fontWeight: 'bold',
                    fontSize:"12px",
                    color: tab.value ? colors.primary : '#fff',
                    width: tab.value === 1 ? '33.33%' : '33.33%', // Adjust width based on your requirements
                    '&:hover': {
                      backgroundColor: currentTab === tab.value ? 'primary' : 'transparent',
                    }
                  }}
                >
                  {tab.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <div className="my-5 flex flex-col md:flex-row gap-5 justify-between p-4 w-full mx-auto">
            {currentTab === 1 ? <Equity />  : currentTab === 2 ? <Debt /> : <Others />}
          </div>
        </div>
      </div>
  );
};

export default AssetAllocationForMobile;
