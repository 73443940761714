import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface InvestmentDataProps {
  schemeName: string;
  totalAmount: string;
  units?: string; // Only units are required
  cagr?: string;
  path?: any;
}

interface DetailsProps {
  holdingsData: any; // New prop for holdings
}

const SummaryDetails: React.FC<DetailsProps> = ({ holdingsData }) => {
  console.log("holdings data mf", holdingsData);

  // Check if holdingsData is empty
  if (!holdingsData || holdingsData.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <h6 className="font-bold text-lg text-gray-500">No Data Available</h6>
      </div>
    );
  }

  // Check if holdingsData length is greater than 5 for scrollable
  const isScrollable = holdingsData.length > 5;

  return (
    <div
      className={`space-y-2 mt-4 ${isScrollable ? "overflow-y-scroll" : ""}`}
      style={isScrollable ? { maxHeight: "600px" } : {}}
    >
      {holdingsData.map((holding: any, index: number) => (
        <InvestmentData
          key={index}
          schemeName={holding.schemeName} // Ensure 'schemeName' exists in holding
          totalAmount={holding.currentMktValue} // Ensure 'totalAmount' exists in holding
          units={holding.units} // Ensure 'units' exists in holding, if applicable
          cagr={holding.cagr}
          path={holding?.filePath}
        />
      ))}
    </div>
  );
};

const InvestmentData: React.FC<InvestmentDataProps> = ({
  schemeName,
  totalAmount,
  units, // Using units prop
  cagr,
  path,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const handleIconClick = () => {
    navigate("/asset-holdings");
  };

  const formattedTotalAmount = totalAmount
    ? Number(totalAmount).toFixed(2)
    : "0.00";
  const formattedUnits = units ? Number(units).toFixed(2) : "0.00"; // Format units

  const handleDownload = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is missing!");
      return;
    }
  
    const url = `${process.env.REACT_APP_DOWNLOAD_URL}${fileUrl}`;
    console.log("Download URL:", url); // Debugging
  
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileUrl.split("/").pop() || "download");
    link.setAttribute("target", "_blank"); // Ensures proper downloading behavior
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="px-4 py-3 border border-primary rounded-lg">
      <Grid
        container
        spacing={2}
        alignItems="center"
        // justifyContent="space-between" // Ensure even spacing
      >
        {/* Circle with initials and fund name */}
        <Grid item xs={12} sm={7} className="flex items-center">
          <div className="flex flex-row">
            <Grid
              item
              xs={2.5}
              className="bg-lightBg rounded-full flex items-center justify-center text-textGrey font-bold mr-2 text-lg lg:text-2xl p-3 sm:p-0 w-14 h-14"
            >
              {getInitials(schemeName)}
            </Grid>

            <Grid
              item
              xs={9.5}
              className="text-gray-700_01 font-bold text-[0.9rem] text-start sm:text-left p-2"
            >
              {toCamelCase(schemeName)}
            </Grid>
          </div>
        </Grid>

        {/* Investment value */}
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">Current Value</p>
          <p className="font-bold">
            {formatIndianCurrency(formattedTotalAmount)}
          </p>
        </Grid>

        {/* CAGR value */}
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">CAGR</p>
          <p className="font-bold">{cagr}</p>
        </Grid>

        {/* Arrow Icon */}
        {path!="" && (<Grid
          item
          xs={12}
          sm={1} // Reduce width for icon
          className="hidden sm:flex md:justify-start justify-center" // Flex alignment
        >
          <p
            className="text-gray-700_01 cursor-pointer m-0" // Remove margin
            style={{ fontSize: "1.5rem" }} // Adjust font size
          >
            <IconButton onClick={() => handleDownload(path)}>
            <VisibilityIcon />
            </IconButton>
          </p>
        </Grid>)}
        <Grid
          item
          xs={12}
          sm={1} // Reduce width for icon
          className="flex sm:hidden md:justify-start justify-center text-2xl" // Flex alignment
        >
          <button
            className="text-xs px-2 py-1 flex justify-center items-center font-medium text-white bg-primary hover:bg-secondary rounded-lg" // Remove margin
            onClick={handleIconClick}
            // style={{ fontSize: "1.5rem" }} // Adjust font size
          >
            View More
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryDetails;
