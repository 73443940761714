import React from "react";
import { motion, useInView } from "framer-motion";
import investors from "../../assets/images/users/investors.jpg";
import family_offices from "../../assets/images/users/family_offices.jpg";
import corporates from "../../assets/images/users/corporates.png";
import hni from "../../assets/images/users/hni.jpg";
import fa from "../../assets/images/users/fa.jpg";

const UserGroupCard = ({ image, title, description, index }: any) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    whileHover={{ scale: 1.02 }}
    className="group relative overflow-hidden rounded-2xl"
  >
    <div className="absolute inset-0 bg-gradient-to-t from-primary/90 via-primary/50 to-transparent z-10 transition-opacity group-hover:opacity-90 opacity-80"></div>
    <img
      src={image}
      alt={title}
      className="w-full h-60 object-cover transform transition-transform duration-700 group-hover:scale-110"
    />
    <div className="absolute bottom-0 left-0 right-0 p-8 z-20">
      <h3 className="text-2xl font-bold text-white mb-3">{title}</h3>
      {/* <p className="text-white/90 text-sm transform transition-all duration-300 opacity-0 translate-y-4 group-hover:opacity-100 group-hover:translate-y-0">
        {description}
      </p> */}
    </div>
  </motion.div>
);

const UserGroupsSection = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  const userGroups = [
    {
      image: investors,
      title: "Investors",
      description:
        "Comprehensive tools for individual investors to manage and grow their portfolios",
    },
    {
      image: family_offices,
      title: "Family Offices",
      description:
        "Sophisticated solutions for managing multi-generational wealth and investments",
    },
    {
      image: corporates,
      title: "Corporates",
      description:
        "Enterprise-level tools for corporate treasury and investment management",
    },
    {
      image: hni,
      title: "HNI/Ultra HNI",
      description:
        "Exclusive services tailored for high net worth individuals and their unique needs",
    },
    {
      image: fa,
      title: "Financial Advisors",
      description:
        "Exclusive services tailored for high net worth individuals and their unique needs",
    },
  ];

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
      className="py-24 bg-white relative overflow-hidden"
    >
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-primary">Who We Serve</h2>
          <p className="text-xl text-darkGrey">
            Our Product Caters to Different User Groups
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {userGroups.map((group, index) => (
            <UserGroupCard key={index} {...group} index={index} />
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default UserGroupsSection;
