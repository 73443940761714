import { Backdrop, Box, Fade, Modal, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface AddPasswordModalProps {
    title: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    onClick: () => void;
    onClose:()=>void;
}


const AddPasswordModal = ({ title, showModal, setShowModal, password, setPassword, onClick,onClose }: AddPasswordModalProps) => {
    const isSmallScreen=useMediaQuery("(max-width: 640px)");
    const boxStyle = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmallScreen ? '90%' : 500,
        bgcolor: "background.paper",
        border: "2px solid rgb(237, 237, 237)",
        boxShadow: 24,
        borderRadius: "8px",
        p:isSmallScreen? 2 : 4,
    };

    // Function to handle key down event
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClick(); // Call the onClick function to verify OTP
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            onClose={(e, reason) => {
                // Prevent modal from closing when clicking outside
                if (reason !== "backdropClick") {
                   onClose();
                }
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Fade in={showModal}>
                <Box sx={boxStyle}>
                     {/* Close Icon */}
                     <button
                        onClick={onClose}
                        style={{
                            position: "absolute",
                            top:isSmallScreen? "6px" : "10px",
                            right:isSmallScreen ? "8px" : "15px",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                        }}
                        className="text-darkGrey"
                    >
                        <CloseIcon />
                    </button>
                    <Typography
                        id="transition-modal-title"
                        variant={isSmallScreen? "subtitle1" : "h6" }
                        component="h2"
                        sx={{mt:isSmallScreen?2:0}}
                    >
                        {title}
                    </Typography>
                    <div
                        id="transition-modal-description"
                        className="flex gap-x-3 py-5 justify-between"
                    >
                        {/* Input field for OTP */}
                        <input
                            type="text"
                            placeholder="Enter Password"
                            className="border border-solid border-lightGrey text-[13px] sm:text-lg px-2 py-1 sm:p-3 rounded-md w-[63%] sm:w-[68%]"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown} // Add onKeyDown handler here
                        />
                        {/* Button for verifying OTP */}
                        <button
                            type="button"
                            className="px-2 py-1 sm:px-4 sm:py-2 text-[12px] sm:text-sm bg-primary hover:bg-secondary text-white rounded"
                            onClick={onClick}
                        >
                            Add Password
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddPasswordModal;
