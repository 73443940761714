import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import { colors } from "../../constants/colors";

interface TabData {
  label: string;
  component?: React.ReactNode;
  isLogout?: boolean;
}

interface TabsComponentProps {
  tabs: TabData[];
  onLogout: () => void;
  activeSection: string;
  setActiveSection: (section: string) => void;
}

const TabsComponentForOnboarding: React.FC<TabsComponentProps> = ({
  tabs,
  activeSection,
  setActiveSection,
  onLogout,
}) => {
  const [completedSections, setCompletedSections] = useState<boolean[]>(
    new Array(tabs.length).fill(false)
  );

  const value = tabs.findIndex((tab) => tab.label.toLowerCase() === activeSection.toLowerCase());

  useEffect(() => {
    if (value === -1) {
      setActiveSection(tabs[0]?.label.toLowerCase());
    }
  }, [activeSection, tabs, setActiveSection]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (tabs[newValue]?.isLogout) {
      onLogout();
    } else {
      if (newValue <= value || completedSections[newValue - 1]) {
        // Allow navigation if moving back or previous section is completed
        setActiveSection(tabs[newValue].label.toLowerCase());

        // Mark the previous tab as completed if moving forward
        if (newValue > value) {
          setCompletedSections((prev) => {
            const updated = [...prev];
            updated[value] = true;
            return updated;
          });
        }
      }
    }
  };

  const isSmallScreen = useMediaQuery("(max-width:640px)");

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs Navigation */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTabs-indicator": {
            backgroundColor: "primary.main",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              fontSize: isSmallScreen ? "0.8rem" : "1rem",
              textTransform: "none",
              color: value === index ? "primary.main" : "text.primary",
              backgroundColor: value === index ? "white" : "transparent",
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
            disabled={index > 0 && !completedSections[index - 1] && index > value} // Disable only uncompleted future tabs
          />
        ))}
      </Tabs>

      {/* Render only the active tab's component */}
      <Box sx={{ width: "100%",backgroundColor: colors.lightBg}}>
        {tabs[value]?.component && (
          <div className="w-full max-h-screen overflow-y-auto rounded-lg scrollbar-hide">
            {tabs[value].component}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default TabsComponentForOnboarding;
