import React from 'react';
import { motion } from 'framer-motion';

const blogs = [
  {
    title: 'WIDENING Spread between CORPORATE BONDS & G-SECS: Invest in corporate bond funds for higher yields',
    excerpt: 'The new financial year 2024-25, which is going to be an event-heavy one with Lok Sabha...',
    image: 'https://images.unsplash.com/photo-1579532537598-459ecdaf39cc?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    date: 'March 15, 2024',
    url: "https://www.financialexpress.com/market/widening-spread-between-corporate-bonds-amp-g-secs-invest-in-corporate-bond-funds-for-higher-yields-3449513/"
  },
  {
    title: 'FY25 begins with a bang for Nifty; time for portfolio revamp to avoid base effect bite later',
    excerpt: 'Discover how SIPs can help you build long-term wealth through disciplined investing...',
    image: 'https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    date: 'March 10, 2024',
    url: "https://economictimes.indiatimes.com/markets/stocks/news/fy25-begins-with-a-bang-for-nifty-time-for-portfolio-revamp-to-avoid-base-effect-bite-later/articleshow/108961047.cms?from=mdr"
  },
  {
    title: 'NFOs in FY24: Total 196 offerings, multi asset allocation fund emerged as new flavour — what lies ahead',
    excerpt: 'Explore alternative investment opportunities to diversify your investment portfolio...',
    image: 'https://images.unsplash.com/photo-1559526324-4b87b5e36e44?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
    date: 'March 5, 2024',
    url: "https://www.cnbctv18.com/personal-finance/nfo-new-mutual-fund-offers-fy24-equity-index-etf-gold-multi-asset-fy25-outlook-collection-19390277.htm"
  }
];

const Blog = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-[#255288]">Latest Insights</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="h-48 overflow-hidden">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-500"
                />
              </div>
              <div className="p-6">
                {/* <p className="text-sm text-gray-500 mb-2">{blog.date}</p> */}
                <h3 className="text-xl font-bold mb-3 text-[#255288]">{blog.title}</h3>
                <p className="text-gray-600">{blog.excerpt}</p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  className="mt-4 text-[#F17A21] font-semibold hover:text-[#255288]"
                  onClick={() => window.open(blog.url, "_blank")}
                >
                  Read More →
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;