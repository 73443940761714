import React from 'react';
import { motion } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import Logo from "../../assets/images/logo.svg";
import { useLocation, useNavigate } from 'react-router-dom';

interface HeaderProps {
  onPartnerClick: () => void;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
}

const HeaderLanding: React.FC<HeaderProps> = ({ onPartnerClick, isMobileMenuOpen, setIsMobileMenuOpen }) => {
  const navigate = useNavigate();

  const scrollToSection = (id: string) => {
    navigate("/"); // Ensure navigation to home first
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        const navbarHeight = document.querySelector("header")?.offsetHeight || 80;
        window.scrollTo({
          top: section.offsetTop - navbarHeight - 10, // Adjust for navbar
          behavior: "smooth",
        });
      }
    }, 100);
  };
  

  return (
    <header className="fixed w-full bg-white shadow-md z-50" id="header">
      <div className="container mx-auto px-4 ">
        <div className="flex items-center justify-between">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex items-center cursor-pointer"
            onClick={() => {
              navigate("/")
              window.scrollTo(0, 0);
            }}
          >
            <img src={Logo} alt="Aum Sampann" className="h-16 my-2" />
          </motion.div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            <button
              onClick={() => scrollToSection("team")}
              className={`pr-4 py-2 transition-colors text-[#255288] hover:text-[#F17A21]`}
            // whileHover={{ scale: 1.05 }}
            >
              Team
            </button>
            <button
              onClick={() => scrollToSection("products")}
              className={`pr-4 py-2 transition-colors text-[#255288] hover:text-[#F17A21]`}
            // whileHover={{ scale: 1.05 }}
            >
              Products
            </button>
            <motion.button
              onClick={() => navigate("/login")}
              className="px-6 py-2 bg-[#F17A21] text-white rounded-md hover:bg-[#255288] transition-colors"
              whileHover={{ scale: 1.05 }}
            >
              Login as Investor
            </motion.button>
            <motion.button
              className="px-6 py-2 bg-[#255288] text-white rounded-md hover:bg-[#F17A21] transition-colors"
              whileHover={{ scale: 1.05 }}
              onClick={() => window.location.href = "https://admin.aumsampann.com/"}
            >
              Login as Partner
            </motion.button>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-[#255288]"
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="md:hidden mt-4 space-y-4 mb-4"
          >
            <button
              onClick={() => {
                scrollToSection("team");
                setIsMobileMenuOpen(false);
              }}
              className="block px-4 py-2 text-[#255288] hover:text-[#F17A21]"
            >
              Team
            </button>
            <button
              onClick={() => {
                scrollToSection("products");
                setIsMobileMenuOpen(false);
              }}
              className="block px-4 py-2 text-[#255288] hover:text-[#F17A21]"
            >
              Products
            </button>
            <button
              onClick={() => {
                navigate("/login");
                setIsMobileMenuOpen(false);
              }}
              className="block w-full px-4 py-2 bg-[#F17A21] text-white rounded-md hover:bg-[#255288]"
            >
              Login as Investor
            </button>
            <button
              onClick={() => {
                window.location.href = "https://admin.aumsampann.com/";
                setIsMobileMenuOpen(false);
              }}
              className="block w-full px-4 py-2 bg-[#255288] text-white rounded-md hover:bg-[#F17A21]"
            >
              Login as Partner
            </button>
          </motion.div>
        )}
      </div>
    </header>
  );
};

export default HeaderLanding;