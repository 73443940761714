import React from 'react';
import { Box, Card, Typography, Button, useMediaQuery } from '@mui/material';
import { colors } from '../../constants/colors';
import { useUserData } from "../../hooks/useUserData";

const ReachOut: React.FC = () => {
    const user= useUserData();
    console.log(user,"Reach Out");
    const relationshipManager = user?.relationManager;

    const referralLink = "https://aumsampann.referrals.com";

    // Function to get the initials from the manager's name
    const getInitials = (name: string) => {
        const names = name?.split(' ');
        return names?.map(n => n[0]).join('');
    };

    const isSmallScreen = useMediaQuery("(max-width:640px)");

    return (
        <Box className='border-2 border-lightGrey rounded-lg'>
            <Card style={{ backgroundColor: colors.lightBg, minHeight: isSmallScreen?'50px':'60px', display: 'flex', alignItems: 'center', padding: '0' }} className='rounded-md h-8 w-full'>
            <Typography variant={isSmallScreen?"subtitle1":"h5"} marginLeft={2} color={colors.darkGrey} fontWeight='bold'>Reach Out</Typography>
            </Card>
            {getInitials(relationshipManager?.firstName)?(<><Box p={2}>
                <Typography variant={isSmallScreen?"subtitle1":'h6'} sx={{ fontWeight: 'bold', color: colors.darkGrey}}>Relationship Manager</Typography>
                <Box 
                    className='mt-4' 
                    style={{ backgroundColor: colors.lightBg, padding: '16px', borderRadius: '8px' }}
                >
                    <div className='flex sm:flex-row flex-col sm:items-center justify-start sm:justify-between mr-0 sm:mr-36 gap-y-5 sm:gap-0'>
                        {/* Circle with initials */}
                        <Box display='flex' flexDirection={'row'}>
                            {!isSmallScreen && (<Box
                                display='flex'
                                alignItems={'center'}
                                justifyContent={'space-evenly'}
                                style={{
                                    borderRadius: '50%',
                                    width:isSmallScreen?'30px': '50px',
                                    height:isSmallScreen?'30px': '50px',
                                    backgroundColor: colors.primary,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize:isSmallScreen?'14px': '20px',
                                    marginRight: '16px'
                                }}
                            >
                                {getInitials(relationshipManager?.firstName)}
                            </Box>)}
                            <div className='flex flex-col justify-start'>
                                <Typography variant={isSmallScreen?'body2':'body1'}><strong>{relationshipManager?.firstName}</strong></Typography>
                                <Typography variant='body2' color='textSecondary'>Experience: {relationshipManager?.experience ? relationshipManager?.experience : "N/A"}</Typography>
                            </div>
                        </Box>
                        <div className="flex flex-col justify-start">
                            <Typography variant='body2' color='textSecondary'><strong>Contact</strong></Typography>
                            <Typography variant='body2' color='textSecondary'>{relationshipManager?.mobile}</Typography>
                        </div>
                        <div className="flex flex-col justify-start">
                            <Typography variant='body2' color='textSecondary'><strong>Email</strong></Typography>
                            <Typography variant='body2' color='textSecondary'>{relationshipManager?.email}</Typography>
                        </div>
                    </div>
                </Box>
            </Box>

            <Box p={2} mb={4}>
                <Typography variant={isSmallScreen?"subtitle2":'h6'} fontWeight={'bold'} style={{ color: colors.darkGrey}}>Support</Typography>
                <Typography variant={isSmallScreen?"body2":'body1'} gutterBottom>Our 24x7 customer care: +91 {relationshipManager?.mobile}</Typography>
                <Typography variant={isSmallScreen?"body2":'body1'} gutterBottom>Write to us at: {relationshipManager?.email}</Typography>
                {/* <Typography variant='body2' color='primary' component='a' href='#'>Terms & Conditions</Typography><br />
                <Typography variant='body2' color='primary' component='a' href='#'>Privacy policy</Typography> */}
            </Box></>):(<>
                <div className='justify-center'>
                    <Typography variant={isSmallScreen?"body2":'body1'} className='p-4 text-center text-gray-600'>No reach out details available.</Typography>
                </div>
            </>)}


            {/* <Box p={2} mb={4} className='border border-lightGrey rounded-lg'>
                <Typography variant='h6'>Referrals</Typography>
                <Typography variant='body1' gutterBottom>Refer a friend and earn 10% of the brokerage they pay & 300 reward points when they open an account.</Typography>
                <Box display='flex' alignItems='center'>
                    <input
                        type='text'
                        value={referralLink}
                        readOnly
                        style={{ flex: 1, marginRight: '8px', padding: '8px', borderRadius: '4px', border: '1px solid lightgrey' }}
                    />
                    <Button variant='contained' color='primary' onClick={() => navigator.clipboard.writeText(referralLink)}>Copy</Button>
                </Box>
            </Box> */}
        </Box>
    );
};

export default ReachOut;
