import React from 'react';

const PrivacyPolicyAndTerms = () => {
  return (
    <div className="max-w-4xl mx-auto p-8">
      <div className="border-4 border-gray-300 p-6  shadow-sm">
        <h1 className="text-3xl font-bold text-primary mb-6">Privacy Policy & Terms and Conditions</h1>

        <p className="mb-4">
          This policy applies to this website which is operated by FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED. (ACMPL) under the domain name 
          <a href="https://ibt.aumtrading.com" className="text-primary hover:underline"> https://ibt.aumtrading.com</a>. 
          We at ACMPL understand your concern for confidentiality and security of information that we may gain about you online. 
          FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED is committed to protecting your privacy. This Privacy and Terms & Conditions statement explains how your personal 
          information will be treated as you access and interact with the ACMPL website. The personal information that we collect may be classified into three parts:
        </p>

        <ul className="list-disc ml-6 mb-4">
          <li>Personally Identifiable Information</li>
          <li>Transaction Details</li>
          <li>System Information</li>
        </ul>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">1. Personally Identifiable Information</h2>
        <p className="mb-4">
          This comprises the information at the time of opening the account or registering online. The information includes the name, address, phone number, 
          email address, and other such information as is required. from time to time based on regulatory or other requirements.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">2. Transaction Details</h2>
        <p className="mb-4">
          Information regarding your investments, including balances, positions, and the history of transactions available with us.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">3. System Information</h2>
        <p className="mb-4">
          Information about computer hardware and software including information like your IP address, browser type, domain names, access times, 
          and referring website addresses, etc., that may be collected by <a href="https://ibt.aumtrading.com" className="text-primary hover:underline">https://ibt.aumtrading.com</a>.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Protection of Information</h2>
        <p className="mb-4">
          We will protect the Personally Identifiable Information, transaction details, and system info with a reasonable degree of care, 
          to prevent unauthorized use, dissemination, or publication of this information as we protect our own confidential information of a similar nature.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Disclosure of Information</h2>
        <p className="mb-4">
          We would not disclose the personal information to third parties, except under the following circumstances:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>For offering products and/or services by ACMPL and its affiliates.</li>
          <li>To companies providing support services for processing application and transactions.</li>
          <li>Under regulations of Indian laws or to any governmental agency or regulatory bodies.</li>
          <li>To protect our rights or property.</li>
        </ul>

        <p className="mb-4">
          ACMPL will ensure that any party such as an agent or employee to whom it may be necessary to disclose personal information, in turn, respects the confidentiality of such information.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Security Measures</h2>
        <p className="mb-4">
          We constantly update our security systems to ensure that any confidential information communicated to us remains safe. 
          We use an advanced 128-bit encryption technology (Secure Socket Layer or SSL) to ensure that information transmitted between you and us across the Internet is safe.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Login Security</h2>
        <p className="mb-4">
          To ensure security of access to the personal information and transaction details, the access to the secured zone of our website is restricted by a unique Login ID and Password. 
          Clients should handle the ID and Password carefully and ensure not to reveal them to anyone.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Cookies</h2>
        <p className="mb-4">
          We use “cookies” as part of our interaction with internet browsers. These cookies will expire the moment clients log off the system and would not be accessible to any other application.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">E-mail Communication</h2>
        <p className="mb-4">
          We will preserve the contents of any e-mail that clients send us if we believe that we have a legal requirement to do so. Any personal information in e-mail will be used or disclosed as outlined in this privacy statement.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Legal Disclaimers</h2>
        <p className="mb-4">
          ACMPL may disclose clients' personal information where we believe in good faith that disclosure is required. under the law, to cooperate with law enforcement authorities, or protect our rights.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Changes to this Policy</h2>
        <p className="mb-4">
          ACMPL may, at any time, vary the privacy and security statement by publishing the varied privacy and security statement on the FINGEEK WEALTH SOLUTIONS Website. 
          By using this website, you acknowledge and accept that use of the FINGEEK WEALTH SOLUTIONS website indicates acceptance of this Privacy and Terms & Conditions Statement.
        </p>

        <h2 className="text-2xl font-semibold text-primary mt-8 mb-4">Contact Us</h2>
        <p className="mb-4">
          For any concerns regarding privacy or the use of your information, you can contact us at 
          <a href="mailto:helpdesk@aumcap.com" className="text-primary hover:underline"> helpdesk@aumcap.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyAndTerms;
