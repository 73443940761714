import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Card,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Button,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import { colors } from "../../constants/colors";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  addPreferences,
  getUserById,
  updateProfile,
} from "../../redux/user/userSlice";

interface PreferencesState {
  assets: {
    mutualFunds: boolean;
    bonds: boolean;
    aif: boolean;
    pms: boolean;
    equity: boolean;
    corporateFD: boolean;
    isDirect: boolean;
    isRegular: boolean;
  };
  investmentPlan: "isDirect" | "isRegular";
  theme: "default" | "dark";
  notifications: {
    whatsapp: boolean;
    email: boolean;
    sms: boolean;
    inApp: boolean;
  };
}

const Preferences: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [userData, setUserData] = useState<any>({});
  const [userID, setUserID] = useState("");

  const [preferences, setPreferences] = useState<PreferencesState>({
    assets: {
      mutualFunds: false,
      bonds: false,
      aif: false,
      pms: false,
      equity: false,
      corporateFD: false,
      isDirect: false,
      isRegular: false,
    },
    investmentPlan: "isRegular",
    theme: "default",
    notifications: {
      whatsapp: false,
      email: false,
      sms: false,
      inApp: false,
    },
  });
  const isSmallScreen = useMediaQuery("(max-width:640px)");

  // Fetch user preferences on component mount
  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const response = await dispatch(getUserById({}));
        setUserData(response.payload.data[0].preferences);
        const Id = response.payload.data[0].preferences.id.toString();
        setUserID(Id);
        const Data = response.payload.data[0];
        // console.log(userData.preferences, 'this is user data');

        // Map API response to state format
        setPreferences({
          assets: {
            mutualFunds: Data.preferences.mutualFunds === 1,
            bonds: Data.preferences.bonds === 1,
            aif: Data.preferences.aif === 1,
            pms: Data.preferences.pms === 1,
            equity: Data.preferences.equity === 1,
            corporateFD: Data.preferences.corporateFD === 1,
            isDirect: Data.preferences.isDirect === 1,
            isRegular: Data.preferences.isRegular === 1,
          },
          investmentPlan:
            Data.preferences.regular === 1 ? "isRegular" : "isDirect",
          theme: "default", // Assuming theme is not part of the fetched data
          notifications: {
            whatsapp: false, // Assuming this is not part of the fetched data
            email: false, // Assuming this is not part of the fetched data
            sms: false, // Assuming this is not part of the fetched data
            inApp: false, // Assuming this is not part of the fetched data
          },
        });
      } catch (error) {
        console.error("Failed to fetch user preferences:", error);
      }
    };

    fetchUserPreferences();
  }, [dispatch]);

  // console.log(userData,userID, "User Data");

  const handleAssetChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPreferences((prevState) => ({
      ...prevState,
      assets: {
        ...prevState.assets,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const handleInvestmentPlanChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      investmentPlan: event.target.value as "isDirect" | "isRegular",
    });
  };

  const handleThemeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      theme: event.target.value as "default" | "dark",
    });
  };

  const handleNotificationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      notifications: {
        ...preferences.notifications,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleSubmit = async () => {
    const updatedPreferences: Array<{
      updateColumn: string;
      updateValue: number;
      oldValue: number;
    }> = [];

    // Compare current preferences with original data and push changes to updatedPreferences array
    const assetFields = [
      { field: "mutualFunds", label: "mutualFunds" },
      { field: "corporateFD", label: "corporateFD" },
      { field: "pms", label: "pms" },
      { field: "aif", label: "aif" },
      { field: "bonds", label: "bonds" },
      { field: "equity", label: "equity" },
    ];

    assetFields.forEach(({ field, label }) => {
      const newValue = preferences.assets[
        field as keyof typeof preferences.assets
      ]
        ? 1
        : 0;
      const oldValue = userData[label];

      if (newValue !== oldValue) {
        updatedPreferences.push({
          updateColumn: label,
          updateValue: newValue,
          oldValue: oldValue,
        });
      }
    });

    // Direct and regular investment plans (always included)
    updatedPreferences.push({
      updateColumn: "direct",
      updateValue: preferences.investmentPlan === "isDirect" ? 1 : 0,
      oldValue: userData?.direct,
    });

    updatedPreferences.push({
      updateColumn: "regular",
      updateValue: preferences.investmentPlan === "isRegular" ? 1 : 0,
      oldValue: userData?.regular,
    });

    // Construct the data object to send to the API
    const data = {
      KYC: {},
      USER: {},
      NOMINEE: {},
      BANK: {},
      PREF: {
        [userID]: updatedPreferences,
      },
    };

    console.log("Data to send:", data);

    try {
      const response = await dispatch(updateProfile(data)); // Send the data payload to the API
      toast.success("Preferences sent to Relation Manager successfully!");
      console.log("API Response:", response);
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Response not sent. Something went wrong!");
    }
  };

  return (
    <Box className="border-2 border-lightGrey rounded-lg">
      <Card
        style={{
          backgroundColor: colors.lightBg,
          minHeight: isSmallScreen ? "50px" : "60px",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }}
        className="rounded-md h-8 w-full"
      >
        <Typography
          variant={isSmallScreen ? "subtitle1" : "h5"}
          marginLeft={2}
          color={colors.darkGrey}
          fontWeight="bold"
        >
          Preferences
        </Typography>
      </Card>

      <Box p={2} mb={1}>
        <Typography variant={isSmallScreen ? "subtitle2" : "h6"}>
          User Preferences
        </Typography>
        <Typography
          variant={isSmallScreen ? "subtitle2" : "body1"}
          gutterBottom
        >
          What types of assets are you interested in investing in?
        </Typography>
        <Box display="flex" flexWrap="wrap" mb={isSmallScreen ? 1 :2}>
          {!preferences.assets.mutualFunds && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.assets.mutualFunds}
                  onChange={handleAssetChange}
                  name="mutualFunds"
                  sx={{
                    transform: "scale(0.9)", // Reduces checkbox size
                    padding: "4px", // Adjusts padding
                  }}
                />
              }
              sx={{
                "& .MuiTypography-root": {
                  fontSize: isSmallScreen ? "0.75rem" : "1.2rem", // Adjust text size
                },
              }}
              label="Mutual Funds"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={preferences.assets.bonds}
                onChange={handleAssetChange}
                name="bonds"
                sx={{
                  transform: "scale(0.9)", // Reduces checkbox size
                  padding: "4px", // Adjusts padding
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: isSmallScreen ? "0.75rem" : "1.2rem", // Adjust text size
              },
            }}
            label="Bonds"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={preferences.assets.aif}
                onChange={handleAssetChange}
                name="aif"
                sx={{
                  transform: "scale(0.9)", // Reduces checkbox size
                  padding: "4px", // Adjusts padding
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: isSmallScreen ? "0.75rem" : "1.2rem", // Adjust text size
              },
            }}
            label="AIF"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={preferences.assets.pms}
                onChange={handleAssetChange}
                name="pms"
                sx={{
                  transform: "scale(0.9)", // Reduces checkbox size
                  padding: "4px", // Adjusts padding
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: isSmallScreen ? "0.75rem" : "1.2rem", // Adjust text size
              },
            }}
            label="PMS"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={preferences.assets.equity}
                onChange={handleAssetChange}
                name="equity"
                sx={{
                  transform: "scale(0.9)", // Reduces checkbox size
                  padding: "4px", // Adjusts padding
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: isSmallScreen ? "0.75rem" : "1.2rem", // Adjust text size
              },
            }}
            label="Equity"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={preferences.assets.corporateFD}
                onChange={handleAssetChange}
                name="corporateFD"
                sx={{
                  transform: "scale(0.9)", // Reduces checkbox size
                  padding: "4px", // Adjusts padding
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: isSmallScreen ? "0.75rem" : "1.2rem", // Adjust text size
              },
            }}
            label="FD"
          />
        </Box>
        {/* <Typography variant="body1" gutterBottom>
          Which investment plan do you prefer for mutual funds:
        </Typography>
        <RadioGroup
          row
          value={preferences.investmentPlan}
          onChange={handleInvestmentPlanChange}
        >
          <FormControlLabel
            value="isDirect"
            control={<Radio />}
            label="Direct"
          />
          <FormControlLabel
            value="isRegular"
            control={<Radio />}
            label="Regular"
          />
        </RadioGroup> */}
      </Box>

      {/* <Box p={2} mb={4}>
        <Typography variant="h6">System Preferences</Typography>
        <Typography variant="body1" gutterBottom>
          Theme
        </Typography>
        <RadioGroup row value={preferences.theme} onChange={handleThemeChange}>
          <FormControlLabel
            value="default"
            control={<Radio />}
            label="Default"
          />
          <FormControlLabel value="dark" control={<Radio />} label="Dark" />
        </RadioGroup>
      </Box> */}

      <Box component={"div"} p={2} mb={isSmallScreen ? 2 :4} className="">
        <Typography
          variant={isSmallScreen ? "subtitle2" : "h6"}
          className="text-lightGrey"
        >
          Notifications
        </Typography>
        <Typography
          variant={isSmallScreen ? "body2" : "body1"}
          gutterBottom
          className="text-lightGrey"
        >
          Please choose how you'd like to receive notifications
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div className="flex w-full md:w-2/5 justify-between items-center">
            <InputLabel disabled htmlFor="input-with-icon-adornment">
              WhatsApp
            </InputLabel>
            <Switch
              disabled
              color="success"
              checked={preferences.notifications.whatsapp}
              onChange={handleNotificationChange}
              name="whatsapp"
            />
          </div>
          <div className="flex w-full md:w-2/5 justify-between items-center">
            <InputLabel disabled htmlFor="input-with-icon-adornment">
              Email
            </InputLabel>
            <Switch
              disabled
              color="success"
              checked={preferences.notifications.email}
              onChange={handleNotificationChange}
              name="email"
            />
          </div>

          <div className="flex w-full md:w-2/5 justify-between items-center">
            <InputLabel disabled htmlFor="input-with-icon-adornment">
              SMS
            </InputLabel>
            <Switch
              disabled
              color="success"
              checked={preferences.notifications.sms}
              onChange={handleNotificationChange}
              name="sms"
            />
          </div>
          <div className="flex w-full md:w-2/5 justify-between items-center">
            <InputLabel disabled htmlFor="input-with-icon-adornment">
              In-App
            </InputLabel>
            <Switch
              disabled
              color="success"
              checked={preferences.notifications.inApp}
              onChange={handleNotificationChange}
              name="inApp"
            />
          </div>
        </Box>
      </Box>

      <Box p={2} mb={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            fontSize: isSmallScreen?"0.75rem":"1rem", // Adjust text size
            padding: "6px 12px", // Adjust padding
            minWidth: "120px", // Set a minimum width
            height:isSmallScreen? "30px":"36px", // Set height
          }}
        >
          Send Preferences
        </Button>
      </Box>
    </Box>
  );
};

export default Preferences;
