import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import {
  addSignature,
  createBSEUser,
  getAUFForm,
  getUserById,
  verifyBseOTP,
} from "../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import AUFFormPDF from "../../../assets/forms/AUF_Form.pdf";
import VideoRecorder from "../../../components/VideoRecorder/VideoRecorder";
import {
  eSign_kyc,
  uploadAufForm,
  uploadVideo,
} from "../../../redux/eSign/eSignSlice";
import toast from "react-hot-toast";
import userService from "../../../redux/user/userService";
import { checkFileSizeAndReset } from "../../../utils/index";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import { hideLoading, showLoading } from "../../../redux/loader/loaderSlice";
import SignatureCanvas from "../../../components/Signature/SignatureCanvas";
import { ToggleButtonGroup, ToggleButton, useMediaQuery } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { Button } from "../../../components";

// Utility function to generate a random code
const generateRandomCode = (length = 6) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

interface SignFormProps {
  allowToProceed: () => void;
  userData: any;
}

interface InputFieldProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  onChange: any;
}

const FileInputField: React.FC<InputFieldProps> = ({ label, onChange }) => {
  const [fileName, setFileName] = React.useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = checkFileSizeAndReset(file, event); // Validate the file size
      if (isValid) {
        setFileName(file.name); // Update the displayed file name
      } else {
        setFileName(null); // Reset the displayed file name if invalid
      }
    }
  };

  return (
    <div
      className="flex flex-col mt-10 mb-10 grow text-lg"
      style={{ color: colors.darkGrey }}
    >
      <div className="mb-5 text-base sm:text-base md:text-xl">{label}</div>
      <label
        className="shrink-0 rounded-lg p-3 flex items-center justify-between "
        style={{
          color: colors.darkGrey,
          backgroundColor: colors.white,
          border: `1px solid ${colors.darkBg}`,
        }}
      >
        <input
          type="file"
          className="hidden sm:w-full"
          style={{ color: colors.white }}
          onChange={(e) => {
            handleFileChange(e);
            onChange(e);
          }}
        />
        <span>{fileName ? fileName : ""}</span>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b68ad659401f77e25e3e5619f719db2c16e9ada8836fddb64d373e828ce07709?apiKey=427d052184b84d30b7eba5c7e76648a1&" // Update with your image path
          alt="Upload Icon"
          className="h-10 w-10 ml-2 cursor-pointer"
        />
      </label>
    </div>
  );
};

const SignForm: FC<SignFormProps> = ({ allowToProceed, userData }) => {
  const [signatureDoc, setSignatureDoc] = useState<any>();
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [showAUFForm, setShowAUFForm] = useState<boolean>(false);
  const [KycDone, setKycDone] = useState<boolean>(false);
  const [AUFForm, setAUFForm] = useState<any>({});
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [recordedVideo, setRecordedVideo] = useState<Blob | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [longitude, setLongitude] = useState<number>(0); // Add longitude state
  const [latitude, setLatitude] = useState<number>(0); // Add latitude state

  const handleLocationChange = (latitude: number, longitude: number) => {
    // Use the longitude and latitude values as needed
    setLatitude(latitude);
    setLongitude(longitude);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = () => {
    // Check if signature and video are uploaded before allowing modal to open
    if (!signatureDoc) {
      toast.error("Please upload a signature before proceeding.");
      return;
    }

    // if (!recordedVideo) {
    //   toast.error("Please record a verification video before proceeding.");
    //   return;
    // }
    // Open the modal when the button is clicked
    setIsModalVisible(true);
  };

  // Function to handle saving signature file
  const handleSignatureSave = (file: File) => {
    setSignatureDoc(file);
    setSignatureUrl(URL.createObjectURL(file)); // Generate URL for the saved signature image
  };

  //check kyc is done or not(Signature and Video)
  useEffect(() => {
    const kycCheck = async () => {
      try {
        const response = await userService.getUserById();
        console.log(response?.data[0]?.kycVideo, "123");
        if (response?.data[0]?.isSignatureAdd === 1) {
          setKycDone(true);
        }
        console.log(KycDone, "KYC");
      } catch (err) {
        console.log(err);
      }
    };
    kycCheck();
  }, []);

  // useEffect(() => {
  //     const VerifyUser = async () => {
  //       try {
  //         const response = await userService.getAUFForm();
  //           if (response?.error?.message) {
  //             console.log("something wents wrong");
  //             return;
  //           }
  //           setPdfUrl(response?.data?.pdf);
  //       } catch (err) {
  //        console.log(err);
  //       }
  //     };
  //     VerifyUser();
  // }, []);

  // Set the random code only once when the component is first rendered

  const onAddSignature = async () => {
    try {
      if (signatureDoc) {
        dispatch(showLoading());
        const formData = new FormData();
        formData.append("signature", signatureDoc);
        // formData.append("signature",signatureFile)
        await dispatch(addSignature(formData));
        setKycDone(true);

        // if (recordedVideo && longitude && latitude) {
        //   console.log("recordedVideo, randomCode", recordedVideo);

        //   const formData = new FormData();
        //   formData.append("video", recordedVideo);
        //   formData.append("longitude", longitude.toString());
        //   formData.append("latitude", latitude.toString());
        //   // Add other form data as needed
        //   const videoupload = await dispatch(uploadVideo(formData));

        //   const response = await userService.getAUFForm();
        //   if (response?.error?.message) {
        //     toast.error("Please upload a signature");
        //     return;
        //   }
        //   // const url = URL.createObjectURL(
        //   //   new Blob([response.data], { type: "applicaton/pdf" })
        //   // );
        //   console.log(response?.data?.pdf, "auf form response");

        //   if (videoupload.payload.success === 400 && videoupload.payload.message === 'Video already uploaded') {
        //     toast.success('Video already uploaded');
        //     setPdfUrl(response?.data?.pdf);
        //     setShowAUFForm(true);
        //   }
        //   setPdfUrl(response?.data?.pdf);
        //   setShowAUFForm(true);
        // } else {
        //   toast.error("Please record a verification video");
        // }
      } else {
        toast.error("Please upload a signature");
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const onUploadAUFForm = async () => {
    try {
      if (AUFForm) {
        dispatch(showLoading());
        const formData = new FormData();
        formData.append("aufForm", AUFForm);
        console.log(formData, "formData");
        const response = await dispatch(uploadAufForm(formData));
        if (response?.error?.message) {
          toast.error(
            "Some error in uploading AUF form. Please try again later"
          );
          return;
        }
        dispatch(hideLoading());
        toast.success("AUF form uploaded successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  // const downloadAUFForm = () => {
  //   if (pdfUrl) {
  //     const link = document.createElement("a");
  //     link.href = `data:application/pdf;base64,${pdfUrl}`;
  //     link.download = "auf_form.pdf";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };

  const fetchData = async () => {
    try {
      console.log("ESign API calling begin");
      const response = await dispatch(eSign_kyc());
      if (response?.error?.message) {
        toast.error(response?.payload?.message);
        return;
      }
      console.log(response?.payload?.data?.urlInfo?.signer_url);
      const url = response?.payload?.data?.urlInfo?.signer_url;
      if (url) {
        toast.success("Please check your email for esign link");
        window.location.href = url;
      } else {
        toast.error("URL not found in response");
      }
      //  onUploadAUFForm();
    } catch (error) {
      //onUploadAUFForm();
      toast.error("Something went wrong!");
      console.error("API Error:", error);
    }
  };

  // const errorMessage = useSelector((state: any) => state.esignKyc.errorMessage);

  // if (errorMessage && errorMessage.message) {
  //   toast(errorMessage.message, {
  //     icon: "🔥",
  //   });
  // }

  const handleModalConfirm = () => {
    // // Perform the desired action based on the current state
    if (showAUFForm) {
      onUploadAUFForm(); // Upload AUF form
    }
    // else if(!KycDone){
    //   onUploadAUFForm();
    // }
    else {
      onAddSignature(); // Add signature
    }
    setShowAUFForm(true);
    onAddSignature();
    setIsModalVisible(false); // Close the modal after confirming
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Just close the modal without taking action
  };

  // const handleToggle = (value: boolean) => {
  //   setShowSignature(value);
  //   setShowUpload(!value);
  // };

  const [showSignature, setShowSignature] = useState<string | null>(null); // Initialize to null

  const [showUpload, setShowUpload] = useState<string | null>(null);

  // Function to handle toggle change
  const handleToggleChange = (event: any, newOption: string | null) => {
    if (newOption !== null) {
      setShowSignature(newOption === "signature" ? "signature" : null);
      setShowUpload(newOption === "upload" ? "upload" : null);
    }
  };

  const [isSignatureUploaded, setIsSignatureUploaded] = useState(false);

  const handleSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setSignatureDoc(file);
    setIsSignatureUploaded(!!file); // Sets true if file exists, otherwise false
  };

  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  return (
    <>
      <section className="w-full max-w-[1200px] mx-auto md:mt-4 lg:mt-10 max-md:max-w-full flex flex-col justify-between">
        <div className="flex justify-between w-full ">
          <div className="flex flex-col mx-auto w-full px-5">
            <div>
              {/* <div
                  className="w-full flex flex-col mt-10 mb-10 grow text-lg"
                  style={{ color: colors.darkGrey }}
                >
                  <div className="w-full mb-5 text-base sm:text-base md:text-xl">
                    <div className="w-full my-4">
                      <span className="font-inter text-red-500 text-sm">
                        viay-bse-auf-from.pdf
                      </span> 
                       <button
                        style={{
                          backgroundColor: colors.primary,
                          color: colors.white,
                        }}
                        type="submit"
                        className={`grow  justify-center px-8 py-2 rounded-md border-3 border-${colors.darkGrey} border-solid `}
                        tabIndex={0}
                        onClick={downloadAUFForm}
                      >
                        Download AUF Form
                      </button>
                      <span className="mx-6">Or</span>
                      <button
                        type="submit"
                        className="form-button"
                        tabIndex={0}
                        onClick={fetchData}
                      >
                        Esign AOF Form
                      </button>
                      <p className="font-bold text-secondary text-xl font-inter mt-5">
                        *Note: Click on Esign AOF Form and esign your AOF form.You will get the esigned form to your email after
                        esigning.
                      </p>
                    </div>
                  </div>
                </div> */}

              {/* {pdfUrl && (
                  <iframe
                    src={`data:application/pdf;base64,${pdfUrl}`}
                    className="w-full"
                    style={{
                      height: "500px",
                      width: "100%",
                      maxWidth: "1400px",
                      border: "none",
                    }}
                  />
                )}
                <div className="max-w-[500px] w-full mx-auto">
                  <FileInputField
                    label="Upload AUF Form *"
                    type="file"
                    id="aufForm"
                    onChange={(e: any) => setAUFForm(e.target.files[0])}
                  />
                </div> 
              </> */}

              {!KycDone ? (
                <div className="flex justify-between lg:gap-8 items-center">
                  <div className="w-full max-w-[600px] mx-auto">
                    <div className="flex justify-evenly items-center lg:mt-5 lg:mb-5">
                      <h2 className="text-xl font-bold text-primary">
                        Your Signature
                      </h2>
                    </div>

                    {!signatureDoc && (
                      <>
                        <SignatureCanvas
                          onSignatureSave={handleSignatureSave}
                        />
                        <h6 className="my-2 lg:my-4 text-darkGrey font-inter text-center">
                          Please ensure that you draw a valid signature
                        </h6>
                      </>
                    )}

                    {showUpload && !signatureDoc && (
                      <div className="mt-4">
                        <FileInputField
                          label="Upload Signature *"
                          type="file"
                          id="signature"
                          onChange={handleSignatureChange}
                        />
                        {!isSignatureUploaded && (
                          <p className="text-red-500 text-sm mt-2">
                            Please upload your signature.
                          </p>
                        )}
                      </div>
                    )}

                    {signatureDoc && (
                      <div className="mt-5 mb-5 flex justify-center">
                        <img
                          src={URL.createObjectURL(signatureDoc)}
                          alt="Saved Signature"
                          className="border border-gray-300 rounded p-2 bg-white"
                        />
                      </div>
                    )}

                    {/* <VideoRecorder
                    recordedVideo={recordedVideo}
                    setRecordedVideo={setRecordedVideo}
                    onLocationChange={(latitude, longitude) =>
                      handleLocationChange(latitude, longitude)
                    }
                  />
                  <h6 className="my-4 text-darkGrey font-inter text-center">
                    Video recording is required. to verify your liveliness.
                    Choose a spot with good lighting and remove any accessories
                    like – hat, glasses etc.
                  </h6> */}
                  </div>
                </div>
              ) : userData?.isSignatureAdd === 1 && !showAUFForm ? (
                <>
                  <div className="flex flex-col gap-5 items-center mt-4 md:mt-0">
                    <CheckCircle
                      className="text-green-600 text-3xl"
                      fontSize="large"
                    />
                    <p className="text-lg font-semibold font-inter text-center">
                      Your signature is already uploaded. Please proceed
                      further.
                    </p>
                    <button
                      type="button"
                      className="form-button"
                      tabIndex={0}
                      onClick={() => setShowAUFForm(true)}
                      style={{
                        fontSize:isSmallScreen?"1rem":"1.3rem",
                        padding:isSmallScreen?"0.5rem":""
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="w-full flex flex-col lg:mt-10 mb-10 grow text-lg"
                    style={{ color: colors.darkGrey }}
                  >
                    <div className="w-full mb-5 text-base sm:text-base md:text-xl">
                      <div className="w-full lg:my-4 text-center md:text-start">
                        <button
                          type="submit"
                          className="form-button"
                          tabIndex={0}
                          onClick={fetchData}
                          style={{
                            fontSize:isSmallScreen?"1rem":"1.3rem",
                            padding:isSmallScreen?"0.5rem":""
                          }}
                        >
                          Esign AOF Form
                        </button>
                        <p className="font-bold text-secondary text-sm md:text-xl font-inter mt-5">
                          *Note: Click on Esign AOF Form and esign your AOF
                          form.You will get the esigned form to your email after
                          esigning.
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Buttons to proceed or skip */}
        <div className="ml-3 lg:mb-5 lg:mt-10">
          <section className="mb-5 max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col md:w-full justify-center items-center lg:justify-start lg:items-start">
                {/* <div className="flex gap-5 justify-between mt-12 text-sm max-md:mt-10"> */}
                {/* <button
                    style={{
                      backgroundColor: colors.white,
                      color: colors.darkGrey,
                      border: `1px solid ${colors.darkGrey}`,
                    }}
                    className={`grow justify-center px-8 py-4 whitespace-nowrap rounded-md border border-solid`}
                    tabIndex={0}
                    onClick={() => {}}
                  >
                    Skip
                  </button>  */}
                {KycDone ? (
                  <></>
                ) : (
                  <button
                    // style={{
                    //   backgroundColor: colors.darkGrey,
                    //   color: colors.white,
                    // }}
                    type="submit"
                    className={`form-button`}
                    tabIndex={0}
                    onClick={handleButtonClick}
                    style={{
                      fontSize: isSmallScreen ? "1rem" : "1.3rem",
                      padding: isSmallScreen ? "0.5rem" : "",
                    }}
                  >
                    Save and Proceed
                  </button>
                )}

                <ConfirmationModal
                  isVisible={isModalVisible} // Modal visibility control
                  message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                  onConfirm={handleModalConfirm} // Confirm action
                  onCancel={handleModalCancel} // Cancel action
                />
                {/* </div> */}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default SignForm;
