import React, { useEffect, useState } from "react";
import { Text, Button, Heading } from "../../components";
import { CorporateFare, PersonOutline } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUserById, setUserType } from "../../redux/user/userSlice";
import { useUserData } from "../../hooks/useUserData";

const entities = [
  {
    icon: PersonOutline,
    name: "Individual or Sole Proprietorship",
    description:
      "For Independent Financial Advisors, Registered Product Partners, Insurance Agents who are running the business in individual capacity",
    userType: 1, // Add userType here
  },
  {
    icon: CorporateFare,
    name: "Non Individual",
    description: "For Banks, Wealth Firms, Advisor Firms.",
    userType: 5, // Add userType here
  },
];

export default function EntityPage() {
  const theme = useTheme();
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [focusedDiv, setFocusedDiv] = useState<number>(-1); // -1 indicates no selection
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  useEffect(() => {
    dispatch(getUserById({}));
  }, [dispatch]);

  useEffect(() => {
    // Pre-select based on userType
    if (userData?.userType === 1) {
      setFocusedDiv(0); // First card
    } else if (userData?.userType === 5) {
      setFocusedDiv(1); // Second card
    }
  }, [userData?.userType]);

  const handleFocus = (index: number) => {
    if (userData?.userType === -1) {
      setFocusedDiv(index); // Allow selection only if userType is -1
    }
  };

  const handleSaveAndProceed = async () => {
    if (focusedDiv === -1) return; // No entity selected
    const selectedEntity = entities[focusedDiv];
    const body = { userType: selectedEntity.userType };

    setLoading(true);
    try {
      const res = await dispatch(setUserType(body));
      if (res?.payload?.data?.success === 200) {
        if (focusedDiv === 0) {
          navigate("/onboarding");
        } else {
          navigate("/nonIndividual");
        }
      }
    } catch (error) {
      console.error("Error setting user type:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full bg-white-A700">
        <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:py-20 bg-primary shadow-xs min-h-screen">
          <div className="flex flex-col items-center justify-center gap-6">
            <Heading as="h1" className="text-white text-lg md:text-3xl">
              Entity Type
            </Heading>
            <Text size="md" as="p" className="text-white text-sm md:text-lg">
              Select entity type to help us better understand
            </Text>
            <div className="flex flex-col md:flex-row items-center justify-center md:items-start md:justify-start gap-3 md:gap-[42px]">
              {entities.map((entity, index) => {
                const isSelected = focusedDiv === index;
                const isDisabled =
                  userData?.userType !== -1 &&
                  userData?.userType !== entity.userType;
                return (
                  <div
                  key={index}
                  className={`flex flex-row w-full min-h-[150px] md:min-h-[250px] max-w-[500px] items-center justify-start gap-16 px-6 border border-solid rounded-lg cursor-pointer ${
                    isSelected ? "border-deep_orange-500 border-4" : "border-gray-300"
                  } ${isDisabled ? "bg-gray-300 opacity-50 cursor-not-allowed" : "bg-white"}`}
                  onClick={() => !isDisabled && handleFocus(index)}
                  tabIndex={index}
                  >
                    <div className="flex flex-col items-center justify-center h-[50px] w-[50px] md:h-[100px] md:w-[100px]">
                      <div className="flex flex-col items-center justify-center h-[50px] w-[50px] md:h-[100px] md:w-[100px] p-6 bg-orange rounded-[50%]">
                        <entity.icon
                          sx={{
                            fontSize: isLargeScreen ? 64 : 32,
                            color: colors.white,
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <Heading
                        size="s"
                        as="h2"
                        className="md:w-[77%] md:mt-[22px] text-center text-sm md:text-xl"
                      >
                        {entity.name}
                      </Heading>
                      <Text
                        as="p"
                        className="md:mt-[9px] mb-[17px] !text-gray-800 text-center text-xs md:text-base"
                      >
                        {entity.description}
                      </Text>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-row justify-start gap-6">
              {userData?.userType === 1 || userData?.userType === 5 ? (
                <>
                  <Button
                    className="font-semibold text-white bg-darkGrey border-white border border-solid mt-8"
                    color={colors.darkGrey}
                    onClick={handleSaveAndProceed}
                  >
                    <span className="text-sm md:text-lg">
                      {loading ? "Proceding..." : "Proceed"}
                    </span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="font-semibold text-white bg-darkGrey border-white border border-solid mt-8"
                    color={colors.darkGrey}
                    disabled={focusedDiv === -1 || loading} // Disable button if no selection or loading
                    onClick={handleSaveAndProceed}
                  >
                    <span className="text-sm md:text-lg">
                      {loading ? "Saving..." : "Save & Proceed"}
                    </span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
