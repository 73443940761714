import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Edit,
  Delete,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from "../../constants/colors";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import {
  deleteHoldingsData,
  editHoldingsData,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";

const BONDTableRowComponent = ({ data, getInitials, modalMenuClick,fetchDataBOND }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");
  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);
  function convertPercentage(value: any) {
    return parseFloat(value?.toFixed(2));
  }
  const handleOpen = (
    id: any,
    quantity: any,
    price: any,
    demat: any,
    settelmentDate: any,
    yeild: any,
    bondSource: any
  ) => {
    setFields([
      { name: "quantity", label: "Quantity", type: "number" },
      { name: "price", label: "Price", type: "number" },
      { name: "demat", label: "Demat", type: "number" },
      { name: "settelmentDate", label: "Settelment Date", type: "text" },
      { name: "yeild", label: "Yield", type: "number" },
      { name: "bondSource", label: "Bond Source", type: "text" },
    ]);
    setValues({
      quantity: Number(quantity),
      price: Number(price),
      demat: Number(demat),
      settelmentDate: settelmentDate,
      yeild: Number(yeild),
      bondSource: bondSource,
    });
    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {

    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData({
          id: id,
          quantity: formData?.quantity,
          price: formData?.price,
          demat: formData?.demat,
          settelmentDate: formData?.settelmentDate,
          yeild: formData?.yeild,
          bondSource: formData?.bondSource,
          holdingType: holdingType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`Bond edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "BOND",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`Bond deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchDataBOND();
    setOpen(false);
    setMessage("");
  };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize:{
                      xs:"12px",
                      sm:"12px",
                      md:"16px"
                    }
                  }}
                  className="min-w-[200px]"
                >
                  <div className="flex items-center">
                    <div className="w-full">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleExpandClick(index)}
                      >
                        {item?.isin}
                      </span>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.quantity || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.price)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.amount)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." + formatIndianCurrency(Number(item?.settelmentAmount)?.toFixed(2)) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.settelmentDate ? DateUtils.formatDate(item?.settelmentDate) : "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.yeild || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{    
                    height: "100%",    
                    verticalAlign: "middle",            
                    borderBottom: "2px solid #ccc",
                  }}
                >
                   <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                  <IconButton>
                    <Edit
                      onClick={() =>
                        handleOpen(
                          item?.id,
                          item?.quantity,
                          item?.price,
                          item?.demat,
                          item?.settelmentDate,
                          item?.yeild,
                          item?.bondSource
                        )
                      }
                    />
                  </IconButton>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleConfirmModalOpen(item?.id, item?.isin)
                      }
                    />
                  </IconButton>
                  </div>
                </TableCell>
              </TableRow>
              {/* <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow> */}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="BOND"
          heading="Bond Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default BONDTableRowComponent;
