import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import { useForm } from 'react-hook-form';
import {
  User,
  Building2,
  Upload,
  Ban as Bank,
  FileSignature,
  FileCheck,
  Camera,
  ChevronRight,
  CheckCircle2,
} from "lucide-react";
import * as yup from "yup";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import PartnerService from "../../redux/Partner/PartnerService";
import toast from "react-hot-toast";
import ConfirmationModal from "../../components/modals/confirmationSuccessModal";

type ProfileFormData = {
  entityName: string;
  photo: File | null;
  signature: File | null;
  certificates: FileList[];
  bankName: string;
  accountNumber: string;
  ifscCode: string;
  objective: string;
};

const steps = [
  {
    id: 1,
    title: "Entity Details",
    icon: Building2,
  },
  {
    id: 2,
    title: "Documents",
    icon: FileCheck,
  },
  {
    id: 3,
    title: "Bank Details",
    icon: Bank,
  },
];

const PartnerProfile = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState<any>(null);
  // get state from route
  const location = useLocation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [profileData, setProfileData] = useState<ProfileFormData>({
    entityName: "",
    photo: null,
    signature: null,
    certificates: [],
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    objective: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state?.data) {
      console.log(location.state.data);
      setData(location.state.data);
      setProfileData({
        ...profileData,
        entityName: location.state?.data?.personName,
      });
    }
  }, [location.state]);

  const submitPartnerData = async () => {
    try {
      const formData = new FormData();
      formData.append("type", data?.type);
      formData.append("distributor_name", data?.distributorName);
      formData.append("person_name", data?.personName);
      formData.append("city", data?.city);
      formData.append("pincode", data?.pinCode);
      formData.append("objective", data?.objective);
      formData.append("email", data?.email);
      formData.append("mobile", `+91${data?.mobile}`);
      formData.append("arn_holder", data?.isArnHolder);
      formData.append("arn_number", data?.arnNumber);
      formData.append("request_type", "1");
      formData.append("bank_name", profileData.bankName);
      formData.append("account_number", profileData.accountNumber);
      formData.append("ifsc_code", profileData.ifscCode);
      formData.append("signature", profileData.signature as Blob);
      formData.append("profilePhoto", profileData.photo as Blob);
      Array.from(profileData.certificates).forEach((file: any) => {
        formData.append("certificates", file as Blob);
      });

      const response = await PartnerService.partnerRequest(formData);
      if (!response?.success) {
        toast.error(response?.message);
        return;
      }
      setOpenSuccess(true);
      // toast.success("Your partner request submitted successfully. We will contact you soon.");
      // navigate("/");
    } catch (error) {
      console.log(error, "error");
      toast.error("Error in submitting partner request.");
    }
  };

  const handleNextStep = (e: React.FormEvent) => {
    e.preventDefault();
    if (currentStep < 3) {
      setCurrentStep((prev) => prev + 1);
    } else {
      console.log(profileData);
      submitPartnerData();
      // Handle final form submission
    }
  };

  const checkValid = () => {
    if (currentStep === 1) {
      return profileData.entityName?.length > 0 && profileData.photo !== null;
    } else if (currentStep === 2) {
      return (
        profileData.certificates.length > 0 && profileData.signature !== null
      );
    } else if (currentStep === 3) {
      return (
        profileData.bankName.length > 0 &&
        profileData.accountNumber.length > 0 &&
        profileData.ifscCode.length > 0
      );
    }
    return false;
  };

  return (
    <div className="min-h-screen bg-lightBg py-12 px-4 sm:px-6 lg:px-8 font-nunitosans">
      <div className="max-w-3xl mx-auto mt-16">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-primary mb-2 font-nunito">
            Complete Your Profile
          </h1>
          <p className="text-textGrey font-inter">
            Fill in your details to get started
          </p>
        </div>

        {/* Steps Progress */}
        <div className="mb-8">
          <div className="flex items-center justify-center gap-4">
            {steps.map((step, index) => (
              <React.Fragment key={step.id}>
                <div
                  className={`flex items-center gap-2 py-2 px-4 rounded-lg transition-colors
                    ${
                      currentStep === step.id
                        ? "bg-primary text-white"
                        : currentStep > step.id
                        ? "bg-tertiary text-white"
                        : "bg-white text-textGrey"
                    }`}
                >
                  {currentStep > step.id ? (
                    <CheckCircle2 className="w-5 h-5" />
                  ) : (
                    <step.icon className="w-5 h-5" />
                  )}
                  <span className="font-nunito">{step.title}</span>
                </div>
                {index < steps.length - 1 && (
                  <ChevronRight className="w-5 h-5 text-textGrey" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <motion.div
          key={currentStep}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-2xl shadow-md p-8"
        >
          <form onSubmit={handleNextStep} className="space-y-6">
            {/* {renderStepContent()} */}
            {currentStep === 1 && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                    Name of Entity / Person
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        entityName: e.target.value,
                      })
                    }
                    name="entityName"
                    value={data?.personName || profileData.entityName}
                    className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition-colors font-nunitosans`}
                    disabled={data?.personName}
                  />
                </div>

                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-primary font-nunito flex items-center gap-2">
                    <Camera className="w-5 h-5 text-secondary" />
                    Profile Photo
                  </h3>
                  <div className="border-2 border-dashed border-lightblueBorder rounded-lg p-6 text-center">
                    <input
                      type="file"
                      onChange={(e: any) =>
                        setProfileData({
                          ...profileData,
                          photo: e.target.files[0],
                        })
                      }
                      accept="image/*"
                      className="hidden"
                      id="photo-upload"
                    />
                    <label
                      htmlFor="photo-upload"
                      className="cursor-pointer flex flex-col items-center"
                    >
                      <Upload className="w-10 h-10 text-primary mb-2" />
                      <span className="text-sm text-textGrey font-inter">
                        {profileData.photo
                          ? profileData.photo.name
                          : "Upload your photo (JPG, PNG up to 2MB)"}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div className="space-y-6">
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-primary font-nunito flex items-center gap-2">
                    <FileSignature className="w-5 h-5 text-secondary" />
                    Signature
                  </h3>
                  <div className="border-2 border-dashed border-lightblueBorder rounded-lg p-6 text-center">
                    <input
                      type="file"
                      onChange={(e: any) =>
                        setProfileData({
                          ...profileData,
                          signature: e.target.files[0],
                        })
                      }
                      accept="image/*"
                      className="hidden"
                      id="signature-upload"
                    />
                    <label
                      htmlFor="signature-upload"
                      className="cursor-pointer flex flex-col items-center"
                    >
                      <Upload className="w-10 h-10 text-primary mb-2" />
                      <span className="text-sm text-textGrey font-inter">
                        {profileData.signature
                          ? profileData.signature.name
                          : "Upload your signature (JPG, PNG up to 2MB)"}
                      </span>
                    </label>
                  </div>
                </div>

                <div className="space-y-4">
                  <h3 className="text-lg font-semibold text-primary font-nunito flex items-center gap-2">
                    <FileCheck className="w-5 h-5 text-secondary" />
                    Certificates
                  </h3>
                  <div className="border-2 border-dashed border-lightblueBorder rounded-lg p-6 text-center">
                    <input
                      type="file"
                      multiple
                      onChange={(e: any) =>
                        setProfileData({
                          ...profileData,
                          certificates: e.target.files,
                        })
                      }
                      accept=".pdf,.jpg,.png"
                      className="hidden"
                      id="cert-upload"
                    />
                    <label
                      htmlFor="cert-upload"
                      className="cursor-pointer flex flex-col items-center"
                    >
                      <Upload className="w-10 h-10 text-primary mb-2" />
                      <span className="text-sm text-textGrey font-inter">
                        {profileData.certificates.length
                          ? `${profileData.certificates.length} certificates uploaded`
                          : "Upload your certificates (JPG, PNG, PDF up to 2MB)"}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        bankName: e.target.value,
                      })
                    }
                    name="bankName"
                    className="w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                    Account Number
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        accountNumber: e.target.value,
                      })
                    }
                    name="accountNumber"
                    className="w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                    IFSC Code
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        ifscCode: e.target.value,
                      })
                    }
                    name="ifscCode"
                    className="w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans"
                  />
                </div>
              </div>
            )}

            <div className="flex justify-between pt-6">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                  className="px-6 py-2 border-2 border-primary text-primary rounded-lg hover:bg-lightBg transition-colors font-nunito"
                >
                  Previous
                </button>
              )}
              <button
                type="submit"
                className={`px-6 py-2 bg-primary disabled:bg-primary/20 text-white rounded-lg hover:bg-indigo-800 transition-colors font-nunito
                  ${currentStep === 1 ? "ml-auto" : ""}`}
                disabled={!checkValid()}
              >
                {currentStep === steps.length
                  ? "Complete Profile"
                  : "Next Step"}
              </button>
            </div>
          </form>
        </motion.div>

        {/* Progress Tips */}
        <div className="mt-8 bg-white rounded-2xl p-6 shadow-md">
          <h3 className="text-lg font-semibold text-primary mb-4 font-nunito">
            Tips for Profile Completion
          </h3>
          <ul className="space-y-3">
            <li className="flex items-start gap-2 text-sm text-textGrey font-inter">
              <CheckCircle2 className="w-5 h-5 text-tertiary flex-shrink-0 mt-0.5" />
              Keep your documents ready before starting the process
            </li>
            <li className="flex items-start gap-2 text-sm text-textGrey font-inter">
              <CheckCircle2 className="w-5 h-5 text-tertiary flex-shrink-0 mt-0.5" />
              Ensure all uploaded documents are clear and legible
            </li>
            <li className="flex items-start gap-2 text-sm text-textGrey font-inter">
              <CheckCircle2 className="w-5 h-5 text-tertiary flex-shrink-0 mt-0.5" />
              Double-check your bank details for accuracy
            </li>
          </ul>
        </div>
      </div>
      <ConfirmationModal
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onConfirm={() => {
          setOpenSuccess(false);
          navigate("/");
        }}
        type="partner"
      />
    </div>
  );
};

export default PartnerProfile;
