import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  InvestMF,
  InvestRequest,
  checkPaymentStatus,
  createPayment,
  getMFundById,
} from "../../redux/MF/MFSlice";
import MFDetailCard from "./MFDetailCard";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { useNavigate } from "react-router-dom";
import MFFooterCard from "./MFFooterCard";
import { FormInput } from "../FormInput/FormInput";
import AmountButtons from "./AmountButtons";
import APIloader from "../AppLoader/APIloader";
import Dialog from "@mui/material/Dialog";
import { Box, Button as Btn, Modal, useMediaQuery, useTheme } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import { useUserData } from "../../hooks/useUserData";
import { showInterest } from "../../redux/user/userSlice";
import CustomDialog from "../modals/BsePaymentAutherizationModal";
import SuccessfullModal from "../modals/SuccessfullModal";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";

interface MFProps {
  activeSection: string;
  fundId: number;
  fundInfo: any;
  onClose?: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "100%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
const Lumpsum: FC<MFProps> = ({ fundId, fundInfo, onClose }) => {
  const ref = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { bank } = useUserData() || { bank: [] };
  const userData = useUserData();
  const [investmentPlan, setInvestmentPlan] =
    useState<string>("New investment");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [transactionData, setTransactionData] = useState<any>(null);
  const [isNewInvestmentEnabled, setIsNewInvestmentEnabled] = useState(true);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authLink, setAuthLink] = useState("");
  const [orderID, setOrderID] = useState("");
  const [button, setButton] = useState(false);
  const [loadmessage, setLoadMessage] = useState<string>("");
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [InterestOpen, setInterestOpen] = useState(false);
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [paymentWindow, setPaymentWindow] = useState<Window | null>(null);
  const [payment, setPayment] = useState(false);
  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // Dynamically adjust height based on screen size
  let chartHeight = 500; // default height for large screens
  if (isLargeScreen) {
    chartHeight = 500; // Keep it 500 for large screens
  } else if (isMediumScreen) {
    chartHeight = 400; // Slightly reduce the height for medium screens
  } else if (isSmallScreen) {
    chartHeight = 250; // Further reduce the height for small screens
  }

  const lumpsumValues = {
    orderType: 1,
    fundId: fundId,
    amount: "",
    folioNumber: "",
    dividend: "",
    bankAccount: "",
  };

  const newInvestmentSchema = yup.object().shape({
    orderType: yup.number().required(),
    amount: yup
      .number()
      .min(
        fundInfo.minimumPurchaseAmount,
        `Amount must be greater than or equal to ${fundInfo.minimumPurchaseAmount}`
      )
      .required("Amount is required."),
    folioNumber: yup.string().notRequired(),
    dividend: yup.string().notRequired(),
    bankAccount: yup.string().notRequired(),
  });

  const existingInvestmentSchema = yup.object().shape({
    orderType: yup.number().required(),
    amount: yup
      .number()
      .min(
        fundInfo.minimumPurchaseAmount,
        `Amount must be greater than or equal to ${fundInfo.minimumPurchaseAmount}`
      )
      .required("Amount is required."),
    folioNumber: yup.string().required("Folio Number is required."),
    dividend: yup.string().notRequired(),
    bankAccount: yup.string().notRequired(),
  });
  const validationSchema =
    investmentPlan === "New investment"
      ? newInvestmentSchema
      : existingInvestmentSchema;
  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center ml-10">
      <input
        type="radio"
        checked={investmentPlan === option}
        onChange={() => setInvestmentPlan(option)}
        className={`mr-2`}
        disabled={option === "New investment" && !isNewInvestmentEnabled}
      />
      {option}
    </label>
  );
  const handleDialogClose = () => setShowSummary(false);
  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const today = new Date();
  const formattedDate = formatDate(today);
  const calculateUnits = (amount: number, nav: number) => {
    return (amount / nav).toFixed(2); // Returns units to two decimal places
  };

  const proceedToPayment = (values: any) => {
    const nav = fundInfo?.currentNAV; // Get current NAV from fundInfo
    const amount = parseFloat(values.amount); // Parse amount from form values
    const units = calculateUnits(amount, nav); // Calculate number of units
    console.log("Current NAV:", fundInfo.currentNAV);

    const data = {
      ...values,
      investmentPlan, // Make sure to include investmentPlan in the data object
      units, // Add calculated units to the data object
    };
    setSummaryData(data);
    setShowSummary(true);
  };

  useEffect(() => {
    if (fundInfo) {
      ref.current?.setFieldValue(
        "folioNumber",
        fundInfo?.folioNumber[0]?.folio
      );
    }
  }, [fundInfo]);

  useEffect(() => {
    fetchData(); // Fetch data when component mounts or dependencies change
  }, []);

  const fetchData = async () => {
    let data = { fetchFor: "aumcapital" };
    try {
      const response = await dispatch(fetchDashboardData(data));
      if (response.payload.data) {
        setTransactionData(response.payload.data);
        evaluateInvestmentStatus(response.payload.data);
      }
    } catch (error) {
      console.error("Fetch dashboard data failed:", error);
      setOpen(true);
      setErrorMessage("Please Refresh");
      // setLoading(false);
    }
  };

  const evaluateInvestmentStatus = (data: any) => {
    let newInvestmentDisabled = false;

    // Ensure `recentTransactions` is an array
    const recentTransactions = Array.isArray(data.recentTransactions)
      ? data.recentTransactions
      : [];

    recentTransactions.forEach(
      (transactionGroup: any[], groupIndex: number) => {
        // Check if `transactionGroup` is an array before calling `.forEach`
        if (Array.isArray(transactionGroup)) {
          transactionGroup.forEach((order, orderIndex) => {
            console.log(`Processing order ${orderIndex}:`, order);

            if (order.orderType === "MF" && order.isBuy === 1) {
              console.log("Found MF order with isBuy 1:", order);
              newInvestmentDisabled = true;
            }
          });
        } else {
          console.warn(
            `transactionGroup at index ${groupIndex} is not an array:`,
            transactionGroup
          );
        }
      }
    );

    setIsNewInvestmentEnabled(!newInvestmentDisabled);
  };

  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: fundId, holdingType: 2 })
      );

      if (response?.payload?.statusCode === 200) {
        setInterestOpen(true);
        setMessage(
          `Your interest has been successfully submitted to your RM. You can also reach out to your RM ${userData?.relationManager?.firstName} at ${userData?.relationManager?.mobile}.`
        );
      } else {
        setInterestOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setInterestOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  const handleInterestClose = () => {
    setInterestOpen(false);
    setMessage("");
    setErrorMessage("");
    onClose();
    if (payment) {
      onClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = async () => {
    if (summaryData) {
      try {
        const { amount, orderType, folioNumber, dividend, bankId } =
          summaryData;
        let updatedBodyData = {};

        if (investmentPlan === "Existing investment") {
          updatedBodyData = {
            orderType: 1,
            fundId: fundId,
            amount: amount,
            folioNo: folioNumber,
            buySellType: "ADDITIONAL",
            dividend: "",
            bankAccount: parseInt(bank[0]?.id),
            isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
          };
        } else {
          updatedBodyData = {
            orderType: 1,
            fundId: fundId,
            amount: amount,
            folioNo: "",
            dividend: "",
            buySellType: "FRESH",
            bankAccount: parseInt(bank[0]?.id),
            isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
          };
        }
        setLoading(true);
        setLoadMessage("Processing your request, please wait 30s to 40s...");
        handleDialogClose();
        const res = await dispatch(InvestMF(updatedBodyData));
        // const res = await dispatch(InvestRequest(updatedBodyData));
        setOrderID(res?.payload?.data?.data?.orderId);
        if (res?.payload?.data?.success === 200) {
          setLoading(false);
          const authLink = res?.payload?.data?.data?.authLink;
          setAuthLink(authLink);
          setAuthModalOpen(true); // Open modal with auth link
        } else {
          setLoading(false);
          setInterestOpen(true);
          setErrorMessage(
            res?.payload?.message !== ""
              ? res?.payload?.message
              : "Error in executing your order."
          );
        }
      } catch (error) {
        setOpen(true);
        setErrorMessage("Investment Failed");
      } finally {
        dispatch(hideLoading());
        handleDialogClose();
      }
    }
  };
  const handleAuthModalClose = async () => {
    setAuthModalOpen(false); // Close the auth link modal
    try {
      const response = await dispatch(
        createPayment({
          orderId: orderID,
          modeofpayment: "DIRECT",
          vpaid: "",
          bankid: parseInt(bank[0]?.id),
          isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
        })
      );
      setOpen(true);
      if (response.payload.statusCode === 400) {
        setErrorMessage(response.payload.message);
      } else {
        setHtmlContent(response.payload.data.data);
        setButton(response.payload.data.statusCode === 200);
        setMessage("Click Ok to proceed your payment.");
      }
    } catch (error) {
      console.error("Authentication is failed", error);
    }
  };

  const paymentStatus = async (): Promise<boolean> => {
    try {
      const response = await dispatch(
        checkPaymentStatus({
          orderId: orderID,
          isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
        })
      );
      if (response.payload.data.statusCode === 200) {
        if (
          response.payload.data.data.includes(
            "AWAITING FOR RESPONSE FROM BILLDESK"
          )
        ) {
          setMessage(
            "Order is under process. Please check after few hours in order tab."
          );
        } else if (
          response.payload.data.data.includes(
            "PAYMENT NOT INITIATED FOR GIVEN ORDER"
          )
        ) {
          setMessage("Payment not initiate for given order.");
        } else {
          setPayment(true);
          setMessage("Payment successful.");
        }
        return true;
      } else {
        setMessage("Payment verification failed or was not successful.");
        return false;
      }
    } catch (error) {
      setPayment(true);
      console.error("Authentication is failed", error);
      setMessage(
        "Payment verification failed or was not successful.Due to server."
      );
      return false;
    }
  };

  useEffect(() => {
    // Check if the popup window is closed every 1000 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setLoading(false);
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        handleAuthModalClose();
      }
    }, 1000);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  useEffect(() => {
    const checkPopupClosed = setInterval(() => {
      const handlePaymentStatus = async () => {
        if (paymentWindow && paymentWindow.closed) {
          clearInterval(checkPopupClosed); // Stop checking once the popup is closed
          // Call the paymentStatus function and wait for the response
          setTimeout(async () => {
            const isPaymentSuccessful = await paymentStatus();
            console.log("Payment status checked:", isPaymentSuccessful);
            if (isPaymentSuccessful) {
              setInterestOpen(true);
              setLoading(false);
            } else {
              setInterestOpen(true);
              setLoading(false);
            }
          }, 15000); // Delay of 15 seconds
        }
      };
      handlePaymentStatus(); // Call the async function
    }, 1000);
    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [paymentWindow]);

  const openAuthLink = () => {
    setAuthModalOpen(false);
    setLoadMessage("Please authenticate your order and close the bse tab...");
    setLoading(true);
    if (authLink) {
      const newPopupWindow = BrowserPopUpModal(
        authLink,
        "BSE Autherization",
        1250,
        550
      );
      setPopupWindow(newPopupWindow);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccess(false);
  };

  const openPaymentTab = () => {
    setOpen(false);
    setMessage("");
    setErrorMessage("");
    setLoadMessage("We are processing you request...");
    setLoading(true);
    if (!htmlContent) return;

    // Create a new Blob object containing the HTML content
    const blob = new Blob([htmlContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    if (url) {
      const newPopupWindow = BrowserPopUpModal(
        url,
        "Payment Window",
        1250,
        550
      );
      setPaymentWindow(newPopupWindow);
    } else {
      setInterestOpen(true);
      setMessage("Unable to open payment tab, possibly due to popup blocker.");
    }
  };

  return (
    <>
      <div className="bg-lightBg lg:pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white mb-3">
              <div className="lg:px-8 lg:py-10">
                <MFDetailCard fundInfo={fundInfo} />
                <div className="mt-2 mx-2">
                  {/* {!fundInfo?.currentValue &&(
                  <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-2 mt-10">
                    <p>Purchase mode:</p>
                    ["New investment"].map(renderRadio)
                  </div>)} */}

                  <Formik
                    innerRef={ref}
                    initialValues={lumpsumValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values: any, { setSubmitting }: any) => {
                      try {
                        const isValid = await validationSchema.isValid(values);
                        if (isValid) {
                          proceedToPayment(values);
                        } else {
                          // Form validation failed, do something (optional)
                        }
                      } catch (error: any) {
                        toast.error("Investment Failed");
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="mt-2">
                        {fundInfo && fundInfo?.currentValue > 0 && (
                          <div className="flex flex-col md:flex-row lg:gap-5">
                            <div className="w-full sm:w-3/4 lg:mb-4 sm:mb-0">
                              <FormInput
                                label="Folio Number"
                                name="folioNumber"
                                type={""}
                                id={""}
                                onChange={handleChange("folioNumber")}
                                value={values.folioNumber}
                                error={errors.folioNumber}
                                touched={touched.folioNumber}
                                disabled
                              />
                            </div>
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0"></div>
                          </div>
                        )}
                        <AmountButtons
                          label="Amount"
                          setFieldValue={setFieldValue}
                          selectedAmount={selectedAmount}
                          setSelectedAmount={setSelectedAmount}
                          fieldName="amount"
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                        {/* <div className="w-full mt-10">
                          <MFFooterCard
                            onBankChange={handleChange("bankAccount")}
                            bankValue={values.bankAccount}
                            bankError={errors.bankAccount}
                            bankTouched={touched.bankAccount}
                          />
                        </div> */}
                        <section className="mt-2 mb-4 lg:mt-10 lg:mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col lg:grow lg:max-md:mt-10 max-md:max-w-full">
                                <div className="flex gap-3 justify-between max-md:mt-1 lg:mt-2 lg:mb-5 text-sm max-md:text-xs my-5">
                                  <button
                                    type="submit"
                                    className="grow md:flex justify-center px-3 py-1 md:px-4 md:py-2 lg:px-8 lg:py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary"
                                    tabIndex={0}
                                    style={{
                                      fontSize:isLargeScreen?"1.3rem":"0.9rem"
                                    }}
                                  >
                                    Invest
                                  </button>
                                  <button
                                    type="button"
                                    className="grow md:flex justify-center px-3 py-1 md:px-4 md:py-2 lg:px-8 lg:py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary"
                                    onClick={showingInterest}
                                    style={{
                                      fontSize:isLargeScreen?"1.3rem":"0.9rem"
                                    }}
                                  >
                                    Show Interest
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={loadmessage} />

      {/* Summary Dialog */}
      <Dialog
        open={showSummary}
        onClose={handleDialogClose}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-2 lg:p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
            <Typography variant={isSmallScreen?"h6":isMediumScreen?"h5":"h4"} className="font-bold mb-2 lg:mb-4">
              Summary
            </Typography>
            <DialogContent className="flex flex-col" sx={{
              padding : 1,
            }}>
              {summaryData ? (
                <>
                  {investmentPlan === "Existing investment" && (
                    <div className="flex justify-between mb-4">
                      <span className="font-semibold text-gray-700">
                        Folio Number:
                      </span>
                      <span>{summaryData.folioNumber}</span>
                    </div>
                  )}
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Investment Plan:
                    </span>
                    <span>{summaryData.investmentPlan}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Amount:</span>
                    <span>Rs. {summaryData.amount}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Approx. Units:
                    </span>
                    <span>{summaryData.units}</span>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <span>Loading...</span>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Btn
                onClick={handleConfirm}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
                onClick={handleDialogClose}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
            {/* <Typography variant="body2" className="mb-4" sx={{ marginTop: 4 }}>
              Once confirmed, you will be redirected to the banking payment page
              for transaction
            </Typography> */}
          </div>
        </div>
      </Dialog>
      <CustomDialog
        open={Open}
        errormessage={message !== "" ? message : errormessage}
        button={button}
        handleDialogClose={handleDialogClose}
        handleClose={handleClose}
        openAuthLink={button ? undefined : openAuthLink}
        openPaymentTab={button ? openPaymentTab : undefined}
      />
      <SuccessfullModal
        open={InterestOpen}
        message={message !== "" ? message : errormessage}
        handleClose={handleInterestClose}
      />
      <Dialog open={authModalOpen} onClose={handleAuthModalClose}>
        <DialogContent>
          <Typography>
            Click below to complete your authorization on the BSE STAR MF
            platform and close the tab after authorizing yourself.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Btn
            onClick={openAuthLink}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            Open Authorization Link
          </Btn>
          <Btn
            onClick={handleAuthModalClose}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            Close
          </Btn>
        </DialogActions>
      </Dialog>
      <Dialog open={success} onClose={handleCloseSuccessModal}>
        <DialogContent>
          <Typography>{message !== "" ? message : errormessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Btn
            onClick={handleCloseSuccessModal}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            OK
          </Btn>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Lumpsum;
