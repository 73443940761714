import React, { useEffect, useState } from 'react';
// import GeneralDetailsForm from '../GeneralDetails/GeneralDetails';
import GeneralDetailsForm from '../GeneralDetails/GeneralDetailsForm';
import Demat from '../Demat/Demat';
import Preferences from '../Preferences/Preferences';
import Sign from '../../IndividualForms/SignForm/SignForm';
import Preferences2 from '../Preferences/Preferences2';
import NonIndividualFormLayout from '../../../layout/NonIndividualFormHeader/NonIndividualFormHeader';
import PanVerification from '../../IndividualForms/PanVerification/PanVerification';
import { useUserData } from '../../../hooks/useUserData';
import BankDetailsForm from '../../IndividualForms/BankDetailsForm/BankDetailsForm';
import DematForm from '../../IndividualForms/DematForm/DematForm';
import NonIndividualCompanyLayout from '../../../layout/NonIndividualCompanyHeader/NonIndividualCompanyHeader';
import Company from '../CompanyPage/Company';
import Director from '../Director/DirectorPage';
import NomineeDetailsForm from "../NomineeDetailsForm/NomineeDetailsForm";
import SelectType from '../SelectTypeNonIndividual/SelectType';
import { useLocation } from 'react-router-dom';

const MainNonIndividual: React.FC = () => {
    const location = useLocation();
  const [activeSection, setActiveSection] = useState("general");
  const [completedSections, setCompletedSections] = useState<boolean[]>(Array(9).fill(false));

  type SectionKey = 'HUF' | 'Corporate' | 'LLP' | 'Trust';
  const [businessType, setBusinessType] = useState<SectionKey>('HUF'); // Default to one of the valid SectionKeys

  const handleSaveAndProcess = (sectionName: string, index: number) => {
    const updatedSections = [...completedSections];
    updatedSections[index] = true;
    setCompletedSections(updatedSections);
    setActiveSection(sectionName);
  };

  const handleSectionChange = (newSection: string) => {
    setActiveSection(newSection);
  };

  const user = useUserData();

  const [formData, setFormData] = useState({
    company: {},
    director: {},
    // Add other sections if needed
  });

  const handleCompanyData = (companyData: object) => {
    setFormData((prevData) => ({
      ...prevData,
      company: companyData,
    }));
  };

    useEffect(() => {
      if (location.state) {
        const updatedSections = [...completedSections];
        for (let i = 0; i <= location.state.sectionIndex; i++) {
          updatedSections[i] = true;
        }
        setCompletedSections(updatedSections);
        setActiveSection(location.state.activeSection);
      }
    }, [location.state])
  
  return (
    <div>
      <NonIndividualFormLayout completedSections={completedSections} activeSection={activeSection} setActiveSection={setActiveSection}>
        {/* {activeSection === 'PAN' && (
          <PanVerification
            onProceed={() => handleSaveAndProcess('general', 0)}
            activeSection={activeSection}
            userData={user}
          />
        )} */}
        {/* {activeSection === "select" && (
          <SelectType
          onSelectToAadhar={() => handleSaveAndProcess("general", 0)}
          allowToProceed={function (): void {
            throw new Error("Function not implemented.");
          }}
          userData={user}
          />
        )} */}
        {activeSection === 'general' && (
          <GeneralDetailsForm
            onGeneralToCompany={() => handleSaveAndProcess('company', 0)}
            activeSection={activeSection}
            allowToProceed={() => handleSaveAndProcess('company', 0)}
            onBusinessTypeChange={setBusinessType}
            userData={user}
          />
        )}
        {/* {activeSection === 'company' && (
          <NonIndividualCompanyLayout
            setBusinessType={setBusinessType}
            activeSection={activeSection}
            onSectionChange={handleSectionChange}
            allowToProceed={() => handleSaveAndProcess('director', 2)}
            completedSections={completedSections}
            businessType={businessType}
          />
        )} */}
        {activeSection === "company" && (
          <Company
          onCompanyToBank={() => handleSaveAndProcess("bank", 1)}
          activeSection={activeSection}
          allowToProceed={() => handleSaveAndProcess("bank", 1)}
          />
        )}
        {/* {activeSection === "director" && (
          <Director
            onDirectorToBank={() => handleSaveAndProcess("bank", 3)}
            activeSection={activeSection}
            allowToProceed={() => handleSaveAndProcess("bank", 3)}
            companyData={formData.company} // Pass company data to Director
            userData={user}
          />
        )} */}
        {/* {activeSection === "nominee" && (
          <NomineeDetailsForm
            onNomineeToDemat={() => handleSaveAndProcess("bank", 4)}
            allowToProceed={function (): void {
              throw new Error("Function not implemented.");
            }}
            // Add any required props, for example:
            label="Nominee Details"
            name="nomineeForm"
            userData={user}
          />
        )} */}
        {activeSection === "bank" && (
          <BankDetailsForm
            onBankToNominee={() => handleSaveAndProcess("preference", 2)}
            allowToProceed={() => {}}
            userData={user}
          />
        )}
        {/* {activeSection === "demat" && (
          <DematForm
            onDematToPreferences={() => handleSaveAndProcess("preference", 4)}
            allowToProceed={() => {}}
            userData={user}
          />
        )} */}
        {activeSection === 'preference' && (
          <Preferences
            onPreferenceToPreferences2={() => handleSaveAndProcess('preference2', 3)}
            // activeSection={activeSection}
            allowToProceed={() => {}}
            userData={user}
          />
        )}
        {activeSection === 'preference2' && (
          <Preferences2
            onPreferenceToDemat={() => handleSaveAndProcess('sign', 4)}
            activeSection={activeSection}
            allowToProceed={() => {}}
          />
        )}
        {/* {activeSection === 'demat' && (
          <Demat
            onDematToGeneral={() => handleSaveAndProcess('general', 3)}
            activeSection={activeSection}
            allowToProceed={() => {}}
          />
        )} */}
        {activeSection === 'sign' && (
          <Sign allowToProceed={() => { } } userData={user} />
        )}
      </NonIndividualFormLayout>
    </div>
  );
};

export default MainNonIndividual;



