import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Popover,
  Skeleton,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { colors } from "../../constants/colors";
import { BookmarkBorder, InfoOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFundInfo, getMFundById } from "../../redux/MF/MFSlice";
import InvestModal from "../../components/modals/investModalAIF";
import AIFAssetAllocation from "../../components/AssetAllocation/AIFAssetAllocation";
import { Button as Btn } from "@mui/material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import { FaBookmark } from "react-icons/fa";
import TimeStamptoExp from "../../utils/timestampConverter";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useUserData } from "../../hooks/useUserData";
import api from "../../config/apiConfig";
import {
  getTransactionRequest,
  WithdrawRequest,
} from "../../redux/AIF/AIFSlice";
import SuccessfullModal from "../../components/modals/SuccessfullModal";
import { DateUtils } from "../../utils/FormatDate";
import TabsComponent from "../../components/Mobile_Components/TabsComponent";
import AIFAssetAllocationMobile from "../../components/Mobile_Components/AIFAssetAllocationMobile";

const FDDetailCard = (data: any) => {
  return (
    <>
      <div className=" py-2 flex flex-col min-w-[80px] md:min-w-[100px]">
        <div className="text-sm sm:text-lg text-gray-700_01">{data.label}</div>
        <div className="text-sm sm:text-lg font-medium text-gray-700_01">
          {data.value}
        </div>
      </div>
    </>
  );
};

const FDMangerCard = ({ data }: any) => {
  const currentDate = Date.now();
  return (
    <>
      {!data || data.length === 0 ? (
        <Skeleton
          variant="rectangular"
          height={200}
          animation="wave"
          sx={{ bgcolor: colors.lightBg }}
        />
      ) : (
        data.map((manager: any, index: number) => (
          <div
            key={index}
            className="px-2 py-8 gap-y-2 rounded-md justify-between"
          >
            <div className="flex flex-row">
              <div className="mr-4">
                <div className="flex items-center">
                  <span>
                    {manager.imgSrc ? (
                      <img className="w-6" src={manager.imgSrc} alt="fd" />
                    ) : (
                      <div className="w-6 h-6 mb-3 md:mb-0 md:w-16 md:h-16 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-2xl font-bold">
                        {getInitials(manager.name)}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div>
                  <div className="flex items-center">
                    <h2 className="text-2xl font-medium text-primary">
                      {manager.name}
                    </h2>
                  </div>
                </div>
                <div className="flex flex-row py-4">
                  <p className="border-r-2 border-black pr-1  text font-medium">
                    Experience:{" "}
                    {TimeStamptoExp.calculateYearsOfExperience(
                      manager.startDate,
                      currentDate
                    )}
                  </p>
                  <p className="pl-2 font-medium">Role: {manager.role}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

const AIFDetails = () => {
  const user = useUserData();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const schemes = location.state?.schemes || [];
  const [interestRateMF, setInterestRateMF] = useState({});
  const [watchList, setWatchList] = useState<any>(schemes?.isInWishlist);
  const [mfDataByiSIN, setMFDataByISIN] = useState<any>();
  const [transactionData, setTransactionData] = useState<{
    ids: string;
    totalAmount: number;
  }>({
    ids: "",
    totalAmount: 0,
  });
  const checkAIF = user?.preferences?.aif;
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");

  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // Dynamically adjust height based on screen size
  let chartHeight = 500; // default height for large screens
  if (isLargeScreen) {
    chartHeight = 500; // Keep it 500 for large screens
  } else if (isMediumScreen) {
    chartHeight = 400; // Slightly reduce the height for medium screens
  } else if (isSmallScreen) {
    chartHeight = 250; // Further reduce the height for small screens
  }

  const ReturnsGrid = ({
    AifreturnRateTable,
    AifAnnualReturnTable,
  }: {
    AifreturnRateTable: string[];
    AifAnnualReturnTable: string[];
  }) => {
    const timePeriods2 = ["1M", "2M", "6M"];
    const timePeriods = ["1Y", "2Y", "3Y", "5Y"];

    return (
      <div className="p-4">
        <Card sx={{ padding: 0, mb: 4 }}>
          <Grid container spacing={0}>
            {/* Table Headers */}
            <Grid
              item
              xs={6}
              sx={{
                border: `1px solid ${colors.lightGrey}`,
                textAlign: "center",
                fontWeight: "bold",
                p: 1,
                fontSize: "0.9rem",
              }}
            >
              Time Period
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                border: `1px solid ${colors.lightGrey}`,
                textAlign: "center",
                fontWeight: "bold",
                p: 1,
                fontSize: "0.9rem",
              }}
            >
              Absolute Returns (in %)
            </Grid>

            {/* Table Rows */}
            {timePeriods2.map((period, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: `1px solid ${colors.lightGrey}`,
                    textAlign: "center",
                    p: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  {period}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: `1px solid ${colors.lightGrey}`,
                    textAlign: "center",
                    p: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  {AifreturnRateTable[index] === "-" ||
                  AifreturnRateTable[index] === "" ||
                  !AifreturnRateTable[index]
                    ? "-"
                    : AifreturnRateTable[index].endsWith("%")
                    ? AifreturnRateTable[index]
                    : AifreturnRateTable[index] + "%"}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Card>
        <Card sx={{ padding: 0, mb: 4 }}>
          <Grid container spacing={0}>
            {/* Table Headers */}
            <Grid
              item
              xs={6}
              sx={{
                border: `1px solid ${colors.lightGrey}`,
                textAlign: "center",
                fontWeight: "bold",
                p: 1,
                fontSize: "0.9rem",
              }}
            >
              Time Period
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                border: `1px solid ${colors.lightGrey}`,
                textAlign: "center",
                fontWeight: "bold",
                p: 1,
                fontSize: "0.9rem",
              }}
            >
              Annualised Returns (in %)
            </Grid>

            {/* Table Rows */}
            {timePeriods.map((period, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: `1px solid ${colors.lightGrey}`,
                    textAlign: "center",
                    p: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  {period}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: `1px solid ${colors.lightGrey}`,
                    textAlign: "center",
                    p: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  {AifAnnualReturnTable[index] === "-" ||
                  AifAnnualReturnTable[index] === "" ||
                  !AifAnnualReturnTable[index]
                    ? "-"
                    : AifAnnualReturnTable[index].endsWith("%")
                    ? AifAnnualReturnTable[index]
                    : AifAnnualReturnTable[index] + "%"}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Card>
      </div>
    );
  };

  const FeesGrid = ({ fixed, hybrid }: { fixed: any; hybrid: any }) => {
    const timePeriods = ["Fixed", "Hybrid/Variable"];

    const values = [fixed, hybrid];
    console.log(values, "values");

    return (
      <div className="p-4">
        <Card sx={{ padding: 0, mb: 4 }}>
          <Grid container spacing={0}>
            {/* Table Headers */}
            <Grid
              item
              xs={12}
              sx={{
                border: `1px solid ${colors.lightGrey}`,
                textAlign: "center",
                fontWeight: "bold",
                p: 1,
                fontSize: "0.9rem",
              }}
            >
              Fee Structure
            </Grid>
            {/* <Grid item xs={6} sx={{ border: `1px solid ${colors.lightGrey}`, textAlign: "center", fontWeight: "bold", p: 1, fontSize: "0.9rem" }}>
                Fees (in %)
              </Grid> */}

            {/* Table Rows */}
            {timePeriods.map((period, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    border: `1px solid ${colors.lightGrey}`,
                    textAlign: "center",
                    p: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  {period}
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    border: `1px solid ${colors.lightGrey}`,
                    textAlign: "center",
                    p: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  {values[index] === "" || values[index] === "-"
                    ? "-"
                    : values[index]}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Card>
      </div>
    );
  };

  const FetchData = async () => {
    try {
      const response = await dispatch(
        getTransactionRequest({ holdingName: schemes?.assetName })
      );
      console.log(response?.payload?.data?.data, "Response");
      setTransactionData(response?.payload?.data?.data || {});
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    FetchData();
  }, []);

  const labelToKeyMap = {
    Category: "category",
    "Exit Load": "exitLoad",
    Inception: "inceptionDate",
  };

  console.log(schemes, "schemes");

  const SchemeDetailsData = () => {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
      null
    );
    const isPopoverOpen = Boolean(popoverAnchorEl);

    // Function to get the truncated text
    const truncateText = (text: string | any[], length: number) => {
      if (text.length > length) {
        return `${text.slice(0, length)}...`;
      }
      return text;
    };
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setPopoverAnchorEl(event.currentTarget); // Correct event type
    };
    const handlePopoverClose = () => {
      setPopoverAnchorEl(null); // Reset
    };
    return (
      <>
        {!schemes || schemes.length === 0 ? (
          <Skeleton
            variant="rectangular"
            height={200}
            animation="wave"
            sx={{ bgcolor: colors.lightBg }}
          />
        ) : (
          <div className="flex flex-col w-full gap-4 justify-between">
            {Object.entries(labelToKeyMap).map(([label, key]) => {
              const value = schemes[key] || "-";
              return (
                <div
                  key={label}
                  className="flex flex-row justify-between w-full h-12 border-b-2 border-gray-200 items-center p-4 hover:bg-lightBg"
                >
                  {label === "Exit Load" ? (
                    <>
                      <h3 className="text-sm sm:text-md font-bold">{label}</h3>
                      <div>
                        <Tooltip title="" arrow>
                          <span
                            className="text-sm text-right sm:text-justify"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100px",
                            }}
                          >
                            {" "}
                            {truncateText(value, 15)}
                          </span>
                        </Tooltip>
                        <IconButton
                          onClick={handlePopoverOpen}
                          size="small"
                          sx={{ padding: "0 5px" }}
                        >
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                        <Popover
                          open={isPopoverOpen}
                          anchorEl={popoverAnchorEl}
                          onClose={handlePopoverClose}
                          disableScrollLock={true}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Box p={2} sx={{ maxWidth: 300 }}>
                            {value}
                          </Box>
                        </Popover>
                      </div>
                    </>
                  ) : (
                    <>
                      <h3 className="text-sm sm:text-md font-bold">{label}</h3>
                      <span className="text-sm sm:text-sm text-right sm:text-justify">
                        {label === "Inception" && value !== "-"
                          ? DateUtils.formatDate(value)
                          : value}
                      </span>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  //model Open
  const openInvestModal = () => {
    if (user?.onboardStatus !== 3 && user?.onboardStatus !== 4) {
      setOpen(true);
      setErrorMessage(
        "Please wait for 24 hours. You are not allowed to invest now."
      );
      return;
    }
    setIsInvestModalOpen(true);
  };

  //model Close
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
  };

  //Watchlist ad and Remove Funtion

  const Watchlist = async (id: number) => {
    if (watchList === 1) {
      const data = { itemId: id, itemType: 5 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setWatchList(0);
        setOpen(true);
        setMessage("AIF removed from watchlist.");
      } else {
        setOpen(true);
        setErrorMessage("Failed to Remove to watchlist.");
      }
    } else if (watchList === 0) {
      const data = { itemId: id, itemType: 5 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setWatchList(1);
        setOpen(true);
        setMessage("AIF added to watchlist.");
      } else {
        setOpen(true);
        setErrorMessage("Failed to add to watchlist.");
      }
    }
  };

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Investments", href: "/aif-listing" },
    { label: "AIF" },
  ];

  //Return Rate Keys value for finding
  const returnRateTable = [
    "DP-Return1Yr",
    "DP-Return2Yr",
    "DP-Return3Yr",
    "DP-Return4Yr",
    "DP-Return5Yr",
    "DP-Return7Yr",
    "DP-Return10Yr",
    "DP-inception",
  ];

  const AifreturnRateTable = [
    schemes?.abs1Month,
    schemes?.abs3Month,
    schemes?.abs6Month,
  ];

  const AIFDetail = [
    {
      label: "Fund Manager",
      value: schemes?.fundManager,
    },
    {
      label: "AUM (in Cr)",
      value: schemes?.aumInCr,
    },
    {
      label: "Return Since Inception",
      value: schemes?.returnSinceInception + "%",
    },
  ];
  const AifAnnualReturnTable = [
    schemes?.ann1Year,
    schemes?.ann2Year,
    schemes?.ann3Year,
    schemes?.ann5Year,
  ];

  const formatTextWithLineBreaks = (text: string) => {
    if (!text) return null; // Handle cases where text might be undefined or null

    // Split the text on each comma
    return text
      .split(/(,)/)
      .map((part, index) =>
        part ? (part.match(/,/) ? [part, <br key={index} />] : part) : null
      );
  };

  const AIFChartData = {
    large: schemes?.largeCap || 0,
    mid: schemes?.middleCap || 0,
    small: schemes?.smallCap || 0,
    cash: schemes?.cash || 0,
  };

  const FundInfo = {
    id: schemes?.id,
    AumInCr: schemes?.aumInCr,
    FundManager: schemes?.fundManager,
    ReturnSinceInception: schemes?.returnSinceInception,
    Category: schemes?.category,
    AssetName: schemes?.assetName,
  };
  const handleShowInterestClick = () => {
    setModalOpen(true);
  };
  const handleTransaction = async () => {
    try {
      const response = await dispatch(
        WithdrawRequest({
          holdingId: transactionData?.ids,
          amount: 0,
        })
      );
      if (response?.payload) {
        setOpen(true);
        setMessage("Redeem request is send successfully.");
      } else {
        setOpen(true);
        setErrorMessage("Failed to send Redeem request.");
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Failed to send Redeem request.");
      console.error("Error uploading file:", error);
    } finally {
      FetchData();
      setModalOpen(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const tabs = [
    {
      label: "Returns",
      component: (
        <ReturnsGrid
          AifreturnRateTable={AifreturnRateTable}
          AifAnnualReturnTable={AifAnnualReturnTable}
        />
      ),
    },
    {
      label: "Fee Structure",
      component: (
        <FeesGrid
          fixed={formatTextWithLineBreaks(schemes?.fixedRate)}
          hybrid={formatTextWithLineBreaks(schemes?.hybridVariableRate)}
        />
      ),
    },
    {
      label: "Scheme Details",
      component: <SchemeDetailsData />,
    },
    {
      label: "Asset Allocation",
      component: (
        <AIFAssetAllocationMobile
          DataBYID={AIFChartData}
          DataBYiSIN={AIFChartData}
        />
      ),
    },
  ];

  return (
    <div>
      {schemes?.id == id ? (
        <div>
          <div className="bg-lightBg">
            <div className="px-4 sm:px-8 pb-8 md:px-[60px] md:pb-[60px] md:pt-[30px] max-w-[1440px] w-full mx-auto ">
              <div className="py-2 sm:pb-4 border-b border-lightGrey sm:mb-8 mb-4">
                <DynamicBreadcrumbs items={breadcrumbItems} />
              </div>
              <div className="flex flex-row sm:flex-row gap-x-6">
                <div className="w-12 h-12 sm:w-14 sm:h-14 mb-3 md:mb-0 md:w-32 md:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl sm:text-4xl font-bold">
                  {getInitials(schemes?.assetName || "")}
                </div>
                <div className="flex flex-col gap-y-3 flex-1 max-w-[800px]">
                  <h3 className="text-xl font-bold sm:text-2xl sm:font-medium text-primary">
                    {schemes?.assetName || ""}
                  </h3>
                  <div className="sm:flex gap-5 mb-5 justify-center md:justify-start hidden">
                    {AIFDetail?.map((detail) => (
                      <FDDetailCard
                        key={detail.label}
                        label={detail.label}
                        value={detail.value}
                      />
                    ))}
                  </div>
                  <div className="sm:flex flex-wrap items-center gap-4 hidden">
                    {/* Buttons and other components */}
                    <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        opacity: checkAIF === 1 ? 1 : 0.5,
                        cursor: checkAIF === 1 ? "pointer" : "not-allowed",
                        fontWeight: "600",
                        border: `1px solid ${colors.primary}`,
                        "&:hover": {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                      onClick={checkAIF === 1 ? openInvestModal : () => {}}
                    >
                      Invest Now
                    </Button>
                    <InvestModal
                      open={isInvestModalOpen}
                      onClose={closeInvestModal}
                      fundInfo={FundInfo}
                    />
                    {/* <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        fontWeight: '600',
                        // fontSize: '14px',
                        '&:hover': {
                          backgroundColor: colors.primary,
                          color: colors.white
                        },
                      }}
                      variant="outlined"
                    >
                      <Download />
                      Download Scheme Document
                    </Button> */}
                    <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        opacity:
                          (transactionData?.totalAmount ?? 0) > 0 ? 1 : 0.5, // Fallback to 0
                        cursor:
                          (transactionData?.totalAmount ?? 0) > 0
                            ? "pointer"
                            : "not-allowed", // Fallback to 0
                        fontWeight: "600",
                        border: `1px solid ${colors.primary}`, // Add a border
                        "&:hover": {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                      onClick={
                        (transactionData?.totalAmount ?? 0) > 0
                          ? handleShowInterestClick
                          : undefined
                      }
                    >
                      Redeem
                    </Button>

                    <Button
                      sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                      }}
                      variant="outlined"
                      onClick={() => Watchlist(schemes?.id)}
                    >
                      {!watchList ? (
                        <>
                          <BookmarkBorder />
                          Add to wishlist
                        </>
                      ) : (
                        <>
                          <FaBookmark
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          Remove from wishlist
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mb-5 justify-center md:justify-start sm:hidden">
                {AIFDetail?.map((detail) => (
                  <>
                    <FDDetailCard
                      key={detail.label}
                      label={detail.label}
                      value={detail.value}
                    />
                  </>
                ))}
              </div>
              <div className="flex justify-end items-center gap-2 my-4 sm:hidden">
                <Button
                  sx={{
                    borderRadius: 1,
                    textTransform: "none",
                    padding: "0.5rem 1rem",
                    color: colors.white,
                    backgroundColor: colors.primary,
                    opacity: checkAIF === 1 ? 1 : 0.5,
                    cursor: checkAIF === 1 ? "pointer" : "not-allowed",
                    fontWeight: "600",
                    fontSize: "12px",
                    "&:hover": {
                      backgroundColor: colors.white,
                      color: colors.primary,
                    },
                  }}
                  onClick={checkAIF === 1 ? openInvestModal : () => {}}
                >
                  Invest Now
                </Button>
                <InvestModal
                  open={isInvestModalOpen}
                  onClose={closeInvestModal}
                  fundInfo={FundInfo}
                />
                <Button
                  sx={{
                    backgroundColor: colors.primary,
                    color: colors.white,
                    borderRadius: 1,
                    textTransform: "none",
                    padding: "0.5rem 1rem",
                    opacity: (transactionData?.totalAmount ?? 0) > 0 ? 1 : 0.5, // Fallback to 0
                    cursor:
                      (transactionData?.totalAmount ?? 0) > 0
                        ? "pointer"
                        : "not-allowed", // Fallback to 0
                    fontWeight: "600",
                    border: `1px solid ${colors.primary}`,
                    fontSize: "12px",
                    "&:hover": {
                      backgroundColor: colors.white,
                      color: colors.primary,
                    },
                  }}
                  onClick={
                    (transactionData?.totalAmount ?? 0) > 0
                      ? handleShowInterestClick
                      : undefined
                  }
                >
                  Redeem
                </Button>
                <Button
                  sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    padding: "0.5rem 0.5rem",
                    color: colors.primary,
                    backgroundColor: "transparent",
                    // border: `1px solid ${colors.primary}`,
                  }}
                  // variant="outlined"
                  onClick={() => Watchlist(schemes?.id)}
                >
                  {!watchList ? (
                    <>
                      <BookmarkBorder />
                    </>
                  ) : (
                    <>
                      <FaBookmark
                        style={{ fontSize: "20px", marginRight: "5px" }}
                      />
                    </>
                  )}
                </Button>
              </div>

              {/* <div>
                <div className='flex justify-end'>
                  <ToggleButtonGroup
                    color="primary"
                    value={filter}
                    exclusive
                    onChange={(_, value) => setFilter(value)}
                    aria-label="text alignment"
                    sx={{
                      paddingVertical: "2px",
                      height: "40px",
                      "& .MuiToggleButton-root.Mui-selected": {
                        backgroundColor: colors.primary,
                        color: "white",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {lineOptions.map((option) => (
                      <ToggleButton key={option.value} value={option.value} aria-label="alignment">
                        {option.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>
                <Box sx={{ width: 1300, overflow: 'auto' }}>
                  {mappedXAxisData.length === filterChartData.series[0]?.data.length ? (
                    <LineChart
                      xAxis={[{
                        label: "Date",
                        data: mappedXAxisData,
                        tickInterval: 'auto',
                        scaleType: 'time',
                        valueFormatter: (date) => dayjs(date).format("DD/MM/YYYY"),
                      }]}
                      series={[
                        { data: filterChartData.series[0]?.data },
                      ]}
                      height={500}
                      slotProps={{
                        mark: {
                          display: "none"
                        }
                      }}
                    // width={1600}

                    />
                  ) : (
                    <Dots />
                  )}
                </Box>



              </div> */}
            </div>
          </div>
          {(isSmallScreen || isMediumScreen) && (
            <div className="w-full flex items-center justify-between mt-2 mb-4 pb-1">
              <TabsComponent
                tabs={tabs}
                onLogout={() => {
                  ("");
                }}
              />
            </div>
          )}

          {!isSmallScreen && !isMediumScreen && (
            <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto ">
              <div className="my-5">
                <div className="my-5">
                  <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        backgroundColor: colors.lightBg,
                        borderRadius: "5px",
                        borderColor: colors.primary,
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      <h6 className="font-semibold text-lg text-primary">
                        Returns & Ranking
                      </h6>
                    </AccordionSummary>
                    <AccordionDetails sx={{ margin: "1rem 0" }}>
                      <TableContainer component={Card}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead
                            sx={{
                              backgroundColor: colors.bgColor,
                              boxShadow: "none",
                            }}
                          >
                            <TableRow className="border border-primary">
                              <TableCell
                                sx={{ border: "none", color: colors.primary }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                sx={{ border: "none", color: colors.primary }}
                              >
                                1M
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "none",
                                  color: colors.primary,
                                  textAlign: "center",
                                }}
                              >
                                2M
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ border: "none", color: colors.primary }}
                              >
                                6M
                              </TableCell>
                              <TableCell
                                sx={{ border: "none", color: colors.primary }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <React.Fragment>
                              <TableRow
                                sx={
                                  {
                                    // "&:last-child td, &:last-child th": { border: 0 },
                                  }
                                }
                                className="border-r border-primary"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="border-x border-primary bg-bgColor border-0"
                                >
                                  Absolute Returns(%)
                                </TableCell>
                                {AifreturnRateTable?.map((rate, index) => (
                                  <TableCell key={index} align="center">
                                    {rate == "-" || rate == ""
                                      ? rate
                                      : rate + "%"}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </React.Fragment>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <TableContainer component={Card} className="mt-8">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead
                            sx={{
                              backgroundColor: colors.bgColor,
                              boxShadow: "none",
                            }}
                          >
                            <TableRow className="border border-primary">
                              <TableCell
                                sx={{ border: "none", color: colors.primary }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                sx={{ border: "none", color: colors.primary }}
                              >
                                1Y
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "none",
                                  color: colors.primary,
                                  textAlign: "center",
                                }}
                              >
                                2Y
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ border: "none", color: colors.primary }}
                              >
                                3Y
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ border: "none", color: colors.primary }}
                              >
                                5Y
                              </TableCell>
                              <TableCell
                                sx={{ border: "none", color: colors.primary }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <React.Fragment>
                              <TableRow
                                sx={
                                  {
                                    // "&:last-child td, &:last-child th": { border: 0 },
                                  }
                                }
                                className="border-r border-primary"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="border-x border-primary bg-bgColor border-0"
                                >
                                  Annualised Returns(%)
                                </TableCell>
                                {AifAnnualReturnTable?.map((rate, index) => (
                                  <TableCell key={index} align="center">
                                    {rate == "-" || rate == ""
                                      ? rate
                                      : rate + "%"}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </React.Fragment>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="my-5">
                  <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        backgroundColor: colors.lightBg,
                        borderRadius: "5px",
                        borderColor: colors.primary,
                        borderWidth: "1px",
                        borderStyle: "solid",
                      }}
                    >
                      <h6 className="font-semibold text-lg text-primary">
                        Fee Structure
                      </h6>
                    </AccordionSummary>
                    <AccordionDetails sx={{ margin: "1rem 0" }}>
                      <TableContainer component={Card}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead
                            sx={{
                              backgroundColor: colors.bgColor,
                              boxShadow: "none",
                            }}
                          >
                            <TableRow className="border border-primary">
                              <TableCell
                                align="center"
                                sx={{ border: "none", color: colors.primary }}
                                colSpan={2}
                              >
                                Fee Structure
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <React.Fragment>
                              <TableRow
                                sx={
                                  {
                                    // "&:last-child td, &:last-child th": { border: 0 },
                                  }
                                }
                                className="border-r border-primary"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="border-x border-primary bg-bgColor border-0"
                                >
                                  Fixed
                                </TableCell>
                                <TableCell align="center">
                                  {formatTextWithLineBreaks(schemes?.fixedRate)}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={
                                  {
                                    // "&:last-child td, &:last-child th": { border: 0 },
                                  }
                                }
                                className="border-r border-primary"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="border-x border-primary bg-bgColor border-0"
                                >
                                  Hybrid/Variable
                                </TableCell>
                                <TableCell align="center">
                                  {formatTextWithLineBreaks(
                                    schemes?.hybridVariableRate
                                  )}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderRadius: "5px",
                    borderColor: colors.primary,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <h6
                    className="font-semibold text-lg"
                    style={{ color: colors.primary }}
                  >
                    About
                    {fundDetails.schemeName &&
                    fundDetails.schemeName?.includes("FUND")
                      ? fundDetails.schemeName.split("FUND")[0]
                      : fundDetails.schemeName}
                  </h6>
                </AccordionSummary>
                <AccordionDetails sx={{ margin: "1rem 0" }}>
                  <Typography className="text-textGrey font-inter">
                    Trusted by millions of customers, Shriram Fixed Deposit is
                    the safest investment option with attractive interest rates.
                    Rated "[ICRA]AA+ (Stable)" by ICRA and "IND AA+/Stable" by
                    India Ratings and Research.
                  </Typography>
                  <div className="flex gap-5 mb-5 justify-between pt-2">
                    <FDMangerCard data={fundDetails?.managers} />
                  </div>
                </AccordionDetails>
              </Accordion> */}
              </div>
              <div className="my-5">
                <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      backgroundColor: colors.lightBg,
                      borderRadius: "5px",
                      borderColor: colors.primary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <h6 className="font-semibold text-lg text-primary">
                      Scheme Details
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ margin: "2rem 0.5rem", padding: "0" }}
                  >
                    <div className="flex flex-wrap gap-5 border border-lightGrey rounded-md  p-4">
                      <SchemeDetailsData />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* <div className="my-5">
              <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderRadius: "5px",
                    borderColor: colors.primary,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <h6 className="font-semibold text-lg text-primary">
                    Return Calculator
                  </h6>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: "0" }} className="mt-8">
                  <MFCalculator
                    interestRateMF={interestRateMF}
                    fundDetails={fundDetails}
                    setIsInvestModalOpen={setIsInvestModalOpen}
                  />
                </AccordionDetails>
              </Accordion>
            </div> */}
              {schemes?.largeCap === "-" &&
              schemes?.middleCap === "-" &&
              schemes?.smallCap === "-" &&
              schemes?.cash === "-" ? (
                <></>
              ) : (
                <>
                  <div className="my-5">
                    <AIFAssetAllocation
                      DataBYID={AIFChartData}
                      DataBYiSIN={AIFChartData}
                    />
                  </div>
                </>
              )}

              {/* <div className="my-5">
              <RiskRatios />
            </div> */}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="bg-lightBg h-full">
            <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto ">
              <div className="flex flex-col md:flex-row gap-x-6">
                <div className="w-14 h-14 mb-3 md:mb-0 md:w-32 md:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-4xl font-bold">
                  <Skeleton variant="circular" width={120} height={120} />
                </div>
                <div className="flex flex-col gap-y-3 flex-1 w-full">
                  <h3 className="text-2xl font-medium text-primary">
                    <Skeleton variant="text" width={200} />
                  </h3>
                  <div className="flex flex-wrap gap-5 mb-1 justify-center md:justify-start">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width={300}
                        height={50}
                      />
                    ))}
                  </div>
                  <div className="flex flex-wrap items-center gap-4">
                    {[1].map((index) => (
                      <Skeleton key={index} width="100%" height={500} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)} // Close modal on cancel or outside click
      >
        <DialogContent>
          <Typography variant="h6" className="font-bold my-3 text-center">
            Are you sure you want to submit redeem request?
          </Typography>
        </DialogContent>
        <DialogActions className="w-[97%] mb-3">
          <Btn
            onClick={handleTransaction}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            Confirm
          </Btn>
          <Btn
            onClick={() => setModalOpen(false)}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            Cancel
          </Btn>
        </DialogActions>
      </Dialog>
      <SuccessfullModal
        open={Open}
        message={message !== "" ? message : errormessage}
        handleClose={handleClose}
      />
    </div>
  );
};

export default AIFDetails;
