import React, { useState } from "react";
import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";

interface TabData {
  label: string;
  isDisabled?: boolean;
  component?: React.ReactNode;
  isLogout?: boolean;
}

interface TabsComponentProps {
  tabs: TabData[];
  onLogout: () => void;
}

const TabsComponent: React.FC<TabsComponentProps> = ({ tabs, onLogout }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (tabs[newValue]?.isLogout) {
      onLogout();
    } else {
      setValue(newValue);
    }
  };

  const isSmallScreen= useMediaQuery("(max-width:640px)");

  return (
    <Box sx={{ width: "100%" }}> {/* Ensure full width */}
      {/* Tabs Navigation */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTabs-indicator": {
            backgroundColor: "primary.main",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              fontSize:isSmallScreen ? "0.8rem" : "1rem",
              textTransform: "none",
              color: value === index ? "primary.main" : "text.primary",
              backgroundColor: value === index ? "white" : "transparent",
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
            disabled={tab.isDisabled}
          />
        ))}
      </Tabs>

      {/* Render only the active tab's component and ensure full width */}
      <Box
        sx={{
          mt: 2,
          width: "100%", // Ensures the container takes full width
          minWidth: "100%", // Forces it to take full available width
          maxWidth: "100%", // Prevents it from shrinking
        }}
      >
        {tabs[value]?.component && (
          <div className="w-full max-h-screen overflow-y-auto rounded-lg scrollbar-hide">
            {tabs[value].component}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default TabsComponent;
