import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Award,
  BadgeCheck,
  Building2,
  Mail,
  MapPin,
  Phone,
  Shield,
  Target,
  TrendingUp,
  User,
} from "lucide-react";
import { cn } from "../../utils";
import PartnerService from "../../redux/Partner/PartnerService";
import toast from "react-hot-toast";
import { Modal } from "@mui/material";
import VerificationModal from "../../components/modals/VerificationModal";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import APIloader from "../../components/AppLoader/APIloader";
import ConfirmationModal from "../../components/modals/confirmationSuccessModal";

type FormData = {
  distributorName: string;
  type: number;
  pinCode: string;
  city: string;
  objective: number;
  personName: string;
  email: string;
  mobile: string;
  isArnHolder: number;
  arnNumber?: string;
  consent: boolean;
};

const RegisterPartner = () => {
  const navigate = useNavigate();
  const formikRef = useRef<any>();
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const registerSchema = yup.object({
    distributorName: yup.string().required(),
    type: yup.number().oneOf([0, 1]).required(),
    pinCode: yup.string().required(),
    city: yup.string().required(),
    objective: yup.number().oneOf([0, 1]).required(),
    personName: yup.string().required(),
    email: yup.string().email().required(),
    mobile: yup.string().required(),
    isArnHolder: yup.number().oneOf([0, 1]).required(),
    arnNumber: yup
      .string()
      .when("isArnHolder", (isArnHolder: any, schema: any) => {
        return isArnHolder === "yes"
          ? schema.required("ARN Number is required")
          : schema.notRequired();
      }),
    consent: yup.boolean().oneOf([true], "Consent is required").required(),
  });

  const registerValues: FormData = {
    distributorName: "",
    type: 0,
    pinCode: "",
    city: "",
    objective: 0,
    personName: "",
    email: "",
    mobile: "",
    isArnHolder: 1,
    arnNumber: "",
    consent: false,
  };

  const handleRequestCall = async () => {
    // Handle request call
    try {
      const formData = new FormData();
      if (
        !formikRef.current ||
        !formikRef.current?.isValid ||
        !formikRef.current?.dirty
      ) {
        return;
      }
      setLoading(true);
      const values = formikRef.current.values;
      formData.append("distributor_name", values?.distributorName);
      formData.append("type", values?.type);
      formData.append("pincode", values?.pinCode);
      formData.append("city", values?.city);
      formData.append("objective", values?.objective);
      formData.append("person_name", values?.personName);
      formData.append("email", values?.email);
      formData.append("mobile", `+91${values?.mobile}`);
      formData.append("arn_holder", values?.isArnHolder);
      formData.append("arn_number", values?.arnNumber);
      formData.append("request_type", "0");

      const response = await PartnerService.partnerRequest(formData);
      if (!response?.success) {
        setLoading(false);
        toast.error(response?.response?.data?.message);
        return;
      }
      // toast.success(
      //   "Your partner request submitted successfully. We will contact you soon."
      // );
      setOpenSuccess(true);
      formikRef.current.resetForm();
      setLoading(false);
      // navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
      toast.error("Failed to send request!");
    }
  };

  const handlePartnerOTP = async (values: any) => {
    try {
      setLoading(true);
      const updatedValues = {
        ...values,
        mobile: `+91${values?.mobile}`,
      };
      const response = await PartnerService.sendPartnerOtp(updatedValues);
      if (response?.success) {
        setOpen(true);
      } else {
        toast.error(response?.response?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Failed to send OTP");
    }
  };

  const handleVerifyOTP = async (values: any) => {
    try {
      setLoading(true);
      const response = await PartnerService.verifyPartnerOtp({
        mobileOtp: values.mobileOTP,
        emailOtp: values.emailOTP,
      });
      if (response?.success) {
        handleSuccess();
      } else {
        toast.error(response?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Failed to send OTP");
    }
  };

  const handleSuccess = () => {
    setOpen(false);
    toast.success("Email and mobile verified successfully!");
    navigate("/partner/complete-profile", {
      state: {
        data: formikRef.current?.values,
      },
    });
  };

  return (
    <>
      <APIloader loadingModal={loading} message="Please wait..." />
      <div className="min-h-screen bg-lightBg py-24 px-4 sm:px-6 lg:px-8 font-nunitosans">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <div className="mb-8 relative">
              <img
                src="https://images.unsplash.com/photo-1560472355-536de3962603?auto=format&fit=crop&q=80&w=2070"
                alt="Financial Success"
                className="w-full h-80 object-cover rounded-2xl shadow-md"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-primary/80 to-transparent rounded-2xl"></div>
              <div className="absolute top-1/2 left-8 transform -translate-y-1/2 text-left">
                <h1 className="text-5xl font-bold text-white mb-4 font-nunito">
                  Join Our Network of
                  <br />
                  Financial Excellence
                </h1>
                <p className="text-xl text-white/90 max-w-xl font-inter">
                  Become a part of India's leading wealth management platform
                </p>
              </div>
            </div>
          </motion.div>

          <div className="grid lg:grid-cols-2 gap-12">
            {/* Form Section */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white rounded-2xl shadow-md p-8"
            >
              <Formik
                innerRef={formikRef}
                initialValues={registerValues}
                validationSchema={registerSchema}
                onSubmit={(values: any) => {
                  handlePartnerOTP(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <>
                    <form onSubmit={handleSubmit} className="space-y-6">
                      {/* Distributor Details */}
                      <div className="space-y-4">
                        <h2 className="text-2xl font-semibold flex items-center gap-2 text-primary font-nunito">
                          <Building2 className="w-6 h-6 text-secondary" />
                          Distributor Details
                        </h2>

                        <div className="grid gap-4">
                          <div>
                            <label className="block text-sm font-medium text-darkGrey mb-2 font-inter">
                              Type
                            </label>
                            <div className="space-x-6">
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  onChange={(e) => setFieldValue("type", 0)}
                                  name="type"
                                  checked={values.type === 0}
                                  value={0}
                                  className="w-4 h-4 text-primary border-lightblueBorder focus:ring-primary"
                                />
                                <span className="ml-2 text-darkGrey font-nunitosans">
                                  Individual
                                </span>
                              </label>
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  onChange={(e) => setFieldValue("type", 1)}
                                  name="type"
                                  checked={values.type === 1}
                                  value={1}
                                  className="w-4 h-4 text-primary border-lightblueBorder focus:ring-primary"
                                />
                                <span className="ml-2 text-darkGrey font-nunitosans">
                                  Non-Individual
                                </span>
                              </label>
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                              Distributor Name
                            </label>
                            <input
                              type="text"
                              onChange={handleChange("distributorName")}
                              name="distributorName"
                              value={values.distributorName}
                              required
                              className={cn(
                                "w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition-colors font-nunitosans",
                                errors.distributorName
                                  ? "border-deep_orange-500"
                                  : "border-lightblueBorder"
                              )}
                            />
                          </div>

                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                                City
                              </label>
                              <input
                                type="text"
                                onChange={handleChange("city")}
                                name="city"
                                value={values.city}
                                required
                                className={cn(
                                  "w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                                  errors.city
                                    ? "border-deep_orange-500"
                                    : "border-lightblueBorder"
                                )}
                                placeholder="Enter your city"
                              />
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                                PIN Code
                              </label>
                              <div className="relative">
                                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-textGrey" />
                                <input
                                  type="text"
                                  onChange={handleChange("pinCode")}
                                  name="pinCode"
                                  value={values.pinCode}
                                  required
                                  className={cn(
                                    "w-full pl-10 pr-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                                    errors.pinCode
                                      ? "border-secondary"
                                      : "border-primary"
                                  )}
                                  placeholder="Enter PIN code"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Objective */}
                      <div>
                        <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                          Select Objective
                        </label>
                        <select
                          onChange={handleChange("objective")}
                          name="objective"
                          value={values.objective}
                          required
                          className={cn(
                            "w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                            errors.objective
                              ? "border-secondary"
                              : "border-primary"
                          )}
                        >
                          <option value={0}>Want to become MFDs</option>
                          <option value={1}>
                            Want to become Sampann CFPDs
                          </option>
                        </select>
                      </div>

                      {/* Personal Details */}
                      <div className="space-y-4">
                        <h2 className="text-2xl font-semibold flex items-center gap-2 text-primary font-nunito">
                          <User className="w-6 h-6 text-secondary" />
                          Personal Details
                        </h2>

                        <div>
                          <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                            Name of Person
                          </label>
                          <input
                            type="text"
                            onChange={handleChange("personName")}
                            name="distributorName"
                            value={values.personName}
                            required
                            className={cn(
                              "w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                              errors.personName
                                ? "border-secondary"
                                : "border-primary"
                            )}
                          />
                        </div>

                        {/* Email with OTP */}
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                            Email Address
                          </label>
                          <div className="flex gap-2">
                            <div className="relative flex-1">
                              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-textGrey" />
                              <input
                                type="email"
                                onChange={handleChange("email")}
                                name="email"
                                value={values.email}
                                required
                                className={cn(
                                  "w-full pl-10 pr-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                                  errors.email
                                    ? "border-secondary"
                                    : "border-primary"
                                )}
                              />
                            </div>
                            {/* <button
                          type="button"
                          onClick={() => setEmailVerified(true)}
                          className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-indigo-800 transition-colors font-nunito"
                        >
                          Verify
                        </button> */}
                          </div>
                          {/* {emailVerified && (
                        <div className="flex items-center gap-2 text-tertiary">
                          <BadgeCheck className="w-5 h-5" />
                          <span className="text-sm font-inter">
                            Email verified
                          </span>
                        </div>
                      )} */}
                        </div>

                        {/* Mobile with OTP */}
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-darkGrey mb-1 font-inter">
                            Mobile Number
                          </label>
                          <div className="flex gap-2">
                            <div className="relative flex-1">
                              <div className="absolute left-3 top-1/2 -translate-y-1/2 flex items-center gap-2">
                                <Phone className="w-5 h-5 text-textGrey" />
                                +91
                              </div>
                              <input
                                type="tel"
                                onChange={handleChange("mobile")}
                                name="mobile"
                                value={values.mobile}
                                required
                                className={cn(
                                  "w-full pl-20 pr-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                                  errors.mobile
                                    ? "border-secondary"
                                    : "border-primary"
                                )}
                              />
                            </div>
                            {/* <button
                          type="button"
                          onClick={() => setMobileVerified(true)}
                          className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-indigo-800 transition-colors font-nunito"
                        >
                          Verify
                        </button> */}
                          </div>
                          {/* {mobileVerified && (
                        <div className="flex items-center gap-2 text-tertiary">
                          <BadgeCheck className="w-5 h-5" />
                          <span className="text-sm font-inter">
                            Mobile verified
                          </span>
                        </div>
                      )} */}
                        </div>

                        {/* ARN Holder */}
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-darkGrey mb-2 font-inter">
                            Are you an ARN Holder?
                          </label>
                          <div className="space-x-6">
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  setFieldValue(
                                    "isArnHolder",
                                    Number(e.target.value)
                                  )
                                }
                                name="isArnHolder"
                                checked={values.isArnHolder === 1}
                                value={1}
                                className="w-4 h-4 text-primary border-lightblueBorder focus:ring-primary"
                              />
                              <span className="ml-2 text-darkGrey font-nunitosans">
                                Yes
                              </span>
                            </label>
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                onChange={(e) =>
                                  setFieldValue(
                                    "isArnHolder",
                                    Number(e.target.value)
                                  )
                                }
                                name="isArnHolder"
                                checked={values.isArnHolder === 0}
                                value={0}
                                className="w-4 h-4 text-primary border-lightblueBorder focus:ring-primary"
                              />
                              <span className="ml-2 text-darkGrey font-nunitosans">
                                No
                              </span>
                            </label>
                          </div>

                          {values.isArnHolder === 1 && (
                            <input
                              type="text"
                              onChange={handleChange("arnNumber")}
                              name="arnNumber"
                              value={values.arnNumber}
                              placeholder="Enter ARN Number"
                              className={cn(
                                "w-full px-4 py-2 border border-lightblueBorder rounded-lg focus:ring-2 focus:ring-primary focus:border-primary font-nunitosans",
                                errors.arnNumber
                                  ? "border-secondary"
                                  : "border-primary"
                              )}
                            />
                          )}
                        </div>
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setFieldValue("consent", e.target.checked);
                              e.target.setCustomValidity(""); // Reset warning when checked
                            }}
                            checked={values.consent}
                            name="consent"
                            className="w-4 h-4 text-primary border-lightblueBorder rounded focus:ring-primary"
                            required
                          />
                          <label className="text-sm text-textGrey font-inter">
                            By filling this inquiry form, I agree to be
                            contacted through call / email / WhatsApp / SMS
                          </label>
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex gap-4">
                        <button
                          type="submit"
                          className="flex-1 bg-primary text-white px-6 py-3 rounded-lg hover:bg-indigo-800 transition-colors font-nunito"
                        >
                          Start Registration
                        </button>
                        <button
                          type="button"
                          className="flex-1 bg-white text-primary px-6 py-3 rounded-lg border-2 border-primary hover:bg-lightBg transition-colors font-nunito"
                          onClick={() => {
                            const consentCheckbox =
                              document.querySelector<HTMLInputElement>(
                                'input[name="consent"]'
                              );
                            if (consentCheckbox && !consentCheckbox.checked) {
                              consentCheckbox.setCustomValidity(
                                "Please tick this box if you want to proceed."
                              );
                              consentCheckbox.reportValidity(); // Show built-in warning
                              return;
                            }
                            handleRequestCall();
                          }}
                        >
                          Request a Call
                        </button>
                      </div>

                      {/* Disclaimer */}
                      <div className="space-y-4 pt-4 border-t border-mediumBg">
                        <div className="flex items-start gap-2 text-sm text-textGrey">
                          <Shield className="w-5 h-5 text-primary flex-shrink-0 mt-0.5" />
                          <p className="font-inter">
                            We value your privacy and will never share your
                            contact information with anyone else
                          </p>
                        </div>
                      </div>
                    </form>
                    <Modal
                      open={open}
                      onClose={() => setOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      sx={{ backgroundColor: colors.white }}
                    >
                      <VerificationModal
                        onClose={() => setOpen(false)}
                        onSuccess={handleSuccess}
                        signupValues={{
                          email: values.email,
                          phone: values.mobile,
                        }}
                        handleSignup={() =>
                          handlePartnerOTP({
                            email: values.email,
                            mobile: values.mobile,
                          })
                        }
                        handleOtp={(values: any) => handleVerifyOTP(values)}
                      />
                    </Modal>
                  </>
                )}
              </Formik>
            </motion.div>

            {/* Info Section */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="lg:pt-12"
            >
              <div className="sticky top-12 space-y-8">
                <div className="bg-gradient-to-br from-primary to-indigo-800 rounded-2xl p-8 text-white relative overflow-hidden">
                  <div className="absolute top-0 right-0 opacity-10">
                    <img
                      src="https://images.unsplash.com/photo-1551434678-e076c223a692?auto=format&fit=crop&q=80&w=2070"
                      alt="Success"
                      className="w-64 h-64 object-cover"
                    />
                  </div>
                  <h3 className="text-2xl font-semibold mb-6 font-nunito">
                    Why Join Us?
                  </h3>
                  <ul className="space-y-6">
                    {[
                      {
                        icon: Award,
                        title: "Industry Recognition",
                        desc: "Access to comprehensive financial products and services",
                      },
                      {
                        icon: Target,
                        title: "Growth Potential",
                        desc: "Industry-leading commission structures",
                      },
                      {
                        icon: TrendingUp,
                        title: "Business Support",
                        desc: "Advanced technology platform and dedicated training",
                      },
                    ].map((benefit, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.6 + index * 0.1 }}
                        className="flex items-start gap-4"
                      >
                        <benefit.icon className="w-6 h-6 text-secondary flex-shrink-0" />
                        <div>
                          <h4 className="font-semibold mb-1 font-nunito">
                            {benefit.title}
                          </h4>
                          <p className="text-sm text-blue-100 font-inter">
                            {benefit.desc}
                          </p>
                        </div>
                      </motion.li>
                    ))}
                  </ul>
                </div>

                <div className="bg-white rounded-2xl p-8 shadow-md">
                  <img
                    src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?auto=format&fit=crop&q=80&w=2069"
                    alt="Success Story"
                    className="w-full h-48 object-cover rounded-lg mb-6"
                  />
                  <h3 className="text-2xl font-semibold mb-4 text-primary font-nunito">
                    Partner Success Story
                  </h3>
                  <div className="space-y-4">
                    <p className="text-textGrey font-inter">
                      "Joining AumSampann was a game-changer for my career in
                      financial distribution. The support, technology, and
                      product range helped me grow my business exponentially."
                    </p>
                    <div>
                      <p className="font-semibold text-primary font-nunito">
                        Rajesh Kumar
                      </p>
                      <p className="text-sm text-textGrey font-inter">
                        Top Performing Partner, Delhi NCR
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        onConfirm={() => {
          setOpenSuccess(false);
          navigate("/");
        }}
        type="partner"
      />
    </>
  );
};

export default RegisterPartner;
