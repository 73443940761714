import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import React from 'react';

interface VerifyOTPModalProps {
    title: string;
    subTitle?: string;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    otp: string;
    setOtp: React.Dispatch<React.SetStateAction<string>>;
    onClick: () => void;
}

const VerifyOTPModal = ({ title, subTitle, showModal, setShowModal, otp, setOtp, onClick }: VerifyOTPModalProps) => {
    const boxStyle = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    // Function to handle key down event
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onClick(); // Call the onClick function to verify OTP
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            onClose={() => setShowModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Fade in={showModal}>
                <Box sx={boxStyle}>
                    <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {title}
                    </Typography>
                    {subTitle && <p className="text-sm text-textGrey mt-2">{subTitle}</p>}
                    <div
                        id="transition-modal-description"
                        className="flex gap-x-3 py-5"
                    >
                        {/* Input field for OTP */}
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            className="border border-solid border-lightGrey p-3 rounded-md"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onKeyDown={handleKeyDown} // Add onKeyDown handler here
                        />
                        {/* Button for verifying OTP */}
                        <button
                            type="button"
                            className="text-orange font-bold text-lg"
                            onClick={onClick}
                        >
                            Verify OTP
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default VerifyOTPModal;
