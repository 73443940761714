import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  TableFooter,
  Typography,
  Box,
  Button,
  Modal,
} from "@mui/material";
import { Close, Delete, Edit } from "@mui/icons-material";
import { GrTransaction } from "react-icons/gr";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";
import {
  deleteHoldingsData,
  editHoldingsData,
  editHoldingsData2,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { DateUtils } from "../../utils/FormatDate";
import VisibilityIcon from "@mui/icons-material/Visibility";

type Transaction = {
  id: number;
};

interface TransactionsModalProps {
  open: boolean;
  onClose: () => void;
  transactions: {
    transactionDate: string;
    investedAmount: number;
    days: number;
  }[];
  fetchDataPMS: any;
}
const TransactionsModal: React.FC<TransactionsModalProps> = ({
  open,
  onClose,
  transactions,
  fetchDataPMS,
}) => {
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");

  const formatTransactionDate = (dateString: any) => {
    if (dateString?.includes("/")) {
      const [day, month, year] = dateString.split("/"); // Convert MM/DD/YYYY format to DD-MM-YYYY
      return `${day}/${month}/${year}`;
    }

    // Check if the date is already in DD-MM-YYYY format
    if (dateString?.includes("-")) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`; // Keep as DD-MM-YYYY
    }

    return dateString; // If the format is unexpected, return the original
  };

  const calculateDays = (dateString: any) => {
    // Convert formatted date string to Date object
    const [day, month, year] = dateString.split("-");
    const transactionDate = new Date(`${year}-${month}-${day}T00:00:00`);
    const today = new Date();

    // Reset the time portion to ignore time differences
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    // Calculate the difference in milliseconds
    const timeDiff = todayWithoutTime.getTime() - transactionDate.getTime();
    // Convert milliseconds to days
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));

    return isNaN(diffDays) ? 1 : diffDays + 1; // Return 0 days for the same day or NaN case
  };

  const handleOpen = (
    id: any,
    investedAmount: any,
    currentAmount: any,
    transactionDate: any
  ) => {
    setFields([
      { name: "investedAmount", label: "Investment Amount", type: "number" },
      { name: "currentAmount", label: "Current Amount", type: "number" },
      { name: "transactionDate", label: "Transaction Date", type: "text" },
    ]);
    setValues({
      investedAmount: Number(investedAmount),
      currentAmount: Number(currentAmount),
      transactionDate: transactionDate,
    });
    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData({
          id: id,
          investedAmount: formData?.investedAmount,
          currentAmount: formData?.currentAmount,
          transactionDate: formData?.transactionDate,
          holdingType: holdingType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`Scheme edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "PMS",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`Scheme deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSuccessClose = () => {
    fetchDataPMS();
    onClose();
    setOpen(false);
    setMessage("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  return (
    <>
      <Modal open={open} onClose={onClose} disableEnforceFocus>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 900, // Adjust width as needed
            maxHeight: "80vh", // Limit the height of the modal
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowX: "auto", // Make the content scrollable on the Y-axis
            borderRadius: 2,
            zIndex: 1000,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6">Transaction Details</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "200px",
              borderTop: "1.5px solid rgba(224, 224, 224, 1)",
              padding: "0 5px",
              overflow: "auto", // Enable horizontal scrolling
              maxWidth: "100%", // Ensure the table doesn't exceed the container's width
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                    Transaction Date
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                    Invested Amount
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                    Days
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions?.map((trans: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {trans?.transactionDate
                        ? DateUtils.formatDate(
                            formatTransactionDate(trans?.transactionDate)
                          )
                        : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {"Rs." +
                        formatIndianCurrency(Number(trans?.investedAmount))}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {trans?.days}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        height: "100%",
                        verticalAlign: "middle",
                        borderBottom: "2px solid #ccc",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <IconButton>
                          <Edit
                            onClick={() =>
                              handleOpen(
                                trans?.id,
                                trans?.investedAmount,
                                trans?.currentAmount,
                                trans?.transactionDate
                              )
                            }
                          />
                        </IconButton>
                        <IconButton>
                          <Delete
                            onClick={() =>
                              handleConfirmModalOpen(
                                trans?.id,
                                trans?.holdingName
                              )
                            }
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="PMS"
          heading="PMS Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

const PMSTableRowComponent = ({
  data,
  getInitials,
  modalMenuClick,
  fetchDataPMS,
}: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [selectedTransactions, setSelectedTransactions] = useState<[] | null>(
    null
  );
  const [transModalOpen, setTransModalOpen] = useState(false);
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");
  const [InvestedAmount, setInvestedAmount] = useState<any>("");

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);

  const handleOpenModal = (transactions: any) => {
    setSelectedTransactions(transactions);
    setTransModalOpen(true);
  };
  const handleCloseModal = () => {
    setTransModalOpen(false);
    setSelectedTransactions(null);
  };

  const handleOpen = (investedAmount: any, currentAmount: any) => {
    setFields([
      // { name: "investedAmount", label: "Investment Amount", type: "number" },
      { name: "currentAmount", label: "Current Amount", type: "number" },
    ]);
    setValues({
      investedAmount: Number(investedAmount),
      currentAmount: Number(currentAmount),
    });
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData2({
          id: id,
          totalInvestment: Number(values.investedAmount),
          currentAmount: Number(formData?.currentAmount),
          holdingType: 6,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`PMS edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "PMS",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`PMS deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchDataPMS();
    setOpen(false);
    setMessage("");
  };

  const getTransactionIdsString = (transactions: Transaction[]): any => {
    const transactionIds = transactions
      ?.map((transaction) => transaction?.id)
      .join(",");
    setID(transactionIds);
  };

  const handleDownload = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is missing!");
      return;
    }
  
    const url = `${process.env.REACT_APP_DOWNLOAD_URL}${fileUrl}`;
    console.log("Download URL:", url); // Debugging
  
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileUrl.split("/").pop() || "download");
    link.setAttribute("target", "_blank"); // Ensures proper downloading behavior
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
  

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          const Name = item?.holdingName || "N/A";

          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                  className="min-w-[200px]"
                >
                  <div className="flex gap-x-2 items-center">
                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                      {getInitials(Name)}
                    </div>
                    <div className="w-full ml-1">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleExpandClick(index)}
                      >
                        {toCamelCase(Name)}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.category || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." +
                    formatIndianCurrency(
                      Number(item?.investedAmount).toFixed(2)
                    ) || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." +
                    formatIndianCurrency(
                      Number(item?.currentAmount).toFixed(2)
                    ) || "-"}
                </TableCell>
                {/* <TableCell sx={{
                                borderTop: '1px solid #ccc',
                                borderBottom: '1px solid #ccc',
                            }} align="center" className="min-w-[100px]">{item?.realisedPnL}</TableCell>
                            <TableCell sx={{
                                borderTop: '1px solid #ccc',
                                borderBottom: '1px solid #ccc',
                            }} align="center" className="min-w-[100px]">{item?.unrealisedPnL}</TableCell> */}
                <TableCell
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.absolutePnL
                    ? Number(item.absolutePnL.replace("%", "")).toFixed(2) + "%"
                    : "-"}
                </TableCell>
                {/* <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.xirr ? item?.xirr : "-"}
                </TableCell> */}
                <TableCell
                  sx={{
                    textAlign: "center",
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  {item?.transactions?.length > 0 ? (
                    <GrTransaction
                      onClick={() => handleOpenModal(item?.transactions)}
                      className="text-gray-600 text-2xl font-bold hover:text-blue-600 mx-auto"
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    height: "100%",
                    verticalAlign: "middle",
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {item?.filePath!="" &&<IconButton onClick={() => handleDownload(item?.filePath)}>
                      <VisibilityIcon />
                    </IconButton>}
                    <IconButton>
                      <Edit
                        onClick={() => {
                          handleOpen(item?.investedAmount, item?.currentAmount);
                          getTransactionIdsString(item?.transactions);
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <Delete
                        onClick={() =>
                          handleConfirmModalOpen(item?.id, item?.holdingName)
                        }
                      />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
              {/* <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow> */}
            </React.Fragment>
          );
        })}
      {selectedTransactions && (
        <TransactionsModal
          open={transModalOpen}
          onClose={handleCloseModal}
          transactions={selectedTransactions}
          fetchDataPMS={fetchDataPMS}
        />
      )}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="PMS"
          heading="PMS Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default PMSTableRowComponent;
