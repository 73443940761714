import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Edit,
  Delete,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from "../../constants/colors";
import TimeStamptoExp from "../../utils/timestampConverter";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import {
  deleteHoldingsData,
  editHoldingsData,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { toCamelCase } from "../../utils/ToCamelCase";
import { DateUtils } from "../../utils/FormatDate";

const FDTableRowComponent = ({
  data,
  getInitials,
  modalMenuClick,
  fetchDataFD,
}: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");

  const isArray = Array.isArray(data);

  const convertDaysToReadableFormat = (
    days: number,
    schemeName: string
  ): string => {
    if (schemeName && schemeName?.includes("HDFC")) {
      if (days <= 90) {
        return `${days} day${days > 1 ? "s" : ""}`;
      }
    } else {
      return days <= 1 ? days + " month" : days + " months"; // Dynamic value for 12 months, approximated as 12 * 30 = 360 days
    }
    const today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth(); // Month is 0-based (0 = January, 11 = December)
    let currentDay = today.getDate();

    // Helper function to get the number of days in a given month and year
    const daysInMonth = (month: number, year: number): number => {
      return new Date(year, month + 1, 0).getDate();
    };

    // Determine the month length to use based on the current day
    let monthLength = daysInMonth(currentMonth, currentYear);
    if (currentDay > 20) {
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    let totalMonths = 0;
    let remainingDays = days;
    let futureDate = new Date(today);

    // Calculate total months and remaining days
    while (remainingDays >= monthLength) {
      totalMonths += 1;
      remainingDays -= monthLength;
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    // Calculate the exact future date
    futureDate.setMonth(today.getMonth() + totalMonths);
    futureDate.setDate(today.getDate() + remainingDays);

    // Format the readable string
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    let readableFormat = "";
    if (years > 0) readableFormat += `${years} yr${years > 1 ? "s" : ""} `;
    if (months > 0) readableFormat += `${months} mon `;
    if (remainingDays > 0)
      readableFormat += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;

    return readableFormat.trim();
  };
  const formatInterestRate = (
    interestRate: string | number | null | undefined
  ): string => {
    if (typeof interestRate === "string" && interestRate.trim().endsWith("%")) {
      // If the interest rate is already a string with '%', return it as is
      return interestRate;
    } else if (
      typeof interestRate === "number" ||
      !isNaN(Number(interestRate))
    ) {
      // If it's a number or can be converted to a number, format it
      return `${Number(interestRate).toFixed(2)}%`;
    } else {
      // If the value is invalid or not provided, return a default value
      return "-";
    }
  };
  const handleOpen = (
    id: any,
    amount: any,
    tenure: any,
    maturityDate: any,
    fdOption: any,
    roi: any
  ) => {
    setFields([
      { name: "amount", label: "Amount", type: "number" },
      { name: "tenure", label: "Tenure (in Months)", type: "text" },
      { name: "maturityDate", label: "Maturity Date", type: "text" },
      { name: "fdOption", label: "Investment Frequency", type: "text" },
      { name: "roi", label: "ROI", type: "number" },
    ]);
    setValues({
      amount: Number(amount),
      tenure: tenure,
      maturityDate: maturityDate,
      fdOption: fdOption,
      roi: Number(roi),
    });
    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    try {
      setLoading(true);
      const response = await dispatch(
        editHoldingsData({
          id: id,
          amount: Number(formData?.amount),
          tenure: formData?.tenure,
          maturityDate: formData?.maturityDate,
          fdOption: formData?.fdOption,
          roi: Number(formData?.roi),
          holdingType: holdingType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`FD edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType: "FD",
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`FD deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchDataFD();
    setOpen(false);
    setMessage("");
  };

  const remove44Months = (inputString: any): string => {
    return inputString.replace(/ Months/g, "").trim();
  };
  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          const Name = item?.schemeName || "N/A";

          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize:{
                      xs:"12px",
                      sm:"12px",
                      md:"16px"
                    }
                  }}
                  className="min-w-[200px]"
                >
                  <div className="flex gap-x-2 items-center">
                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                      {getInitials(Name)}
                    </div>
                    <div className="w-full ml-1">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleExpandClick(index)}
                      >
                        {toCamelCase(Name)}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[120px]"
                >
                  {item?.investmentDate ? DateUtils.formatDate(item?.investmentDate) : "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[120px]"
                >
                  {item?.endDate
                    ? /^\d{2}\/\d{2}\/\d{4}$/.test(item?.endDate.toString()) // Check if the endDate is in DD/MM/YYYY format
                      ? item.endDate // If already in correct format, display the date as is
                      : DateUtils.formatDate(
                          TimeStamptoExp.formatDateFromTimestamp(
                            Number(item?.endDate)
                          )
                        ) // Otherwise, format it
                    : "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.investmentTenure}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." +
                    formatIndianCurrency(Number(item?.amount).toFixed(2)) ||
                    "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." +
                    formatIndianCurrency(
                      Number(item?.returnAmount).toFixed(2)
                    ) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {"Rs." +
                    formatIndianCurrency(
                      Number(item?.returnAmount - item?.amount).toFixed(2)
                    ) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {formatInterestRate(item?.interesetRate) || "-"}
                </TableCell>
                <TableCell
                  sx={{ borderBottom:"2px solid #ccc",fontSize:{
                    xs:"12px",
                    sm:"12px",
                    md:"16px"
                  }}}
                  align="center"
                  className="min-w-[100px]"
                >
                  {item?.investmentFrequency}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{    
                    height: "100%",    
                    verticalAlign: "middle",            
                    borderBottom: "2px solid #ccc",
                  }}
                >
                   <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                  <IconButton>
                    <Edit
                      onClick={() =>
                        handleOpen(
                          item?.id,
                          item?.amount,
                          remove44Months(item?.investmentTenure),
                          item?.endDate
                            ? /^\d{2}\/\d{2}\/\d{4}$/.test(
                                item?.endDate.toString()
                              )
                              ? item.endDate
                              : TimeStamptoExp.formatDateFromTimestamp(
                                  Number(item?.endDate)
                                )
                            : "-",
                          item?.investmentFrequency,
                          item?.interesetRate
                        )
                      }
                    />
                  </IconButton>
                  <IconButton>
                    <Delete
                      onClick={() =>
                        handleConfirmModalOpen(
                          item?.id,
                          toCamelCase(item?.schemeName)
                        )
                      }
                    />
                  </IconButton>
                  </div>
                </TableCell>
              </TableRow>
              {/* <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow> */}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="FD"
          heading="Fixed Deposite Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default FDTableRowComponent;
