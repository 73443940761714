import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform, useInView } from "framer-motion";

const MissionSection = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
      className="py-24 bg-gradient-to-b from-white to-lightBg relative overflow-hidden"
    >
      <div className="absolute inset-0 opacity-5 bg-fixed"></div>
      <div className="container mx-auto px-6 relative">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 text-primary">Our Mission</h2>
          <div className="space-y-6 text-lg text-darkGrey">
            <p>
              We started AUM Sampann to cater to the lack of availability in the
              market as an All in One tool. Investors largely relied on multiple
              data tools & complex spreadsheets to maintain their data manually,
              a process which is tedious and error-prone. AUM Sampann here comes
              as a One Stop Tool wherein you can not only manage your wealth but
              also family’s wealth and investments across multiple asset
              classes.
            </p>
            <p>
              Adding to that, one can perform all asset class transactions with
              both Online and Offline Support, Analyze your family level
              investments and also conduct your own research of MF, FD, PMS,
              AIF, Unlisted Shares and so much more.
            </p>
            <p className="font-semibold text-primary">
              Our goal has always been to provide services to solve all wealth
              related problems by remaining focused only on building the best
              technology solutions via automation. Being an all in one solution
              platform, we have created our position as a must-have tool for
              savvy investors and wealth professionals.
            </p>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default MissionSection;
