import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Modal,
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import getInitials from "../../utils/getInitailasIMGUtils";
import { LoaderIcon } from "react-hot-toast";
import { toCamelCase } from "../../utils/ToCamelCase";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import { Close, Delete, Edit } from "@mui/icons-material";
import { DateUtils } from "../../utils/FormatDate";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import {
  deleteHoldingsData,
  editHoldingsData,
  editHoldingsData2,
} from "../../redux/asset_Holdings/assetSlice";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";

interface CardComponentProps {
  loading?: boolean;
  assetType?: string;
  schemeName?: string;
  subInfoTitle?: string;
  subInfo?: any;
  details?: { label: string; value: string | number }[];
  transactionsDetails?: any;
  arrayData?: any;
  fetchData?: any;
  onEdit?: () => void;
  onDelete?: () => void;
}

type Transaction = {
  id: number;
};

interface TransactionsModalProps {
  open: boolean;
  onClose: () => void;
  assetType: string;
  transactions: {
    transactionDate: string;
    investedAmount: number;
    days: number;
  }[];
  fetchData: () => void;
}

const TransactionsModal: React.FC<TransactionsModalProps> = ({
  open,
  onClose,
  assetType,
  transactions,
  fetchData,
}) => {
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");

  const formatTransactionDate = (dateString: any) => {
    if (dateString === "") {
      return;
    }
    if (dateString?.includes("/")) {
      const [day, month, year] = dateString?.split("/"); // Convert MM/DD/YYYY format to DD-MM-YYYY
      return `${day}/${month}/${year}`;
    }

    // Check if the date is already in DD-MM-YYYY format
    if (dateString?.includes("-")) {
      const [year, month, day] = dateString?.split("-");
      return `${day}/${month}/${year}`; // Keep as DD-MM-YYYY
    }

    return dateString; // If the format is unexpected, return the original
  };

  const handleOpen = (
    id?: any,
    initialNAV?: any,
    currentNAV?: any,
    units?: any,
    transactionDate?: any,
    investedAmount?: any,
    currentAmount?: any,
    quantity?: any,
    price?: any,
    demat?: any,
    settelmentDate?: any,
    yeild?: any,
    bondSource?: any,
    purchasePrice?: any,
    interestRate?: any,
    amount?: any,
    maturityDate?: any,
    lockInPeriod?: any,
    uanNo?: any,
    tenure?: any,
    fdOption?: any,
    roi?: any
  ) => {
    if (assetType === "AIF") {
      setFields([
        { name: "initialNAV", label: "Purchased NAV", type: "number" },
        { name: "currentNAV", label: "Current NAV", type: "number" },
        { name: "units", label: "Units", type: "number" },
        { name: "transactionDate", label: "Transaction Date", type: "text" },
      ]);
      setValues({
        initialNAV: Number(initialNAV),
        currentNAV: Number(currentNAV),
        units: Number(units),
        transactionDate: transactionDate,
      });
    } else if (assetType === "PMS") {
      setFields([
        { name: "investedAmount", label: "Investment Amount", type: "number" },
        { name: "currentAmount", label: "Current Amount", type: "number" },
        { name: "transactionDate", label: "Transaction Date", type: "text" },
      ]);
      setValues({
        investedAmount: Number(investedAmount),
        currentAmount: Number(currentAmount),
        transactionDate: transactionDate,
      });
    } else if (assetType === "BOND") {
      setFields([
        { name: "quantity", label: "Quantity", type: "number" },
        { name: "price", label: "Price", type: "number" },
        { name: "demat", label: "Demat", type: "number" },
        { name: "settelmentDate", label: "Settelment Date", type: "text" },
        { name: "yeild", label: "Yield", type: "number" },
        { name: "bondSource", label: "Bond Source", type: "text" },
      ]);
      setValues({
        quantity: Number(quantity),
        price: Number(price),
        demat: Number(demat),
        settelmentDate: settelmentDate,
        yeild: Number(yeild),
        bondSource: bondSource,
      });
    } else if (assetType === "Equity") {
      setFields([
        { name: "units", label: "Units", type: "number" },
        { name: "purchasePrice", label: "Purchased Price", type: "number" },
      ]);
      setValues({
        units: Number(units),
        purchasePrice: Number(purchasePrice),
      });
    } else if (assetType === "Unlisted Equity") {
      setFields([
        { name: "units", label: "Units", type: "number" },
        { name: "purchasePrice", label: "Purchased Price", type: "number" },
      ]);
      setValues({
        units: Number(units),
        purchasePrice: Number(purchasePrice),
      });
    } else if (assetType === "PPF/EPS/KWP") {
      setFields([
        { name: "interestRate", label: "Interest Rate", type: "number" },
        { name: "amount", label: "Amount", type: "number" },
        { name: "maturityDate", label: "Maturity Date", type: "text" },
        { name: "lockInPeriod", label: "Lock In Period", type: "number" },
        { name: "uanNo", label: "UAN No", type: "number" },
      ]);
      setValues({
        interestRate: Number(interestRate),
        amount: Number(amount),
        maturityDate: maturityDate,
        lockInPeriod: Number(lockInPeriod),
        uanNo: Number(uanNo),
      });
    } else {
      setFields([
        { name: "amount", label: "Amount", type: "number" },
        { name: "tenure", label: "Tenure (in Months)", type: "text" },
        { name: "maturityDate", label: "Maturity Date", type: "text" },
        { name: "fdOption", label: "Investment Frequency", type: "text" },
        { name: "roi", label: "ROI", type: "number" },
      ]);
      setValues({
        amount: Number(amount),
        tenure: tenure,
        maturityDate: maturityDate,
        fdOption: fdOption,
        roi: Number(roi),
      });
    }

    setID(id);
    handleModalOpen();
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    let payload;
    if (assetType === "AIF") {
      payload = {
        id: id,
        initialNAV: formData?.initialNAV,
        currentNAV: formData?.currentNAV,
        units: formData?.units,
        transactionDate: formData?.transactionDate,
        holdingType: "AIF",
      };
    } else if (assetType === "PMS") {
      payload = {
        id: id,
        investedAmount: formData?.investedAmount,
        currentAmount: formData?.currentAmount,
        transactionDate: formData?.transactionDate,
        holdingType: "PMS",
      };
    } else if (assetType === "Equity") {
      payload = {
        id: id,
        units: formData?.units,
        purchasePrice: formData?.purchasePrice,
        holdingType: "STOCK",
      };
    } else if (assetType === "Unlisted Equity") {
      payload = {
        id: id,
        units: formData?.units,
        purchasePrice: formData?.purchasePrice,
        holdingType: "PRIVATE_STOCK",
      };
    } else if (assetType === "PPF") {
      payload = {
        id: id,
        interestRate: formData?.interestRate,
        amount: formData?.amount,
        maturityDate: formData?.maturityDate,
        lockInPeriod: formData?.lockInPeriod,
        uanNo: formData?.uanNo,
        holdingType: "PPF",
      };
    } else {
      payload = {
        id: id,
        amount: Number(formData?.amount),
        tenure: formData?.tenure,
        maturityDate: formData?.maturityDate,
        fdOption: formData?.fdOption,
        roi: Number(formData?.roi),
        holdingType: holdingType,
      };
    }

    try {
      setLoading(true);
      const response = await dispatch(editHoldingsData(payload));
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`${assetType} edided successfully.`);
        setModalOpen(false);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoading(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType:
            assetType === "Equity"
              ? "STOCK"
              : assetType === "Unlisted Equity"
              ? "PRIVATE_STOCK"
              : assetType === "PPF/EPS/KWP"
              ? "PPF"
              : assetType === "Fixed Deposit"
              ? "FD"
              : assetType,
        })
      );
      if (response.payload.success === 400) {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`${assetType} deleted successfully.`);
      } else {
        setLoading(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoading(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchData();
    onClose();
    setOpen(false);
    setMessage("");
  };

  const remove44Months = (inputString: any): any => {
    if (inputString === "") {
      return;
    }
    return inputString?.replace(/ Months/g, "")?.trim();
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // Adjust width as needed
            maxHeight: "50%", // Limit the height of the modal
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 1,
            overflowX: "auto", // Make the content scrollable on the Y-axis
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="subtitle1">Transaction Details</Typography>
            <IconButton onClick={onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "200px",
              borderTop: "1.5px solid rgba(224, 224, 224, 1)",
              padding: "0 5px",
              overflowY: "auto", // Enable horizontal scrolling
              overflowX: "hidden",
              maxWidth: "100%", // Ensure the table doesn't exceed the container's width
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      whiteSpace: "wrap",
                      textAlign: "center",
                      fontSize: "10px",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "wrap",
                      textAlign: "center",
                      fontSize: "10px",
                    }}
                  >
                    Invested Amount
                  </TableCell>
                  {/* <TableCell sx={{ whiteSpace: "wrap", textAlign: "center",fontSize:"10px" }}>
                    Withdrawal
                  </TableCell> */}
                  {/* <TableCell sx={{ whiteSpace: "wrap", textAlign: "center",fontSize:"10px" }}>
                    Days
                  </TableCell> */}
                  <TableCell
                    sx={{
                      whiteSpace: "wrap",
                      textAlign: "center",
                      fontSize: "10px",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions?.map((trans: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: "center", fontSize: "10px" }}>
                      {trans?.transactionDate
                        ? DateUtils.formatDate(
                            formatTransactionDate(trans?.transactionDate)
                          )
                        : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", fontSize: "10px" }}>
                      {"Rs." +
                        formatIndianCurrency(
                          trans?.investedAmount
                            ? Number(trans?.investedAmount)
                            : Number(trans?.amount)
                        )}
                    </TableCell>
                    {/* <TableCell sx={{ textAlign: "center",fontSize:"10px" }}>
                      {"Rs." +
                        formatIndianCurrency(Number(trans?.redeemValue || 0))}
                    </TableCell> */}
                    {/* <TableCell sx={{ textAlign: "center",fontSize:"10px" }}>
                      {trans?.days}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      sx={{
                        // borderBottom: "2px solid #ccc",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "10px",
                      }}
                    >
                      <IconButton>
                        <Edit
                          sx={{ fontSize: "14px" }}
                          onClick={() =>
                            handleOpen(
                              trans?.id,
                              trans?.initialNAV,
                              trans?.currentNAV,
                              trans?.units
                                ? Number(trans?.units).toFixed(2)
                                : 0,
                              trans?.transactionDate,
                              trans?.investedAmount,
                              trans?.currentAmount,
                              trans?.quantity,
                              trans?.price,
                              trans?.demat,
                              trans?.settelmentDate,
                              trans?.yeild,
                              trans?.bondSource,
                              trans?.purchasePrice,
                              trans?.interestRate,
                              trans?.amount,
                              trans?.maturityDate,
                              trans?.lockInPeriod,
                              trans?.uanNo,
                              remove44Months(trans?.investmentTenure),
                              trans?.investmentFrequency,
                              trans?.interesetRate
                            )
                          }
                        />
                      </IconButton>
                      <IconButton>
                        <Delete
                          sx={{ fontSize: "14px" }}
                          onClick={() =>
                            handleConfirmModalOpen(
                              trans?.id,
                              toCamelCase(
                                trans?.holdingName
                                  ? trans?.holdingName
                                  : trans?.symbol
                              )
                            )
                          }
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType={
            assetType === "Equity"
              ? "STOCK"
              : assetType === "Unlisted Equity"
              ? "PRIVATE_STOCK"
              : assetType === "PPF/EPS/KWP"
              ? "PPF"
              : assetType === "Fixed Deposit"
              ? "FD"
              : assetType
          }
          heading={`${assetType} Transaction`}
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

const CardComponent: React.FC<CardComponentProps> = ({
  loading,
  assetType,
  schemeName,
  subInfoTitle,
  subInfo,
  details,
  transactionsDetails,
  fetchData,
  arrayData,
  onEdit,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedTransactions, setSelectedTransactions] = useState<[] | null>(
    null
  );
  const [transModalOpen, setTransModalOpen] = useState(false);
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleOpenModal = (transactions: any) => {
    setSelectedTransactions(transactions);
    setTransModalOpen(true);
  };
  const handleCloseModal = () => {
    setTransModalOpen(false);
    setSelectedTransactions(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoader(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType:
            assetType === "Equity"
              ? "STOCK"
              : assetType === "Unlisted Equity"
              ? "PRIVATE_STOCK"
              : assetType === "PPF/EPS/KWP"
              ? "PPF"
              : assetType === "Fixed Deposit"
              ? "FD"
              : assetType,
        })
      );
      if (response.payload.success === 400) {
        setLoader(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoader(false);
        setOpen(true);
        setMessage(`${assetType} deleted successfully.`);
      } else {
        setLoader(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoader(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchData();
    setOpen(false);
    setMessage("");
  };

  const handleOpen = (
    id?: any,
    currentNAV?: any,
    units?: any,
    transactionDate?: any,
    investedAmount?: any,
    currentAmount?: any,
    quantity?: any,
    price?: any,
    demat?: any,
    settelmentDate?: any,
    yeild?: any,
    bondSource?: any,
    purchasePrice?: any,
    interestRate?: any,
    amount?: any,
    maturityDate?: any,
    lockInPeriod?: any,
    uanNo?: any,
    tenure?: any,
    fdOption?: any,
    roi?: any
  ) => {
    console.log(investedAmount,"Invested Amount");
    if (assetType === "AIF") {
      setFields([{ name: "currentNAV", label: "Current NAV", type: "number" }]);
      setValues({
        currentNAV: Number(currentNAV),
      });
    } else if (assetType === "PMS") {
      setFields([
        { name: "currentAmount", label: "Current Amount", type: "number" },
      ]);
      setValues({
        investedAmount: Number(investedAmount),
        currentAmount: Number(currentAmount),
      });
    }
    setID(id);
    handleModalOpen();
  };

  const handleSubmit = async (
    formData: Record<string, string | number>,
    id: string,
    holdingType: string
  ) => {
    let payload;
    if (assetType === "AIF") {
      payload = {
        id: String(id),
        currentNAV: Number(formData?.currentNAV),
        holdingType: 5,
      };
    } else if (assetType === "PMS") {
      payload = {
        id: String(id),
        totalInvestment: Number(arrayData?.investedAmount),
        currentAmount: Number(formData?.currentAmount),
        holdingType: 6,
      };
    } else if (assetType === "Equity") {
      payload = {
        id: id,
        units: formData?.units,
        purchasePrice: formData?.purchasePrice,
        holdingType: "STOCK",
      };
    } else if (assetType === "Unlisted Equity") {
      payload = {
        id: id,
        units: formData?.units,
        purchasePrice: formData?.purchasePrice,
        holdingType: "PRIVATE_STOCK",
      };
    } else if (assetType === "PPF") {
      payload = {
        id: id,
        interestRate: formData?.interestRate,
        amount: formData?.amount,
        maturityDate: formData?.maturityDate,
        lockInPeriod: formData?.lockInPeriod,
        uanNo: formData?.uanNo,
        holdingType: "PPF",
      };
    } else {
      payload = {
        id: id,
        amount: Number(formData?.amount),
        tenure: formData?.tenure,
        maturityDate: formData?.maturityDate,
        fdOption: formData?.fdOption,
        roi: Number(formData?.roi),
        holdingType: holdingType,
      };
    }

    try {
      setLoader(true);
      const response = await dispatch(editHoldingsData2(payload));
      if (response.payload.success === 400) {
        setLoader(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }

      if (response.payload.data.success === 200) {
        setLoader(false);
        setOpen(true);
        setMessage(`${assetType} edided successfully.`);
        setModalOpen(false);
      } else {
        setLoader(false);
        setOpen(true);
        setMessage(response?.payload.message);
        setModalOpen(false);
      }
    } catch (error) {
      setLoader(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
      setModalOpen(false);
    }
  };

  const handleFormClose = () => {
    setModalOpen(false);
    setFields([]);
    setValues({});
    setID("");
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh] w-full">
          <LoaderIcon style={{ width: "40px", height: "40px" }} />
        </div>
      ) : (
        <>
          {details && details.length > 0 ? (
            <>
              <div className="bg-white p-2 sm:px-4 sm:py-2 w-[95%] mx-auto sm:mx-auto my-3 overflow-x-hidden border-[2px] border-lightGrey rounded-lg">
                {/* Header Row */}
                <div className="flex justify-between items-start">
                  <div className="flex">
                    <div className="w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] mb-3 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-lg sm:text-2xl font-bold">
                      {getInitials(schemeName || "")}
                    </div>
                    <div className="ml-2 flex flex-col justify-start items-start mt-2 text-[13px] sm:text-lg font-semibold text-gray-800">
                      {toCamelCase(schemeName || "")}
                      {subInfo && (
                        <p className="text-[10px] sm:text-[16px] text-gray-500">
                          {subInfoTitle} :{" "}
                          {subInfoTitle === "Interest Rate" ||
                          subInfoTitle === "Expense Ratio" ||
                          subInfoTitle === "3Y Abs. Returns"
                            ? typeof subInfo === "number"
                              ? subInfo.toFixed(2) + "%"
                              : !isNaN(Number(subInfo))
                              ? Number(subInfo).toFixed(2)
                              : subInfo
                            : typeof subInfo === "number"
                            ? subInfo.toFixed(2)
                            : !isNaN(Number(subInfo))
                            ? Number(subInfo).toFixed(2)
                            : subInfo}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Three Dots Menu */}
                  {!(assetType === "Mutual Fund") && (
                    <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                  )}

                  {/* Dropdown Menu */}
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {!(
                      assetType === "Fixed Deposit" ||
                      assetType === "PPF/EPS/KWP" ||
                      assetType === "Bond"
                    ) && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal(transactionsDetails);
                          handleClose();
                        }}
                      >
                        Transactions
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        handleOpen(
                          arrayData?.id,
                          arrayData?.currentNAV,
                          arrayData?.investedAmount,
                          arrayData?.currentAmount
                        );
                        handleClose();
                      }}
                      disabled={assetType !== "AIF" && assetType !== "PMS"}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleConfirmModalOpen(
                          transactionsDetails[0]?.id,
                          schemeName
                        );
                        handleClose();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </div>

                {/* Grid for Labels & Values */}
                <div className="grid grid-cols-3 gap-3 mt-1">
                  {details &&
                    details?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col justify-center items-center"
                      >
                        <span className="text-gray-500 text-[12px] sm:text-lg font-medium">
                          {item.label}
                        </span>
                        <span className="text-gray-800 text-[13px] sm:text-xl font-semibold">
                          {typeof item.value === "number"
                            ? item.value.toFixed(2)
                            : !isNaN(Number(item.value))
                            ? Number(item.value).toFixed(2)
                            : item.value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              {isModalOpen && (
                <HoldingsModalForm
                  fields={fields}
                  values={values}
                  id={Id}
                  holdingType={assetType ? assetType : ""}
                  heading={`${assetType} Transaction`}
                  handleOpen={handleModalOpen}
                  handleClose={handleFormClose}
                  onSubmit={handleSubmit}
                />
              )}
              {transactionsDetails && (
                <TransactionsModal
                  open={transModalOpen}
                  onClose={handleCloseModal}
                  assetType={assetType || ""}
                  transactions={transactionsDetails}
                  fetchData={fetchData}
                />
              )}
              <ConfirmationModal2
                isVisible={ConfirmOpen}
                message={Message}
                onConfirm={handleConfirmOpen}
                onCancel={handleConfirmClose}
              />
              <SuccessfullModal
                open={Open}
                message={Message}
                handleClose={handleSuccessClose}
              />
            </>
          ) : (
            <div className="w-full text-center text-darkGrey my-6 text-sm sm:text-xl font-semibold">
              No data is available for this asset type.
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CardComponent;
