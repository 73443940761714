import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";

const DisclaimerPage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="py-20">
      <Paper elevation={3} className="p-6">
        <Typography variant="h4" gutterBottom sx={{fontWeight:"bold"}}>
          Platform Disclaimer
        </Typography>
        <Box className="space-y-6">
          {/* Section 1 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              1. USER ACCOUNT REPRESENTS AND WARRANTS
            </Typography>
            <Typography className="text-justify">
              If the Platform User wishes to use or browse through the Platform, they are required to maintain an account on the Platform (“Account”) and provide certain information, including the Platform User’s name (or the name of the authorized person in the case of a legal entity), email ID, contact number, financial information, and any other details deemed necessary by FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its group companies/subsidiaries/affiliates.
            </Typography>
            <Typography className="text-justify">
              The Platform User is responsible for maintaining the security and confidentiality of their Account and login details. They are solely responsible for any activity conducted through their Account and for sharing login credentials with designated individuals or third parties, ensuring such parties maintain confidentiality.
            </Typography>
            <ul className="list-disc pl-6">
              <li>All information provided during Account creation and subsequent use is accurate, up-to-date, and truthful.</li>
              <li>Any updates to account details will be made accordingly.</li>
              <li>They do not have any pre-existing accounts on the Platform at the time of creating a new one.</li>
            </ul>
            <Typography className="text-justify">
              Failure to comply with these requirements may result in account suspension or termination.
            </Typography>
          </Box>

          {/* Section 2 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              2. USE OF PLATFORM
            </Typography>
            <ul className="list-disc pl-6">
              <li>The Platform User agrees to comply with all applicable laws while using the Platform or its services.</li>
              <li>
              FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its group companies/subsidiaries/affiliates are not liable for delays or failures caused by technical issues, such as server downtimes, network outages, or other infrastructure problems. Users acknowledge these risks when accessing the Platform.
              </li>
              <li>
              FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its group companies/subsidiaries/affiliates reserve the right to reject any request to open or operate an account without providing reasons, including compliance with directives from regulatory or statutory authorities.
              </li>
              <li>
                The Platform User consents to the recording of telephonic conversations with FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED customer support teams for quality control and record-keeping purposes.
              </li>
              <li>
                All transactions through the Platform must originate from legitimate sources or bank accounts. Users affirm that such transactions comply with applicable laws, including tax, anti-money laundering, and anti-corruption regulations.
              </li>
            </ul>
          </Box>

          {/* Section 3 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              3. PROHIBITED USES
            </Typography>
            <Typography className="text-justify">
              The Platform User shall not use or access the Platform to:
            </Typography>
            <ul className="list-disc pl-6">
              <li>Host, upload, or share information belonging to another person without authorization.</li>
              <li>Publish defamatory, obscene, privacy-invasive, harmful, or otherwise unlawful content.</li>
              <li>Infringe any patent, trademark, copyright, or other proprietary rights.</li>
              <li>Mislead or deceive recipients about the origin of information or intentionally transmit false information.</li>
              <li>Endanger national security, public order, or relations with foreign states.</li>
              <li>Introduce viruses, malware, or other malicious software.</li>
              <li>
                Use automated means (e.g., bots or scrapers) to access or interact with the Platform without written consent.
              </li>
            </ul>
            <Typography className="text-justify">
              Violation of these terms may result in account suspension and legal action.
            </Typography>
          </Box>

          {/* Section 4 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              4. INTELLECTUAL PROPERTY RIGHTS
            </Typography>
            <ul className="list-disc pl-6">
              <li>
                All content on the Platform, including but not limited to text, graphics, logos, and software, is the property of FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its affiliates or respective third parties. Unauthorized use of such content is prohibited.
              </li>
              <li>
                Users may not reproduce, copy, distribute, or exploit any portion of the Platform for commercial purposes without express written permission.
              </li>
              <li>Users agree not to reverse-engineer, decompile, or tamper with the Platform’s functionality.</li>
            </ul>
          </Box>

          {/* Section 5 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              5. THIRD-PARTY MATERIAL AND SERVICES
            </Typography>
            <Typography className="text-justify">
              FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its affiliates may provide third-party services or materials (e.g., investment data). These are provided on an “as-is” basis, and FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & does not warrant their accuracy or quality. Disputes arising from third-party services must be resolved directly with the respective third party.
            </Typography>
            <Typography className="text-justify">
              The Platform User acknowledges that any financial information published (e.g., statements) is provided without independent verification and on an "AS IS, WITH ALL FAULTS" basis.
            </Typography>
          </Box>

          {/* Section 6 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              6. DISCLAIMERS
            </Typography>
            <ul className="list-disc pl-6">
              <li>
                FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its affiliates disclaim liability for indirect, incidental, or consequential damages, including loss of revenue, profits, or investments, arising from Platform use.
              </li>
              <li>Users are advised to verify all data independently before making decisions.</li>
              <li>FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & disclaims responsibility for errors, omissions, or inaccuracies in Platform content.</li>
              <li>
                Users waive all claims against FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its affiliates for any damages arising from Platform use.
              </li>
            </ul>
          </Box>

          {/* Section 7 */}
          <Box>
            <Typography variant="h6" gutterBottom sx={{fontWeight:"bold"}}>
              7. INDEMNITY
            </Typography>
            <Typography className="text-justify">
              Platform Users agree to indemnify FINGEEK WEALTH SOLUTIONS PRIVATE LIMITED & its affiliates, employees, directors, agents, and partners from any claims, losses, damages, or expenses arising from:
            </Typography>
            <ul className="list-disc pl-6">
              <li>Misuse of the Platform,</li>
              <li>Violations of these terms,</li>
              <li>Infringements of intellectual property or other rights, or</li>
              <li>Content posted or transmitted through the Platform.</li>
            </ul>
            <Typography className="text-justify">
              This indemnity obligation will survive account termination.
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default DisclaimerPage;
