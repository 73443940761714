import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Modal,
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import getInitials from "../../utils/getInitailasIMGUtils";
import { LoaderIcon } from "react-hot-toast";
import { toCamelCase } from "../../utils/ToCamelCase";
import {
  deleteHoldingsData,
  editHoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

interface ListingCardComponentProps {
  loading?: boolean;
  assetType?: string;
  schemeName?: string;
  subInfoTitle?: string;
  data?:any;
  subInfo?: any;
  subInfo2?: any;
  subInfoTitle2?: string;
  arrayData?:any;
  dataObject?:any;
  EmptyMessage?:string;
  details?: { label: string; value: string | number }[];
  transactionsDetails?: any;
  fetchData?: any;
  onEdit?: () => void;
  onDelete?: () => void;
}

const ListingCardComponent: React.FC<ListingCardComponentProps> = ({
  loading,
  assetType,
  schemeName,
  data,
  subInfoTitle,
  subInfo,
  details,
  arrayData,
  fetchData,
  EmptyMessage,
  dataObject,
  onEdit,
  onDelete,
}) => {
  const navigate= useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedTransactions, setSelectedTransactions] = useState<[] | null>(
    null
  );
  const [transModalOpen, setTransModalOpen] = useState(false);
  const [Id, setID] = useState<any>("");
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const [Open, setOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");
  const [loader, setLoader] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleOpenModal = (transactions: any) => {
    setSelectedTransactions(transactions);
    setTransModalOpen(true);
  };
  const handleCloseModal = () => {
    setTransModalOpen(false);
    setSelectedTransactions(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmOpen = async () => {
    setConfirmOpen(false);
    try {
      setLoader(true);
      const response = await dispatch(
        deleteHoldingsData({
          id: userData?.id,
          holdingId: Id,
          holdingType:
            assetType === "Equity"
              ? "STOCK"
              : assetType === "Unlisted Equity"
              ? "PRIVATE_STOCK"
              : assetType === "PPF/EPS/KWP"
              ? "PPF"
              : assetType === "Fixed Deposit"
              ? "FD"
              : assetType,
        })
      );
      if (response.payload.success === 400) {
        setLoader(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }

      if (response.payload.data.success === 200) {
        setLoader(false);
        setOpen(true);
        setMessage(`${assetType} deleted successfully.`);
      } else {
        setLoader(false);
        setOpen(true);
        setMessage(response?.payload.message);
      }
    } catch (error) {
      setLoader(false);
      setOpen(true);
      setMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }
  };
  const handleSuccessClose = () => {
    fetchData();
    setOpen(false);
    setMessage("");
  };

  const handleCardClick = () => {
    if (assetType === "Mutual Fund" && data?.isin && data?.id) {
      navigate(`/mf-details/${data.isin}/${data.id}`);
    }else if(assetType==="PMS"){
      navigate(`/pms-details/${data?.id}`, { state: { schemes: data }})
    }else if(assetType==="AIF"){
      navigate(`/aif-details/${data?.id}`, { state: { schemes: data }})
    }else if(assetType==="FD"){
      navigate(`/fd-details/${data?.id}`);
    }
  };

  const navigateDetails = (obj: any) => {
    if (obj.assetType === "FD") {
      navigate(`/fd-details/${obj?.itemId}`);
    } else if (obj.assetType === "Mutual Fund") {
      navigate(`/mf-details/${obj?.fundIsin}/${obj?.itemId}`);
    } else if (obj.assetType === "AIF") {
      navigate(`/aif-details/${obj?.itemId}`);
    } else if (obj.assetType === "PMS") {
      navigate(`/pms-details/${obj?.itemId}`);
    }
    // window.location.reload();
  };


  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh] w-full">
          <LoaderIcon style={{ width: "40px", height: "40px" }} />
        </div>
      ) : (
        <>
          {((details && details.length > 0)|| (Array.isArray(arrayData) && arrayData.length > 0 && arrayData.some(obj => Object.keys(obj).length > 0))) ? (
              <div className="bg-white p-2 sm:px-4 sm:py-2 w-[95%] sm:w-full mx-auto sm:mx-0 my-3 overflow-x-hidden border-[2px] border-lightGrey rounded-lg" onClick={assetType==="wishlist"?()=>{navigateDetails(dataObject)}:handleCardClick}>
                {/* Header Row */}
                <div className="flex justify-between items-start">
                  <div className="flex">
                    <div className="w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] mb-3 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-lg sm:text-2xl font-bold">
                      {getInitials(schemeName || "")}
                    </div>
                    <div className="ml-2 flex flex-col justify-start items-start mt-2 text-[13px] sm:text-lg font-semibold text-gray-800">
                      {toCamelCase(schemeName || "")}
                      {subInfo && (
                        <p className="text-[10px] sm:text-[16px] text-gray-500">
                          {subInfoTitle} :{" "}
                          {subInfoTitle === "Interest Rate" ||
                          subInfoTitle === "Expense Ratio" ||
                          subInfoTitle === "3Y Abs. Returns"
                            ? typeof subInfo === "number"
                              ? subInfo.toFixed(2) + "%"
                              : !isNaN(Number(subInfo))
                              ? Number(subInfo).toFixed(2)
                              : subInfo
                            : typeof subInfo === "number"
                            ? subInfo.toFixed(2)
                            : !isNaN(Number(subInfo))
                            ? Number(subInfo).toFixed(2)
                            : subInfo}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Three Dots Menu */}
                  {/* {!(assetType === "Mutual Fund") && (
                    <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                  )} */}

                  {/* Dropdown Menu */}
                  {/* <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        //   onEdit();
                        handleClose();
                      }}
                    >
                      Invest
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleConfirmModalOpen(
                          transactionsDetails[0]?.id,
                          schemeName
                        );
                        handleClose();
                      }}
                    >
                      Add to Wishlist
                    </MenuItem>
                  </Menu> */}
                </div>

                {/* Grid for Labels & Values */}
                <div className="grid grid-cols-3 gap-3 mt-1">
                  {details &&
                    details?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col justify-center items-center"
                      >
                        <span className="text-gray-500 text-[12px] sm:text-lg font-medium">
                          {item.label}
                        </span>
                        <span className="text-gray-800 text-[13px] sm:text-xl font-semibold">
                          {item.label!=="Order Status"?typeof item.value === "number"
                            ? item.value.toFixed(2)
                            : !isNaN(Number(item.value))
                            ? Number(item.value).toFixed(2)
                            : item.value:item.value===""?"-":item.value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
          ) : (
            <div className="w-full text-center text-darkGrey my-6 text-sm sm:text-xl font-semibold">
              {EmptyMessage? EmptyMessage :`No data is available for this ${assetType}.`}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ListingCardComponent;
