// Main.tsx

import React, { useEffect, useState } from "react";
import GeneralDetailsForm from "../GeneralDetailsForm/GeneralDetailsForm";
import BankDetailsForm from "../BankDetailsForm/BankDetailsForm";
import NomineeDetailsForm from "../NomineeDetailsForm/NomineeDetailsForm";
import DematForm from "../DematForm/DematForm";
import Preferences from "../Preferences/Preferences";
import SignForm from "../SignForm/SignForm";
import { Img, Text } from "../../../components/index";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import IndividualFormLayout from "../../../layouts/IndividualFormHeader/IndividualFormLayout";
import { colors } from "../../../constants/colors";
import dayjs, { Dayjs } from "dayjs";
import { useUserData } from "../../../hooks/useUserData";
import PanVerification from "../PanVerification/PanVerification";
import AadharVerification from "../AadharVerification/AadharVerification";
import SelectType from "../SelectType/SelectType";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import TabsComponentForOnboarding from "../../../components/Mobile_Components/TabsComponentForOnboarding";

const Main = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("aadhar");
  const [completedSections, setCompletedSections] = useState<boolean[]>(
    Array(6).fill(false)
  );
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const [passingData, setPassingData] = useState({});
  const [isVerified, setIsVerified] = useState<{
    isAadharVerified: boolean;
    isPanVerified: boolean;
  }>({
    isAadharVerified: false,
    isPanVerified: false,
  });
  const user = useUserData();

  const isSmallScreen = useMediaQuery("(max-width:1023px)");

  // const handleKYCAllow = () => {
  //   setActiveSection("bank"); // Change state to 'bank' when KYC is allowed
  // };

  // const handleBankToNominee = () => {
  //   setActiveSection("nominee"); // Activate the "Nominee Details" section
  // };

  // const handleNomineeToDemat = () => {
  //   setActiveSection("demat"); // Activate the "Demat Details" section
  // };

  // const handleDematToPreferences = () => {
  //   setActiveSection("preference"); // Activate the "Demat Details" section
  // };

  // const handlePreferencesToSignature = () => {
  //   setActiveSection("sign"); // Activate the "Demat Details" section
  // };

  const handleSaveAndProcess = (sectionName: string, index: number) => {
    console.log(sectionName, "sectionName");
    if (sectionName === "bank") {
      console.log(sectionName, "selectionname");
    }
    const updatedSections = [...completedSections];
    updatedSections[index] = true;
    setCompletedSections(updatedSections);
    setActiveSection(sectionName);
  };

  const setSectionActive = (sectionName: string) => {
    setActiveSection(sectionName);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setDateOfBirth(date); // Update the state with the new date
  };

  useEffect(() => {
    if (location.state) {
      const updatedSections = [...completedSections];
      for (let i = 0; i <= location.state.sectionIndex; i++) {
        updatedSections[i] = true;
      }
      setCompletedSections(updatedSections);
      setActiveSection(location.state.activeSection);
    }
  }, [location.state]);

  const tabs = [
    // {
    //   label: "Select Type",
    //   component: (
    //     <SelectType
    //       onSelectToAadhar={() => handleSaveAndProcess("aadhar", 0)}
    //       userData={user}
    //     />
    //   ),
    // },
    {
      label: "AADHAR",
      component: (
        <AadharVerification
          onProceed={() => handleSaveAndProcess("general details", 1)}
          activeSection={activeSection}
          userData={user}
        />
      ),
    },
    {
      label: "General Details",
      component: (
        <GeneralDetailsForm
          activeSection={activeSection}
          onProceed={() => handleSaveAndProcess("nominee details", 2)}
          passingData={passingData}
          userData={user}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
        />
      ),
    },
    {
      label: "Nominee Details",
      component: (
        <NomineeDetailsForm
          onNomineeToDemat={() => handleSaveAndProcess("bank details", 3)}
          allowToProceed={function (): void {
            throw new Error("Function not implemented.");
          }}
          // Add any required props, for example:
          label="Nominee Details"
          name="nomineeForm"
          userData={user}
        />
      ),
    },
    {
      label: "Bank Details",
      component: (
        <BankDetailsForm
          onBankToNominee={() => handleSaveAndProcess("preferences", 4)}
          allowToProceed={function (): void {
            throw new Error("Function not implemented.");
          }}
          userData={user}
        />
      ),
    },
    {
      label: "Preferences",
      component: (
        <Preferences
          onPreferencesToSignature={() => handleSaveAndProcess("aof", 5)}
          allowToProceed={function (): void {
            throw new Error("Function not implemented.");
          }}
          userData={user}
        />
      ),
    },
    {
      label: "AOF",
      component: (
        <SignForm
          allowToProceed={function (): void {
            throw new Error("Function not implemented.");
          }}
          userData={user}
        />
      ),
    },
  ];

  return (
    <>
      {isSmallScreen ? (
        <div className="w-full flex flex-col items-center justify-between mt-2">
          {/* <div
            className="font-bold text-xl text-center my-4"
            style={{ color: colors.primary }}
          >
            Individual or Sole Proprietorship
          </div> */}
          <TabsComponentForOnboarding
            tabs={tabs}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            onLogout={() => {
              ("");
            }}
          />
        </div>
      ) : (
        <div>
          <IndividualFormLayout
            completedSections={completedSections}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          >
            {/* {activeSection === "pan" && (
          <PanVerification
            onProceed={() => handleSaveAndProcess("select", 0)}
            activeSection={activeSection}
            userData={user}
          />
        )} */}
            {/* {activeSection === "select" && (
              <SelectType
                onSelectToAadhar={() => handleSaveAndProcess("aadhar", 0)}
                userData={user}
              />
            )} */}

            {activeSection === "aadhar" && (
              <AadharVerification
                onProceed={() => handleSaveAndProcess("general", 0)}
                activeSection={activeSection}
                userData={user}
              />
            )}
            {activeSection === "general" && (
              <GeneralDetailsForm
                activeSection={activeSection}
                onProceed={() => handleSaveAndProcess("nominee", 1)}
                passingData={passingData}
                userData={user}
                isVerified={isVerified}
                setIsVerified={setIsVerified}
              />
            )}
            {activeSection === "nominee" && (
              <NomineeDetailsForm
                onNomineeToDemat={() => handleSaveAndProcess("bank", 2)}
                allowToProceed={function (): void {
                  throw new Error("Function not implemented.");
                }}
                // Add any required props, for example:
                label="Nominee Details"
                name="nomineeForm"
                userData={user}
              />
            )}
            {activeSection === "bank" && (
              <BankDetailsForm
                onBankToNominee={() => handleSaveAndProcess("preference", 3)}
                allowToProceed={function (): void {
                  throw new Error("Function not implemented.");
                }}
                userData={user}
              />
            )}

            {/* {activeSection === "demat" && (
          <DematForm
            onDematToPreferences={() => handleSaveAndProcess("preference", 5)}
            allowToProceed={function (): void {
              throw new Error("Function not implemented.");
            }}
            userData={user}
          />
        )} */}
            {activeSection === "preference" && (
              <Preferences
                onPreferencesToSignature={() => handleSaveAndProcess("sign", 4)}
                allowToProceed={function (): void {
                  throw new Error("Function not implemented.");
                }}
                userData={user}
              />
            )}
            {activeSection === "sign" && (
              <SignForm
                allowToProceed={function (): void {
                  throw new Error("Function not implemented.");
                }}
                userData={user}
              />
            )}
          </IndividualFormLayout>
        </div>
      )}
    </>
  );
};

export default Main;
