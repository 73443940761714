import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormLabel,
  FormControl,
  useMediaQuery,
  
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { skipOnboarding, getUserById } from "../../../redux/user/userSlice";
import { useUserData } from "../../../hooks/useUserData";
import { allowNominee } from "../../../redux/nominee/nomineeSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "../../../components";

interface AddNomineesProps {
  onSubmit?: any;
}

const NomineeOption: React.FC<AddNomineesProps> = ({ onSubmit }) => {
  const userData = useUserData();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [investmentPlan, setInvestmentPlan] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInvestmentPlanChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvestmentPlan(event.target.value);
  };

  const handleButtonClick = async () => {
    await dispatch(
      allowNominee({ allowNominee: investmentPlan === "Yes" ? 1 : 0 })
    );
    await dispatch(getUserById({}));
    if (investmentPlan === "Yes") {
      return;
    } else {
      onSubmit();
    }
  };

  const handleProceed = () => {
    onSubmit();
  };

  const isSmallScreen = useMediaQuery("(max-width:1023px)");

  return (
    <>
      {userData?.allowNominee === 0 ? (
        <div className="flex flex-col gap-5 items-center lg:mt-8">
          <CancelIcon className="text-red-700 text-sm lg:text-3xl" fontSize="large" />
          <p className="text-lg font-semibold font-inter">
            Nominee Details were skipped.
          </p>
          <button
            type="button"
            className="form-button"
            tabIndex={0}
            onClick={handleProceed}
            style={{
              fontSize:isSmallScreen?"1rem":"1.3rem",
              padding:isSmallScreen?"0.5rem":""
            }}
            //onClick={() => updateUserRef.current.click()} // Add onClick event handler
            // Disable button if radio is not selected
          >
            Proceed
          </button>
        </div>
      ) : (
        <div className="mx-auto sm:w-full px-4 lg:px-14 lg:mt-5">
          <div className="flex flex-col gap-4 w-full justify-center items-center">
            {/* Label aligned to the left */}
            <FormControl>
              <Typography variant={isSmallScreen?"body1":"h6"} className="text-left text-darkGrey mb-4">
                Would you like to add a nominee to your investment plan?
              </Typography>

              {/* RadioGroup with Yes/No options in a row */}
              <RadioGroup
                row
                value={investmentPlan}
                onChange={handleInvestmentPlanChange}
                className="gap-4 mt-4"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Save and Proceed button */}
            <div className="mt-6">
              <button
                type="button"
                className={`px-8 py-4 rounded-md ${
                  investmentPlan
                    ? "bg-primary text-white hover:bg-secondary border"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                } transition-all`}
                onClick={handleButtonClick}
                disabled={!investmentPlan}
                style={{
                  fontSize:isSmallScreen?"1rem":"1.3rem",
                  padding:isSmallScreen?"0.5rem":""
                }}
              >
                Save and Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NomineeOption;
