import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { colors } from "../../constants/colors";
import { useDispatch } from "react-redux";
import { addPasswordForProtectedMail } from "../../redux/AutoFetchEmail/FetchedEmailSlice";
import { Button } from "../Button";
import AddPasswordModal from "../modals/AddPasswordModal";
import SuccessfullModal from "../modals/SuccessfullModal";
import APIloader from "../AppLoader/APIloader";

interface OrderRow {
  id: number;
  fileName: string;
  emailSubject: string;
  createdAt: string;
  ocrStatus: string;
  requiredPassword: number; // Added optional orderStatus field
}

interface AutoFetchedEmailTableProps {
  headings: string[];
  rows: OrderRow[];
  fetchData:()=>void;
}

// Utility function to format date
const formatDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const AutoFetchedEmailTable: React.FC<AutoFetchedEmailTableProps> = ({
  headings,
  rows,
  fetchData
}) => {
  const dispatch = useDispatch<any>();
  const [Id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAdd = (id: any) => {
    setModalOpen(true);
    setId(id);
  };

  const addPassword = async () => {
    const payload = {
      id: Id,
      password: password,
    };
    try {
      setLoading(true);
      const response = await dispatch(addPasswordForProtectedMail(payload));
      console.log(response?.payload?.data?.message,response?.payload?.data,response?.payload,"SuccessFull submission");
      if (response?.payload?.data?.statusCode === 200) {
        setModalOpen(false);
        setSuccessModal(true);
        setMessage("Password added successfully.");
        setLoading(false);
      } else {
        setModalOpen(false);
        setSuccessModal(true);
        setMessage(response?.payload?.message);
        setLoading(false);
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setLoading(false);
    } catch (error) {
      console.error("API Error:", error);
      setSuccessModal(true);
      setMessage("Server is not working.Please try again after sometime!");
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setLoading(false);
    }
  };
  const handleClose = () => {
    setSuccessModal(false);
    setPassword("");
    setMessage("");
    fetchData();
  };
  const handleModalClose = () =>{
    setPassword("");
    setModalOpen(false);
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          overflowY: "auto",
          overflowX: "auto",
          boxShadow: "none",
          border: "2px solid #ccc",
        }}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead
            sx={{
              backgroundColor: colors.lightBg,
              borderBottom: "2px solid #ccc",
              "& th": {
                backgroundColor: colors.lightBg,
                fontWeight: 600,
                color: colors.primary,
                fontSize: "16px",
                borderBottom: "2px solid #ccc",
              },
            }}
          >
            <TableRow>
              {headings?.map((heading, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                    textAlign:
                      heading === "requiredPassword" ? "center" : "start",
                    width:
                      heading === "createdAt" || heading === "requiredPassword"
                        ? "200px"
                        : "auto",
                  }}
                >
                  {heading === "id"
                    ? "ID"
                    : heading === "fileName"
                    ? "File Name"
                    : heading === "emailSubject"
                    ? "Subject"
                    : heading === "createdAt"
                    ? "Date"
                    : heading === "ocrStatus"
                    ? "Status"
                    : heading === "requiredPassword"
                    ? "Required Password"
                    : ""}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {headings?.map((heading, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      fontSize: "16px",
                      textAlign:
                        heading === "requiredPassword" ? "center" : "start",
                    }}
                  >
                    {heading === "createdAt" ? (
                      formatDate(row[heading as keyof OrderRow] as string)
                    ) : heading === "requiredPassword" ? (
                      row.requiredPassword === 0 ? (
                        "Not Required"
                      ) : (
                        <button
                          className="bg-primary px-2 py-1 xl:px-4 xl:py-2 text-[0.9rem] xl:text-md text-white hover:bg-secondary rounded"
                          onClick={() => {
                            handleAdd(row?.id);
                          }}
                        >
                          Add Password
                        </button>
                      )
                    ) : (
                      row[heading as keyof OrderRow]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <APIloader loadingModal={loading} message="Please wait ..." />
      <AddPasswordModal
        title="Please provide password to access this pdf."
        showModal={modalOpen}
        setShowModal={setModalOpen}
        password={password}
        setPassword={setPassword}
        onClick={addPassword}
        onClose={handleModalClose}
      />
      <SuccessfullModal
        open={successModal}
        message={message}
        handleClose={handleClose}
      />
    </>
  );
};

export default AutoFetchedEmailTable;
