import React, { FC, useState, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RequestQuote } from "../../redux/FD/FDSlice";
import toast from "react-hot-toast";
import getInitials from "../../utils/getInitailasIMGUtils";
import Calculator from "../Calculetor/Calculetor";
import { colors } from "../../constants/colors";
import TimeStamptoExp from "../../utils/timestampConverter";
import ConfirmationModal from "../modals/ConfirmationModal2";
import AcknowledgementModel from "../modals/AcknowledgementModel";
import { RxCross2 } from "react-icons/rx";
import { showInterest } from "../../redux/user/userSlice";
import { useParams } from "react-router-dom";
import SuccessfullModal from "../modals/SuccessfullModal";
import { useUserData } from "../../hooks/useUserData";

interface FormInputProps {
  label: string;
  type: string;
  id: string;
  altText?: string;
  name?: string;
  onChange: any;
  value?: any;
  disabled?: boolean;
}

interface MFProps {
  activeSection: string;
  fundId: number;
  Rowdata: any;
  modelClose: () => void;
}

const FDSchema = yup.object().shape({
  fdId: yup.number().required(),
  investAmount: yup.number().required(),
  investTenure: yup.number().required(),
  interestRate: yup.number().required(),
});

const FDDetails: FC<MFProps> = ({ Rowdata, modelClose }) => {
  const userData = useUserData();
  const [selectedValue, setSelectedValue] = useState("first");
  const [showSummary, setShowSummary] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any>(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch<any>();
  const [tempData, setTemp] = useState();
  const [Frequncy, setFrequency] = useState("Days");
  const { id } = useParams();
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleToggleSummary = useCallback(
    (data1: any) => {
      if (!data1) {
        setConfirmModal(true);
        return;
      }
      setShowSummary(!showSummary);
    },
    [showSummary]
  );

  const handleYesSubmit = async (FDData: any) => {
    // console.log('data finally', FDData);
    if (FDData) {
      setLoader(true);
      try {
        // console.log('data=>>> 11', FDData);
        const response = await dispatch(RequestQuote(FDData));
        if (response.payload.data.statusCode === 200) {
          setMessage("FD Request Submitted");
          setLoader(false);
          setShowSummary(false);
          setConfirmModal(false);
          setOpen(true);
        } else {
          setErrorMessage("FD Request Failed");
          setConfirmModal(false);
          setOpen(true);
        }
      } catch (error) {
        setOpen(true);
        // console.error('Request for Quote failed:', error);
        setErrorMessage("FD Request Failed");
        setConfirmModal(false);
      } finally {
        setLoader(false);
      }
    }
  };

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const fieldsToShow = {
    investAmount: "Invested Amount",
    investTenure: "Investment Period",
    interestRate: "Interest Rate",
    interestFrequency: "Interest Frequency",
    return: "Interest Earned",
    maturityAmount: "Total Return",
    maturityDate: "Maturity Date",
  };

  type FieldsToShowKeys = keyof typeof fieldsToShow;

  const handleUpdate = useCallback((updatedData: any) => {
    setData((prevData: any) => ({
      ...prevData,
      [updatedData.fieldName]: updatedData.value,
    }));
  }, []);

  useEffect(() => {
    if (data) {
      const { calculation } = data;
      setTemp(calculation);
      setData(tempData);
    }
  }, [data]);

  const handleModalCancel = () => {
    setConfirmModal(false);
  };

  // if (Rowdata?.FDInfo?.isHDFC === 0) {
  //   setFrequency('Months');
  // }

  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: Number(id ?? Rowdata?.data?.id), holdingType: 3 })
      );

      if (response?.payload?.statusCode === 200) {
        setOpen(true);
        setMessage(
          `Your interest has been successfully submitted to your RM. You can also reach out to your RM ${userData?.relationManager?.firstName} at ${userData?.relationManager?.mobile}.`
        );
      } else {
        setOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    modelClose();
  };

  return (
    <>
      <div className="bg-lightBg py-3 lg:pt-10 lg:pb-10 scrollbar-hide overflow-y-auto overflow-x-hidden">
        <div className="w-full relative border-b-2 pb-2 lg:pb-0 border-lightGrey lg:border-0">
          <div
            className="absolute right-0 top-0 text-3xl pl-2 lg:pl-10 font-bolder mr-4 "
            style={{ color: colors.primary }}
          >
            <RxCross2
              fontSize={isLargeScreen ? "2rem" : "1.2rem"}
              className="cursor-pointer"
              onClick={modelClose}
            />
          </div>
          <div className="flex justify-start">
            <div className="relative">
              <h6
                className="text-[1rem] lg:text-3xl pl-2 lg:pl-10 font-medium"
                style={{ color: colors.primary }}
              >
                Transact Now
              </h6>
              <h6
                className="text-[0.8rem] lg:text-lg pl-2 lg:pl-10 mt-2 lg:mt-5 font-medium"
                style={{ color: colors.textGrey }}
              >
                Client Code: {userData?.kyc?.pancardNumber}
              </h6>
            </div>
          </div>
        </div>
        <div className="h-full mx-auto px-4 lg:px-8">
          <div className="lg:bg-white">
            <div className="lg:px-4 py-5">
              <div className="flex flex-row lg:gap-x-6 lg:p-10 bg-lightBg items-start lg:items-center">
                <div className="w-12 h-12 md:w-16 md:h-16 mb-1 sm:mb-3 md:mb-0 lg:w-32 lg:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-[1.5rem] lg:text-4xl font-bold">
                  {getInitials(Rowdata?.data?.schemeName || "")}
                </div>
                <div className="flex flex-col gap-y-3 flex-1 max-w-[1200px]">
                  <h3
                    className="text-[1.2rem] lg:text-2xl pl-4 font-bold lg:font-medium"
                    style={{ color: colors.primary }}
                  >
                    {Rowdata?.data?.schemeName}
                  </h3>

                  <div className="ml-4 lg:ml-0">
                    <RadioGroup
                      value={selectedValue}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="first"
                        control={<Radio />}
                        label="Buy"
                      />
                      <FormControlLabel
                        value="second"
                        control={<Radio />}
                        label="Sell"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
              <div className="py-2 lg:py-20">
                <Formik
                  initialValues={{
                    fdId: 0,
                    investAmount: 0,
                    investTenure: 0,
                    interestRate: 0,
                  }}
                  validationSchema={FDSchema}
                  onSubmit={(values, { setSubmitting }) =>
                    handleToggleSummary(tempData)
                  }
                >
                  <Form>
                    <Calculator
                      fdData={Rowdata}
                      data={data}
                      onUpdate={handleUpdate}
                    />
                    <section className="lg:mt-10 mb-2 lg:mb-5 w-full flex flex-row">
                      <button
                        className="w-full md:w-auto justify-center px-2 py-2 lg:px-8 lg:py-4 rounded-[4px] lg:rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary text-[1rem] lg:text-xl mx-4 lg:mr-6"
                        type="submit"
                      >
                        {selectedValue === "first" ? "Buy FD" : "Sell FD"}
                      </button>
                      {/* <button
                                  type="button"
                                     className="w-full md:w-auto justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary text-xl"
                                    onClick={showingInterest}
                                  >
                                    Show Interest
                                  </button> */}
                    </section>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {tempData && (
        <Dialog open={showSummary} onClose={handleToggleSummary}>
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-2 lg:p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
              <Typography
                variant={isSmallScreen ? "h6" : isMediumScreen ? "h5" : "h4"}
                className="font-bold mb-2 lg:mb-4"
              >
                Summary
              </Typography>
              <DialogContent
                className="flex flex-col"
                sx={{
                  padding: 1,
                }}
              >
                {loader ? (
                  <div className="flex justify-center items-center">
                    <span>Loading...</span>
                  </div>
                ) : (
                  (Object.keys(fieldsToShow) as FieldsToShowKeys[]).map(
                    (key) => (
                      <div key={key} className="flex justify-between">
                        <span>{fieldsToShow[key]}:</span>
                        <span>
                          {key === "maturityDate"
                            ? TimeStamptoExp.formatDateFromTimestamp(
                                tempData?.[key]
                              )
                            : key === "investTenure"
                            ? `${tempData?.[key]} ${
                                Rowdata?.FDInfo?.isHDFC === 0
                                  ? "Months"
                                  : "Days"
                              }`
                            : tempData?.[key]}
                        </span>
                      </div>
                    )
                  )
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleYesSubmit(tempData);
                  }}
                  sx={{
                    backgroundColor: colors.primary,
                    borderRadius:isLargeScreen? "40px" : "20px",
                    color: colors.lightBg,
                    padding:isLargeScreen? "10px" : "5px",
                    fontSize:isLargeScreen ? "20px" : "12px",
                    "&:hover": {
                      backgroundColor: colors.primary,
                      // borderColor:colors.lightBg,
                      color: colors.lightBg,
                    },
                  }}
                >
                  Yes, Submit FD Request
                </Button>

                <Button
                  onClick={handleToggleSummary}
                  sx={{
                    backgroundColor: colors.primary,
                    borderRadius:isLargeScreen? "40px" : "20px",
                    color: colors.lightBg,
                    padding:isLargeScreen? "10px" : "5px",
                    fontSize:isLargeScreen ? "20px" : "12px",
                    "&:hover": {
                      backgroundColor: colors.primary,
                      // borderColor:colors.lightBg,
                      color: colors.lightBg,
                    },
                  }}
                >
                  No, Cancel
                </Button>
              </DialogActions>
            </div>
          </div>
        </Dialog>
      )}

      <AcknowledgementModel
        isVisible={confirmModal}
        message="Please ensure you click the Calculate button to generate all necessary values before proceeding to submit your FD request."
        onConfirm={handleModalCancel}
        onCancel={handleModalCancel}
        buttonSecond="Ok"
      />
      <SuccessfullModal
        open={Open}
        message={message !== "" ? message : errormessage}
        handleClose={handleClose}
      />
    </>
  );
};

export default FDDetails;
