import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  TextField,
  IconButton,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useUserData } from "../../hooks/useUserData";
import { colors } from "../../constants/colors";
import getInitials from "../../utils/getInitailasIMGUtils";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../redux/user/userSlice";
import ConfirmationModal from "../modals/confirmationSuccessModal";

function BankDetails() {
  const { bank } = useUserData();
  const [isEditing, setEditing] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [updatedBankDetails, setUpdatedBankDetails] = useState({
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    bankBranch: "",
    id: "",
  });
  const dispatch = useDispatch<any>();

  // Effect to update details when bank data changes
  React.useEffect(() => {
    if (!isEditing && bank.length > 0) {
      setUpdatedBankDetails({
        bankName: bank[0].bankName || "",
        accountNumber: bank[0].accountNumber || "",
        ifscCode: bank[0].ifscCode || "",
        bankBranch: bank[0].bankBranch || "",
        id: bank[0]?.id || "",
      });
    }
  }, [bank, isEditing]);

  const handleEditClick = () => {
    setEditing(true);
    if (bank.length > 0) {
      setUpdatedBankDetails({
        bankName: bank[0].bankName || "",
        accountNumber: bank[0].accountNumber || "",
        ifscCode: bank[0].ifscCode || "",
        bankBranch: bank[0].bankBranch || "",
        id: bank[0]?.id || "",
      });
    } else {
      setUpdatedBankDetails({
        bankName: "",
        accountNumber: "",
        ifscCode: "",
        bankBranch: "",
        id: "",
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedBankDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const { bankName, accountNumber, ifscCode, bankBranch, id } =
      updatedBankDetails;

    // Create the old values from the bank details that are currently displayed
    const oldBankDetails = bank[0]; // Assuming only one bank detail is being edited

    const bankData = {
      [id]: [
        {
          updateColumn: "bankName",
          updateValue: bankName,
          oldValue: oldBankDetails.bankName || "", // Use the currently displayed value
        },
        {
          updateColumn: "accountNumber",
          updateValue: accountNumber,
          oldValue: oldBankDetails.accountNumber || "", // Use the currently displayed value
        },
        {
          updateColumn: "ifscCode",
          updateValue: ifscCode,
          oldValue: oldBankDetails.ifscCode || "", // Use the currently displayed value
        },
        {
          updateColumn: "bankBranch",
          updateValue: bankBranch,
          oldValue: oldBankDetails.bankBranch || "", // Use the currently displayed value
        },
      ],
    };

    const data = {
      KYC: {}, // If you have KYC data, include it here
      USER: {}, // If you have USER data, include it here
      NOMINEE: {}, // If you have NOMINEE data, include it here
      BANK: bankData, // Add the BANK data
    };

    try {
      const response = await dispatch(updateProfile(data));
      if (updateProfile.fulfilled.match(response)) {
        console.log("API Response:", response.payload);
        setSuccessModalOpen(true);
        setEditing(false);
      } else {
        console.error("Failed to update bank details:", response);
      }
    } catch (error) {
      console.error("Failed to update bank details:", error);
    }
  };

  const handleCancel = () => {
    setEditing(false);
    // Optionally reset updatedBankDetails if you want to revert changes
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const isSmallScreen = useMediaQuery("(max-width:640px)");

  if (bank.length === 0) {
    return (
      <div className="border-2 border-lightGrey rounded-md">
        <Card
          style={{
            backgroundColor: colors.lightBg,
            minHeight: isSmallScreen ? "50px" : "60px",
            display: "flex",
            alignItems: "center",
            padding: "0",
          }}
          className="h-8 w-full"
        >
          <Typography
            variant={isSmallScreen ? "subtitle1" : "h5"}
            marginLeft={2}
            color={colors.darkGrey}
            fontWeight="bold"
          >
            Bank Details
          </Typography>
        </Card>
        <div className="justify-center">
          <Typography variant="body1" className="p-4 text-center text-gray-600">
            No bank details available.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="border-2 border-lightGrey rounded-md">
      <Card
        style={{
          width: "100%",
          backgroundColor: colors.lightBg,
          minHeight: isSmallScreen ? "50px" : "60px",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }}
        className="rounded-md h-8 w-full"
      >
        <Typography
          variant={isSmallScreen ? "subtitle1" : "h5"}
          marginLeft={2}
          color={colors.darkGrey}
          fontWeight="bold"
        >
          Bank Details
        </Typography>
      </Card>
      <div className="w-full flex flex-col justify-center my-0 items-center">
        {isEditing ? (
          <Box className="flex flex-col border border-lightGrey rounded-lg p-4 w-full">
            <Grid container style={{ width: "100%" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Name"
                  name="bankName"
                  value={updatedBankDetails.bankName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Account Number"
                  name="accountNumber"
                  value={updatedBankDetails.accountNumber}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="IFSC Code"
                  name="ifscCode"
                  value={updatedBankDetails.ifscCode}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Branch"
                  name="bankBranch"
                  value={updatedBankDetails.bankBranch}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
            <div className="flex justify-end mt-4">
              <Button
                variant="outlined"
                onClick={handleCancel}
                style={{
                  marginRight: "8px",
                  backgroundColor: colors.secondary,
                  color: colors.white,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{
                  marginRight: "8px",
                  backgroundColor: colors.primary,
                  color: colors.white,
                }}
              >
                Submit
              </Button>
            </div>
          </Box>
        ) : (
          bank.map((item: any) => (
            <Box
              key={item.id}
              className="flex flex-col sm:h-52 justify-center items-center py-2 px-4 w-full mb-4 h-full"
            >
              <div className="flex justify-start items-start w-full">
                <span>
                  <span className="text-lg sm:text-xl font-semibold">
                    Edit Account Details
                    {!isEditing && (
                      <IconButton
                        style={{ marginLeft: "auto" }}
                        onClick={handleEditClick}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </span>
                </span>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full mt-4">
                <div className="w-full h-auto flex flex-col justify-between">
                  <span className="text-lightGreyFont font-semibold">Bank</span>
                  <div className="flex items-center gap-x-2">
                    <div className="w-[25px] h-[25px] sm:w-[40px] sm:h-[40px] rounded-full flex items-center justify-center bg-gray-300 text-primary text-[14px] sm:text-xl font-bold">
                      {getInitials(item.bankName || "")}
                    </div>
                    <span className="text-[14px] sm:text-lg">{item.bankName}</span>
                  </div>
                </div>
                <div className="w-full h-auto flex flex-col justify-between">
                  <span className="text-lightGreyFont font-semibold">
                    Account
                  </span>
                  <span className="text-[14px] sm:text-lg">{item.accountNumber || "-"}</span>
                </div>
                <div className="w-full h-auto flex flex-col justify-between">
                  <span className="text-lightGreyFont font-semibold">
                    IFSC Code
                  </span>
                  <span className="text-[14px] sm:text-lg">{item.ifscCode || "-"}</span>
                </div>
                <div className="w-full h-auto flex flex-col justify-between">
                  <span className="text-lightGreyFont font-semibold">
                    Branch
                  </span>
                  <span className="text-[14px] sm:text-lg">{item.bankBranch || "-"}</span>
                </div>
              </div>
            </Box>
          ))
        )}
      </div>
      <ConfirmationModal
        open={isSuccessModalOpen}
        type="bank"
        value=""
        onConfirm={handleCloseSuccessModal}
        onClose={handleCloseSuccessModal}
      />
    </div>
  );
}

export default BankDetails;
