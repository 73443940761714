import React from "react";
import { useAuth } from "../hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoutes = () => {
  const { accessToken, refreshToken } = useAuth();
  const location = useLocation();
  if (accessToken && refreshToken) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default PublicRoutes;
