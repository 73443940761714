/**
 * Converts a string to camel case (Title Case), with the exception that
 * if the first word is fully uppercase, it retains its original case.
 * 
 * @param str - The input string to convert.
 * @returns The converted string in Title Case.
 */
export const toCamelCase = (str: string | undefined): string => {
  if (!str) return ''; // Handle undefined or empty input safely

  const words = str.split(' ');

  return words
    .map((word, index) => {
      // If it's the first word and fully uppercase, keep it as is
      if (index === 0 && word === word.toUpperCase()) {
        return word;
      }
      // Otherwise, capitalize the first letter and lowercase the rest
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' '); // Join the words back into a single string
};

