import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Box,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Menu,
  Modal,
  IconButton,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import toast from "react-hot-toast";
import { colors } from "../../../constants/colors";
import { useUserData } from "../../../hooks/useUserData";
import { CheckCircle } from "@mui/icons-material";
import {
  addOrganizationFatca,
  getOrganizationFatca,
} from "../../../redux/NonInd_Company/companySlice";
import { useDispatch } from "react-redux";
import { getUserById } from "../../../redux/user/userSlice";

interface BankDetailsFormProps {
  onCompanyToBank: () => void;
  activeSection: string;
  allowToProceed: () => void;
}

const options = {
  idDocumentType: [
    { label: "Passport", value: "A" },
    { label: "Election ID Card", value: "B" },
    { label: "PAN Card", value: "C" },
    { label: "ID Card", value: "D" },
    { label: "Driving License", value: "E" },
    { label: "UIDIA / Aadhar letter", value: "G" },
    { label: "NREGA Job Card", value: "H" },
    { label: "Others", value: "O" },
    { label: "Not categorized", value: "X" },
    { label: "TIN [Tax Payer Identification Number]", value: "T" },
    { label: "Company Identification Number", value: "C1" },
    { label: "US GIIN", value: "G1" },
    { label: "Global Entity Identification Number", value: "E1" },
  ],
  incomeSlab: [
    { value: "31", label: "Below 1 Lakh" },
    { value: "32", label: "1 to 5 Lacs" },
    { value: "33", label: "5 to 10 Lacs" },
    { value: "34", label: "10 to 25 Lacs" },
    { value: "35", label: "25 Lacs to 1 Crore" },
    { value: "36", label: "Above 1 Crore" },
  ],
  occupationCode: [
    { value: "01", label: "Business" },
    { value: "02", label: "Service" },
    { value: "03", label: "Professional" },
    { value: "04", label: "Agriculturist" },
    { value: "05", label: "Retired" },
    { value: "06", label: "Housewife" },
    { value: "07", label: "Student" },
    { value: "08", label: "Others" },
    { value: "09", label: "Doctor" },
    { value: "41", label: "Private Sector Service" },
    { value: "42", label: "Public Sector Service" },
    { value: "43", label: "Forex Dealer" },
    { value: "44", label: "Government Service" },
    { value: "99", label: "Unknown / Not Applicable" },
  ],
  exemptionCode: [
    {
      value:
        "An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)",
      label: "A",
    },
    // {
    //   value: "The United States or any of its agencies or instrumentalities",
    //   label: "B",
    // },
    // {
    //   value:
    //     "A state, the District of Columbia, a possession of the United States, or any of their political subdivisions or instrumentalities",
    //   label: "C",
    // },
    {
      value:
        "A corporation the stock of which is regularly traded on one or more established securities markets, as described in Reg. section 1.1472-1(c)(1)(i)",
      label: "D",
    },
    {
      value:
        "A corporation that is a member of the same expanded affiliated group as a corporation described in Reg. section 1.1472-1(c)(1)(i)",
      label: "E",
    },
    // {
    //   value:
    //     "A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any state",
    //   label: "F",
    // },
    { value: "A real estate investment trust", label: "G" },
    {
      value:
        "A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940",
      label: "H",
    },
    { value: "A common trust fund as defined in section 584(a)", label: "I" },
    { value: "A bank as defined in section 581", label: "J" },
    { value: "Broker", label: "K" },
    {
      value:
        "A trust exempt from tax under section 664 or described in section 4947(a)(1)",
      label: "L",
    },
    {
      value:
        "A tax exempt trust under a section 403(b) plan or section 457(g) plan",
      label: "M",
    },
    { value: "Not Applicable", label: "N" },
  ],

  entityCategory: [
    { label: "Listed entity", value: "L" },
    { label: "Related to listed entity", value: "RL" },
    { label: "Active NFFE", value: "A" },
    { label: "Passive NFFE", value: "P" },
    { label: "Not Applicable for Non NFFE", value: "NA" },
  ],
  entitySubCategory: [
    {
      value:
        "Less than 50 percent of the NFE's gross income for the preceding financial year is passive income and less than 50 percent of the assets held by the NFE during the preceding financial year are assets that produce or are held for the production of passive income",
      label: "01",
    },
    {
      value:
        "The NFE is a Governmental Entity, an International Organization, a Central Bank, or an entity wholly owned by one or more of the foregoing",
      label: "02",
    },
    {
      value:
        "Substantially all of the activities of the NFE consist of holding (in whole or in part) the outstanding stock of, or providing financing and services to, one or more subsidiaries that engage in trades or businesses other than the business of a Financial Institution, except that an entity shall not qualify for this status if the entity functions as an investment fund, such as a private equity fund, venture capital fund, leveraged buyout fund, or any investment vehicle whose purpose is to acquire or fund companies and then hold interests in those companies as capital assets for investment purposes",
      label: "03",
    },
    {
      value:
        "The NFE is not yet operating a business and has no prior operating history, but is investing capital into assets with the intent to operate a business other than that of a Financial Institution, provided that the NFE shall not qualify for this exception after the date that is 24 months after the date of the initial organization of the NFE",
      label: "04",
    },
    {
      value:
        "The NFE was not a Financial Institution in the past five years, and is in the process of liquidating its assets or is reorganizing with the intent to continue or recommence operations in a business other than that of a Financial Institution",
      label: "05",
    },
    {
      value:
        "The NFE primarily engages in financing and hedging transactions with, or for, Related Entities that are not Financial Institutions, and does not provide financing or hedging services to any Entity that is not a Related Entity, provided that the group of any such Related Entities is primarily engaged in a business other than that of a Financial Institution",
      label: "06",
    },
    {
      value:
        "Any NFE that fulfills all of the following requirements: It is established and operated in India exclusively for religious, charitable, scientific, artistic, cultural, athletic, or educational purposes; or it is established and operated in India and it is a professional organization, business league, chamber of commerce, labor organization, agricultural or horticultural organization, civic league or an organization operated exclusively for the promotion of social welfare; It is exempt from income tax in India; It has no shareholders or members who have a proprietary or beneficial interest in its income or assets; The applicable laws of the NFE's country or territory of residence or the NFE's formation documents do not permit any income or assets of the NFE to be distributed to, or applied for the benefit of, a private person or non-charitable Entity other than pursuant to the conduct of the NFE's charitable activities, or as payment of reasonable compensation for services rendered, or as payment representing the fair market value of property which the NFE has purchased; and The applicable laws of the NFE's country or territory of residence or the NFE's formation documents require that, upon the NFE's liquidation or dissolution, all of its assets be distributed to a governmental entity or other non-profit organization, or escheat to the government of the NFE's country or territory of residence or any political subdivision thereof.",
      label: "07",
    },
  ],
  addressType: [
    { label: "Residential or Business", value: "1" },
    { label: "Residential", value: "2" },
    { label: "Business", value: "3" },
    { label: "Registered Office", value: "4" },
    { label: "Unspecified", value: "5" },
  ],
  countryOfIncorporation: [
    { label: "India", value: "India" },
    // { label: "USA", value: "USA" },
    // { label: "UK", value: "UK" },
  ],
  residentialCountry: [
    { label: "India", value: "India" },
    // { label: "USA", value: "USA" },
    // { label: "UK", value: "UK" },
  ],
  wealthSource: [
    { label: "Salary", value: "01" },
    { label: "Business Income", value: "02" },
    { label: "Gift", value: "03" },
    { label: "Ancestral Property", value: "04" },
    { label: "Rental Income", value: "05" },
    { label: "Prize Money", value: "06" },
    { label: "Royalty", value: "07" },
    { label: "Others", value: "08" },
  ],
  corporateServiceSector: [
    { label: "Foreign Exchange / Money Changer Services", value: "01" },
    {
      label: "Gaming / Gambling / Lottery Services [e.g. casinos, betting]",
      value: "02",
    },
    { label: "Money laundering / Pawning", value: "03" },
    { label: "Not Applicable", value: "04" },
  ],
  occupationType: [
    { label: "Service", value: "S" },
    { label: "Business", value: "B" },
    { label: "Other", value: "O" },
    { label: "Not Categorized", value: "X" },
  ],
  politicallyExposed: [
    { label: "Politically Exposed Person", value: "Y" },
    { label: "Not a Politically Exposed Person", value: "N" },
    { label: "Relative of the Politically Exposed Person", value: "R" },
  ],
  ffiDrnfe: [
    // { label: "FFI", value: "FFI" },
    // { label: "DRNFE", value: "DRNFE" },
    { label: "NA", value: "NA" },
  ],
  uboGender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ],
  uboAddressType: [
    { label: "Residential or Business", value: "1" },
    { label: "Residential", value: "2" },
    { label: "Business", value: "3" },
    { label: "Registered Office", value: "4" },
    { label: "Unspecified", value: "5" },
  ],
  uboNationality: [{ value: "IN", label: "Indian" }],
  uboCode: [
    { label: "CP of legal person-ownership", value: "C01" },
    { label: "CP of legal person-other means", value: "C02" },
    { label: "CP of legal person-senior managing official", value: "C03" },
    { label: "CP of legal arrangement-trust-settlor", value: "C04" },
    { label: "CP of legal arrangement-trust-trustee", value: "C05" },
    { label: "CP of legal arrangement-trust-protector", value: "C06" },
    { label: "CP of legal arrangement-trust-beneficiary", value: "C07" },
    { label: "CP of legal arrangement-trust-other", value: "C08" },
    {
      label: "CP of legal arrangement-trust-other-settlor equivalent",
      value: "C09",
    },
    {
      label: "CP of legal arrangement-trust-other-trustee-equivalent",
      value: "C10",
    },
    {
      label: "CP of legal arrangement-trust-other-protector equivalent",
      value: "C11",
    },
    {
      label: "CP of legal arrangement-trust-other-beneficiary-equivalent",
      value: "C12",
    },
    {
      label: "CP of legal arrangement-trust-other-other-equivalent",
      value: "C13",
    },
    { label: "Unknown", value: "C14" },
  ],
};

const ModalTable = ({
  title,
  data,
  onClose,
}: {
  title: string;
  data: { label: string; value: string }[];
  onClose: () => void;
}) => {
  // Log the data passed to the modal
  console.log("Modal Data:", data);

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "60%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <CloseIcon onClick={onClose} />
        </Box>

        {data && data.length > 0 ? (
          <Box>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderBottom: "2px solid #ccc",
                    "& th": {
                      backgroundColor: colors.lightBg,
                      fontWeight: 600,
                      color: colors.primary,
                      fontSize: "16px",
                      borderBottom: "2px solid #ccc",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      CODE
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      PARTICULARS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        {row.label}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Box>
    </Modal>
  );
};
const mandatoryFields = [
  "taxPayerId",
  "idDocumentType",
  "incomeSlab",
  "occupationCode",
  "netWorth",
  "exemptionCode",
  "entityCategory",
  // "entitySubCategory",
  "wealthSource",
  "corporateServiceSector",
  "dateOfNetworth",
  "occupationType",
  "politicallyExposed",
  "ffiDrnfe",
  "natureOfBusiness",

];

const mandatoryFields2 = [
  "uboName",
  "uboNationality",
  "uboAddressType",
  "uboCountryOfBirth",
  "uboPan",
  "uboTaxResidency",
  "uboCode",
    "uboCount"
];

const Company: React.FC<BankDetailsFormProps> = ({
  onCompanyToBank,
  allowToProceed,
  activeSection,
}) => {
  const [formData, setFormData] = useState<Record<string, string>>({
    taxPayerId: "",
    idDocumentType: "",
    incomeSlab: "",
    occupationCode: "",
    netWorth: "",
    exemptionCode: "",
    entityCategory: "",
    entitySubCategory: "",
    wealthSource: "",
    corporateServiceSector: "",
    dateOfNetworth: "",
    occupationType: "",
    politicallyExposed: "",
    ffiDrnfe: "",
    natureOfBusiness: "",
    uboName: "",
    uboNationality: "",
    uboAddressType: "",
    uboCountryOfBirth: "",
    uboPan: "",
    uboTaxResidency: "",
    uboCode: "",
    uboCount:""
  });
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({}); // Track errors for each mandatory field
  const [isFormValid, setIsFormValid] = useState(false);
  const [uboFields, setUboFields] = useState(false);
  const [countries, setCountries] = useState([
    {
      label: "India",
      value: "IN",
    },
  ]);
  const user = useUserData();
  const [fieldDirty, setFieldDirty] = useState<Record<string, boolean>>({});
  const dispatch = useDispatch<any>();
  const [isDataValid, setIsDataValid] = useState(false);

  const formatDateToDDMMYYYY = (dateString: string) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateToYYYYMMDD2 = (dateString: string) => {
    if (!dateString) return null;

    // Split the input date string into day, month, and year
    const [day, month, year] = dateString.split("/");

    // Return the formatted date in yyyy-mm-dd
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const isValid = mandatoryFields.every((field) => formData[field] !== "");
    setIsFormValid(isValid);
  }, [formData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetch("https://restcountries.com/v3.1/all")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const countriesData = data
    //       .filter((country: any) => country.name.common === "India")
    //       .map((country: any) => ({
    //         label: country.name.common,
    //         value: country.cca2, // Country code (ISO 3166-1 alpha-2)
    //       }))
    //       .sort((a: any, b: any) => a.label.localeCompare(b.label)); // Sorting countries alphabetically

    //     setCountries(countriesData); // Setting sorted countries data to state
    //   })
    //   .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const formatDateForInput = (dateString: string) => {
    if (!dateString) return "";
    const [day, month, year] = dateString?.split('/');
    if (!day || !month || !year) return "";
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    dispatch(getUserById({}));
    if (user) {
      const body = { organizationId: user?.organization?.id };
      dispatch(getOrganizationFatca(body))
        .unwrap() // This unwraps the result of the fulfilled action
        .then((response: any) => {
          console.log("FATCA data received:", response.data);
          setFormData({
            taxPayerId: response?.data?.tin,
            idDocumentType: response?.data?.docType,
            incomeSlab: response?.data?.incomeSlab,
            occupationCode: response?.data?.occupation,
            netWorth: String(response?.data?.networth),
            exemptionCode: response?.data?.exempCode,
            entityCategory: response?.data?.nfec,
            entitySubCategory: response?.data?.nfesc,
            wealthSource: response?.data?.wealthSource,
            corporateServiceSector: response?.data?.corpServiceSector,
            dateOfNetworth: formatDateForInput(response?.data?.dateOfNetwworth),
            dateOfIncorporation:formatDateForInput(response?.data?.dateOfIncorporation),
            occupationType: response?.data?.occupationType,
            politicallyExposed: String(response?.data?.politicalExposed),
            ffiDrnfe: response?.data?.ffi,
            natureOfBusiness: response?.data?.businessNature,
            uboName: response?.data?.uboInfo?.name,
            uboNationality: response?.data?.uboInfo?.nation,
            uboAddressType: response?.data?.uboInfo?.addressType,
            uboCountryOfBirth: "IN",
            uboPan: response?.data?.uboInfo?.panCard,
            uboTaxResidency: response?.data?.uboInfo?.nation,
            uboCode: response?.data?.uboInfo?.code,
            uboCount:response?.data?.uboCount,
          });
          if (response?.data?.uboInfo?.name) {
            setIsDataValid(true);
            setUboFields(true);
          }else if(response?.data?.id){
            setIsDataValid(true);
          }
        })
        .catch((error: any) => {
          console.error("Error fetching FATCA data:", error);
          // Handle the error here
        });
    }
  }, [dispatch]);

  const [modalData, setModalData] = useState<{
    title: string;
    data: { label: string; value: string }[];
  } | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nonEntity, setNonEntity] = useState(false);
  const handleOpenModal = (
    title: string,
    data: { label: string; value: string }[]
  ) => {
    console.log("Opening Modal with data:", data); // Log the data before opening
    setModalData({ title, data });
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Disable scroll when dropdown is open
  useEffect(() => {
    if (dropdownOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on cleanup
    };
  }, [dropdownOpen]);

  const handleDropdownOpen = () => setDropdownOpen(true);
  const handleDropdownClose = () => setDropdownOpen(false);

  const handleSubmit = () => {
    // Map mandatory fields to their respective labels
    const fieldLabels: Record<string, string> = {
      taxPayerId: "Taxpayer ID",
      idDocumentType: "ID Document Type",
      incomeSlab: "Income Slab",
      occupationCode: "Occupation Code",
      netWorth: "Net Worth",
      exemptionCode: "Exemption Code",
      entityCategory: "Entity Category",
      entitySubCategory: "Entity Subcategory",
      wealthSource: "Source of Wealth",
      corporateServiceSector: "Corporate Service Sector",
      dateOfNetworth: "Date of Net Worth",
      occupationType: "Occupation Type",
      politicallyExposed: "Politically Exposed Person",
      // ffiDrnfe: "FFI/DRNFE",
      natureOfBusiness: "Nature of Business",

      uboName: "UBO Name",
      uboNationality: "UBO Nationality",
      uboAddressType: "UBO Address Type",
      uboCountryOfBirth: "UBO Country Of Birth",
      uboPan: "UBO Pan",
      uboTaxResidency: "UBO TAX Residency",
      uboCode: "UBO Code",
      uboCount: "UBO Count"
    };

    const unfilledFields = mandatoryFields.filter(
      (field) => formData[field] === ""
    );

    if (uboFields) {
      const unfilledFields2 = mandatoryFields2.filter(
        (field) => formData[field] === ""
      );
      if (unfilledFields2.length > 0) {
        // Show error messages for unfilled fields
        const newErrors: Record<string, string> = {};
        unfilledFields2.forEach((field) => {
          const label = fieldLabels[field] || field; // Default to field name if label is missing
          newErrors[field] = `${label} is required.`;
        });
        setFieldErrors(newErrors);
        return;
      }
    }

    if (unfilledFields.length > 0) {
      // Show error messages for unfilled fields
      const newErrors: Record<string, string> = {};
      unfilledFields.forEach((field) => {
        const label = fieldLabels[field] || field; // Default to field name if label is missing
        newErrors[field] = `${label} is required.`;
      });
      setFieldErrors(newErrors);
      return;
    }

    setIsModalVisible(true);
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    field: string,
    label: string
  ) => {
    const value = event.target.value;

    if (field === "entityCategory" && (value === "L" || value === "RL")) {
      setUboFields(false);
      setNonEntity(false);
    }else if(field === "entityCategory" && value === "A"){
     setNonEntity(true); 
     setUboFields(true);
    }else if(field === "entityCategory" && (value === "P" || value === "NA")){
      setNonEntity(false);
      setUboFields(true);
    }
     else if (
      field === "entityCategory" &&
      !(value === "L" || value === "RL")
    ) {
      setUboFields(true);
    }

    if (field === "occupationCode" && (value === "01" || value === "43")) {
      setFormData((prev) => ({
        ...prev,
        ["occupationType"]: "B",
      }));
    } else if (
      field === "occupationCode" &&
      (value === "05" ||
        value === "06" ||
        value === "07" ||
        value === "08" ||
        value === "99")
    ) {
      setFormData((prev) => ({
        ...prev,
        ["occupationType"]: "O",
      }));
    } else if (
      field === "occupationCode" &&
      (value === "02" ||
        value === "03" ||
        value === "04" ||
        value === "09" ||
        value === "41" ||
        value === "42" ||
        value === "44")
    ) {
      setFormData((prev) => ({
        ...prev,
        ["occupationType"]: "S",
      }));
    }

    // Update form data
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Mark the field as dirty
    setFieldDirty((prevDirty) => ({
      ...prevDirty,
      [field]: true,
    }));

    // Validate and set field errors
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value === "") {
        newErrors[field] = `${label} is required.`; // Set error if field is empty
      } else {
        delete newErrors[field]; // Clear error if valid
      }
      return newErrors;
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    label: string
  ) => {
    let value = e.target.value;
    if (field === "netWorth" && !isNaN(Number(value))) {
      value = String(Number(value)); // Convert the value to a string
    }
    console.log(value, "NetWorth");

    setFormData({ ...formData, [field]: value });

    // Mark the field as dirty when user starts typing
    setFieldDirty((prevDirty) => ({ ...prevDirty, [field]: true }));

    // Clear or set the error message based on the field value
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value === "" || value === "0") {
        newErrors[field] = `${label} is required.`; // Show error if field is empty
      } else {
        delete newErrors[field]; // Remove error if field is valid
      }
      return newErrors;
    });
  };

  const renderField = (
    label: string,
    type: string,
    field: string,
    options?: any
  ) => {
    const isMandatory = mandatoryFields.includes(field);
    const isMandatory2 = mandatoryFields2.includes(field);
    const errorMessage = fieldErrors[field];
    return (
      <Grid item xs={12} container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body1" sx={{ color: "gray", fontSize: "18px" }}>
            {label}
            {(isMandatory || isMandatory2) && <span> *</span>}
          </Typography>
        </Grid>
        <Grid item>
          {type === "dropdown" ? (
            <FormControl fullWidth>
              {/* <InputLabel>{label}</InputLabel> */}
              {field === "exemptionCode" || field === "entitySubCategory" ? (
                <FormControl fullWidth>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {field === "entitySubCategory"?(
                      <>
                      <Select
                      value={formData[field]}
                      onChange={(event) =>
                        handleSelectChange(event, field, label)
                      }
                      onOpen={handleDropdownOpen}
                      onClose={handleDropdownClose}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        backgroundColor: "white",
                        flexGrow: 1, // Ensures Select takes up available space
                        whiteSpace: "wrap",
                        opacity: nonEntity ? 1 : 0.5,
                      }}
                      disabled={!nonEntity}
                    >
                      {options?.map((option: any) => (
                        <MenuItem
                          key={option.value}
                          value={option.label}
                          style={{
                            whiteSpace: "wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      onClick={() => handleOpenModal(label, options)}
                      sx={{ ml: 1 }}
                    >
                      <InfoIcon />
                    </IconButton>
                      </>
                    ):(
                      <>
                      <Select
                      value={formData[field]}
                      onChange={(event) =>
                        handleSelectChange(event, field, label)
                      }
                      onOpen={handleDropdownOpen}
                      onClose={handleDropdownClose}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        backgroundColor: "white",
                        flexGrow: 1, // Ensures Select takes up available space
                        whiteSpace: "wrap",
                      }}
                      disabled={isDataValid}
                    >
                      {options?.map((option: any) => (
                        <MenuItem
                          key={option.value}
                          value={option.label}
                          style={{
                            whiteSpace: "wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      onClick={() => handleOpenModal(label, options)}
                      sx={{ ml: 1 }}
                    >
                      <InfoIcon />
                    </IconButton>
                      </>
                    )}
                  </Box>
                </FormControl>
              ) : (
                <FormControl fullWidth>
                  <Select
                    value={formData[field]}
                    onChange={(event) =>
                      handleSelectChange(event, field, label)
                    }
                    onOpen={handleDropdownOpen}
                    onClose={handleDropdownClose}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    sx={{
                      backgroundColor: "white",
                      whiteSpace: "wrap",
                    }}
                    disabled={field === "occupationType" || isDataValid}
                  >
                    {options?.map((option: any) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        style={{
                          whiteSpace: "wrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </FormControl>
          ) : formData[field] === "netWorth" ? (
            <TextField
              fullWidth
              type="number"
              value={Number(formData[field])}
              disabled={isDataValid}
              sx={{
                backgroundColor: "white",
              }}
              onWheel={(e: any) => e.target.blur()}
            />
          ) : (
            <TextField
              fullWidth
              type={type}
              value={
                field === "uboPan"
                  ? formData[field]?.toUpperCase()
                  : formData[field]
              }
              onChange={(e: any) => handleInputChange(e, field, label)}
              onWheel={(e: any) => e.target.blur()} // Disable scroll-based value change
              sx={{
                backgroundColor: "white",
              }}
              disabled={isDataValid}
            />
          )}
          <Grid item style={{ minHeight: "40px" }}>
            <Typography
              sx={{ color: "rgb(241,122,33)", fontSize: 18, marginTop: 1 }}
            >
              {errorMessage}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);
    // console.log(formData, "FormData");
    const body = {
      organizationId: user.organization.id,
      addressType: user?.addressType,
      city: user?.city,
      wealthSource: formData?.wealthSource,
      incomeSlab: formData?.incomeSlab,
      politicalExposed: formData?.politicallyExposed,
      occupation: formData?.occupationCode,
      occupationType: formData?.occupationType,
      tin: formData?.taxPayerId,
      docType: formData?.idDocumentType,
      corpServiceSector: formData?.corporateServiceSector,
      dateOfNetworth: formatDateToDDMMYYYY(formData?.dateOfNetworth),
      dateOfIncorporation: formatDateToDDMMYYYY(formData?.dateOfIncorporation),
      networth: Number(Number(formData?.netWorth).toFixed(2)),
      exempCode: formData?.exemptionCode,
      ffi: formData?.ffiDrnfe,
      giin: "NA",
      nfec: formData?.entityCategory,
      nfesc: formData?.entitySubCategory?formData?.entitySubCategory:"",
      businessNature: formData?.natureOfBusiness,
      pincode: "",
      uboCount:formData?.uboCount?formData?.uboCount:"",
      uboInfo:
        formData?.entityCategory === "L" || formData?.entityCategory === "RL"
          ? {
              name: "",
              panCard: "",
              nation: "",
              address1: "",
              address2: "",
              address3: "",
              city: "",
              pincode: "",
              state: "",
              country: "",
              addressType: "",
              tin: "",
              docType: "",
              code: "",
            }
          : {
              name: formData.uboName, // required
              panCard: formData?.uboPan?.toUpperCase(), //tin or pan
              nation: formData.uboNationality, // required
              address1: "", // required
              address2: "", // required
              address3: "",
              city: "", // required
              pincode: "",
              state: "", // required
              country: "IN", // required
              addressType: formData.uboAddressType, // required
              tin: "", //tin or pan
              docType: "C",
              code: formData.uboCode, // required
            },
    };

    //console.log(body, "Body");
    try {
      const response = await dispatch(addOrganizationFatca(body));
      if (response.payload.success === 200) {
        toast.success("UBO added successfully!");
        onCompanyToBank(); // Proceed to next step
      } else {
        toast.error("Failed to add UBO. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error(error);
    }
  };

  // const handleModalConfirm = () => {
  //   setIsModalVisible(false);
  //   onCompanyToDirector();
  //   console.log(formData, "Company");
  //   onSave(formData);
  //   // if (formikRef.current) {

  //   //   console.log('handle submit', formikRef?.current);

  //   //   formikRef?.current?.handleSubmit();
  //   //}
  // };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Box p={4} marginX="8%">
        <Grid container spacing={4}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {/* {renderField(
                    "Date Of Incorporation",
                    "date",
                    "dateOfIncorporation"
                  )} */}
              {/* {renderField(
                    "Place Of Incorporation (City)",
                    "text",
                    "placeOfIncorporation"
                  )} */}
              {renderField(
                "Tax Payer Identification No.",
                "text",
                "taxPayerId"
              )}
              {renderField(
                "Occupation Code",
                "dropdown",
                "occupationCode",
                options.occupationCode
              )}
              {renderField(
                "Wealth Source",
                "dropdown",
                "wealthSource",
                options.wealthSource
              )}
              {renderField("Net Worth (in Lakhs)", "number", "netWorth")}
              {renderField(
                "Exemption Code",
                "dropdown",
                "exemptionCode",
                options.exemptionCode
              )}
              {renderField(
                "Non Financial Entity Category",
                "dropdown",
                "entityCategory",
                options.entityCategory
              )}
              {renderField(
                "Corporate Service Sector",
                "dropdown",
                "corporateServiceSector",
                options.corporateServiceSector
              )}
              {/* {renderField("Nature Of Business", "text", "natureOfBusiness")} */}
              {/* {renderField("Spouse Name", "text", "spouseName")} */}
              {/* {renderField("SPR Entity", "text", "sprEntity")} */}
              {/* {renderField(
                    "UBO Gender",
                    "dropdown",
                    "uboGender",
                    options.uboGender
                  )} */}
              {renderField("Nature Of Business", "text", "natureOfBusiness")}
              {uboFields && renderField("UBO Name", "text", "uboName")}

              {uboFields && renderField("UBO PAN", "text", "uboPan")}

              {uboFields &&
                renderField("UBO Code", "dropdown", "uboCode", options.uboCode)}
              {uboFields &&
                renderField(
                  "UBO Country of Tax Residency",
                  "dropdown",
                  "uboTaxResidency",
                  countries
                )}
            </Grid>
          </Grid>
          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {renderField(
                "Identification Document Type",
                "dropdown",
                "idDocumentType",
                options.idDocumentType
              )}
              {renderField(
                "Occupation Type",
                "dropdown",
                "occupationType",
                options.occupationType
              )}
              {renderField(
                "Income Slab",
                "dropdown",
                "incomeSlab",
                options.incomeSlab
              )}
              {renderField(
                "Date Of Incorporation",
                "date",
                "dateOfIncorporation"
              )}
              {renderField("Date of Networth", "date", "dateOfNetworth")}
              {renderField(
                "Non Financial Entity Sub Category",
                "dropdown",
                "entitySubCategory",
                options.entitySubCategory
              )}
              {renderField(
                "FFI/DRNFE",
                "dropdown",
                "ffiDrnfe",
                options.ffiDrnfe
              )}
              {renderField(
                "Politically Exposed?",
                "dropdown",
                "politicallyExposed",
                options.politicallyExposed
              )}

              {/* {renderField(
                    "Address Type",
                    "dropdown",
                    "addressType",
                    options.addressType
                  )} */}
              {/* {renderField("Pincode", "text", "pincode")} */}
              {/* {renderField(
                    "Country of Incorporation",
                    "dropdown",
                    "countryOfIncorporation",
                    countries
                  )} */}
              {/* {renderField(
                    "Residential Country",
                    "dropdown",
                    "residentialCountry",
                    countries
                  )} */}
              {/* {renderField(
                    "Corporate Service Sector",
                    "dropdown",
                    "corporateServiceSector",
                    options.corporateServiceSector
                  )} */}

              {/* {renderField(
                    "Politically Exposed?",
                    "dropdown",
                    "politicallyExposed",
                    options.politicallyExposed
                  )} */}
              {uboFields &&
                renderField(
                  "UBO Address Type",
                  "dropdown",
                  "uboAddressType",
                  options.uboAddressType
                )}

              {uboFields &&
                renderField(
                  "UBO Nationality",
                  "dropdown",
                  "uboNationality",
                  options.uboNationality
                )}
              {uboFields &&
                renderField(
                  "UBO Country of Birth",
                  "text",
                  "uboCountryOfBirth"
                )}
                {uboFields &&
                renderField(
                  "UBO Count",
                  "number",
                  "uboCount"
                )}
              {/* {renderField("Fathers Name", "text", "fathersName")} */}
            </Grid>
          </Grid>
          {/* Modal for Info */}
          {modalData && (
            <ModalTable
              title={modalData.title}
              data={modalData.data}
              onClose={handleCloseModal}
            />
          )}
        </Grid>
        {isDataValid ? (
          <div className="w-full flex justify-center mt-2 mb-4">
            <button
              type="submit"
              onClick={onCompanyToBank}
              className="form-button"
            >
              Proceed
            </button>
          </div>
        ) : (
          <div className="w-full flex justify-center mt-2 mb-4">
            <button
              type="submit"
              onClick={handleSubmit}
              className="form-button"
            >
              Save and Proceed
            </button>
          </div>
        )}
      </Box>

      {isModalVisible && (
        <ConfirmationModal
          isVisible={isModalVisible}
          message="Are you sure you want to proceed? After submission, changes cannot be made."
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
        />
      )}
    </>
  );
};

export default Company;
