import { Box, Card, Typography, Grid, Button, IconButton, Snackbar, CircularProgress, Modal, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import { FormInput } from "../FormInput/FormInput";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import EditModal from "../modals/confirmationModal";
import ConfirmationSuccessModal from "../modals/confirmationSuccessModal";
import { updateProfile, userDeactive } from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import authService from "../../redux/auth/authService";

interface UserData {
  id:number;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address1?: string;
  address2?: string;
  address3?: string;
  Entity?: string;
  kyc?: {
    pancardNumber?: string;
    aadharNumber?: string;
  };
}

interface EditState {
  [key: string]: boolean;
}

interface InputValues {
  [key: string]: string;
}

const GeneralDetails: React.FC = () => {
  const user: UserData = useUserData();
  const dispatch = useDispatch<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const [updateStatus, setUpdateStatus] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<any>();
  const [ConfirmOpen,setConfirmOpen]=useState(false);
  const [Open,setOpen]=useState(false);
  const [modalData, setModalData] = useState<{
    type: 'phone' | 'email' | 'text' | 'address';
    value: string;
  }>({ type: 'phone', value: '' });
  const [Message,setMessage]=useState("");
  const [loadingModal,setLoadingModal]=useState(false);
  const [isDelete,setIsDelete]=useState(false);

  const [editState, setEditState] = useState<EditState>({
    name: false,
    mobile: false,
    email: false,
    address: false,
    entity: false,
    designation: false,
    pan: false,
    aadhar: false,
  });

  const [inputValues, setInputValues] = useState<InputValues>({
    name: `${user?.firstName ? user?.firstName + user?.lastName : ""}`,
    mobile: user.mobile,
    email: user.email,
    address: `${user?.address1 || ""} ${user?.address2 || ""} ${
      user?.address3 || ""
    }`,
    entity: user.Entity || "",
    designation: user.Entity || "",
    pan: user?.kyc?.pancardNumber || "",
    aadhar: user?.kyc?.aadharNumber || "",
  });

  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);

  const handleEditClick = (field: keyof EditState) => {
    if (field === "mobile" || field === "email") {
        setModalData({
            type: field === "mobile" ? "phone" : "email",
            value: field === "mobile" ? user.mobile : user.email,
        });
        setIsModalOpen(true);
    } else if (field === "address") {
        setModalData({
            type: "address", // This is now valid
            value: `${user.address1 || ""} ${user.address2 || ""} ${user.address3 || ""}`,
        });
        setIsModalOpen(true);
    } else {
        setEditState((prevState) => ({
            ...prevState,
            [field]: true,
        }));
    }
};

  

  const handleModalConfirm = (newValue: string) => {
    setNewValue(newValue);

    // Update the specific field in inputValues
    setInputValues((prevState) => ({
      ...prevState,
      [modalData.type === 'phone' ? 'mobile' : 'email']: newValue, // Update with new value
    }));

    setEditState((prevState) => ({
      ...prevState,
      [modalData.type === 'phone' ? 'mobile' : 'email']: true,
    }));

    handleUpdateProfile(newValue); // Update profile with new value
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsConfirmationModalOpen(false);

    // Clear the input field and modal data
    setNewValue('');
    setModalData({ type: 'phone', value: '' });
  };

  const handleInputChange = (field: keyof InputValues, value: string) => {
    setInputValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleConfirmationConfirm = () => {
    // Handle confirmation action here
    console.log('Confirmation confirmed!');
    
    // Close ConfirmationModal
    setIsConfirmationModalOpen(false);
  };

  const handleUpdateProfile = async (newValue: string) => {
    const requests: Record<string, any> = {
      KYC: {},
      USER: {},
      NOMINEE: {},
      BANK: {},
      PREF: {}
    };
  
    // Add details to the USER section if applicable
    if (modalData.type === 'phone') {
      requests.USER[user.id] = [
        {
          updateColumn: 'mobileNumber',
          updateValue: newValue,
          oldValue: user.mobile
        }
      ];
    } else if (modalData.type === 'email') {
      requests.USER[user.id] = [
        {
          updateColumn: 'email',
          updateValue: newValue,
          oldValue: user.email
        }
      ];
    } else if (modalData.type === 'text') { // Address case
      requests.USER[user.id] = [
        {
          updateColumn: 'address',
          updateValue: newValue,
          oldValue: `${user.address1 || ''} ${user.address2 || ''} ${user.address3 || ''}`
        }
      ];
    }
   
    const data = requests;
  
    console.log('Data to send:', data);
  
    try {
      const response = await dispatch(updateProfile(data)); // Send the data payload to the API
      setUpdateStatus('Profile updated successfully!');
      console.log('API Response:', response);
  
      const mobileRequested = requests.USER[user.id]?.some((req: any) => req.updateColumn === 'mobileNumber');
      const emailRequested = requests.USER[user.id]?.some((req: any) => req.updateColumn === 'email');
      const addressRequested = requests.USER[user.id]?.some((req: any) => req.updateColumn === 'address');
      const panRequested = requests.KYC[user.id]?.some((req: any) => req.updateColumn === 'pancardNumber');
      const aadharRequested = requests.KYC[user.id]?.some((req: any) => req.updateColumn === 'aadharNumber');
  
      setNotificationMessage(
        mobileRequested && emailRequested && addressRequested
          ? 'Phone number, email, and address have been requested for change.'
          : mobileRequested && emailRequested
          ? 'Both phone number and email have been requested for change.'
          : mobileRequested
          ? 'Phone number has been requested for change.'
          : emailRequested
          ? 'Email has been requested for change.'
          : addressRequested
          ? 'Address has been requested for change.'
          : panRequested
          ? 'PAN number has been requested for change.'
          : aadharRequested
          ? 'Aadhar number has been requested for change.'
          : ''
      );
  
      setIsConfirmationModalOpen(true);
    } catch (error) {
      setUpdateStatus('Failed to update profile.');
      console.error('API Error:', error);
    }
  
    // Reset edit state after saving
    setEditState({
      name: false,
      mobile: false,
      email: false,
      address: false,
      entity: false,
      designation: false,
      pan: false,
      aadhar: false,
    });
  };
  

  const handleCancel = (field: keyof EditState) => {
    setInputValues((prevState) => ({
      ...prevState,
      name: `${user?.firstName} ${user?.lastName}`,
      mobile: user.mobile,
      email: user.email,
      address: `${user?.address1 || ''} ${user?.address2 || ''} ${user?.address3 || ''}`,
      entity: user.Entity || '',
      designation: user.Entity || '',
      pan: user?.kyc?.pancardNumber || '',
      aadhar: user?.kyc?.aadharNumber || '',
    }));

    setEditState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };
  const handleConfirmOpen = async() => {
        setLoadingModal(true);
        try {
            const response = await dispatch(userDeactive());
            console.log(response,response.payload,"BSe Onboarding Response");
            if (response.payload.statusCode === 200) {
              setConfirmOpen(false);
              setOpen(true);
              setIsDelete(true);
              setMessage(`Your account has been deactivated successfully.`);
            } else {
              setOpen(true);
              setMessage("Something wents wrong.Please try after sometime!");
            }
            setLoadingModal(false);
        } catch (error) {
            console.error("Error downloading the PDF:", error);
            setOpen(true);
            setMessage("Internal server error.Please try after sometime!");
            setLoadingModal(false);
        }
  };

  const handleConfirmClose =()=>{
    setConfirmOpen(false);
  }

  const handleSuccessClose = ()=>{
    setOpen(false);
    if(isDelete){
      setLoadingModal(true);
      authService.logout();
    }
  }
  const isSmallScreen = useMediaQuery("(max-width:640px)");

  return (
    <div className=" border-2 border-lightGrey rounded-md ">
      <Card
        style={{
          backgroundColor: colors.lightBg,
          minHeight:isSmallScreen?"50px": "60px",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }}
        className="h-8 w-full"
      >
        <Typography variant={isSmallScreen?"subtitle1":"h5"} marginLeft={2} color={colors.darkGrey} fontWeight='bold'>
          General Details
        </Typography>
      </Card>

      {/* Display Notification Message */}
      
      <div className="justify-center sm:pt-4 mx-4 ">
      {notificationMessage && (
        <div style={{ backgroundColor: '#f17b21', padding: '10px', borderRadius: '5px', marginBottom: '10px', textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary" fontWeight='bold'>
            {notificationMessage}
          </Typography>
        </div>
      )}
      <Grid
          container
          spacing={2}
          className="flex flex-wrap justify-between py-8 sm:py-12 items-center"
        >
          <Grid item xs={6} md={6} lg={4}>
            {editState.name ? (
              <Box display="flex" alignItems="center">
                <FormInput
                  label="Name"
                  name="name"
                  value={inputValues.name}
                  onChange={(e: any) =>
                    handleInputChange("name", e.target.value)
                  }
                  placeholder="Full Name"
                  className="mb-4"
                  required
                />
                <IconButton onClick={() => handleCancel("name")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <div>
                <span className="text-textGrey text-sm md:text-base">
                  Name
                </span>
                <Typography
                  variant={isSmallScreen?"subtitle2":"h6"}
                  color={colors.darkGrey}
                  className="font-bold text-wrap"
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
              </div>
            )}
          </Grid>

          <Grid item xs={6} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Phone Number{" "}
                <IconButton
                  onClick={() => handleEditClick("mobile")}
                  size="small"
                >
                  <EditIcon sx={{ color: colors.primary }} />
                </IconButton>
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle2":"h6"}
                color={colors.darkGrey}
                className="font-bold text-wrap"
              >
                {user.mobile}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={6} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Email{" "}
                <IconButton
                  onClick={() => handleEditClick("email")}
                  size="small"
                >
                  <EditIcon sx={{ color: colors.primary }} />
                </IconButton>
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle2":"h6"}
                color={colors.darkGrey}
                className="font-bold break-all"
              >
                {user.email}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={6} md={6} lg={4}>
          <div>
              <span className="text-textGrey text-sm md:text-base">
                Address{" "}
                {/* <IconButton
                  onClick={() => handleEditClick("address")}
                  size="small"
                >
                  <EditIcon sx={{ color: colors.primary }} />
                </IconButton> */}
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle2":"h6"}
                color={colors.darkGrey}
                className="font-bold text-wrap"
              >
                {user.address1}, {user.address2}, {user.address3}
              </Typography>
            </div>
          </Grid>
        </Grid>

        {/* <Grid
          container
          
          className="border-t border-lightGrey py-8"
        >
          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Entity type
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle1":"h6"}
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user.Entity || "-"}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Designation
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle1":"h6"}
                color={colors.darkGrey}
                className="font-bold text-sm md:text-base"
              >
                {user.Entity || "-"}
              </Typography>
            </div>
          </Grid>
        </Grid> */}

        <Grid container className="border-t border-lightGrey py-8 m-0">
          <Grid item xs={6} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                PAN No.
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle2":"h6"}
                color={colors.darkGrey}
                className="font-bold text-wrap"
              >
                {user?.kyc?.pancardNumber || "-"}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={6} md={6} lg={4}>
            <div>
              <span className="text-textGrey text-sm md:text-base">
                Aadhar No.
              </span>
              <Typography
                 variant={isSmallScreen?"subtitle2":"h6"}
                color={colors.darkGrey}
                className="font-bold text-wrap"
              >
                {user?.kyc?.aadharNumber || "-"}
              </Typography>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <div className="flex flex-col justify-center">
              <span className="text-textGrey text-md md:text-base mb-2">
                Deactivate your account
              </span>
              <button className="text-white px-4 py-2 bg-primary hover:bg-secondary rounded cursor-pointer w-[200px]">
                Delete Account
              </button>
            </div>
          </Grid> */}
        </Grid>

        <Grid container className="border-t border-lightGrey py-8">
          <Grid item xs={12} md={12} lg={12}>
            <div className="flex flex-col justify-center">
              <p className="text-textGrey text-[14px] sm:text-sm md:text-lg mb-4">
               If you want to Deactivate your account.Please Click the below button.
              </p>
              <button className="text-white px-2 py-1 sm:px-4 sm:py-2 bg-primary hover:bg-secondary rounded cursor-pointer sm:w-[200px] w-[150px] text-[14px] sm:text-md" onClick={()=>{
                setConfirmOpen(true);
              }}>
                Deactivate Account
              </button>
            </div>
          </Grid>
        </Grid>

        <EditModal
          open={isModalOpen}
          type={modalData.type}
          value={modalData.value}
          onConfirm={handleModalConfirm}
          onClose={handleModalClose}
        />

       <ConfirmationSuccessModal
        open={isConfirmationModalOpen}
        type={modalData.type}
        value={modalData.value}
        onConfirm={handleConfirmationConfirm}
        onClose={handleModalClose}
      />

<ConfirmationModal2
        isVisible={ConfirmOpen}
        message={`Do you really want to delete or deactivate your account?`}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
        <Modal
          open={loadingModal}
          aria-labelledby="loading-modal-title"
          aria-describedby="loading-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
              <Typography
                id="loading-modal-title"
                variant="subtitle1"
                component="h2"
                marginBottom={5}
              >
                {`please wait...`}
              </Typography>
              <CircularProgress />
            </div>
          </Box>
        </Modal>

      </div>
    </div>
  );
};

export default GeneralDetails;
