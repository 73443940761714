import React, { useEffect, useState } from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import LaptopIcon from '@mui/icons-material/Laptop';
import TvIcon from '@mui/icons-material/Tv';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { colors } from '../../constants/colors';
import { Skeleton, TabContext, TabPanel } from '@mui/lab';
import { Button, Slider, TextField, Typography, useMediaQuery } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { calculateInvestment } from '../../utils/calculationUtils';
import { useUserData } from '../../hooks/useUserData';

interface TimePeriodOptions {
    [key: string]: string[];
}
interface InvestmentResult {
    TotalReturn: number;
    TotalInvestment: number;
    FutureValue: string;
}
const MFCalculator = ({ interestRateMF, fundDetails, setIsInvestModalOpen }: { interestRateMF?: any, fundDetails?: any, setIsInvestModalOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const user = useUserData();
    const [alignment, setAlignment] = useState('SIP');
    const [loading, setLoading] = useState(false);
    const [investmentAmount, setInvestmentAmount] = useState<any>(500);
    const [expectedReturn, setExpectedReturn] = useState<number | ''>(0);
    const [timePeriod, setTimePeriod] = useState<any>(1);
    const [investmentType, setInvestmentType] = useState('SIP');
    const [timePeriodUnit, setTimePeriodUnit] = useState('Years');
    const [timePeriodValue, setTimePeriodValue] = useState('0');
    const [investmentFrequency, setInvestmentFrequency] = useState('Monthly')
    const [detailsOftotalInvestment, setTotalInvestment] = useState<InvestmentResult>({
        TotalReturn: 0,
        TotalInvestment: 0,
        FutureValue: '0',
    });

    // console.log('Details Rate By MF ', fundDetails);
    // console.log('Interest Rate By MF ', interestRateMF);
    const handleExpectedReturnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
    
        // Remove the default value '0' when the user starts typing
        if (value === '') {
          setExpectedReturn('');
        } else {
          const numValue = Number(value);
          // Validate the value to be between 0 and 30
          if (numValue >= 0 && numValue <= 30) {
            setExpectedReturn(numValue);
          }
        }
      };
      const handleFocus = () => {
        // Clear the input when the user focuses on the TextField
        if (expectedReturn === 0) {
          setExpectedReturn('');
        }
      };

    const handleTimePeriodUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTimePeriodUnit(event.target.value);
        setTimePeriodValue('');
    };

    const handleFrequencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setInvestmentFrequency(event.target.value);
    };

    const timePeriodOptions: TimePeriodOptions = {
        Years: ["Choose", "1Yr", "2Yr", "3Yr", "4Yr", "5Yr", "7Yr", "10Yr"],
        months: ["Choose", "1Mth", "3Mth", "6Mth"],
        days: ["Choose", "1Day", "1Week"]
    };

    const validateInputs = () => {
        if (timePeriod === 'Choose') {
            toast.error('Please select a time period');
            return false;
        }
        if (isNaN(investmentAmount) || investmentAmount <= 0) {
            toast.error('Investment amount should be a valid positive number');
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (!validateInputs()) return;
        // console.log('update', "investmentAmount=>>", investmentAmount, 'timePeriod=>>', timePeriod, 'expectedReturn=>>', expectedReturn);

        if (investmentAmount && timePeriod && alignment && expectedReturn) {
            const calculatedValues = calculateInvestment({
                investmentAmount,
                timePeriodValue: timePeriod,
                investmentFrequency,
                investmentType: alignment,
                expectedReturn
            });

            setTotalInvestment(calculatedValues);
        }
    }, [investmentAmount, investmentFrequency, timePeriod, alignment, expectedReturn]);


    const openInvestModal = () => {
        if(user?.onboardStatus !== 3) {
            toast.error("Please wait for 24 hours. You are not allowed to invest now.");
            return;
        }
        setIsInvestModalOpen(true);
    };

    const handleAlignmentss = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    // console.log('New value', alignment);

    const isSmallScreen = useMediaQuery("(max-width:640px)");

    const isMediumScreen = useMediaQuery("(max-width:820px)");

    return (
        <Stack spacing={isSmallScreen ? 2 : 4} alignItems={isSmallScreen ? "center" : "normal"} paddingX={isSmallScreen ? 2 : isMediumScreen? 2 : 0 }>
            <ToggleButtonGroup
                value={alignment}
                color='primary'
                exclusive
                onChange={handleAlignmentss}

                aria-label="text alignment"
                sx={{
                    border: `1px solid ${colors.primary}`,
                    width:"100%",
                    borderRadius: '5px',
                    '& .css-19mip7l-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                        backgroundColor: colors.primary,
                        color: colors.white
                    }
                }}
            >
                <ToggleButton
                    value="SIP"
                    aria-label="left aligned"
                    sx={{
                        width: '50%',
                        backgroundColor: alignment === 'SIP' ? colors.primary : colors.white,
                        color: alignment === 'SIP' ? colors.white : colors.primary,
                        '&:hover': {
                            backgroundColor: alignment === 'SIP' ? colors.primary : colors.white,
                        }
                    }}
                >
                    <span className="text-[14px] sm:text-lg font-bold antialiased">SIP</span>
                </ToggleButton>
                <ToggleButton
                    value="Lumpsum"
                    aria-label="centered"
                    sx={{
                        width: '50%',
                        backgroundColor: alignment === 'Lumpsum' ? colors.primary : colors.white,
                        color: alignment === 'Lumpsum' ? colors.white : colors.primary,
                        '&:hover': {
                            backgroundColor: alignment === 'Lumpsum' ? colors.primary : colors.white,
                        }
                    }}
                >
                    <span className="text-[14px] sm:text-lg antialiased">Lumpsum</span>
                </ToggleButton>
            </ToggleButtonGroup>




            <div>
                {loading ? (
                    <Skeleton
                        variant="rectangular"
                        height={200}
                        animation="wave"
                        sx={{ bgcolor: colors.lightBg }}
                    />
                ) : (
                    <>
                        <div className="border border-lightGrey rounded-md px-4 sm:px-0">
                            <div className="my-5 flex flex-col md:flex-row gap-5 justify-between p-4  w-full mx-auto">
                                <div className="md:w-4/5 h-full flex flex-col justify-between">
                                    <div className="flex flex-col gap-y-2 mb-3">
                                        <div className="flex items-center justify-between">
                                            <Typography variant="subtitle2">Investment amount {alignment === 'Lumpsum' ? '' : 'per month'}</Typography>

                                            <div className="flex gap-x-2">
                                                <TextField
                                                    hiddenLabel
                                                    id="filled-hidden-label-small"
                                                    value={investmentAmount}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: "100px" }}
                                                />
                                                {/* {alignment === 'SIP' && (
                                                    <div className='w-1/2 flex items-center justify-center border border-lightGrey rounded-md'>
                                                        <span>
                                                            {investmentFrequency}
                                                        </span>
                                                    </div>
                                                )} */}
                                            </div>
                                        </div>
                                        <Slider
                                            value={investmentAmount}
                                            // scale={(x) => x / 10}
                                            min={500}
                                            step={500}
                                            max={100000}
                                            onChange={(event, value) => setInvestmentAmount(value)}
                                            aria-label="Default"
                                            valueLabelDisplay="auto"
                                            sx={{ color: colors.secondary, fontSize:isSmallScreen ? "12px" : "14px"}}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-y-2 mb-3">
                                        <div className="flex items-center justify-between">
                                            <Typography variant="subtitle2">Time period in years</Typography>
                                            <div className='flex gap-x-2'>
                                                {/* <div className='h-10 w-20 flex items-center justify-center border border-lightGrey rounded-md'>
                                                    <span className=''>
                                                        {timePeriodUnit}
                                                    </span>
                                                </div> */}
                                                <TextField
                                                    hiddenLabel
                                                    id="filled-hidden-label-small"
                                                    value={timePeriod}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: "100px" }}
                                                    inputProps={{ min: 1 }}
                                                    onChange={(e) => setTimePeriod(Math.max(1, Number(e.target.value)))}
                                                />

                                                {/* <select
                                                    className="border border-solid border-stone-300 rounded-md"
                                                    value={timePeriodValue}
                                                    onChange={(event) => setTimePeriodValue(event.target.value)} // Event handler for value change
                                                >
                                                    {/* Mapping over options based on the selected unit */}
                                                {/* {timePeriodOptions[timePeriodUnit].map((option) => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))} */}
                                                {/* </select> */}
                                            </div>
                                        </div>

                                        <Slider
                                            value={timePeriod}
                                            min={1}
                                            max={40}
                                            onChange={(event, value) => setTimePeriod(value)}
                                            // onChange={(event, value) => console.log(value)}
                                            aria-label="Default"
                                            valueLabelDisplay="auto"
                                            sx={{ color: colors.secondary }}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-y-2 mb-3">
                                        <div className="flex items-center justify-between">
                                            <Typography variant="subtitle2">Expected return rate (per annum)</Typography>
                                            <TextField
                                                hiddenLabel
                                                id="filled-hidden-label-small"
                                                value={expectedReturn}
                                                variant="outlined"
                                                size="small"
                                                sx={{ width: "100px" }}
                                                onChange={handleExpectedReturnChange}
                                                onFocus={handleFocus}
                                                inputProps={{ 
                                                    min: 0,
                                                    max: 30,
                                                    type: "number", // Ensure input is of type number
                                                    step: 0.1 // Optional: allows decimal values, change as needed
                                                  }}
                                            />
                                        </div>
                                        <Slider
                                            value={expectedReturn === '' ? 0 : expectedReturn}
                                            max={30}
                                            onChange={(event, value) => setExpectedReturn(Number(value))}
                                            aria-label="Default"
                                            valueLabelDisplay="auto"
                                            sx={{ color: colors.secondary }}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-y-2 mb-3">
                                        <div className="flex flex-row justify-between w-full border-b border-lightblue h-10 text-sm sm:text-normal">
                                            <span>Invested amount</span>
                                            <span>₹ {detailsOftotalInvestment?.TotalInvestment || 0}</span>
                                        </div>
                                        <div className="flex flex-row justify-between w-full border-b border-lightblue h-10 text-sm sm:text-normal">
                                            <span>Est. returns</span>
                                            <span>₹ {detailsOftotalInvestment?.TotalReturn.toFixed(0) || 0}</span>
                                        </div>
                                        <div className="flex flex-row justify-between w-full text-sm sm:text-normal">
                                            <span>Total value</span>
                                            <span>₹ {Number(detailsOftotalInvestment?.FutureValue).toFixed(0) || 0}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="md:w-1/2 max-w-[700px] min-h-full items-center justify-center">
                                    <div className="">
                                        {/* <PieChart
                                                series={[
                                                    {
                                                        data: [{ id: 0, value: detailsOftotalInvestment?.TotalInvestment, label: "Investment" },
                                                        { id: 1, value: detailsOftotalInvestment?.TotalReturn, label: "Earnings" }],
                                                        innerRadius: 75,
                                                        outerRadius: 100,
                                                        paddingAngle: 2,
                                                        startAngle: -45,
                                                        endAngle: 360,
                                                    }
                                                ]}
                                                colors={["#8DAEE5", "#FEE1A7"]}
                                                sx={{ width: "100%" }}
                                                height={300}
                                                margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
                                                slotProps={{
                                                    legend: {
                                                        direction: 'row',
                                                        position: { vertical: 'bottom', horizontal: 'middle' },
                                                        padding: 0,
                                                        itemGap: 10,
                                                    },
                                                }}

                                            /> */}
                                        <PieChart
                                            series={[
                                                {
                                                    data: [{ id: 0, value: detailsOftotalInvestment?.TotalInvestment || 50, label: "Investment" },
                                                    { id: 1, value: detailsOftotalInvestment?.TotalReturn || 50, label: "Earnings" }],
                                                    innerRadius: 75,
                                                    outerRadius: 100,
                                                    paddingAngle: 2,
                                                    startAngle: -45,
                                                    endAngle: 360,
                                                }
                                            ]}
                                            colors={["#8DAEE5", "#FEE1A7"]}
                                            sx={{ width: "100%" }}
                                            height={300}
                                            margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
                                            slotProps={{
                                                legend: {
                                                    direction: 'row',
                                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                                    padding: 0,
                                                    itemGap: 10,
                                                },
                                            }}

                                        />
                                    </div>
                                    <div className="flex flex-row items-center justify-center h-1/3 mt-4 sm:mt-0">
                                        <Button
                                            className="w-2/4 text-2xl "
                                            sx={{
                                                backgroundColor: colors.darkGrey,
                                                borderRadius: 1,
                                                textTransform: "none",
                                                padding: "0.5rem 1rem",
                                                color: "#fff",
                                                height: "3rem",
                                                fontSize:isSmallScreen? "14px" : isMediumScreen ? '1rem' : '1.2rem'
                                            }}
                                            variant="contained"
                                            onClick={openInvestModal}
                                        >
                                            Invest Now
                                        </Button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Stack >
    );
}
export default MFCalculator;