import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useCallback, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useDispatch, useSelector } from "react-redux";
import { getFDSchemeInfo, getFDinfo } from "../../redux/FD/FDSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BorderBottom, InfoOutlined, MoreVert, ReportProblem } from "@mui/icons-material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import { addToWatchlist, removeToWatchlist } from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import InvestModal from "../modals/FDRequestModal";
import TableRowComponent from "./TableRowComponent";
interface Data {
  schemes: Scheme[];
}

interface Scheme {
  stockName: string;
  plans: Plan[];
  ratings: string;
  minInvestAmountinINR: {
    monthly: number;
    quaterly: number;
    halfYearly: number;
    annual: number;
    cumulativeDeposit: number;
    annualisedYield: number;
  };
  maxInvestAmountinINR: number;
}

interface Plan {
  id: number;
  interestRates: {
    annual: number;
  };
  name: string;
  plans: {};
  ratings: string;
  minInvestAmountInINR: number;
  maxInvestAmountinINR: number;
}

interface Result {
  highestRateOverall: number;
  planWithHighestRateOverall: Plan | null;
  highestRatePerScheme: { [stockName: string]: { highestRate: number; highestPlanId: number } };
}

const Rows = ({ data, setLoading,setDataById, setTenureALL, openInvestModal }: any) => {
  const dispatch = useDispatch<any>();
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const openMenuMD = Boolean(anchorMD);
  const [seniorCitizenRate, setSeniorCitizenRate] = useState<number | null>(null);
  const [womanSpecialRate, setWomanSpecialRate] = useState<number | null>(null);
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const isSmallScreen = useMediaQuery('(max-width:600px)');



  return (
    <>
      <TableRowComponent
        data={data}
      />
    </>
  );
};
const UnlistedShareTable = ({ data, loader }: any) => {
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string | null }>({
    key: 'stockName',
    direction: 'ascending',
  });

  const getSortedData = useCallback(() => {
    if (!sortConfig.key) return data;

    const sortedData = [...data];
    

    switch (sortConfig.key) {
      case 'stockName':
        sortedData.sort((a, b) => {
          const nameA = (a.stockName || '').toUpperCase();
          const nameB = (b.stockName || '').toUpperCase();
          return (nameA < nameB ? -1 : nameA > nameB ? 1 : 0) * (sortConfig.direction === 'ascending' ? 1 : -1);
        });
        break;
        case 'stockSector':
        sortedData.sort((a, b) => {
          const nameA = (a.stockSector || '').toUpperCase();
          const nameB = (b.stockSector || '').toUpperCase();
          return (nameA < nameB ? -1 : nameA > nameB ? 1 : 0) * (sortConfig.direction === 'ascending' ? 1 : -1);
        });
        break;
        case "lotSize":
      sortedData.sort((a, b) => {
        const lotA = a.lotSize || 0;
        const lotB = b.lotSize || 0;
        return (lotA - lotB) * (sortConfig.direction === "ascending" ? 1 : -1);
      });
      break;
      default:
        break;
    }

    return sortedData;
  }, [data, sortConfig]);

  const handleSort = (key: string) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  const sortedData = getSortedData();

  return (
    <>
      <TableContainer component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          maxHeight: 550,
          overflow: "auto",
        }}>
        <Table aria-label="collapsible table" >
          <TableHead sx={{
            borderBottom: "2px solid #ccc",
            backgroundColor: colors.lightBg,
            "& th": {
              backgroundColor: colors.lightBg,
              border: "none",
              paddingTop: "2%",
              paddingBottom: "2%",
              borderBottom: "2px solid #ccc",
            },
          }}  >
            <TableRow sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              borderBottom: "2px solid #ccc",
              backgroundColor: colors.lightBg,
              "& th": {
                backgroundColor: colors.lightBg,
                border: "none",
                paddingTop: "2%",
                paddingBottom: "2%",
                borderBottom: "2px solid #ccc",
              },
            }}>
              {/* <TableCell padding="checkbox" /> */}
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} >
              <div className={`flex items-center w-auto`}>
                  <>Equity Name</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('stockName')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('stockName')}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} >
              <div className={`flex items-center w-auto`}>
                  <>Depository Applicable</>
                  {/* <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('stockName')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('stockName')}
                    />
                  </div> */}
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} >
              <div className={`flex items-center w-auto`}>
                  <>Lot Size</>
                  {/* <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('lotSize')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('lotSize')}
                    />
                  </div> */}
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center">
                <Dots />
              </div>
            </div>
          ) : (
            <TableBody className="" sx={{
              border: 'none',
              '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root': {
                borderBottom: 'none',
              },
            }} >
              <TableRow sx={{ height: '5%', }}>
                <TableCell colSpan={8} align="center">

                </TableCell>
              </TableRow>

              {sortedData && sortedData.length > 0 ? (
                <Rows data={sortedData} />
              )


                : <TableRow>
                  <TableCell colSpan={8} align="center">
                    No similar plans available.
                  </TableCell>
                </TableRow>}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default UnlistedShareTable;