import React from "react";
import { motion } from "framer-motion";
import {
  PieChart,
  Users,
  FileSpreadsheet,
  ArrowUpRight,
  Search,
  BarChart2,
} from "lucide-react";
import { useInView } from "framer-motion";

const FeatureCard = ({ icon: Icon, title, description, index }: any) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-50px" });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ y: -8 }}
      className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
    >
      <div className="w-16 h-16 bg-primary/10 rounded-xl flex items-center justify-center mb-6">
        <Icon className="w-8 h-8 text-primary" />
      </div>
      <h3 className="text-2xl font-semibold text-primary mb-4">{title}</h3>
      <p className="text-darkGrey">{description}</p>
    </motion.div>
  );
};

const FeaturesSection = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  const features = [
    {
      icon: PieChart,
      title: "Track Multiple Asset Classes",
      description:
        "Track your investments in Listed Stocks, Mutual Funds, Bonds, Fixed Deposits, PMS, AIF, Unlisted Shares & many other asset classes",
    },
    {
      icon: Users,
      title: "Track Family Wealth",
      description:
        "Investors can maintain individual portfolios and create family groups to view consolidated holdings and gain complete visibility into portfolio performance across asset classes",
    },
    {
      icon: FileSpreadsheet,
      title: "Auto-import Files",
      description:
        "Auto-import contract notes, back-office files, Mutual Fund CAS statements, PMS & AIF statements",
    },
    {
      icon: ArrowUpRight,
      title: "Perform Transactions",
      description:
        "Service to invest across asset classes like MF, FD, Bonds, PMS, AIF, Equities, Unlisted Shares and so much more with both online and offline support",
    },
    {
      icon: Search,
      title: "Do Your Own Research",
      description:
        "Measure performance, risk, attribution, exposures, and characteristics for all asset classes with best-in-class, flexible reports and charts",
    },
    {
      icon: BarChart2,
      title: "Analyse Investments",
      description:
        "Your portfolios stay updated in real-time, without you needing to worry about any manual effort. Tracking and analyzing portfolio with multi-level asset allocation insights can be done seamlessly",
    },
  ];

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
      className="py-24 bg-gradient-to-b from-lightBg to-white relative overflow-hidden"
    >
      <div className="absolute inset-0 opacity-5 bg-fixed"></div>
      <div className="container mx-auto px-6 relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-primary">Our Features</h2>
          <p className="text-xl text-darkGrey">
            Comprehensive Tools for Your Financial Success
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              index={index}
            />
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default FeaturesSection;
