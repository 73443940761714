import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Modal,
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import getInitials from "../../utils/getInitailasIMGUtils";
import { LoaderIcon } from "react-hot-toast";
import { toCamelCase } from "../../utils/ToCamelCase";
import {
  deleteHoldingsData,
  editHoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addPasswordForProtectedMail } from "../../redux/AutoFetchEmail/FetchedEmailSlice";
import APIloader from "../AppLoader/APIloader";
import AddPasswordModal from "../modals/AddPasswordModal";
import SuccessfullModal from "../modals/SuccessfullModal";

interface ListingCardComponentProps {
  loading?: boolean;
  assetType?: string;
  schemeName?: string;
  subInfoTitle?: string;
  data?:any;
  subInfo?: any;
  subInfo2?: any;
  subInfoTitle2?: string;
  arrayData?:any;
  EmptyMessage?:string;
  details?: { label: string; value: string | number }[];
  transactionsDetails?: any;
  fetchData?: any;
  onEdit?: () => void;
  onDelete?: () => void;
}

const FetchedEmailCardComponent : React.FC<ListingCardComponentProps> = ({
  loading,
  assetType,
  schemeName,
  data,
  subInfoTitle,
  subInfo,
  details,
  arrayData,
  transactionsDetails,
  fetchData,
  subInfoTitle2,
  subInfo2,
  EmptyMessage,
  onEdit,
  onDelete,
}) => {
    const dispatch = useDispatch<any>();
    const [Id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const handleAdd = (id: any) => {
    setModalOpen(true);
    setId(id);
  };

    const addPassword = async () => {
      const payload = {
        id: Id,
        password: password,
      };
      try {
        setLoader(true);
        const response = await dispatch(addPasswordForProtectedMail(payload));
        console.log(response?.payload?.data?.message,response?.payload?.data,response?.payload,"SuccessFull submission");
        if (response?.payload?.data?.statusCode === 200) {
          setModalOpen(false);
          setSuccessModal(true);
          setMessage("Password added successfully.");
          setLoader(false);
        } else {
          setModalOpen(false);
          setSuccessModal(true);
          setMessage(response?.payload?.message);
          setLoader(false);
        }
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setLoader(false);
      } catch (error) {
        console.error("API Error:", error);
        setSuccessModal(true);
        setMessage("Server is not working.Please try again after sometime!");
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setLoader(false);
      }
    };
    const handleClose = () => {
      setSuccessModal(false);
      setPassword("");
      setMessage("");
      fetchData();
    };
    const handleModalClose = () =>{
      setPassword("");
      setModalOpen(false);
    }


  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[80vh] w-full">
          <LoaderIcon style={{ width: "40px", height: "40px" }} />
        </div>
      ) : (
        <>
          {((details && details.length > 0)|| (arrayData && arrayData?.length > 0)) ? (
              <div className="bg-white p-2 sm:px-4 sm:py-2 w-[95%] mx-auto my-3 overflow-x-hidden border-[2px] border-lightGrey rounded-lg">
                {/* Header Row */}
                <div className="flex justify-between items-start">
                  <div className="flex">
                    <div className="w-[40px] h-[40px] sm:w-[60px] sm:h-[60px] mb-3 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-lg sm:text-2xl font-bold">
                      {getInitials(schemeName || "")}
                    </div>
                    <div className="ml-2 flex flex-col justify-start items-start mt-2 text-[13px] sm:text-lg font-semibold text-gray-800">
                      {toCamelCase(schemeName || "")}
                      {subInfo && (
                        <p className="text-[10px] sm:text-[16px] text-gray-500">
                          {subInfoTitle} :{" "}
                          {subInfoTitle === "Interest Rate" ||
                          subInfoTitle === "Expense Ratio" ||
                          subInfoTitle === "3Y Abs. Returns"
                            ? typeof subInfo === "number"
                              ? subInfo.toFixed(2) + "%"
                              : !isNaN(Number(subInfo))
                              ? Number(subInfo).toFixed(2)
                              : subInfo
                            : typeof subInfo === "number"
                            ? subInfo.toFixed(2)
                            : !isNaN(Number(subInfo))
                            ? Number(subInfo).toFixed(2)
                            : subInfo}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Three Dots Menu */}
                  {/* {!(assetType === "Mutual Fund") && (
                    <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                  )} */}

                  {/* Dropdown Menu */}
                  {/* <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        //   onEdit();
                        handleClose();
                      }}
                    >
                      Invest
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleConfirmModalOpen(
                          transactionsDetails[0]?.id,
                          schemeName
                        );
                        handleClose();
                      }}
                    >
                      Add to Wishlist
                    </MenuItem>
                  </Menu> */}
                </div>

                {/* Grid for Labels & Values */}
                <div className="grid grid-cols-3 gap-3 mt-1">
                  {details &&
                    details?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col justify-center items-center"
                      >
                        <span className="text-gray-500 text-[12px] sm:text-lg font-medium">
                          {item.label}
                        </span>
                        <span className="text-gray-800 text-[13px] sm:text-xl font-semibold text-wrap">
                          {item.label!=="Password"?typeof item.value === "number"
                            ? item.value.toFixed(2)
                            : !isNaN(Number(item.value))
                            ? Number(item.value).toFixed(2)
                            : item.value:item.value===0? "Not Required":(
                              <button
                                className="bg-primary px-2 py-1 text-[12px] sm:text-lg text-white hover:bg-secondary rounded"
                                onClick={() => {
                                  handleAdd(arrayData?.id);
                                }}
                              >
                                Add Password
                              </button>
                            )}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
          ) : (
            <div className="w-full text-center text-darkGrey my-6 text-sm sm:text-xl font-semibold">
              {EmptyMessage? EmptyMessage :`No data is available for this ${assetType}.`}
            </div>
          )}
          <APIloader loadingModal={loader} message="Please wait ..." />
      <AddPasswordModal
        title="Please provide password to access this pdf."
        showModal={modalOpen}
        setShowModal={setModalOpen}
        password={password}
        setPassword={setPassword}
        onClick={addPassword}
        onClose={handleModalClose}
      />
      <SuccessfullModal
        open={successModal}
        message={message}
        handleClose={handleClose}
      />
        </>
      )}
    </>
  );
};

export default FetchedEmailCardComponent ;
