import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import {formatIndianCurrency} from "../../utils/formatIndianNumber";
import { toCamelCase } from "../../utils/ToCamelCase";

interface InvestmentDataProps {
  schemeName: string;
  totalAmount: string;
  units?: string; // Only units are required
  cagr?: number;
  isin?:string;
  id?:string;
}

interface DetailsProps {
  holdingsData: any; // New prop for holdings
}

const SummaryDetails: React.FC<DetailsProps> = ({ holdingsData }) => {
  console.log("holdings data mf", holdingsData);
  
  // Check if holdingsData length is greater than 10
  const isScrollable = holdingsData.length > 10;

  return (
    <div
      className={`space-y-2 mt-4 ${isScrollable ? 'overflow-y-scroll' : ''}`}
      style={isScrollable ? { maxHeight: '600px' } : {}}
    >{holdingsData?.length>0 ?
      (holdingsData.map((holding: any, index: number) => (
        <InvestmentData
          key={index}
          schemeName={holding.schemeName} // Ensure 'title' exists in holding
          totalAmount={holding.currentMktValue} // Ensure 'totalAmount' exists in holding
          units={holding.units} // Ensure 'units' exists in holding, if applicable\
          cagr={holding.cagr}
          isin={holding.isin}
          id={holding.id}
        />
      ))):(
        <div className="flex flex-col items-center justify-center">
          <h6 className="font-bold text-sm text-gray-500">No Data Available</h6>
        </div>
      )}
    </div>
  );
};

const InvestmentData: React.FC<InvestmentDataProps> = ({
  schemeName,
  totalAmount,
  units,
  cagr,
  isin,
  id // Using units prop
}) => {
  const navigate = useNavigate();
    const theme = useTheme();
    // Detect screen size using useMediaQuery
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  
    // Dynamically adjust height based on screen size
    let chartHeight = 500; // default height for large screens
    let chartWidth = 1300;
    if (isLargeScreen) {
      chartHeight = 500; // Keep it 500 for large screens
    } else if (isMediumScreen) {
      chartHeight = 400; // Slightly reduce the height for medium screens
    } else if (isSmallScreen) {
      chartHeight = 250; // Further reduce the height for small screens
    }

  const formattedTotalAmount = totalAmount
    ? Number(totalAmount).toFixed(2)
    : "0.00";
  const formattedUnits = units ? Number(units).toFixed(2) : "0.00"; // Format units
  

  return (
    <div className="px-4 py-3 border border-primary rounded-lg">
      <Grid
        container
        spacing={2}
        alignItems="center"
        // justifyContent="space-between" // Ensure even spacing
      >
        {/* Circle with initials and fund name */}
        <Grid item xs={12} sm={5} className="flex items-center">
          <div className="flex flex-row">
          <Grid item xs={2.5}
            className="bg-lightBg rounded-full flex items-center justify-center text-textGrey font-bold mr-2 text-lg lg:text-2xl p-3 sm:p-0 w-14 h-14" 
          >
            {getInitials(schemeName)}
          </Grid>

          <Grid item xs={9.5} className="text-gray-700_01 font-bold text-[0.9rem] text-start sm:text-left p-2">{toCamelCase(schemeName)}</Grid>
          </div>
        </Grid>

        {/* Investment value */}
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">Current Value</p>
          <p className="font-bold">{formatIndianCurrency(formattedTotalAmount)}</p>
        </Grid>

        {/* Units */}
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">Units</p>
          <p className="font-bold">{formattedUnits}</p>
        </Grid>

        {/* CAGR */}
        <Grid item xs={12} sm={2} textAlign="center">
          <p className="text-gray-700_01 text-md">CAGR</p>
          <p className="font-bold">{cagr?.toFixed(3)}</p>
        </Grid>

        {/* Arrow Icon */}
        <Grid
          item
          xs={12}
          sm={1}// Reduce width for icon
          textAlign="center"
          className="hidden sm:flex md:justify-start justify-center"
        >
          <p
            className="text-gray-700_01 cursor-pointer m-0" // Remove margin
            onClick={()=>navigate(`/mf-details/${isin}/${id}`)}
            style={{ fontSize: "1.5rem" }} // Adjust font size
          >
            <MdKeyboardArrowRight />
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}// Reduce width for icon
          textAlign="center"
          className="flex sm:hidden md:justify-start justify-center text-2xl" >
          <button
           className="text-xs px-2 py-1 flex justify-center items-center font-medium text-white bg-primary hover:bg-secondary rounded-lg"// Remove margin
            onClick={()=>navigate(`/mf-details/${isin}/${id}`)}
            // style={{ fontSize: "1.5rem" }} // Adjust font size
          >
            View More
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryDetails;
