import React from 'react';
import Modal from '@mui/material/Modal';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import Main from '../MFTransaction/main';
import MFDetails from '../../pages/MFDetails/MFDetails';
import { colors } from '../../constants/colors';
import FDRequest from '../FDRequest/FDRequest';


interface InvestModalProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

const InvestModal: React.FC<InvestModalProps> = ({ open, onClose, data }) => {
  // console.log('model data', data);
    const theme = useTheme();
    // Detect screen size using useMediaQuery
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '8px',
          width:isSmallScreen? '95%' : '70%',
          height: '90%',// Adjusted width to fill most of the screen
          maxHeight: '90%', // Adjusted maxHeight to allow some space at the bottom for the close button
          overflow: 'hidden', // Use overflow instead of overflowY
          display: 'flex',

        }}
      >

        <FDRequest activeSection={''} fundId={data?.data?.id} Rowdata={data} modelClose={onClose} />
      </Box>
    </Modal>
  );
};

export default InvestModal;
