import { colors } from "../../constants/colors";

const AmountCard = (data: any) => {
  return (
    <div className="max-w-[1400px]">
      {" "}
      {/* Adjust the max width as needed */}
      <div
        className="px-2 lg:px-5 py-2 flex flex-col gap-y-2 rounded-md border border-solid  min-w-[90px] lg:min-w-[200px]"
        style={{
          backgroundColor: colors.bgGrey,
          border: `1px solid ${colors.lightGrey}`,
        }}
      >
        <div className="text-[0.8rem] md:text-sm font-medium" style={{ color: colors.textGrey }}>
          {data.label}
        </div>
        <div className="text-[1rem] md:text-lg font-bold" style={{ color: colors.black }}>
          {data.value}
        </div>
      </div>
    </div>
  );
};

export default AmountCard;
