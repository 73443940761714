import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";

const TermsOfUsePage: React.FC = () => {
  return (
    <Container maxWidth="lg" className="py-20">
      <Paper elevation={3} className="p-6">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Aum Sampann Terms and Conditions of Use
        </Typography>
        <Box className="space-y-6">
          {/* Section 1 */}
          <Box>
            <h4 className="text-lg font-semibold mb-4">1. Introduction</h4>
            <Typography className="text-justify">
              Aum Sampann (&quot;Platform&quot;) is a wealth management tool
              owned and operated by Fingeek Wealth Solutions Private Limited
              (&quot;We,&quot; &quot;Us,&quot; or &quot;Aum Sampann&quot;). The
              Platform enables users (&quot;You,&quot; &quot;User&quot;) to view
              and manage financial portfolios across asset classes, including
              AIFs, PMS, fixed deposits, bonds, gold, mutual funds, and
              equities. By accessing, installing, or using the Platform, You
              agree to these Terms and Conditions (&quot;Terms&quot;). If You do
              not agree, You must discontinue use immediately.
            </Typography>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">2. Eligibility</h4>
            <li className="text-justify">
              You must be legally competent (as per the Indian Contract Act,
              1872) to use the Platform.
            </li>
            <li>
              You warrant that all provided data is accurate and complete.
            </li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">
              3. Account Registration & Security
            </h4>
            <li className="text-justify">
              You must create an account to access certain features.
            </li>
            <li>
              You are solely responsible for maintaining confidentiality of
              login credentials.
            </li>
            <li>Notify Aum Sampann immediately of any unauthorized access.</li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">
              4. Services & Restrictions
            </h4>
            <div className="mb-4">
              4.1. Permitted Use
              <li className="text-justify">
                The Platform provides portfolio tracking, analytics, and
                reporting.
              </li>
              <li>
                Aum Sampann is not a SEBI-registered PMS/AIF provider but acts
                as a technology service provider.
              </li>
            </div>
            <div className="mb-4">
              4.2. Prohibited Activities <br />
              You shall not:
              <li className="text-justify">
                Use the Platform for illegal/unethical purposes.
              </li>
              <li>Impersonate others or misrepresent affiliations.</li>
              <li>
                Reverse-engineer, modify, or distribute Aum Sampann’s
                software/data.
              </li>
              <li>
                Upload malicious code or prohibited content (defamatory/pirated
                material).
              </li>
            </div>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">5. Fees & Payments</h4>
            <li className="text-justify">
              Subscription fees are non-refundable unless stated otherwise.
            </li>
            <li>
              Aum Sampann reserves the right to modify pricing with prior
              notice.
            </li>
            <li>
              Failure to pay may result in suspension/termination of services.
            </li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">6. Data & Privacy</h4>
            <li className="text-justify">
              Your data is governed by our Privacy Policy.
            </li>
            <li>
              Aum Sampann may process and store data for service improvement.
            </li>
            <li>
              You consent to promotional communications (opt-out available).
            </li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">
              8. Intellectual Property
            </h4>
            <li className="text-justify">
              All Platform content (logos, trademarks, software) is owned by Aum
              Sampann.
            </li>
            <li>Unauthorized use/copying is strictly prohibited.</li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">
              9. Disclaimer of Warranties
            </h4>
            <li className="text-justify">
              The Platform is provided "as-is" without warranties of accuracy,
              completeness, or fitness for a particular purpose.
            </li>
            <li>
              Aum Sampann does not guarantee uninterrupted/error-free service.
            </li>
            <li>
              Failure to pay may result in suspension/termination of services.
            </li>
            <li>
              Capital gains/tax reports are for informational purposes
              only—verify with a tax advisor before filing returns.
            </li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">
              10. Limitation of Liability
            </h4>
            <li className="text-justify">
              Aum Sampann’s liability is capped at the fees paid by You in the
              calendar year.
            </li>
            <li>
              We are not liable for:
              <br />
              - Third party actions.
              <br />
              - Data loss/corruption
              <br />- Investment decisions based on Platform insights.
            </li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">11. Indemnification</h4>
            <div className="mb-4">
              You agree to indemnify Aum Sampann against claims arising from:
              <li className="text-justify">Your misuse of the Platform.</li>
              <li className="text-justify">Violation of these Terms.</li>
              <li className="text-justify">
                Unauthorized sharing of account access.
              </li>
            </div>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">12. Termination</h4>
            <li className="text-justify">
              Aum Sampann may suspend/terminate access for breach of Terms.
            </li>
            <li>
              Upon termination, You must cease all use and delete stored data.
            </li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">
              13. Dispute Resolution & Governing Law
            </h4>
            <li className="text-justify">
              Disputes shall be resolved through arbitration in Kolkata under
              the Arbitration and Conciliation Act, 1996.
            </li>
            <li>Governing law: Indian law.</li>
          </Box>
          <Box>
            <h4 className="text-lg font-semibold mb-4">14. Miscellaneous</h4>
            <li className="text-justify">
              Force Majeure: Aum Sampann is not liable for disruptions due to
              unforeseen events (e.g., natural disasters, cyberattacks).
            </li>
            <li>
              Amendments: Terms may be updated periodically—continued use
              constitutes acceptance.
            </li>
            <li>
              Severability: If any clause is unenforceable, the rest remain
              valid.
            </li>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfUsePage;
