import React from 'react';
import { motion } from 'framer-motion';
import { Users, Building2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface UserSelectionProps {
  onPartnerClick: () => void;
}

const UserSelection: React.FC<UserSelectionProps> = ({ onPartnerClick }) => {
  const navigate = useNavigate();
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-8">
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-white rounded-lg shadow-lg p-8 border-2 border-[#255288] cursor-pointer"
            onClick={() => navigate("/register-partner")}
          >
            <Building2 className="w-16 h-16 text-[#255288] mb-4" />
            <h3 className="text-2xl font-bold mb-4 text-[#255288]">Register as Partner</h3>
            <p className="text-gray-600">
              Join our network of financial Partners and grow your business with access to premium investment products.
            </p>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-white rounded-lg shadow-lg p-8 border-2 border-[#F17A21] cursor-pointer"
            onClick={()=>navigate("/register")}
          >
            <Users className="w-16 h-16 text-[#F17A21] mb-4" />
            <h3 className="text-2xl font-bold mb-4 text-[#F17A21]">Register as Investor</h3>
            <p className="text-gray-600">
              Start your investment journey with personalized solutions and expert guidance for your financial goals.
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default UserSelection;