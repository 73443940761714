import React, { useEffect } from "react";

import axios from "axios";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import { colors } from "../../constants/colors";
import { Link } from "react-router-dom";
import { useUserData } from "../../hooks/useUserData";
import { analyticsConfig } from "../../config/analyticsConfig";

const supersetUrl =
  process.env.REACT_APP_ANALYTICS_URL || "http://localhost:8088";
const supersetApiUrl = supersetUrl + "/api/v1/security";
const capDashboardId = analyticsConfig.capDashboardId;
const sectorDashboardId = analyticsConfig.sectorDashboardId;

const AnalyticsDashboard = () => {
  const user = useUserData();
  const [state, setState] = React.useState({
    showInvestments: true,
  });
  const [currentChart, setCurrentChart] = React.useState("capChart");
  const [accessToken, setAccessToken] = React.useState<string | null>(null);

  const toggleInvestments = () => {
    setState({ ...state, showInvestments: !state.showInvestments });
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      if (currentChart === "capChart") {
        getCapDashboard();
      } else if (currentChart === "sectorChart") {
        getSectorDashboard();
      }
    }
  }, [accessToken, currentChart]);

  async function getToken() {
    try {
      //calling login to get access token
      const login_body = {
        password: process.env.REACT_APP_ANALYTICS_PASSWORD,
        provider: "db",
        refresh: true,
        username: process.env.REACT_APP_ANALYTICS_USERNAME,
      };

      const login_headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        supersetApiUrl + "/login",
        login_body,
        login_headers
      );
      const access_token = data["access_token"];

      setAccessToken(access_token);
    } catch (err) {
      console.log(err, 'err in getToken');
    }
  }

  const getCapDashboard = async () => {
    // Calling guest token
    const guest_token_body = JSON.stringify({
      resources: [
        {
          type: "dashboard",
          id: capDashboardId,
        },
      ],
      rls: [
        {
          clause: `userId = ${user?.id}`,
        },
      ],
      user: {
        username: `${user?.id}`,
        first_name: "report-viewer",
        last_name: "report-viewer",
      },
    });

    const guest_token_headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    await axios
      .post(
        supersetApiUrl + "/guest_token/",
        guest_token_body,
        guest_token_headers
      )
      .then((dt) => {
        embedDashboard({
          id: capDashboardId, // given by the Superset embedding UI
          supersetDomain: supersetUrl,
          mountPoint: document.getElementById("superset-container") as any, // html element in which iframe render
          fetchGuestToken: () => dt.data["token"],
          dashboardUiConfig: { hideTitle: true, hideChartControls: true },
        });
      })
      .catch((err) => {
        console.log(err);
        // window.location.reload();
      });

    var iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.style.width = "100%"; // Set the width as needed
      // height shoud be auto
      iframe.style.minHeight = "1000px";
    }
  };

  const getSectorDashboard = async () => {
    // Calling guest token
    const guest_token_body = JSON.stringify({
      resources: [
        {
          type: "dashboard",
          id: sectorDashboardId,
        },
      ],
      rls: [
        {
          clause: `userId = ${user?.id}`,
        },
      ],
      user: {
        username: `${user?.id}`,
        first_name: "report-viewer",
        last_name: "report-viewer",
      },
    });

    const guest_token_headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    await axios
      .post(
        supersetApiUrl + "/guest_token/",
        guest_token_body,
        guest_token_headers
      )
      .then((dt) => {
        embedDashboard({
          id: sectorDashboardId, // given by the Superset embedding UI
          supersetDomain: supersetUrl,
          mountPoint: document.getElementById("superset-container") as any, // html element in which iframe render
          fetchGuestToken: () => dt.data["token"],
          dashboardUiConfig: { hideTitle: true },
        });
      })
      .catch((err) => {
        console.log(err);
        // window.location.reload();
      });

    var iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.style.width = "100%"; // Set the width as needed
      iframe.style.minHeight = "1000px"; // Set the height as needed
    }
  };

  const isButtonActive = (chart: string) => {
    return currentChart === chart;
  };

  return (
    <div className="min-h-screen w-[100%] mx-auto">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col md:w-1/5">
          <h2 className="text-xl font-bold my-4 font-inter text-center">
            Analytics Dashboard
          </h2>
          <List sx={{ marginLeft: 1 }}>
            <ListItem
              disablePadding
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                // marginBottom: "10px",
              }}
            >
              <ListItemButton
                onClick={toggleInvestments}
                sx={{
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: colors.secondary,
                  },
                }}
              >
                <ListItemText
                  primary="Investment Break-Up"
                  sx={{
                    fontSize: 32,
                    color: colors.primary,
                  }}
                />
                <ListItemIcon>
                  {state.showInvestments ? (
                    <KeyboardArrowDownOutlined
                      sx={{
                        marginLeft: 3,
                        fontSize: 32,
                        color: colors.primary,
                      }}
                    />
                  ) : (
                    <KeyboardArrowRightOutlined
                      sx={{
                        marginLeft: 3,
                        fontSize: 32,
                        color: colors.primary,
                      }}
                    />
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            {state.showInvestments && (
              <List sx={{ marginLeft: 1 }}>
                <ListItem
                  disablePadding
                  sx={{
                    backgroundColor: isButtonActive("capChart")
                      ? colors.primary
                      : "inherit",
                  }}
                >
                  <ListItemButton
                    // component={Link}
                    // to="/mf-listing"
                    onClick={() => setCurrentChart("capChart")}
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.secondary,
                      },
                    }}
                    selected={isButtonActive("capChart")}
                  >
                    <ListItemText
                      primary="Small vs Mid vs Large Cap"
                      sx={{
                        fontSize: 32,
                        color: isButtonActive("capChart")
                          ? colors.white
                          : colors.primary,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{
                    backgroundColor: isButtonActive("sectorChart")
                      ? colors.primary
                      : "inherit",
                  }}
                >
                  <ListItemButton
                    // component={Link}
                    // to="/mf-listing"
                    onClick={() => setCurrentChart("sectorChart")}
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.secondary,
                      },
                    }}
                    selected={isButtonActive("sectorChart")}
                  >
                    <ListItemText
                      primary="Sector Break-Up"
                      sx={{
                        fontSize: 32,
                        color: isButtonActive("sectorChart")
                          ? colors.white
                          : colors.primary,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </List>
        </div>

        <div
          id="superset-container"
          className="flex justify-center w-full"
        ></div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
