import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useState, useCallback } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useDispatch, useSelector } from "react-redux";
import { getFDSchemeInfo, getFDinfo } from "../../redux/FD/FDSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BorderBottom,
  InfoOutlined,
  MoreVert,
  ReportProblem,
} from "@mui/icons-material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import InvestModal from "../modals/FDRequestModal";
import MainTableComponent from "./MainTableComponent";

interface Data {
  schemes: Scheme[];
}

interface Scheme {
  schemeName: string;
  plans: Plan[];
  ratings: string;
  minInvestAmountinINR: {
    monthly: number;
    quaterly: number;
    halfYearly: number;
    annual: number;
    cumulativeDeposit: number;
    annualisedYield: number;
  };
  maxInvestAmountinINR: number;
}

interface Plan {
  id: number;
  interestRates: {
    annual: number;
  };
  name: string;
  plans: {};
  ratings: string;
  minInvestAmountInINR: number;
  maxInvestAmountinINR: number;
}

interface Result {
  highestRateOverall: number;
  planWithHighestRateOverall: Plan | null;
  highestRatePerScheme: {
    [schemeName: string]: { highestRate: number; highestPlanId: number };
  };
}

const Rows = ({
  data,
  setLoading,
  setDataById,
  setTenureALL,
  openInvestModal,
}: any) => {
  const dispatch = useDispatch<any>();
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const openMenuMD = Boolean(anchorMD);
  const [seniorCitizenRate, setSeniorCitizenRate] = useState<number | null>(
    null
  );
  const [womanSpecialRate, setWomanSpecialRate] = useState<number | null>(null);
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const isSmallScreen = useMediaQuery("(max-width:640px)");

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    isinWishilist: number,
    id: number
  ) => {
    setAnchorMD(event.currentTarget);
    let data = { status: isinWishilist, id: id };
    setIsinWishilist(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const Watchlist = async () => {
    console.log("Sonu");
    if (!isinWishilist) return;

    if (isinWishilist.status === 1) {
      const data = { itemId: isinWishilist.id, itemType: 5 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 0 });
        toast.success("Removed from watchlist");
      } else {
        toast.error("Failed to remove from watchlist");
      }
    } else if (isinWishilist.status === 0) {
      const data = { itemId: isinWishilist.id, itemType: 5 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 1 });
        toast.success("Added to watchlist");
      } else {
        toast.error("Failed to add to watchlist");
      }
    }
  };

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const response = await dispatch(getFDSchemeInfo({ schemeId: id }));

      if (response?.payload) {
        setDataById(response.payload.scheme);
        setTenureALL(response?.payload?.scheme?.tenures);
        openInvestModal();
      } else {
        toast.error("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };
  // console.log('data on fd', data);

  return (
    <>
      <MainTableComponent data={data} modalMenuClick={modalMenuClick} />
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: isSmallScreen ? "80%" : "12%",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem button onClick={handleClose} sx={{ marginLeft: "-25px" }}>
            <MenuItem className="text-xs sm:text-sm" onClick={Watchlist}>
              {isinWishilist?.status === 1
                ? "Remove from watchlist"
                : "Add to Watchlist"}
            </MenuItem>
          </ListItem>
          <Divider sx={{ backgroundColor: "#fff" }} />
          <ListItem button onClick={handleClose} sx={{ marginLeft: "-25px" }}>
            <MenuItem
              className="text-xs sm:text-sm"
              onClick={(e: any) => {
                fetchData(isinWishilist.id);
              }}
            >
              Invest
            </MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
    </>
  );
};

const AIFTable = ({ data, loader }: any) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string | null;
  }>({
    key: "assetName",  // Set the default key to "assetName" or any other column you want
    direction: "ascending",  // Set the default direction to ascending
  });


  const getSortedData = useCallback(() => {
    if (!sortConfig.key) return data;
    const sortedData = [...data];

    const compareStrings = (a: string, b: string) => {
      if (a < b) return sortConfig.direction === "ascending" ? -1 : 1;
      if (a > b) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    };

    const compareNumbers = (a: number, b: number) => {
      return sortConfig.direction === "ascending" ? a - b : b - a;
    };

    const compareDates = (a: string, b: string) => {
      // Check if either date is invalid
      if (!a || !b) return 0; // You can handle this differently based on requirements

      // Split the date string into components
      const [dayA, monthA, yearA] = a.split("-")?.map(Number);
      const [dayB, monthB, yearB] = b.split("-")?.map(Number);

      // Validate the parsed components to ensure they form a valid date
      if (
        isNaN(dayA) ||
        isNaN(monthA) ||
        isNaN(yearA) ||
        isNaN(dayB) ||
        isNaN(monthB) ||
        isNaN(yearB)
      ) {
        return 0; // You can handle invalid dates differently
      }

      // Create Date objects
      const dateA = new Date(yearA, monthA - 1, dayA);
      const dateB = new Date(yearB, monthB - 1, dayB);

      // Compare dates
      return sortConfig.direction === "ascending"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    };

    sortedData.sort((a, b) => {
      const schemeA = a.schemes?.[0] || {};
      const schemeB = b.schemes?.[0] || {};

      switch (sortConfig.key) {
        case "assetName":
          return compareStrings(
            a?.promoter.toUpperCase(),
            b?.promoter.toUpperCase()
          );
        case "inceptionDate":
          return compareDates(schemeA.inceptionDate, schemeB.inceptionDate);
        case "aumInCr":
        case "abs1Month":
        case "ann1Year":
        case "returnSinceInception":
          return compareNumbers(
            parseFloat(schemeA[sortConfig.key]),
            parseFloat(schemeB[sortConfig.key])
          );
        case "promoter":
          return compareStrings(
            a.promoter.toUpperCase(),
            b.promoter.toUpperCase()
          );
        default:
          return 0;
      }
    });

    return sortedData;
  }, [data, sortConfig]);

  const handleSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = getSortedData();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          maxHeight: 550,
          overflow: "auto",
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead
          sx={{
            borderBottom: "2px solid #ccc",
            backgroundColor: colors.lightBg,
            "& th": {
              backgroundColor: colors.lightBg,
              border: "none",
              paddingTop: "2%",
              paddingBottom: "2%",
              borderBottom: "2px solid #ccc",
            },
          }}            
          >
            <TableRow
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              borderBottom: "2px solid #ccc",
              backgroundColor: colors.lightBg,
              "& th": {
                backgroundColor: colors.lightBg,
                border: "none",
                paddingTop: "2%",
                paddingBottom: "2%",
                borderBottom: "2px solid #ccc",
              },
            }}
            >
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Product Name</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("assetName")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("assetName")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                Fund Manager
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Inception</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("inceptionDate")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("inceptionDate")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>AUM (in Cr)</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("aumInCr")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("aumInCr")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Absoloute Returns (in 1 M)</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("abs1Month")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("abs1Month")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Annualised Returns (in 1 Yr)</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("ann1Year")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("ann1Year")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                <div className={`flex items-center w-auto justify-center`}>
                  <>Return Since Inception</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort("returnSinceInception")}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort("returnSinceInception")}
                    />
                  </div>
                </div>
              </TableCell>
              {/* <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: "16px" }} align="center">
                Raised Bar
              </TableCell> */}
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                }}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center">
                <Dots />
              </div>
            </div>
          ) : (
            <TableBody
              sx={{
                border: "none",
                "& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root":
                  {
                    borderBottom: "none",
                  },
              }}
            >
              <TableRow sx={{ height: "5%" }}>
                <TableCell colSpan={8} align="center"></TableCell>
              </TableRow>

              {sortedData && sortedData?.length > 0 ? (
                <Rows data={sortedData} />
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No similar plans available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default AIFTable;
