import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { colors } from "../../constants/colors";
import { BookmarkBorder, Download, InfoOutlined } from "@mui/icons-material";
import { FaBookmark } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PieChart } from "@mui/x-charts/PieChart";
import InvestModal from "../../components/modals/FDRequestModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button as Btn } from "@mui/material";
import { current } from "@reduxjs/toolkit";
import {
  getFDById,
  getFDInterestRate,
  getFDSchemeInfo,
  getFDTransactions,
  getFDWithdraw,
} from "../../redux/FD/FDSlice";
import { useDispatch, useSelector } from "react-redux";
import calculetor from "../../components/Calculetor/Calculetor";
import Calculetor from "../../components/Calculetor/Calculetor";
import getInitials from "../../utils/getInitailasIMGUtils";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import { useUserData } from "../../hooks/useUserData";
import SuccessfullModal from "../../components/modals/SuccessfullModal";
import { getFDInfo } from "../../redux/FDHoldings/fdSlice";
import TabsComponent from "../../components/Mobile_Components/TabsComponent";
import ListingCardComponent from "../../components/Mobile_Components/ListingCardComponent";

const FDDetailCard2 = (data: any) => {
  return (
    <>
      <div className="w-auto lg:max-w-[800px]">
        {" "}
        {/* Adjust the max width as needed */}
        <div
          className="px-5 py-2 flex flex-col gap-y-2 rounded-md border border-solid bg-opacity-80 sm:min-w-[150px] lg:min-w-[250px]"
          style={{
            backgroundColor: colors.white,
            border: `1px solid ${colors.lightGrey}`,
          }}
        >
          <div className="text-[12px] lg:text-sm font-medium">{data.label}</div>
          <div
            className="text-[0.7rem] lg:text-lg font-bold"
            style={{ color: colors.textGrey }}
          >
            {data.value}
          </div>
        </div>
      </div>
    </>
  );
};


type Provider =
  | "HDFC Bank LTD"
  | "ICICI HOUSING FINANCE"
  | "MAHINDRA & MAHINDRA"
  | "LIC HOUSING FINANCE"
  | "SUNDARAM HOME FINANCE"
  | "BAJAJ FINANCE LTD";

interface ProviderData {
  about: string;
  features: { name: string; value: string }[];
}

const providersData: Record<Provider, ProviderData> = {
  "HDFC Bank LTD": {
    about:
      "HDFC Bank Fixed Deposits and the interest on them are a good source of income – in a safe and assured manner. Choose a tenure and amount of your choice to grow your income in a steady fashion. You can open an FD for as less as ₹ 5,000, and use it as a back-up for your savings or current account with Sweep-in and Super Saver Facilities. HDFC Bank Fixed Deposits easy and convenient to open or liquidate whenever necessary.",
    features: [
      {
        name: "Higher Interest Rate",
        value:
          "Easy investment with attractive interest rates, flexibility, high returns and security.",
      },
      {
        name: "Benefits for Senior Citizens",
        value: "Higher rate of interest on Fixed Deposit for Senior Citizen.",
      },
      {
        name: "Flexible Tenure",
        value: "Create FD for as short as 7 days or as long as 10 years.",
      },
      {
        name: "Flexible Payout Options",
        value:
          "Choose from flexible interest payout options, i.e., quarterly, half-yearly, yearly or at maturity",
      },
      {
        name: "Guaranteed Returns",
        value:
          "Get steady and assured returns irrespective of market fluctuations.",
      },
    ],
  },
  "ICICI HOUSING FINANCE": {
    about:
      "ICICI Bank Fixed Deposit is a safe investment option which offers assured returns on investment. Returns on Fixed Deposits do not change with any of the external factors.",
    features: [
      {
        name: "Higher Interest Rate",
        value: " Grow your savings with interest rates as high as 7.7%* p.a.",
      },
      {
        name: "Benefits for Senior Citizens",
        value:
          "Senior citizen customers get 0.50% additional interest rate over and above regular Fixed Deposit interest rate.",
      },
      {
        name: "Flexible Tenure",
        value:
          "ICICI Bank Fixed Deposit offers customers wide range of tenure from 7 days to 10 years. This will not only offer great flexibility, but customers can also plan their investment horizon basis their goals.",
      },
      {
        name: "Flexible Payout Options",
        value:
          "ICICI Bank also offers overdraft against Fixed Deposit. This is an excellent option to avail of instant liquidity against Fixed Deposit without breaking it.",
      },
      {
        name: "Guaranteed Returns",
        value:
          "Get steady and assured returns irrespective of market fluctuations.",
      },
    ],
  },
  "MAHINDRA & MAHINDRA": {
    about:
      "Mahindra Finance is one of the leading NBFCs in India that offers a variety of FD schemes to all. You can open an FD account with us and enjoy a range of FD benefits, including: High interest rate, Flexible Tenure and Choose your own interest payout term.",
    features: [
      {
        name: "Higher Interest Rate",
        value: "Grow your savings with interest rates as high as 8.05%* p.a",
      },
      {
        name: "Benefits for Senior Citizens",
        value: "Senior citizens get an additional 0.25% p.a.",
      },
      { name: "Flexible Tenure", value: "" },
      {
        name: "Flexible Payout Options",
        value:
          "Choose from flexible interest payout options, i.e., quarterly, half-yearly, yearly or at maturity",
      },
      {
        name: "Guaranteed Returns",
        value:
          "Get steady and assured returns irrespective of market fluctuations.",
      },
    ],
  },
  "LIC HOUSING FINANCE": {
    about:
      "LIC Housing Finance Limited (LIC HFL), one of the biggest housing finance companies in India, offers a number of Fixed Deposit schemes to suit the needs of every type of investor. LIC Housing Finance FD is offered under the Sanchay scheme. LIC offers competitive interest rates and a good rate of return on the overall investment, among other benefits. This is a public deposit scheme that was launched in 2007 that is rated FAAA/Stable by CRISIL.",
    features: [
      {
        name: "Higher Interest Rate",
        value: "Grow your savings with interest rates as high as 7.8%* p.a.",
      },
      {
        name: "Benefits for Senior Citizens",
        value:
          "Additional benefit of 0.25% p.a. in interest rate to senior citizens (for deposits of ₹ 20,000/- & above but upto ₹ 2 Crores) on all tenures",
      },
      {
        name: "Flexible Tenure",
        value:
          "Choose from the flexible investment options starting from 12 to 60 months.",
      },
      {
        name: "Flexible Payout Options",
        value:
          "The minimum amount of deposit for monthly & yearly option is Rs.2 lakh & Rs.20,000 respectively. Additional deposits can be made in the multiples of Rs.10,000 for monthly deposits and Rs.1,000 for yearly deposits.",
      },
      {
        name: "Guaranteed Returns",
        value:
          "Get steady and assured returns irrespective of market fluctuations.",
      },
    ],
  },
  "SUNDARAM HOME FINANCE": {
    about:
      "Sundaram housing finance has been accredited with a credit rating of AAA/Stable by ICRA and AAA/Stable by CRISIL.",
    features: [
      {
        name: "Higher Interest Rate",
        value: "Grow your savings with interest rates as high as 7.8%* p.a.",
      },
      {
        name: "Benefits for Senior Citizens",
        value:
          "0.50% p.a. additional interest for Senior Citizen for 1st, 2nd and 3rd year and .35% p.a. for 4th and 5th year",
      },
      {
        name: "Flexible Tenure",
        value:
          "The flexible tenure of the scheme ranges from 12 months to 36 months.",
      },
      {
        name: "Flexible Payout Options",
        value:
          "Interest payment frequency: Monthly, Quarterly, Half-yearly, Annual & Cumulative Quick Loan against Deposit facility",
      },
      {
        name: "Guaranteed Returns",
        value:
          "Get steady and assured returns irrespective of market fluctuations.",
      },
    ],
  },
  "BAJAJ FINANCE LTD": {
    about:
      "Bajaj Finance FD offers secured returns, flexible tenures. Earn high returns of up to 8.35%* p.a. on our FD with safety certified by ICRA and CRISIL.",
    features: [
      {
        name: "Higher Interest Rate",
        value: "Grow your savings with interest rates as high as 8.35%* p.a.",
      },
      {
        name: "Benefits for Senior Citizens",
        value: "Senior citizens get an additional 0.25% p.a.",
      },
      {
        name: "Flexible Tenure",
        value:
          "The flexible tenure of the scheme ranges from 12 months to 60 months.",
      },
      {
        name: "Flexible Payout Options",
        value:
          "Choose from flexible interest payout options, i.e., quarterly, half-yearly, yearly or at maturity",
      },
      {
        name: "Guaranteed Returns",
        value:
          "Get steady and assured returns irrespective of market fluctuations.",
      },
    ],
  },
};

type FilterOption = {
  name: string;
  value: string | number;
  isChecked: boolean;
};

type FilterCategory = {
  name: string;
  options: FilterOption[];
};

type InputData = {
  pageIndex: number;
  pageSize: number;
  minInvest: number;
  minTenure: string;
  minInterest: number;
  maxInterest: number;
  search: string;
};

type ResData = {
  schemeId: string | undefined;
};

type AnchorElState = {
  anchorEl: HTMLElement | null;
  planId: number | null;
};

const initialFilterOptions: FilterCategory[] = [
  {
    name: "Tenure",
    options: [
      { name: "1Y", value: 1 * 365, isChecked: false },
      { name: "3Y", value: 3 * 365, isChecked: false },
      { name: "5Y", value: 5 * 365, isChecked: false },
      { name: "7Y", value: 7 * 365, isChecked: false },
      { name: "8Y", value: 8 * 365, isChecked: false },
      { name: "11Y", value: 11 * 365, isChecked: false },
    ],
  },
  {
    name: "Special Rate",
    options: [
      { name: "Senior citizen", value: "senior citizen", isChecked: false },
      { name: "Woman", value: "woman", isChecked: false },
    ],
  },
  {
    name: "Interest Rate",
    options: [
      { name: "0-5%", value: "0-5", isChecked: false },
      { name: "5-15%", value: "5-15", isChecked: false },
      { name: "15-25%", value: "15-25", isChecked: false },
    ],
  },
];

const FDDetails = () => {
  const user = useUserData();
  const dispatch = useDispatch<any>();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [alignment, setAlignment] = useState<string | null>("1Y");
  const [fdData, setFDData] = useState<any>(state?.data);
  const [resData, setResData] = useState<ResData>({ schemeId: id });
  const [dataById, setDataById] = useState<any>();
  const [tenureALL, setTenureALL] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<AnchorElState>({
    anchorEl: null,
    planId: null,
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [watchList, setWatchList] = useState<any>();
  const [similarPlans, setSimilarPlans] = useState<any>();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [providerData, setProviderData] = useState<ProviderData | null>(null);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState<boolean>(false);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [transactionData, setTransactionData] = useState<
    Array<{ id: string; amount: number }>
  >([]);

  const checkFD = user?.preferences?.corporateFD;
  console.log(user?.preferences?.corporateFD, "FD Details page");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const AboutData = () => {
    return (
      <>
        <div style={{ margin: "1rem 1rem" }}>
          <Typography className="text-textGrey font-inter text-[1rem]">
            {/* Render about information from providerData */}
            {providerData?.about}
          </Typography>
        </div>
      </>
    );
  };

  const BenefitsData = () => {
    return (
      <div style={{ margin: "1rem 1rem" }}>
        <Typography className="text-textGrey font-inter text-[0.9rem]">
          {/* Render features information from providerData */}
          Trusted by millions of customers,{" "}
          {dataById?.fdName && dataById?.fdName?.includes("Schemes")
            ? dataById?.fdName.split("Schemes")[0]
            : dataById?.fdName}{" "}
          is the safest investment option with attractive interest rates. Rated
          {dataById?.ratings} by India Ratings and Research.
        </Typography>
        <div className="flex flex-wrap items-center justify-between gap-3 my-4">
          {providerData?.features.map((feature, index) => (
            <div key={index} className="flex flex-col md:w-1/4">
              <h5
                className="font-bold text-[1rem]"
                style={{ color: colors.textGrey }}
              >
                {feature.name}
              </h5>
              <p
                className="text-[1rem] font-inter"
                style={{ color: colors.textGrey }}
              >
                {feature.value}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const InvestmentData = () => {
    return(
      <>
      <Calculetor fdData={{ FDInfo: FDInfo, tenure: tenureALL }} />
      </>
    );
  }

  const FetchData = async () => {
    try {
      const response = await dispatch(getFDTransactions({ schemeId: id }));

      // Extract the payload, which is an array of transactions
      const transactions = response?.payload || [];

      // Update transactionData with the array of transactions
      setTransactionData(transactions);
    } catch (error: any) {
      console.error("Error fetching transactions:", error);
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    FetchData();
  }, [id]); // Re-fetch when `id` changes

  // Fetch FD scheme info based on resData
  useEffect(() => {
    if (resData) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await dispatch(getFDSchemeInfo(resData));
          if (response?.payload) {
            const { scheme, otherCompanySchemes } = response.payload;
            setWatchList(scheme.isInWishlist);
            setDataById(scheme);
            setTenureALL(scheme?.tenures);
            setSimilarPlans(otherCompanySchemes || []);
            const providerFromBackend =
              scheme.providerName as keyof typeof providersData;
            if (providersData.hasOwnProperty(providerFromBackend)) {
              setProviderData(providersData[providerFromBackend]);
            } else {
              console.error(
                "Provider not found in static data:",
                providerFromBackend
              );
            }
          }
        } catch (error) {
          console.error("API Error:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [resData, dispatch]);

  // Handle alignment change for the tenures
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment);
  };

  // Open and close invest modal
  const openInvestModal = () => {
    if (user?.onboardStatus !== 3 && user?.onboardStatus !== 4) {
      setOpen(true);
      setErrorMessage(
        "Please wait for 24 hours. You are not allowed to invest now."
      );
      return;
    }
    setIsInvestModalOpen(true);
  };

  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
  };

  // Label to key mapping for FD details
  const labelToKeyMap: Record<string, string | string[]> = {
    "Credit Rating": "creditRating",
    Tenure: ["minTenure", "maxTenure"],
    "Min Investment": "minInvestmentAmount.annual",
    "Max Investment": "maxInvestmentamount",
    "Interest Rate": "maxInterestRate",
    "Women Depositors": "womenInterestRate.yearly",
    "Senior Citizen": "maxSeniorInterestRate",
  };

  // Get value from object using dot notation path
  const getValue = (obj: any, path: string) => {
    return path
      .split(".")
      .reduce(
        (acc: any, key: any) =>
          acc && acc[key] !== undefined ? acc[key] : null,
        obj
      );
  };

  let FDInfo: any;
  if (dataById) {
    FDInfo = {
      schemeID: dataById.id,
      schemeName: dataById.schemeName,
      maxInvestmentAmount: dataById.maxInvestmentamount,
      minInvestmentAmount: dataById.minInvestmentAmount,
      isHDFC: dataById.isHDFC,
    };
  }

  const renderFDDetails = () => {
    return Object.entries(labelToKeyMap).map(([label, key]) => {
      let value: string | number | undefined;
  
      if (Array.isArray(key)) {
        const [minKey, maxKey] = key;
        const minValue = getValue(dataById, minKey);
        const maxValue = getValue(dataById, maxKey);
  
        value =
          minValue === maxValue ? `${maxValue}` : `${minValue} - ${maxValue}`;
        value += FDInfo?.isHDFC ? " Days" : " Months";
      } else {
        value = getValue(dataById, key);
        value = typeof value === "number" ? value.toString() : value;
      }
  
      const displayValue =
        label === "Women Depositors" && !value ? "0" : value || "-";
  
      return <FDDetailCard2 key={label} label={label} value={displayValue} />;
    });
  };
  
  interface FDDetailCardProps {
    label: string;
    value: string | number;
  }
  
  const FDDetailCard: React.FC<FDDetailCardProps> = ({ label, value }) => {
    return (
      <>
      {/* {label==="Credit Rating"?
      (<div className="py-2 flex flex-col w-[300px] md:w-full md:pl-10">
        <div className="text-sm sm:text-lg text-gray-700_01">{label}</div>
        <div className="text-sm sm:text-lg font-medium text-gray-700_01 text-wrap">
          {value}
        </div>
      </div>)
        :(<div className="py-2 flex flex-col min-w-[150px] md:min-w-[200px]">
        <div className="text-sm sm:text-lg text-gray-700_01">{label}</div>
        <div className="text-sm sm:text-lg font-medium text-gray-700_01 text-wrap">
          {value}
        </div>
      </div>)
      } */}
      {label!=="Credit Rating" && (<div className="py-2 flex flex-col min-w-[150px] md:min-w-[200px]">
        <div className="text-sm sm:text-lg text-gray-700_01">{label}</div>
        <div className="text-sm sm:text-lg font-medium text-gray-700_01 text-wrap">
          {value}
        </div>
      </div>)
      }
      </>
    );
  };

  // Render FD details dynamically
  const renderFDDetails2 = () => {
    return Object.entries(labelToKeyMap).map(([label, key]) => {
      let value;
      if (Array.isArray(key)) {
        const [minKey, maxKey] = key;
        const minValue = getValue(dataById, minKey);
        const maxValue = getValue(dataById, maxKey);
        value =
          minValue === maxValue ? `${maxValue}` : `${minValue} - ${maxValue}`;
        value += FDInfo?.isHDFC ? " Days" : " Months";
      } else {
        value = getValue(dataById, key);
        value = typeof value === "number" ? value.toString() : value;
      }
      const displayValue =
        label === "Women Depositors" && !value ? "0" : value || "-";
      return <FDDetailCard key={label} label={label} value={displayValue} />;
    });
  };

  // Handle navigation to FD details
  const navigateDetails = (id: number, type: string) => {
    let data;
    if (type === "plans") {
      data = dataById?.schemePlans?.find((item: any) => item.id === id);
    }
    navigate(`/fd-details/${id}`);
    window.location.reload();
  };

  // Handle adding or removing from watchlist
  const Watchlist = async (id: number) => {
    const data = { itemId: id, itemType: 3 };
    const response =
      watchList === 1
        ? await dispatch(removeToWatchlist(data))
        : await dispatch(addToWatchlist(data));
    if (response.payload) {
      setWatchList(watchList === 1 ? 0 : 1);
      setOpen(true);
      setMessage(
        watchList === 1
          ? "Scheme remove from watchlist"
          : "Scheme added to watchlist"
      );
    } else {
      setOpen(true);
      setErrorMessage("Failed to update watchlist");
    }
  };

  // Toggle visibility of all plans
  const handleToggleClick = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const handleClose = () => {
    setAnchorEl({ ...anchorEl, anchorEl: null });
  };

  const handleTransaction = async () => {
    try {
      const data = await dispatch(getFDInfo({}));
      const id = data.payload[0].id;
      console.log("holdings data", id);
      const response = await dispatch(
        getFDWithdraw({
          holdingId: id,
        })
      );
      if (response?.payload) {
        setOpen(true);
        setMessage("Redeem request is send successfully.");
      } else {
        setOpen(true);
        setErrorMessage("Failed to send Redeem request.");
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Failed to send Redeem request.");
      console.error("Error uploading file:", error);
    } finally {
      FetchData();
      setModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleShowInterestClick = () => {
    setModalOpen(true);
  };


  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // Dynamically adjust height based on screen size
  let chartHeight = 500; // default height for large screens
  if (isLargeScreen) {
    chartHeight = 500; // Keep it 500 for large screens
  } else if (isMediumScreen) {
    chartHeight = 400; // Slightly reduce the height for medium screens
  } else if (isSmallScreen) {
    chartHeight = 250; // Further reduce the height for small screens
  }

  const tabs = [
    {
      label: "About",
      component: <AboutData />,
    },
    { label: "Future & Benefits", component: <BenefitsData /> },
    {
      label: "Make Investment",
      component: <InvestmentData />,
    },
    {
      label: "Plans",
      component: (
        <div className="mx-auto sm:mx-3">
          {Array.isArray(similarPlans) ? (
            similarPlans?.map((fund: any, index: any) => (
              <ListingCardComponent
                key={index}
                schemeName={fund.schemeName || ""}
                data={fund}
                details={[
                  {
                    label: "Senior Citizen",
                    value:
                    typeof fund?.seniorCitizenRate === "number"
                      ? fund.seniorCitizenRate.toFixed(2)+"%"
                      : "-"
                  },
                  {
                    label: "Max Investment",
                    value:
                      fund.maxInvestmentamount === "-" ||
                      fund.maxInvestmentamount === ""
                        ? "-"
                        : fund.maxInvestmentamount,
                  },
                  {
                    label: "Interest Rate",
                    value:
                      fund.maxInterestRate === "-" ||
                      fund.maxInterestRate === null
                        ? "-"
                        : fund.maxInterestRate + "%",
                  },
                  // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
                ]}
                // onEdit={() => handleEdit(fund.schemeName)}
                // onDelete={() => handleDelete(fund.schemeName)}
                assetType="FD"
                subInfo={fund?.minInvestmentAmount?.monthly || "-"}
                subInfoTitle="Minimum Investment Amount"
                arrayData={similarPlans}
              />
            ))
          ) : (
            <ListingCardComponent details={[]} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-lightBg sm:py-0 py-5">
        <div className="flex flex-row md:flex-row gap-x-2 px-4 lg:gap-x-6 md:p-[60px] max-w-[1440px] w-full mx-auto">
          <div className="w-12 h-12 sm:w-14 sm:h-14 mb-3 md:mb-0 md:w-32 md:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl sm:text-4xl font-bold">
            {!dataById ? "Loading" : getInitials(dataById?.schemeName)}
          </div>
          <div className="flex flex-col gap-y-3 flex-1 max-w-[1200px]">
            <h3 className="text-xl font-bold sm:text-2xl sm:font-medium text-primary">
              {dataById?.schemeName}
            </h3>
            <div className="hidden sm:flex flex-wrap items-center gap-4 my-3">
              <Button
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 1,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.primary,
                  opacity: checkFD === 1 ? 1 : 0.5,
                  cursor: checkFD === 1 ? "pointer" : "not-allowed",
                  fontWeight: "600",
                  border: `1px solid ${colors.primary}`,
                  // fontSize: '14px',
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.white,
                  },
                }}
                onClick={checkFD === 1 ? openInvestModal : () => {}}
              >
                Invest Now
              </Button>

              {dataById && (
                <InvestModal
                  open={isInvestModalOpen}
                  onClose={closeInvestModal}
                  data={{ FDInfo: FDInfo, tenure: tenureALL, data: dataById }}
                />
              )}
              <Button
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 1,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.primary,
                  opacity: transactionData.length > 0 ? 1 : 0.5, // Enable if there are transactions
                  cursor:
                    transactionData.length > 0 ? "pointer" : "not-allowed",
                  fontWeight: "600",
                  border: `1px solid ${colors.primary}`,
                  "&:hover": {
                    backgroundColor:
                      transactionData.length > 0
                        ? colors.primary
                        : colors.white,
                    color:
                      transactionData.length > 0
                        ? colors.white
                        : colors.primary,
                  },
                }}
                onClick={
                  transactionData.length > 0
                    ? handleShowInterestClick
                    : undefined
                }
              >
                Redeem
              </Button>
              <Button
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.primary,
                  border: `1px solid ${colors.primary}`,
                }}
                variant="outlined"
                onClick={() => Watchlist(dataById?.id)}
              >
                {!watchList ? (
                  <>
                    <BookmarkBorder />
                    Add to wishlist
                  </>
                ) : (
                  <>
                    <FaBookmark
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    Remove from wishlist
                  </>
                )}
              </Button>
            </div>

            <div className="flex justify-end items-center gap-2 my-4 sm:hidden">
              <Button
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: 1,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.white,
                  opacity: checkFD === 1 ? 1 : 0.5,
                  cursor: checkFD === 1 ? "pointer" : "not-allowed",
                  fontWeight: "600",
                  //  border: `1px solid ${colors.primary}`,
                  fontSize: "12px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.white,
                  },
                }}
                onClick={checkFD === 1 ? openInvestModal : () => {}}
              >
                Invest Now
              </Button>

              {dataById && (
                <InvestModal
                  open={isInvestModalOpen}
                  onClose={closeInvestModal}
                  data={{ FDInfo: FDInfo, tenure: tenureALL, data: dataById }}
                />
              )}
              <Button
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: 1,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.white,
                  opacity: transactionData.length > 0 ? 1 : 0.5, // Enable if there are transactions
                  cursor:
                    transactionData.length > 0 ? "pointer" : "not-allowed",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
                onClick={
                  transactionData.length > 0
                    ? handleShowInterestClick
                    : undefined
                }
              >
                Redeem
              </Button>
              <Button
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  padding: "0.5rem 0.5rem",
                  color: colors.primary,
                  backgroundColor: "transparent",
                }}
                //  variant="outlined"
                onClick={() => Watchlist(dataById?.id)}
              >
                {!watchList ? (
                  <>
                    <BookmarkBorder />
                  </>
                ) : (
                  <>
                    <FaBookmark
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                  </>
                )}
              </Button>
            </div>

            <div className="lg:flex flex-wrap gap-5 mb-5 justify-center md:justify-start hidden">
              {renderFDDetails()}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 md:gap-x-5 mb-2 md:mb-5 justify-center lg:justify-start lg:hidden pl-10 lg:pl-0">
          {renderFDDetails2()}
        </div>
      </div>
      {(isSmallScreen || isMediumScreen) && (
        <div className="w-full flex items-center justify-between mt-2 mb-4 pb-1">
          <TabsComponent
            tabs={tabs}
            onLogout={() => {
              ("");
            }}
          />
        </div>
      )}
      {!isSmallScreen && !isMediumScreen && (
        <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
          <div className="my-5">
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  backgroundColor: colors.lightBg,
                  border: `1px solid ${colors.primary}`,
                }}
              >
                <h6
                  className="font-semibold text-lg"
                  style={{ color: colors.primary }}
                >
                  About{" "}
                  {dataById?.schemeName &&
                  dataById?.schemeName?.includes("Schemes")
                    ? dataById?.schemeName.split("Schemes")[0]
                    : dataById?.schemeName}
                </h6>
              </AccordionSummary>
              <AccordionDetails sx={{ margin: "1rem 0" }}>
                <Typography className="text-textGrey font-inter">
                  {/* Render about information from providerData */}
                  {providerData?.about}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="my-5">
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  backgroundColor: colors.lightBg,
                  border: `1px solid ${colors.primary}`,
                }}
              >
                <h6
                  className="font-semibold text-lg"
                  style={{ color: colors.primary }}
                >
                  Feature & Benefits
                </h6>
              </AccordionSummary>
              <AccordionDetails sx={{ margin: "1rem 0" }}>
                <Typography className="text-textGrey font-inter">
                  {/* Render features information from providerData */}
                  Trusted by millions of customers,{" "}
                  {dataById?.fdName && dataById?.fdName?.includes("Schemes")
                    ? dataById?.fdName.split("Schemes")[0]
                    : dataById?.fdName}{" "}
                  is the safest investment option with attractive interest
                  rates. Rated
                  {dataById?.ratings} by India Ratings and Research.
                </Typography>
                <div className="flex flex-wrap items-center justify-between gap-3 my-4">
                  {providerData?.features.map((feature, index) => (
                    <div key={index} className="flex flex-col md:w-1/4">
                      <h5
                        className="font-semibold text-lg"
                        style={{ color: colors.textGrey }}
                      >
                        {feature.name}
                      </h5>
                      <p
                        className="text-lg font-inter"
                        style={{ color: colors.textGrey }}
                      >
                        {feature.value}
                      </p>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <Calculetor fdData={{ FDInfo: FDInfo, tenure: tenureALL }} />
          <div className="my-5">
            <div className="flex justify-between items-center">
              <h6
                className="font-bold text-2xl mb-5 mt-10"
                style={{ color: colors.primary }}
              >
                Plans of Other Companies
              </h6>
              {similarPlans?.length > 5 && (
                <Button
                  className=""
                  style={{ color: colors.primary }}
                  onClick={handleToggleClick}
                >
                  <div className="font-bold cursor-pointer no-underline">
                    {showAll ? "Collapse" : "View All"}
                  </div>
                </Button>
              )}
            </div>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: colors.lightGrey,
                    boxShadow: "none",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ color: colors.textGrey, fontWeight: 600 }}>
                      Plan
                    </TableCell>
                    <TableCell sx={{ color: colors.textGrey, fontWeight: 600 }}>
                      Min. Investment
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: colors.textGrey, fontWeight: 600 }}
                    >
                      Max. Investment
                    </TableCell>
                    {/* <TableCell align="center" sx={{ color: colors.textGrey, fontWeight: 600 }}>
                    Frequencies
                  </TableCell> */}
                    <TableCell
                      align="center"
                      sx={{ color: colors.textGrey, fontWeight: 600 }}
                    >
                      ROI
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: colors.textGrey, fontWeight: 600 }}
                    >
                      Senior Citizen ROI
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* otherCompanySchemes */}
                  {similarPlans && similarPlans.length > 0 ? (
                    (showAll ? similarPlans : similarPlans.slice(0, 5)).map(
                      (plan: any) => (
                        <TableRow
                          key={plan.id}
                          sx={{
                            backgroundColor: colors.white,
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ color: colors.textGrey }}
                            onClick={() =>
                              navigateDetails(plan.id, "similarPlans")
                            }
                            className="cursor-pointer"
                          >
                            {plan.schemeName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: colors.textGrey }}
                          >
                            {plan.minInvestmentAmount.monthly || "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: colors.textGrey }}
                          >
                            {plan.maxInvestmentamount}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: colors.textGrey }}
                          >
                            {plan.maxInterestRate
                              ? `${plan.maxInterestRate}%`
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ color: colors.textGrey }}
                          >
                            {typeof plan.seniorCitizenRate === "number"
                              ? plan.seniorCitizenRate.toFixed(2)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No similar plans available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Menu
              id="fade-menu"
              anchorEl={anchorEl.anchorEl}
              open={Boolean(anchorEl.anchorEl)}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {similarPlans
                ?.filter((p: any) => p.id === anchorEl.planId)
                .map((p: any) => (
                  <MenuItem key={p.id}>
                    <div className="flex flex-col gap-x-2">
                      <div>
                        {Object.entries(p.interestRates).map(([key, value]) => (
                          <div
                            key={key}
                            className="flex flex-row justify-between"
                          >
                            <span className="font-medium text-sm text-gray-700_01">
                              {key}:
                            </span>
                            <span className="font-medium text-sm text-gray-700_01">
                              {/* {value}% */}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </MenuItem>
                ))}
            </Menu>
            <Dialog
              open={isModalOpen}
              onClose={() => setModalOpen(false)} // Close modal on cancel or outside click
            >
              <DialogContent>
                <Typography variant="h6" className="font-bold my-3 text-center">
                  Are you sure you want to submit redeem request?
                </Typography>
              </DialogContent>
              <DialogActions className="w-[97%] mb-3">
                <Btn
                  onClick={handleTransaction}
                  sx={{
                    backgroundColor: colors.primary,
                    borderRadius: "40px",
                    color: colors.lightBg,
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: colors.primary,
                      color: colors.lightBg,
                    },
                  }}
                >
                  Confirm
                </Btn>
                <Btn
                  onClick={() => setModalOpen(false)}
                  sx={{
                    backgroundColor: colors.primary,
                    borderRadius: "40px",
                    color: colors.lightBg,
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: colors.primary,
                      color: colors.lightBg,
                    },
                  }}
                >
                  Cancel
                </Btn>
              </DialogActions>
            </Dialog>
            <SuccessfullModal
              open={Open}
              message={message !== "" ? message : errormessage}
              handleClose={handleCloseModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FDDetails;
