import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  Container,
} from "@mui/material";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Edit,
  Delete,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from "../../constants/colors";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";
import { useUserData } from "../../hooks/useUserData";
import HoldingsModalForm from "../modals/HoldingsModalForm";
import ConfirmationModal2 from "../modals/ConfirmationModal2";
import SuccessfullModal from "../modals/SuccessfullModal";
import { useDispatch } from "react-redux";
import { deleteHoldingsData, editHoldingsData } from "../../redux/asset_Holdings/assetSlice";

const MFTableRowComponent = ({
  data,
  getInitials,
  modalMenuClick,
  fetchDataMF,
}: any) => {
  const dispatch = useDispatch<any>();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const user = useUserData();
  const [fields, setFields] = useState<any>([]);
  const [values, setValues] = useState<any>({});
  const [Id, setID] = useState<any>("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Message, setMessage] = useState<any>("");
  const [Open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleExpandClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const isArray = Array.isArray(data);

  const handleModalOpen = () => setModalOpen(true);

  const handleOpen = (id: any, units: any, purchasePrice: any) => {
    setFields([
      { name: "units", label: "Units", type: "number" },
      { name: "purchasePrice", label: "Purchased Price", type: "number" },
    ]);
    setValues({
      units: Number(units),
      purchasePrice: Number(purchasePrice),
    });
    setID(id);
    handleModalOpen();
  };

  const handleConfirmModalOpen = (holdingId: any, schemeName: any) => {
    setConfirmOpen(true);
    setID(holdingId);
    setMessage(`Do you want to delete ${schemeName} from your holdings ?`);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

    const handleConfirmOpen = async () => {
      setConfirmOpen(false);
      try {
        setLoading(true);
        const response = await dispatch(
          deleteHoldingsData({
            id: user?.id,
            holdingId: String(Id),
            holdingType: "MUTUAL_FUND",
          })
        );
        if (response.payload.success === 400) {
          setLoading(false);
          setOpen(true);
          setMessage(response?.payload.message);
        }
  
        if (response.payload.data.success === 200) {
          setLoading(false);
          setOpen(true);
          setMessage(`Mutual Fund deleted successfully.`);
        } else {
          setLoading(false);
          setOpen(true);
          setMessage(response?.payload.message);
        }
      } catch (error) {
        setLoading(false);
        setOpen(true);
        setMessage(
          "Failed due to server issues.Please try again after sometimes!"
        );
      }
    };
    const handleSuccessClose = () => {
      fetchDataMF();
      setOpen(false);
      setMessage("");
    };

    const handleClose = () => {
      setModalOpen(false);
      setFields([]);
      setValues({});
      setID("");
    };
      const handleSubmit = async (
        formData: Record<string, string | number>,
        id: string,
        holdingType: string
      ) => {
        try {
          setLoading(true);
          const response = await dispatch(
            editHoldingsData({
              id: String(id),
              units: formData?.units,
              purchasePrice: formData?.purchasePrice,
              holdingType: "mutual_fund",
            })
          );
          if (response.payload.success === 400) {
            setLoading(false);
            setOpen(true);
            setMessage(response?.payload.message);
            setModalOpen(false);
          }
    
          if (response.payload.data.success === 200) {
            setLoading(false);
            setOpen(true);
            setMessage(`Mutual Fund edided successfully.`);
            setModalOpen(false);
          } else {
            setLoading(false);
            setOpen(true);
            setMessage(response?.payload.message);
            setModalOpen(false);
          }
        } catch (error) {
          setLoading(false);
          setOpen(true);
          setMessage(
            "Failed due to server issues.Please try again after sometimes!"
          );
          setModalOpen(false);
        }
      };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          const Name = item?.schemeName || "N/A";

          return (
            <React.Fragment key={item.id}>
              <TableRow className="border-[2px] border-lightGrey hover:bg-lightBg">
                <TableCell
                  component="th"
                  scope="row"
                  className="min-w-[200px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  <div className="flex gap-x-2 items-center">
                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                      {getInitials(Name)}
                    </div>
                    <div className="w-full ml-1">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleExpandClick(index)}
                      >
                        {toCamelCase(Name)}
                      </span>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {item?.schemeType.toUpperCase() || "-"}
                </TableCell>
                {user && user?.userType!==5 && <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {item?.transactionDate || "-"}
                </TableCell>}
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {Number(item?.units).toFixed(2) || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {"Rs." +
                    formatIndianCurrency(Number(item?.costValue).toFixed(2)) ||
                    "-"}
                </TableCell>
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {"Rs." +
                    formatIndianCurrency(
                      Number(item?.currentValue).toFixed(2)
                    ) || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {"Rs." +
                    formatIndianCurrency(
                      Number(item?.unrealisedPNL).toFixed(2)
                    )}
                </TableCell>
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {item?.unrealisedPNLPercentage + "%" || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {Number(item?.currentNAV).toFixed(2) || "-"}
                </TableCell>
                <TableCell
                  align="center"
                  className="min-w-[100px]"
                  sx={{
                    borderBottom: "2px solid #ccc",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                    },
                  }}
                >
                  {item?.folioNumber || "-"}
                </TableCell>
                {user && user?.userType === 5 && (
                  <TableCell
                    align="center"
                    sx={{
                      height: "100%",
                      verticalAlign: "middle",
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <IconButton>
                        <Edit
                          onClick={() =>
                            handleOpen(
                              item?.id,
                              item?.units,
                              item?.purchaseNAV
                            )
                          }
                        />
                      </IconButton>
                      <IconButton>
                        <Delete
                          onClick={() =>
                            handleConfirmModalOpen(
                              item?.id,
                              toCamelCase(item?.schemeName)
                            )
                          }
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                )}
              </TableRow>
              {/* <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow> */}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <HoldingsModalForm
          fields={fields}
          values={values}
          id={Id}
          holdingType="Mutual_Fund"
          heading="MF Transaction"
          handleOpen={handleModalOpen}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <ConfirmationModal2
        isVisible={ConfirmOpen}
        message={Message}
        onConfirm={handleConfirmOpen}
        onCancel={handleConfirmClose}
      />
      <SuccessfullModal
        open={Open}
        message={Message}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default MFTableRowComponent;
