import Family from "../../assets/icons/Family.png";
import personal from "../../assets/icons/person.png";
import bankP from "../../assets/icons/Vector.png";
import grow from "../../assets/icons/ss.svg";
import doc from "../../assets/icons/Hr Compliance.png";
import shield from "../../assets/icons/Shield.png";
import filter from "../../assets/icons/Filter.png";
import phone from "../../assets/icons/Phone.png";
import Phone from "../../assets/icons/Phone-white.png";
import preference from "../../assets/icons/preference.png";
import access from "../../assets/icons/access control.png";
import compliance from "../../assets/icons/compliance.png";
import demat from "../../assets/icons/demat.png";
import bank from "../../assets/icons/bank.png";
import person from "../../assets/icons/person-white.png";
import login from "../../assets/icons/Vector (2).png";
import updateicon from "../../assets/icons/reset-password.png";
import logoutIcon from "../../assets/icons/logout.svg";
import {
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useRef } from "react";
import Breadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useUserData } from "../../hooks/useUserData";
import getInitials from "../../utils/getInitailasIMGUtils";
import GeneralDetails from "../../components/Profile/GeneralDetails";
import BankDetails from "../../components/Profile/BankDetails";
import NomineeForm from "../../components/Profile/NomineeDetails";
import Preferences from "../../components/Profile/Preferences";
import ReachOut from "../../components/Profile/ReachOut";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "../../redux/kyc/kycSlice";
import authService from "../../redux/auth/authService";
import { colors } from "../../constants/colors";
import UpdatePassword from "../../components/Profile/UpdatePassword";
import Compliance from "../../components/Profile/Compliance";
import BSEOnboarding from "../../components/Profile/BSEOnboarding";
import TabsComponent from "../../components/Mobile_Components/TabsComponent";

const imageMap: any = {
  "General Details": personal,
  "Bank Details": bankP,
  Nominees: Family,
  Compliance: doc,
  "BSE Onboarding": shield,
  Preferences: filter,
  "Reach Out": phone,
  "Update Password": updateicon,
};

const imageMapSelected: any = {
  "General Details": person,
  "Bank Details": bank,
  Nominees: Family,
  Compliance: compliance,
  "BSE Onboarding": access,
  Preferences: preference,
  "Reach Out": Phone,
  "Update Password": updateicon,
};

const breadcrumbItems = [
  { label: "Home", href: "/" },
  { label: "Profile" },
];

function Profile() {
  const [value, setValue] = useState(0);
  const user = useUserData();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const menuRef = useRef<HTMLDivElement>(null);

  const logout = () => {
    authService.logout();
    dispatch(resetState());
    navigate("/");
  };

  const menuItems = [
    "General Details",
    "Bank Details",
    "Nominees",
    "Compliance",
    "BSE Onboarding",
    "Preferences",
    "Reach Out",
    "Update Password",
  ];


    // Define the tabs data
    const tabs = [
      { label: "General Details", component: <GeneralDetails /> },
      { label: "Bank Details", component: <BankDetails /> },
      { label: "Nominees", component: <NomineeForm /> },
      { label: "Compliance", component: <Compliance /> },
      { label: "BSE Onboarding", component: <BSEOnboarding /> },
      { label: "Preferences", component: <Preferences /> },
      { label: "Reach Out", component: <ReachOut /> },
      { label: "Update Password", component: <UpdatePassword /> },
      { label: "Logout", isLogout: true }
    ];

  return (
    <div className="p-3 pt-4 sm:p-5 xl:p-[60px] md:max-w-[1640px] w-full mx-auto">
      <div className="md:pb-10">
        <Breadcrumbs items={breadcrumbItems} />
      </div>

      {/* Horizontal Menu Bar for Small Screens */}
      {isSmallScreen && (
        <div className="w-full flex items-center justify-between mt-2 mb-4 pb-1">          
            <TabsComponent tabs={tabs} onLogout={logout} />
        </div>
      )}

      <div className="flex flex-col sm:flex-row sm:mt-0 mt-2">
        {/* Vertical Sidebar for Larger Screens */}
        {!isSmallScreen && (
          <div className="w-[4.5rem] sm:w-1/4 ">
            <div className="border-2 border-lightGrey rounded-lg mr-1 sm:mr-4 h-full flex flex-col justify-between">
              <div>
                <div className="p-3 md:py-4 md:px-2 lg:p-8 bg-lightBg rounded-lg">
                  <div className="flex flex-row items-center justify-center md:justify-start">
                    <div className="w-[80px] h-[40px] mb-3 md:mb-0 sm:w-[80px] sm:h-[80px] rounded-5xl flex items-center justify-center bg-gray-300 text-primary sm:text-4xl text-2xl font-bold">
                      {getInitials(user.firstName || "")}
                    </div>
                    <div className="md:flex flex-col hidden md:ml-4">
                      <span className="md:text-xl lg:text-2xl text-darkGrey font-bold">
                        {`${user.firstName?.toUpperCase()} ${user.lastName?.toUpperCase()}`}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-between">
                  <div className="flex flex-col w-full items-center justify-around">
                    {menuItems.map((label, index) => (
                      <Button
                        key={index}
                        onClick={() => setValue(index)}
                        sx={{
                          width: "85%",
                          height: "50px",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          padding: "0 16px",
                          margin: "10px 0px",
                          backgroundColor:
                            value === index ? colors.primary : "transparent",
                          color: value === index ? "#fff" : "initial",
                          "&:hover": {
                            backgroundColor:
                              value === index
                                ? colors.primary
                                : "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          <img
                            src={
                              value === index
                                ? imageMapSelected[label]
                                : imageMap[label]
                            }
                            alt={label}
                            style={{ marginRight: "8px", maxWidth: "30px" }}
                          />
                          <span>{label}</span>
                        </Box>
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-[40%] gap-y-4">
                <div
                  className="flex items-center cursor-pointer mt-6 sm:ml-7 md:ml-8 lg:ml-11"
                  onClick={logout}
                >
                  <img
                    src={logoutIcon}
                    alt="Logout"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <span className="text-secondary text-xl ml-2">Logout</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        {!isSmallScreen && <div className="hidden lg:block w-full xl:w-3/4 sm:ml-4 max-h-screen overflow-y-auto border border-lightGrey rounded-lg">
          {value === 0 && <GeneralDetails />}
          {value === 1 && <BankDetails />}
          {value === 2 && <NomineeForm />}
          {value === 3 && <Compliance />}
          {value === 4 && <BSEOnboarding />}
          {value === 5 && <Preferences />}
          {value === 6 && <ReachOut />}
          {value === 7 && <UpdatePassword />}
        </div>}
      </div>
    </div>
  );
}

export default Profile;

