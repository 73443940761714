import React, { useState } from "react";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import InvestmentPage from "./InvestmentPage";
import { RxCross2 } from "react-icons/rx";
import { useMediaQuery,useTheme } from "@mui/material";

interface MainProps {
  fundId?: number;
  fundInfo?: any;
  onClose?: () => void;
}

const Main: React.FC<MainProps> = ({ fundId = 0, fundInfo, onClose }) => {
  const userData = useUserData();
  console.log("fundInfo", fundInfo);

    const theme = useTheme();
    // Detect screen size using useMediaQuery
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  
    // Dynamically adjust height based on screen size
    let chartHeight = 500; // default height for large screens
    if (isLargeScreen) {
      chartHeight = 500; // Keep it 500 for large screens
    } else if (isMediumScreen) {
      chartHeight = 400; // Slightly reduce the height for medium screens
    } else if (isSmallScreen) {
      chartHeight = 250; // Further reduce the height for small screens
    }

  return (
    <div className="bg-lightBg py-3 lg:pt-10 lg:pb-10 scrollbar-hide overflow-y-auto overflow-x-hidden">
    <div className="w-full relative">
    <div className="absolute right-0 top-0 text-3xl pl-2 lg:pl-10 font-bolder mr-4 "
            style={{ color: colors.primary }}>
        <RxCross2 fontSize={isLargeScreen ? "2rem" : "1.2rem"} className="cursor-pointer" onClick={onClose} />
        </div>
      <div className="flex justify-start w-auto">
        <div className="relative">
          <h6
            className="text-[1rem] lg:text-3xl pl-2 lg:pl-10 font-medium"
                          style={{ color: colors.primary }}
          >
            Transact Now
          </h6>
          <h6
            className="text-[0.8rem] lg:text-lg pl-2 lg:pl-10 mt-2 lg:mt-5 font-medium"
                          style={{ color: colors.textGrey }}
          >
            Client Code: {userData?.kyc?.pancardNumber}
          </h6>
        </div>
      </div>
      <div>          
          <InvestmentPage
            fundId={fundId}
            fundInfo={fundInfo}
            onClose={onClose}
          />
      </div>
    </div>
  </div>
  );
};

export default Main;
