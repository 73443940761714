import React, { useEffect, useState } from "react";
import Header from "../../components/LandingPageComponents/HeaderLanding";
import Hero from "../../components/LandingPageComponents/Hero";
import UserSelection from "../../components/LandingPageComponents/UserSelection";
import Products from "../../components/LandingPageComponents/Products";
import AboutUs from "../../components/LandingPageComponents/AboutUs";
import Stats from "../../components/LandingPageComponents/Stats";
import Blog from "../../components/LandingPageComponents/Blog";
import HowWeWork from "../../components/LandingPageComponents/HowWeWork";
import Footer from "../../components/Footer/Footer";
import Testimonials from "../../components/LandingPageComponents/Testimonials";
import PartnerForm from "../../components/LandingPageComponents/PartnerForm";
import MissionSection from "../../components/LandingPageComponents/MissionSection";
import UserGroupsSection from "../../components/LandingPageComponents/UserGroupSection";
import ProductOfferingsSection from "../../components/LandingPageComponents/ProductOfferings";
import FeaturesSection from "../../components/LandingPageComponents/FeaturesSection";

function LandingPage() {
  const [isPartnerFormOpen, setIsPartnerFormOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <Header
        onPartnerClick={() => setIsPartnerFormOpen(true)}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <main>
        <Hero />
        <UserSelection onPartnerClick={() => setIsPartnerFormOpen(true)} />
        <MissionSection />
        <UserGroupsSection />
        <ProductOfferingsSection />
        <FeaturesSection />
        {/* <Products /> */}
        <AboutUs />
        {/* <Stats /> */}
        {/* <Testimonials /> */}
        <Blog />
        {/* <HowWeWork /> */}
      </main>
      <Footer />

      {isPartnerFormOpen && (
        <PartnerForm onClose={() => setIsPartnerFormOpen(false)} />
      )}
    </div>
  );
}

export default LandingPage;
