import React, { useEffect, useState } from "react";
import { Divider, useMediaQuery } from "@mui/material";

import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useDispatch } from "react-redux";
import OrderHistoryTable from "../../components/OrderHistoryTable/OrderHistoryTable";
import { getAllOrderHistory } from "../../redux/OrderHistory/OrderSlice";
import { LoaderIcon } from "react-hot-toast";
import TabsComponent from "../../components/Mobile_Components/TabsComponent";
import { set } from "date-fns";
import OrdersCardComponent from "../../components/Mobile_Components/OrdersCardComponent";

const MFheadings = [
  "createdAt",
  "orderType",
  "schemeName",
  "amount",
  "paymentStatus",
  "orderStatus",
];

const FDheadings = ["createdAt", "schemeName", "amount", "orderStatus"];

const AIFheadings = ["createdAt", "holdingName", "amount", "orderStatus"];

const PMSheadings = ["createdAt", "holdingName", "amount", "orderStatus"];

const OrderHistory = () => {
  const dispatch = useDispatch<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [MFrows, setMFrows] = useState([]);
  const [FDrows, setFDrows] = useState([]);
  const [AIFrows, setAIFrows] = useState([]);
  const [PMSrows, setPMSrows] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectType = (orderType: number) => {
    switch (orderType) {
      case 1:
        return "Buy";
      case 2:
        return "Buy";
      case 5:
        return "Sell";
      default:
        return "-";
    } 
  }

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Orders" }, // Breadcrumb item with link
  ];
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getAllOrderHistory({}));
      if (response) {
        let res = response?.payload?.data?.data || [];
        setMFrows(res?.mutualFund);
        setFDrows(res?.fd);
        setAIFrows(res?.aif);
        setPMSrows(res?.pms);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  const tabs = [
    {
      label: "Mutual Fund",
      component: MFrows && MFrows?.length > 0 ? (
        MFrows?.map((fund: any, index: any) => (
          <OrdersCardComponent 
            key={index}
            schemeName={fund?.schemeName || ""}
            details={[
              { label: "Invested Amount", value: fund.amount },
              { label: "Payment Status", value: fund.paymentStatus },
              { label: "Order Status", value: fund.orderStatus },
              // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
            ]}
            // onEdit={() => handleEdit(fund.schemeName)}
            // onDelete={() => handleDelete(fund.schemeName)}
            assetType="Mutual Fund"
            loading={loading}
            subInfo2={selectType(fund?.orderType)}
            subInfoTitle2="Type"
            subInfo={fund.createdAt}
            subInfoTitle="Transaction Date"
          />
        ))
      ) : (
        <OrdersCardComponent  details={[]} EmptyMessage="No orders yet."/>
      ),
    },
    {
      label: "Fixed Deposit",
      component: FDrows && FDrows?.length > 0 ? (
        FDrows?.map((fund: any, index: any) => (
          <OrdersCardComponent 
            key={index}
            schemeName={fund.schemeName || ""}
            details={[
              { label: "Transaction Date", value: fund.createdAt },
              { label: "Invested Amount", value: fund.amount },
              { label: "Order Status", value: fund.orderStatus },
              // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
            ]}
            // onEdit={() => handleEdit(fund.schemeName)}
            // onDelete={() => handleDelete(fund.schemeName)}
            assetType="Fixed Deposit"
            // subInfo={fund.interesetRate}
            loading={loading}
          />
        ))
      ) : (
        <OrdersCardComponent  details={[]} EmptyMessage="No orders yet." />
      ),
    },

    {
      label: "PMS",
      component: PMSrows && PMSrows?.length > 0 ? (
        PMSrows?.map((fund: any, index: any) => (
          <OrdersCardComponent 
            key={index}
            schemeName={fund.holdingName || ""}
            details={[
              { label: "Transaction Date", value: fund.createdAt },
              { label: "Invested Amount", value: fund.amount },
              { label: "Order Status", value: fund.orderStatus },
              // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
            ]}
            // onEdit={() => handleEdit(fund.schemeName)}
            // onDelete={() => handleDelete(fund.schemeName)}
            assetType="PMS"
            arrayData={fund}
            loading={loading}
          />
        ))
      ) : (
        <OrdersCardComponent  details={[]} EmptyMessage="No orders yet." />
      ),
    },
    {
      label: "AIF",
      component: AIFrows && AIFrows?.length > 0 ? (
        AIFrows?.map((fund: any, index: any) => (
          <OrdersCardComponent 
            key={index}
            schemeName={fund.holdingName || ""}
            details={[
              { label: "Transaction Date", value: fund.createdAt },
              { label: "Invested Amount", value: fund.amount },
              { label: "Order Status", value: fund.orderStatus },
              // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
            ]}
            // onEdit={() => handleEdit(fund.schemeName)}
            // onDelete={() => handleDelete(fund.schemeName)}
            assetType="AIF"
            loading={loading}
            arrayData={fund}
          />
        ))
      ) : (
        <OrdersCardComponent  details={[]} EmptyMessage="No orders yet."/>
      ),
    },
  ];

  return (
    <div className="scrollbar-hide">
      <div className="p-3 md:px-8 md:pt-8 max-w-[1440px] w-full mx-auto flex flex-col gap-y-2 bg-lightBg lg:bg-white">
        <DynamicBreadcrumbs items={breadcrumbItems} />
        <Divider sx={{ my: isSmallScreen ? 0 : 2 }} />{" "}
        {/* Adds a division between the breadcrumbs and the content below */}
        <div className="flex flex-row gap-x-2 items-center w-full">
          <div className="flex items-center">
            <h1 className="text-xl md:text-3xl font-bold lg:font-medium text-primary">
              Orders History
            </h1>
          </div>
        </div>
      </div>
      {isSmallScreen ? (
        <div className="w-full flex items-center justify-between mt-2 mb-4 pb-1 scrollbar-hide">
          <TabsComponent
            tabs={tabs}
            onLogout={() => {
              ("");
            }}
          />
        </div>
      ) : (
        <>
          {loading ? (
            <div className="flex justify-center items-center h-[20vh] w-full">
              <LoaderIcon style={{ width: "60px", height: "60px" }} />
            </div>
          ) : (
            <>
              {!(MFrows && MFrows?.length > 0) &&
              !(FDrows && FDrows?.length > 0) &&
              !(AIFrows && AIFrows?.length > 0) &&
              !(PMSrows && PMSrows?.length > 0) ? (
                <>
                  <div className="flex flex-row items-center justify-center w-full h-[30vh] mb-[40px]">
                    <div>
                      <h1 className="text-2xl text-center font-medium text-darkGrey">
                        No orders yet.
                      </h1>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {MFrows && MFrows?.length > 0 && (
                    <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
                      <div className="">
                        <div className="flex flex-col">
                          <div className="flex gap-x-8 items-center">
                            <div className="flex flex-row w-full justify-start pb-4">
                              <div>
                                <h1 className="text-2xl font-semibold text-primary">
                                  Mutual Funds
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <OrderHistoryTable
                            headings={MFheadings}
                            rows={MFrows}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {FDrows && FDrows?.length > 0 && (
                    <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
                      <div className="">
                        <div className="flex flex-col">
                          <div className="flex gap-x-8 items-center">
                            <div className="flex flex-row w-full justify-start pb-4">
                              <div>
                                <h1 className="text-2xl font-semibold text-primary">
                                  Fixed Deposits
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <OrderHistoryTable
                            headings={FDheadings}
                            rows={FDrows}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {AIFrows && AIFrows?.length > 0 && (
                    <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
                      <div className="">
                        <div className="flex flex-col">
                          <div className="flex gap-x-8 items-center">
                            <div className="flex flex-row w-full justify-start pb-4">
                              <div>
                                <h1 className="text-2xl font-semibold text-primary">
                                  AIF
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <OrderHistoryTable
                            headings={AIFheadings}
                            rows={AIFrows}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {PMSrows && PMSrows?.length > 0 && (
                    <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
                      <div className="">
                        <div className="flex flex-col">
                          <div className="flex gap-x-8 items-center">
                            <div className="flex flex-row w-full justify-start pb-4">
                              <div>
                                <h1 className="text-2xl font-semibold text-primary">
                                  PMS
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <OrderHistoryTable
                            headings={PMSheadings}
                            rows={PMSrows}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrderHistory;
