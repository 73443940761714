import React, { useEffect, useState } from "react";
import { Divider, useMediaQuery } from "@mui/material";

import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useDispatch } from "react-redux";
import { getAllOrderHistory } from "../../redux/OrderHistory/OrderSlice";
import AutoFetchedEmailTable from "../../components/AutoFetchedEmailTable/AutoFetchedEmailTable";
import { getAllFetchedEmail } from "../../redux/AutoFetchEmail/FetchedEmailSlice";
import { LoaderIcon } from "react-hot-toast";
import FetchedEmailCardComponent from "../../components/Mobile_Components/FetchedEmailCardComponent";

const EmailHeadings = ["id","fileName", "emailSubject", "createdAt", "ocrStatus","requiredPassword"];

const FetchResponseTable = () => {
  const dispatch = useDispatch<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [Emailrows, setEmailrows] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Auto Fetched Emails" }, // Breadcrumb item with link
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getAllFetchedEmail());
  
      if (response?.payload?.data?.data) {
        let res = response?.payload?.data?.data || [];
        setEmailrows(res);
        setLoading(false);
      }else{
        setLoading(false);
      }
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const formatDate = (isoDateString: string): string => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  return (
    <div className="scrollbar-hide">
      <div className="p-3 md:px-8 md:pt-8 max-w-[1440px] w-full mx-auto flex flex-col gap-y-2 bg-lightBg lg:bg-white">
        <DynamicBreadcrumbs items={breadcrumbItems} />
        <Divider sx={{ my: isSmallScreen ? 0 : 2 }} />{" "}
        {/* Adds a division between the breadcrumbs and the content below */}
        <div className="flex flex-row gap-x-2 items-center w-full">
          <div className="flex items-center">
            <h1 className="text-xl md:text-3xl font-bold lg:font-medium text-primary">
              Auto Fetched Emails History
            </h1>
          </div>
        </div>
      </div>
      {isSmallScreen ? (
        <div className="w-full flex flex-col items-center justify-between mt-2 mb-4 pb-1 scrollbar-hide">
          {(Emailrows && Emailrows?.length > 0) ?
          (Emailrows?.map((fund: any, index: any) => (
          <FetchedEmailCardComponent 
            key={index}
            schemeName={fund.fileName || ""}
            details={[
              { label: "Date", value: formatDate(fund.createdAt) },
              { label: "Subject", value: fund.emailSubject },
              { label: "Password", value: fund.requiredPassword },
              // { label: "P & L (in %)", value: `${fund.unrealisedPNLPercentage}%` },
            ]}
            // onEdit={() => handleEdit(fund.schemeName)}
            // onDelete={() => handleDelete(fund.schemeName)}
            assetType=""
            arrayData={fund}
            loading={loading}
            subInfoTitle="Status"
            subInfo={fund.ocrStatus}
            fetchData={fetchData}
          />
        ))):
          (<>
          <FetchedEmailCardComponent  details={[]} EmptyMessage="No emails fetched." />
          </>)}
        </div>
      ) : (
        <>
      {loading?(<div className="flex justify-center items-center h-[20vh] w-full">
                <LoaderIcon style={{ width: "60px", height: "60px" }} />
              </div>):
              <>
      {(Emailrows && Emailrows?.length > 0) ? (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
              <AutoFetchedEmailTable headings={EmailHeadings} rows={Emailrows} fetchData={fetchData} />
          </div>
        </div>
      ):(<div className="flex flex-row items-center justify-center w-full h-[30vh] mb-[40px]">
        <div>
          <h1 className="text-2xl text-center font-medium text-darkGrey">
            No emails fetched.
          </h1>
        </div>
      </div>)}
      </>}
      </>)}      
    </div>
  );
};

export default FetchResponseTable;
