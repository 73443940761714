import React, { FC } from "react";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";

const Details: FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="max-w-[800px]">
    <div className="pl-2 lg:pl-5 py-2 flex flex-col lg:gap-y-2 rounded-md bg-opacity-80 min-w-[150px] md:min-w-[200px]">
      <div className="text-[0.8rem] lg:text-lg font-medium">{label}</div>
      <div className="text-[0.9rem] lg:text-xl font-bold" style={{ color: colors.textGrey }}>
        {value}
      </div>
    </div>
  </div>
);

const MFDetailCard = ({ fundInfo }: any) => {
  const mfDetails = [
    {
      label: "Current NAV",
      value: fundInfo?.currentNAV,
    },
    {
      label: "Min. Investment",
      value: fundInfo?.minimumPurchaseAmount,
    },
    {
      label: "Risk Level",
      value: fundInfo?.riskLevel,
    },
    {
      label: "AUM (in Cr)",
      value: fundInfo?.fundSize || fundInfo?.aum,
    },
    {
      label: "Min Investment",
      value: fundInfo?.minAmount,
    },
  ];

  return (
    <div className="flex flex-col lg:flex-row gap-x-6 lg:p-10 bg-lightBg pt-2">
      <div className="flex flex-row lg:flex-row sm:px-4">
        <div className="w-12 h-12 md:w-16 md:h-16 mb-1 sm:mb-3 md:mb-0 lg:w-32 lg:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-[1.5rem] lg:text-4xl font-bold">
          {getInitials(fundInfo?.schemeName)}
        </div>
        <div className="flex flex-col gap-y-3 flex-1 max-w-[1200px]">
          <h3
            className="text-[1rem] lg:text-2xl pl-4 font-bold lg:font-medium"
            style={{ color: colors.primary }}
          >
            {fundInfo?.schemeName || "-"}
          </h3>
          <div className="hidden md:flex md:flex-wrap md:gap-3 lg:gap-5 pt-5 pb-2 justify-center md:justify-start">
            {mfDetails.map(
              (fd: any) =>
                fd.value && <Details label={fd.label} value={fd.value} />
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden grid grid-cols-2 gap-2 py-2 sm:px-4">
          {mfDetails.map(
            (fd: any) =>
              fd.value && <Details label={fd.label} value={fd.value} />
          )}
      </div>
    </div>
  );
};

export default MFDetailCard;
