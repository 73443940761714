import React from "react";
import { motion, useInView } from "framer-motion";
import {
  BarChart3,
  Briefcase,
  Coins,
  FileText,
  LineChart,
  TrendingUp,
} from "lucide-react";
import { SafetyCheck } from "@mui/icons-material";

const ProductCard = ({ icon: Icon, title, description, index }: any) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-50px" });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      whileHover={{ y: -10 }}
      className="relative group w-full"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-primary to-secondary rounded-[2.5rem] blur-xl opacity-20 group-hover:opacity-30 transition-opacity"></div>
      <div className="relative bg-white rounded-[2rem] p-8 shadow-lg hover:shadow-xl transition-all duration-300">
        <div className="w-20 h-20 bg-gradient-to-br from-primary/10 to-secondary/10 rounded-2xl flex items-center justify-center mb-8 group-hover:scale-110 transition-transform duration-300">
          <Icon className=" text-primary group-hover:text-secondary transition-colors duration-300" />
        </div>
        <h3 className="text-2xl font-bold text-primary mb-4 group-hover:text-secondary transition-colors duration-300">
          {title}
        </h3>
        {/* <p className="text-darkGrey/80">{description}</p> */}
      </div>
    </motion.div>
  );
};

const ProductOfferingsSection = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  const products = [
    {
      icon: TrendingUp,
      title: "Mutual Funds",
      description:
        "Comprehensive tracking and analysis of mutual fund investments with real-time updates and detailed performance metrics",
    },
    {
      icon: LineChart,
      title: "Equity",
      description:
        "Advanced tools for monitoring stock portfolios, market analysis, and trading insights across multiple exchanges",
    },
    {
      icon: Briefcase,
      title: "AIF",
      description:
        "Specialized tracking for Alternative Investment Funds with detailed reporting and performance analytics",
    },
    {
      icon: BarChart3,
      title: "PMS",
      description:
        "Comprehensive Portfolio Management Services tracking with customized reporting and analysis tools",
    },
    {
      icon: FileText,
      title: "Bonds",
      description: "Track your fixed-income investments with detailed insights"
    },
    {
      icon: SafetyCheck,
      title: "Fixed Deposit",
      description: "Monitor your FD investments with maturity tracking and returns"
    },
    {
      icon: Coins,
      title: "Unlisted Shares",
      description:
        "Unique platform for tracking and managing investments in unlisted securities with valuation tools",
    },
  ];

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
      className="py-24 bg-gradient-to-b from-white to-lightBg relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1642543492481-44e81e3914a6?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80')] opacity-5 bg-fixed"></div>
      <div className="container mx-auto px-6 relative">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-primary">
            An Array Of Our Investment Services
          </h2>
          <p className="text-xl text-darkGrey">
            Asset Classes that can be tracked on our platform
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {products.map((product, index) => (
            <ProductCard key={index} {...product} index={index} />
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default ProductOfferingsSection;
