import React, { useEffect, useState } from "react";
import { Button, Text, Input, Heading } from "..";
import { NavLink } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { OTP } from "../../models/signup.model";
import { verifyOTP } from "../../redux/auth/authSlice";
import toast from "react-hot-toast";

interface VerificationModalProps {
  onClose: () => void;
  onSuccess: () => void;
  signupValues?: any;
  handleSignup?: any;
  handleOtp?: any;
}


const otpSchema = yup.object({
  mobileOTP: yup.string(),
  emailOTP: yup.string().required(),
});

const otpValues: OTP = {
  mobileOTP: "",
  emailOTP: "",
  otpFor: "1",
};

const VerificationModal: React.FC<VerificationModalProps> = ({
  onClose,
  onSuccess,
  signupValues,
  handleSignup,
  handleOtp
}) => {
  const isSuccess = useSelector((state: any) => state?.auth?.otp);
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

const isSmallScreen = useMediaQuery("(max-width:640px)");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width:isSmallScreen?"95%": "100%",
  maxWidth: 650,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p:isSmallScreen ? 2 : 4,
};

  const dispatch = useDispatch<any>();
  const handleVerifyOTP = async (values: OTP) => {
    const payload = {
      firstName: signupValues?.firstName,
      lastName: "",
      email: signupValues?.email,
      mobile: signupValues?.mobile,
      emailOTP: values?.emailOTP,
      mobileOTP: values?.mobileOTP,
      otpFor: "1",
    };
    const response = await dispatch(verifyOTP(payload));
    if (response?.error?.message) {
      toast.error("Please enter valid OTP");
      return;
    }
    if(response?.payload?.data?.userId){
    localStorage.setItem("userId", response?.payload?.data?.userId);
    }
  };

  const handleResendOTP = () => {
    if (timer > 0) return;
    if (timer === 0) {
      handleSignup();
      toast.success("OTP has been sent to your email address as well as phone number");
      setTimer(30);
      setTimerActive(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0 && timerActive) {
        setTimer(timer - 1);
      } else {
        setTimerActive(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer, timerActive]);

  // console.log(signupValues, "123456789");


  return (
    <Box
      className="flex flex-col items-center justify-center mx-auto lg:w-full w-[350px] lg:max-w-[600px] bg-white rounded-[10px] md:rounded-[18px]"
      sx={style}
    >
      <Heading as={isSmallScreen?"h6":"h1"} className="text-center">
        OTP Verification
      </Heading>
      <Text size={isSmallScreen ? "xs" : "s"} as="p" className="w-[98%]lg:w-[95%] lg:mt-3 !text-gray-800 text-center">
        A one-time verification code has been dispatched to your email address{" "}
        {/* {signupValues?.email
          ?.split("@")[0]
          .substring(0, signupValues?.email?.split("@")[0]?.length - 6)} */}
        <span className="text-deep_orange-500">{signupValues?.email}</span> and
        phone number{" "}
        <span className="text-deep_orange-500">{signupValues?.mobile}</span>.
        Please use both the code to complete the verification process.
      </Text>
      <Formik
        initialValues={otpValues}
        validationSchema={otpSchema}
        onSubmit={(values) => handleOtp ? handleOtp(values) : handleVerifyOTP(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex flex-col items-center justify-start w-[90%] lg:w-[79%] mt-[27px] mb-[5px] gap-4 mx-auto">
              <div className="flex flex-col items-center justify-start w-full">
                <Input
                  name="email"
                  placeholder="Email OTP"
                  className="w-full"
                  type="string"
                  onChange={handleChange("emailOTP")}
                  value={values.emailOTP}
                />
                <Input
                  name="mobile_otp"
                  placeholder="Mobile OTP"
                  className="w-full"
                  type="string"
                  onChange={handleChange("mobileOTP")}
                  value={values.mobileOTP}
                />
                <div className="flex justify-end w-full mt-2">
                  {timer > 0 && (
                    <p className="text-gray-700_01">
                      Resend in 00:{timer < 10 ? `0${timer}` : `${timer}`}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center w-full">
                <Text size={isSmallScreen?"xs":"s"} as="p">
                  <span className="text-gray-700_01">
                    Didn’t receive the code?{" "}
                  </span>
                  <button
                    type="button"
                    onClick={handleResendOTP}
                    className="text-deep_orange-500 underline disabled:text-primary"
                    disabled={timerActive}
                  >
                    Resend
                  </button>
                </Text>
                <div className="flex flex-row justify-center">
                  <Button
                    className="w-full font-semibold bg-primary text-white"
                    type="submit"
                  >
                    <span className="text-sm md:text-lg">Proceed</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default VerificationModal;
