// Main.tsx

import React, { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Img, Text } from "../../components/index";
import Header from "../../components/Header/Header";
import Footer from "../Footer/Footer";

import Lumpsum from "../MFTransaction/Lumpsum";
import Redeem from "../MFTransaction/Redeem";
import Switch from "../MFTransaction/Switch";
import SIP from "../MFTransaction/SIP";
import STP from "../MFTransaction/STP";
import SWP from "../MFTransaction/SWP";
import { colors } from "../../constants/colors";
import { useUserData } from "../../hooks/useUserData";
import MfInfo from "../../components/MFInfoSection/MFInfo";
import { RxCross2 } from "react-icons/rx";
import { useMediaQuery, useTheme } from "@mui/material";
import TabsComponent from "../Mobile_Components/TabsComponent";

interface MainProps {
  fundId?: number;
  fundInfo?: any;
  onClose?: () => void;
}

const Main: React.FC<MainProps> = ({ fundId = 0, fundInfo, onClose }) => {
  const [activeSection, setActiveSection] = useState("lumpsum");
  const userData = useUserData();

  const isDisabled = !fundInfo?.totalUnits && !fundInfo?.currentValue;

  const stpDisabled = !(fundInfo?.stpFlag === "Y") ;

  const handleLumpsum = () => {
    setActiveSection("lumpsum");
  };

  const handleRedeem = () => {
    if (!isDisabled) {
    setActiveSection("redeem");
    }
  };

  const handleSwitch = () => {
    if (!isDisabled) {
      setActiveSection("switch");
    }
  };

  const handleSIP = () => {
    setActiveSection("sip");
  };

  const handleSTP = () => {
    if (!stpDisabled) {
      setActiveSection("stp");
    }
  };

  const handleSWP = () => {
    if (!isDisabled) {
      setActiveSection("swp");
    }
  };

  // console.log("fundInfo", fundInfo);
  const tabs = [
    {
      label: "Lumpsum",
      component: <Lumpsum fundId={fundId} activeSection={activeSection} fundInfo={fundInfo} onClose={onClose} />,
      isDisabled: false, // Always enabled
      onClick: handleLumpsum,
    },
    {
      label: "Redeem",
      component: <Redeem activeSection={activeSection} fundInfo={fundInfo} onClose={onClose} />,
      isDisabled: isDisabled,
      onClick: handleRedeem,
    },
    {
      label: "Switch",
      component: <Switch activeSection={activeSection} fundInfo={fundInfo} onClose={onClose} />,
      isDisabled: isDisabled,
      onClick: handleSwitch,
    },
    {
      label: "SIP",
      component: <SIP fundId={fundId} activeSection={activeSection} fundInfo={fundInfo} onClose={onClose} />,
      isDisabled: false, // Always enabled
      onClick: handleSIP,
    },
    {
      label: "STP",
      component: <STP fundId={fundId} activeSection={activeSection} fundInfo={fundInfo} onClose={onClose} />,
      isDisabled: stpDisabled,
      onClick: handleSTP,
    },
    {
      label: "SWP",
      component: <SWP fundId={fundId} onClose={onClose} activeSection={activeSection} fundInfo={fundInfo} />,
      isDisabled: isDisabled,
      onClick: handleSWP,
    },
  ];
  

  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // Dynamically adjust height based on screen size
  let chartHeight = 500; // default height for large screens
  if (isLargeScreen) {
    chartHeight = 500; // Keep it 500 for large screens
  } else if (isMediumScreen) {
    chartHeight = 400; // Slightly reduce the height for medium screens
  } else if (isSmallScreen) {
    chartHeight = 250; // Further reduce the height for small screens
  }

  return (
    <div className="bg-lightBg py-3 lg:pt-10 lg:pb-10 scrollbar-hide overflow-y-auto overflow-x-hidden">
      <div className="w-full relative">
      <div className="absolute right-0 top-0 text-3xl pl-2 lg:pl-10 font-bolder mr-4 "
              style={{ color: colors.primary }}>
          <RxCross2 fontSize={isLargeScreen ? "2rem" : "1.2rem"} className="cursor-pointer" onClick={onClose} />
          </div>
        <div className="flex justify-start">
          <div className="relative">
            <h6
              className="text-[1rem] lg:text-3xl pl-2 lg:pl-10 font-medium"
              style={{ color: colors.primary }}
            >
              Transact Now
            </h6>
            <h6
              className="text-[0.8rem] lg:text-lg pl-2 lg:pl-10 mt-2 lg:mt-5 font-medium"
              style={{ color: colors.textGrey }}
            >
              Client Code: {userData?.kyc?.pancardNumber}
            </h6>
          </div>
        </div>
            <div className="w-full lg:hidden flex items-center justify-between mt-2 mb-4 pb-1">
              <TabsComponent
                tabs={tabs}
                onLogout={() => {
                  ("");
                }}
              />
            </div>
        <div className="hidden lg:flex flex-wrap justify-center gap-5 md:gap-6 pt-10">
          <MfInfo onClick={handleLumpsum} title="Lumpsum" isActive={activeSection === "lumpsum"} />
          <MfInfo onClick={handleRedeem} title="Redeem" isActive={activeSection === "redeem"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
          <MfInfo onClick={handleSwitch} title="Switch" isActive={activeSection === "switch"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
          <MfInfo onClick={handleSIP} title="SIP" isActive={activeSection === "sip"} />
          <MfInfo onClick={handleSTP} title="STP" isActive={activeSection === "stp"} style={{
              cursor: stpDisabled ? "not-allowed" : "pointer",
              opacity: stpDisabled ? 0.5 : 1,
            }} />
          <MfInfo onClick={handleSWP} title="SWP" isActive={activeSection === "swp"} style={{
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1,
            }} />
        </div>
        {(!isSmallScreen || !isMediumScreen || !isLargeScreen ) &&
        (<div className="hidden lg:block">
          {activeSection === "lumpsum" && (
            <Lumpsum
              fundId={fundId}
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {activeSection === "redeem" && (
            <Redeem
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {(fundInfo?.switchFlag === "Y" && activeSection === "switch") && (
            <Switch
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {(fundInfo?.sipFlag === "Y" && activeSection === "sip") && (
            <SIP
              fundId={fundId}
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}
          {(fundInfo?.stpFlag === "Y" && activeSection === "stp") && (
            <STP
              fundId={fundId}
              activeSection={activeSection}
              fundInfo={fundInfo}
              onClose={onClose}
            />
          )}

          {(fundInfo?.swpFlag === "Y" && activeSection === "swp") && (
            <SWP
              fundId={fundId}
              onClose={onClose}
              activeSection={activeSection}
              fundInfo={fundInfo}
            />
          )}
        </div>
        )}
      </div>
    </div>
  );
};

export default Main;
