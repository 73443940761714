import axios from "axios";
import api from "../../config/apiConfig";
import { CreatePassword, OTP, SignUp } from "../../models/signup.model";
import { LoginModel } from "../../models/login.model";
import { SocialLogin } from "../../models/social-login.model";
import {jwtDecode} from "jwt-decode";
import toast from "react-hot-toast";

const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";
const activityKey = "lastActivityTime"; 

const getToken = () => {
  return {
    accessToken: sessionStorage.getItem(accessTokenKey) ? sessionStorage.getItem(accessTokenKey) : localStorage.getItem(accessTokenKey),
    refreshToken: localStorage.getItem(refreshTokenKey),
  };
};

const setToken = (token: string,RFToken:string) => {
  localStorage.setItem(accessTokenKey, token);
  localStorage.setItem(refreshTokenKey,RFToken);
};

const isTokenExpired = (): boolean => {
  const { accessToken } = getToken();
  if (!accessToken) return true;

  try {
    const decoded: { exp?: number } = jwtDecode(accessToken); // Explicitly type the decoded token
    if (!decoded.exp) return true; // Treat as expired if `exp` is missing
    return Date.now() >= decoded.exp * 1000; // Convert `exp` to milliseconds and compare
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // Treat as expired if decoding fails
  }
};



const getAccessToken = () => {
  const refreshToken = localStorage.getItem(refreshTokenKey);
  return api.post("/user/refreshToken",  {refreshToken} );
};

const refreshToken = async (): Promise<string> => {
  try {
    const refreshToken = localStorage.getItem(refreshTokenKey);
    const response = await api.post("/user/refreshToken",{refreshToken});
    const { accessToken } = response.data?.data;
    const RefreshToken=response.data?.data.refreshToken;
    setToken(accessToken,RefreshToken); // Save the new token in localStorage
    return accessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};


const signUp = async (data: SignUp) => {
  const response = await api.post("/user/signUp", data);
  return response.data;
};

const login = async (data: LoginModel) => {
  const response = await api.post("user/logInv2", data);
  setToken(response.data.accessToken,response.data.refreshToken);
  return response.data;
};

const verifyOTP = async (data: OTP) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/verifyOTP", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const addPassword = async (data: CreatePassword) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("/user/addPassword", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data;
};

const removeTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
}

const socialSignUp = async (data: SocialLogin) => {
  const response = await api.post("/user/socialSignUp", data);
  return response.data;
};

const logout = () => {
  removeTokens();
  localStorage.removeItem("user");
  // Send custom event with key
  window.dispatchEvent(new CustomEvent("sessionExpired", { detail: "accessToken" }))
  window.location.reload();
};

// Function to check if the user is active in the last 1 minute
const isUserActive = (): boolean => {
  const lastActivityTime = localStorage.getItem(activityKey);
  if (!lastActivityTime) return false;

  const currentTime = Date.now();
  const lastActivityTimestamp = parseInt(lastActivityTime, 10); // Correct radix value
  const oneMinuteInMs =10 * 60 * 1000; // 10 minute in milliseconds

  return currentTime - lastActivityTimestamp < oneMinuteInMs;
};

// Debounced function to update the last activity time
let activityDebounceTimer: NodeJS.Timeout | null = null;

const updateLastActivity = () => {
  if (activityDebounceTimer) clearTimeout(activityDebounceTimer);

  activityDebounceTimer = setTimeout(() => {
    localStorage.setItem(activityKey, Date.now().toString());
  }, 1000); // Update at most once per second
};

// Function to monitor user activity
const startUserActivityListener = () => {
  const events = ["mousemove", "keydown", "click", "scroll", "resize"];
  events.forEach((event) =>
    window.addEventListener(event, updateLastActivity)
  );
};

// Function to stop user activity listener
const stopUserActivityListener = () => {
  const events = ["mousemove", "keydown", "click", "scroll", "resize"];
  events.forEach((event) =>
    window.removeEventListener(event, updateLastActivity)
  );
};

// Start monitoring user activity
updateLastActivity();
startUserActivityListener();

export const cleanupActivityListener = stopUserActivityListener;

// Function to handle token refresh with user activity check
const refreshTokenIfNeeded = async (): Promise<void> => {
  try {
    if (isTokenExpired()) {
      console.log("Is user active in the last 1 minute:", isUserActive());
      if (isUserActive()) {
        console.log("Is user active in the last 1 minute:***", isUserActive());
        await refreshToken();
      } else {
        console.warn("User is inactive. Logging out.");
        logout(); // Log out the user if inactive
      }
    }
  } catch (error) {
    console.error("Error during token refresh:", error);
    logout(); // Log out in case of an error
  }
};


const authService = {
  getToken,
  isTokenExpired,
  refreshToken,
  signUp,
  login,
  verifyOTP,
  addPassword,
  socialSignUp,
  logout,
  removeTokens,
  startUserActivityListener,
  cleanupActivityListener,
  refreshTokenIfNeeded,
};
export default authService;
