import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import Loader from '../Loader/Loader';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

interface PartnerFormProps {
  onClose: () => void;
}

const PartnerForm: React.FC<PartnerFormProps> = ({ onClose }) => {
  const dispatch= useDispatch<any>();
  const [loading,setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    bseCode: ''
  });

  const handleSubmit = async(e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    try{
      if(!formData.name || !formData.email || !formData.mobile){
        toast.error("Please fill all the fields.");
        return;
      }
      const payload = {
        ...formData,
        mobile: `+91${formData.mobile}`
      }
      // const response = await dispatch(partnerSignUp(payload));
      // if(response.payload.statusCode === 200){
      //   toast.success("Your request submitted successfully.");
      // }else{
      //   toast.error("Failed to register partner.");
      // }
    }catch(error){
      toast.error("Something went wrong.");
      console.error(error,"Error in partner registration");
    }finally{
        onClose();
    }  
  };

  return (
    <>
    {loading && <Loader/>}
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-lg p-8 max-w-md w-full mx-4"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-[#255288]">Register as Partner</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-2">Name *</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#255288]"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Email *</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#255288]"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Mobile *</label>
            <input
              type="tel"
              value={formData.mobile}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#255288]"
              required
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Any Other Info (like ARN Number)</label>
            <input
              type="text"
              value={formData.bseCode}
              onChange={(e) => setFormData({ ...formData, bseCode: e.target.value })}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#255288]"
            />
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-[#F17A21] text-white rounded-md hover:bg-[#255288] transition-colors"
          >
            Submit
          </button>
          <p className='text-textGrey text-xs italic'>Note: Aum Sampann team will reach out for registration within 48 hours</p>
        </form>
      </motion.div>
    </motion.div>
    </>
  );
};

export default PartnerForm;