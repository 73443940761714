import React, { useState } from 'react';
import { Button, Accordion, AccordionDetails, AccordionSummary, ToggleButtonGroup, ToggleButton, Skeleton, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from '../../constants/colors';
import { PieChart } from "@mui/x-charts";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import formatKeyToTitle from '../../utils/formatKeytitle';

const AIFAssetAllocationMobile = ({ DataBYID, DataBYiSIN }: { DataBYID: any, DataBYiSIN: any }) => {
  const [currentTab, setCurrentTab] = useState(1);

  const tabs = [
    { label: 'Asset Allocation', value: 1 }
  ];


  const AllAssetChartValue = () => {
    const data = DataBYID;
  
    return Object.entries(data).map(([key, value]) => ({
      id: key,
      value: value as number,
      label: formatKeyToTitle(key),
    }));
  };
  

  const AssetItem = ({ title, value }: { title: string; value: number }) => {
    return (
      <>
        <Box
            key={title}
            sx={{
              width:"100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr", // Equal halves
              alignItems: "center",
              gap: 2,
              mb: 2,
            }}
          >
            {/* Label Section - Left Half */}
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, textAlign: "left" }}
            >
              {title}
            </Typography>

            <LinearProgressWithLabel value={value} />
          </Box>
      </>
    );
  };

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', flexDirection:"column"}}>
      <Box sx={{ width: "100%",display:'flex',justifyContent:"end", alignItems:'end' }}>
          <Typography variant="body2" color="text.secondary">{`${(props.value).toFixed(2)}%`}</Typography>
        </Box>
        
        <Box sx={{ width: '100%'}}>
          <LinearProgress variant="determinate" {...props} sx={{
                  width: "100%", // Make sure the progress bar stretches
                  height: 5,
                  borderRadius: 5,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    backgroundColor: "#4caf50",
                  },
                }} />
        </Box>
      </Box>
    );
  }

  const AssetList = ({ data }: { data: any }) => {
    const assetArray = Object.entries(data.assetAllocation).map(([key, value]) => ({ key, value: parseFloat(value as string) }));
    const filteredData = assetArray.filter((item) => item.value >= 0);
  
    return (
      <div className="flex flex-wrap">
        {filteredData.map(({ key, value }, index) => (
          <div key={index} className="my-4 w-full px-8">
            <AssetItem title={formatKeyToTitle(key)} value={value} />
          </div>
        ))}
      </div>
    );
  };
  




  const AssetAllocationTab = () => {
    const pieChart = AllAssetChartValue();
    // console.log('pieChart', pieChart);

    return (
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-center mx-auto px-auto">
          <PieChart
            series={[
              {
                data: pieChart,
                innerRadius: 120,
                outerRadius: 150,
                paddingAngle: 0,
                startAngle: -45,
                endAngle: 360,
              }
            ]}
            colors={['#8DAEE5', "#FF6B6B", "#FF69B4", "#FFFF66", '#B0E0E6', "#FFB347", "#7FFFD4", "#00FA9A"]}
            sx={{
              '& .css-ecs132-MuiChartsSurface-root': {
                backgroundColor: '#ff0000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            width={400}
            height={300}  // You can keep this height or adjust as needed
            margin={{ top: 4, right: 5, bottom: 4, left: 5 }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </div>
        <AssetList data={{ assetAllocation: DataBYID }} />
      </div>
    )
  };


  // const handleAlignmentss = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string | null,
  // ) => {
  //   if (newAlignment !== null) {
  //     setAlignment(newAlignment);
  //   }
  // };

  return (
    <>
    {(Object?.values(DataBYiSIN)?.every(value => value === "" || value === "-"))?<>
     <h3 className="w-full text-lg text-[darkGrey] text-center">No assets data found for this AIF.</h3>
     </>:
      (<div style={{ padding: '0', margin: '1rem 1rem' }}>
        <div className="border border-lightGrey rounded-md">
          <div className='border-b-2 border-primary'>
                                <ToggleButtonGroup
                                  color="primary"
                                  exclusive
                                  value={currentTab}
                                  onChange={(event, value) => setCurrentTab(value)}
                                  aria-label="Platform"
                                  sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    '& .css-1xhpvac-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                                      backgroundColor: colors.primary,
                                    },
                                    '& .css-233i2o-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                                      color: '#fff',
                                      backgroundColor: colors.primary
                                    },
                                    '& .css-1vwtnbs-MuiButtonBase-root-MuiToggleButton-root.Mui-selected': {
                                      color: '#fff',
                                      backgroundColor: colors.primary
                                    }
                                  }}
                                >
                                  {tabs.map((tab) => (
                                    <ToggleButton
                                      key={tab.value}
                                      value={tab.value}
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize:"12px",
                                        color: tab.value ? colors.primary : '#fff',
                                        width: tab.value === 1 ? '100%' : '25%', // Adjust width based on your requirements
                                        '&:hover': {
                                          backgroundColor: currentTab === tab.value ? 'primary' : 'transparent',
                                        }
                                      }}
                                    >
                                      {tab.label}
                                    </ToggleButton>
                                  ))}
                                </ToggleButtonGroup>
                              </div>
          <div className="my-5 flex flex-col md:flex-row gap-5 justify-between p-4 w-full mx-auto">
            <AssetAllocationTab />
          </div>
        </div>
      </div>)}
      </>
  );
};

export default AIFAssetAllocationMobile;
