import React, { useState, useEffect } from 'react';
import { X, ArrowRight, CheckCircle } from '@mui/icons-material';
import bgImage from '../../assets/images/onboardingmodalbg.jpeg';

interface OnboardingModalProps {
    isOpen: boolean;
    onSkip: () => void;
    onProceed: () => void;
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ isOpen, onSkip, onProceed }) => {
    const [animateIn, setAnimateIn] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
            const timer = setTimeout(() => setAnimateIn(true), 50);
            return () => clearTimeout(timer);
        } else if (showModal) {
            setAnimateIn(false);
            const timer = setTimeout(() => setShowModal(false), 500); // Wait for animation to finish
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    if (!showModal) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex items-center justify-center z-50 p-4">
            <div
                className={`bg-white rounded-xl shadow-2xl w-full max-w-md overflow-hidden transform transition-all duration-500 ease-out ${animateIn ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
                    }`}
            >
                <div className="relative">
                    <img
                        src={bgImage}
                        alt="Onboarding"
                        className="w-full h-48 object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-primary/100 to-transparent flex items-end">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold text-white">Complete Your BSE KYC</h3>
                            <p className="text-blue-100 mt-1">Unlock the full potential of your investment journey</p>
                        </div>
                    </div>
                </div>

                <div className="p-6">
                    <div className="space-y-4 mb-8">
                        {[
                            {
                                title: 'Seamless Investments',
                                description: 'Start investing in top mutual funds effortlessly with a guided experience.',
                            }
                        ].map((item, index) => (
                            <div key={index} className="flex items-start space-x-3">
                                <div className="bg-blue-100 p-2 rounded-full text-primary mt-0.5">
                                    <CheckCircle fontSize="medium" />
                                </div>
                                <div>
                                    <h4 className="font-medium text-gray-900">{item.title}</h4>
                                    <p className="text-gray-600 text-sm">{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className="flex justify-between space-x-3">
                        <button
                            onClick={() => {
                                setAnimateIn(false);
                                setTimeout(onProceed, 500);
                            }}
                            className="flex-1 px-4 py-2.5 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors font-medium"
                        >
                            Continue
                        </button>
                        <button
                            onClick={() => {
                                setAnimateIn(false);
                                setTimeout(onSkip, 500);
                            }}
                            className="flex-1 px-5 py-2.5 bg-primary text-white rounded-lg hover:bg-secondary transition-colors font-medium flex items-center justify-center group"
                        >
                            Skip for Now
                            <ArrowRight fontSize="medium" className="ml-2 group-hover:translate-x-1 transition-transform" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingModal;
