import React, { FC, useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { InvestRequest } from "../../redux/AIF/AIFSlice";
import MFDetailCard from "../MFTransaction/MFDetailCard";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../FormInput/FormInput";
import AmountButtons from "../MFTransaction/AmountButtons";
import APIloader from "../AppLoader/APIloader";
import Dialog from "@mui/material/Dialog";
import { Button as Btn, useMediaQuery, useTheme } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import AIFDetailCard from "./AIFDetailCard";
import { showInterest } from "../../redux/user/userSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import { useUserData } from "../../hooks/useUserData";

interface MFProps {
  fundId: number;
  fundInfo: any;
  onClose?: any;
}

const InvestmentPage: FC<MFProps> = ({ fundId, fundInfo, onClose }) => {
  const ref = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userData=useUserData();

  // const lumpsumValues = {
  //   orderType: 1,
  //   fundId: fundId,
  //   amount: "",
  //   folioNumber: "",
  //   dividend: "",
  //   bankAccount: "",
  // };

  const [investmentPlan, setInvestmentPlan] =
    useState<string>("New investment");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [investmentPeriod, setInvestmentPeriod] = useState<number | null>(null);
  const [lumpsumValues, setLumpsumValues] = useState({
    aifId: 0,
    amount: 0,
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const handleDialogClose = () => setShowSummary(false);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
    const theme = useTheme();
    // Detect screen size using useMediaQuery
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  
    // Dynamically adjust height based on screen size
    let chartHeight = 500; // default height for large screens
    if (isLargeScreen) {
      chartHeight = 500; // Keep it 500 for large screens
    } else if (isMediumScreen) {
      chartHeight = 400; // Slightly reduce the height for medium screens
    } else if (isSmallScreen) {
      chartHeight = 250; // Further reduce the height for small screens
    }

  const handleConfirm = async () => {
    if (summaryData) {
      try {
        const { amount, fundId } = summaryData;

        const updatedBodyData = {
          aifId: fundInfo?.id,
          amount: parseInt(amount),
        };

        console.log(fundInfo, "bodyData");
        setLoading(true);
        const res = await dispatch(InvestRequest(updatedBodyData));
        if (res?.payload?.data?.success === 200) {
          setLoading(false);
          setOpen(true);
          setMessage("Investment Request is submitted successfully");
        } else {
          setLoading(false);
          setOpen(true);
          setErrorMessage(
            "Error in executing your order."
          );
        }
      } catch (error) {
        setOpen(true);
        setErrorMessage("Investment Failed");
      } finally {
        dispatch(hideLoading());
        handleDialogClose();
      }
    }
  };

  const calculateUnits = (amount: number, nav: number) => {
    amount *= 10000000;
    return (amount / nav).toFixed(2); // Returns units to two decimal places
  };

  // const proceedToPayment = (values: any) => {
  //   const nav = fundInfo?.currentNAV; // Get current NAV from fundInfo
  //   const amount = parseFloat(values.amount); // Parse amount from form values
  //   const units = calculateUnits(amount, nav); // Calculate number of units
  //   console.log("Current NAV:", fundInfo.currentNAV);

  //   const data = {
  //     ...values,
  //     investmentPlan, // Make sure to include investmentPlan in the data object
  //     units, // Add calculated units to the data object
  //   };
  //   setSummaryData(data);
  //   setShowSummary(true);
  // };

  // useEffect(() => {
  //   if (fundInfo) {
  //     ref.current?.setFieldValue("folioNumber", fundInfo?.folioNo ?? "112233");
  //   }
  // }, [fundInfo]);

  // useEffect(() => {
  //   fetchData(); // Fetch data when component mounts or dependencies change
  // }, []);

  // const fetchData = async () => {
  //   let data = { fetchFor: "aumcapital" };
  //   try {
  //     const response = await dispatch(fetchDashboardData(data));
  //     if (response.payload.data) {
  //       setTransactionData(response.payload.data);
  //       evaluateInvestmentStatus(response.payload.data);
  //     }
  //   } catch (error) {
  //     console.error("Fetch dashboard data failed:", error);
  //     toast.error("Please Refresh");
  //     // setLoading(false);
  //   }
  // };

  // const evaluateInvestmentStatus = (data: any) => {
  //   let newInvestmentDisabled = false;

  //   // Log the entire recentTransactions array for debugging
  //   console.log("Recent Transactions Data:", data.recentTransactions);

  //   // Check if any of the MF orders are of type `isBuy` 1
  //   data.recentTransactions.forEach(
  //     (transactionGroup: any[], groupIndex: number) => {
  //       transactionGroup.forEach((order: any, orderIndex: number) => {
  //         console.log(`Processing order ${orderIndex}:`, order);

  //         if (order.orderType === "MF" && order.isBuy === 1) {
  //           console.log("Found MF order with isBuy 1:", order);
  //           newInvestmentDisabled = true;
  //         }
  //       });
  //     }
  //   );

  //   // Update the state based on the evaluation
  //   setIsNewInvestmentEnabled(!newInvestmentDisabled);

  //   // Log the final state of newInvestmentDisabled
  //   console.log("New Investment Disabled:", newInvestmentDisabled);
  // };

  const handleShowInterestClick = () => {
    setModalOpen(true);
  };

  const showingInterest = async () => {
    try {
      setModalOpen(false);
      const updatedBodyData = {
        aifId: fundInfo?.id,
        amount: 20,
      };
      setLoading(true);
      const res = await dispatch(InvestRequest(updatedBodyData));
      if (res?.payload?.data?.success === 200) {
        setLoading(false);
        setOpen(true);
        setMessage(`Your interest has been successfully submitted to your RM. You can also reach out to your RM ${userData?.relationManager?.firstName} at ${userData?.relationManager?.mobile}.`);
      } else {
        setLoading(false);
        setOpen(true);
        setErrorMessage(
          "Your Interest is not submitted.Somthing wents wrong"
        );
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Investment Failed");
    } finally {
      dispatch(hideLoading());
      handleDialogClose();
    }
    // try {
    //   const response = await dispatch(showInterest({ id:fundId, holdingType:5 }));

    //   if (response?.payload?.statusCode === 200) {
    //     toast.success("Your Interest is submitted successfully");
    //   } else {
    //     toast.error("Something wents wrong.Please try again later!");
    //     console.warn('Unexpected response structure:', response);
    //   }
    // } catch (error) {
    //   toast.error("Something wents wrong.Please try again later!");
    //   console.error("Error fetching users:", error);
    // }
  };
  const handleInvestClick = () => {
    // Validate selectedAmount and investmentPeriod
    if (!selectedAmount) {
      setOpen(true);
      setErrorMessage('Please enter the amount to invest'); // Show error if selectedAmount is missing
      return;
    }else{
      if(Number(selectedAmount) < 10000000){
      setOpen(true);
      setErrorMessage('Minimumn investment amount for this scheme is 1Cr. Please enter the correct amount to invest.'); // Show error if selectedAmount is missing
      return;
      }
    }
    
    // if (!investmentPeriod) {
    //   toast.error('Please enter the investment period'); // Show error if investmentPeriod is missing
    //   return;
    // }    
  };

  const handleClose=()=>{
    setOpen(false);
    setMessage("");
  }


  return (
    <>
      <div className="bg-lightBg lg:pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white mb-3">
              <div className="lg:px-8 lg:py-10">
                <AIFDetailCard fundInfo={fundInfo} />
                <div className="m-2 lg:my-5">
                  <Formik
                    innerRef={ref}
                    initialValues={lumpsumValues}
                    onSubmit={async () => {
                      handleInvestClick();
                      if (!selectedAmount) {
                        return; // Do nothing if values are missing
                      }else{
                        if(Number(selectedAmount) < 10000000){
                        return;
                        }
                      }

                      // Show summary dialog
                      setSummaryData({
                        amount: selectedAmount,
                        fundId: fundInfo?.id,
                      });
                      setShowSummary(true);
                    }}
                    // validationSchema={validationSchema}
                    // onSubmit={async (values: any) => {
                    //   try {
                    //     // const isValid = await validationSchema.isValid(values);
                    //     if (isValid) {
                    //       // proceedToPayment(values);
                    //     } else {
                    //       // Form validation failed, do something (optional)
                    //     }
                    //   } catch (error: any) {
                    //     toast.error("Investment Failed");
                    //   } finally {
                    //     setSubmitting(false);
                    //   }
                    // }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="mt-5">
                        <AmountButtons
                          label="Amount"
                          // setFieldValue={setFieldValue}
                          selectedAmount={selectedAmount}
                          setSelectedAmount={setSelectedAmount}
                          fieldName="amount"
                          values={values}
                          errors={errors}
                          touched={touched}
                        />

                        {/* Investment Period Dropdown */}
                        {/* <div className="mt-4">
                          <label
                            htmlFor="investmentPeriod"
                            className="block text-xl font-medium text-gray-700"
                          >
                            Investment Period
                          </label>
                          <select
                            id="investmentPeriod"
                            name="investmentPeriod"
                            value={investmentPeriod || ""}
                            onChange={(e) =>
                              setInvestmentPeriod(parseInt(e.target.value))
                            }
                            className="mt-2 block w-full pl-3 pr-10 py-4 text-base border-2 border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-md rounded-md text-gray-700"
                          >
                            <option value="" disabled>
                              Select investment period
                            </option>
                            {Array.from({ length: 15 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1} year{i + 1 > 1 ? "s" : ""}
                              </option>
                            ))}
                          </select>
                        </div> */}

                        {/* Display chosen investment period */}
                        {/* {investmentPeriod && (
                          <p className="mt-2 text-md  text-gray-700">
                            You choose investment period for the next{" "}
                            {investmentPeriod} year
                            {investmentPeriod > 1 ? "s" : ""}.
                          </p>
                        )} */}
                        <section className="mt-2 mb-4 lg:mt-10 lg:mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col lg:grow lg:max-md:mt-10 max-md:max-w-full">
                                <div className="flex gap-3 justify-between max-md:mt-1 lg:mt-2 lg:mb-5 text-sm max-md:text-xs my-5">
                                  <button
                                    type="submit"
                                    className="grow md:flex justify-center px-3 py-1 md:px-4 md:py-2 lg:px-8 lg:py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary"
                                    tabIndex={0}
                                    style={{ cursor: !selectedAmount?'not-allowed':'pointer', fontSize:isLargeScreen?"1.3rem":"0.9rem" }}
                                  >
                                    Invest
                                    {/* Proceed to payment */}
                                  </button>
                                  <button
                                    type="button"
                                    className="grow md:flex justify-center px-3 py-1 md:px-4 md:py-2 lg:px-8 lg:py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary"
                                    onClick={handleShowInterestClick}
                                    style={{
                                      fontSize:isLargeScreen?"1.3rem":"0.9rem"
                                    }}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={""} />

      {/* Summary Dialog */}
      <Dialog
        open={showSummary}
        onClose={handleDialogClose}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-2 lg:p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
          <Typography variant={isSmallScreen?"h6":isMediumScreen?"h5":"h4"} className="font-bold mb-2 lg:mb-4">
              Summary
            </Typography>
            <DialogContent className="flex flex-col" sx={{
              padding : 1,
            }}>
              {summaryData ? (
                <>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Investment Plan:
                    </span>
                    <span >{fundInfo?.AssetName}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Amount:</span>
                    <span >Rs. {summaryData.amount}</span>
                  </div>
                  {/* <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Approx. Units:
                    </span>
                    <span>{calculateUnits(fundInfo?.AUM, fundInfo?.NAV)}</span>
                  </div> */}
                  {/* <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Investment Period:</span>
                    <span>{investmentPeriod && (
                          <span>
                            {investmentPeriod} year
                            {investmentPeriod > 1 ? "s" : ""}
                          </span>
                        )}</span>
                  </div> */}
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Return Since Inception:
                    </span>
                    <span >{fundInfo?.ReturnSinceInception===""||fundInfo?.ReturnSinceInception==="-"?"-":fundInfo?.ReturnSinceInception+"%"}</span>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <span>Loading...</span>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Btn
                onClick={handleConfirm}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
                onClick={handleDialogClose}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
            {/* <Typography variant="body2" className="mb-4" sx={{ marginTop: 4 }}>
              Once confirmed, you will be redirected to the banking payment page
              for transaction
            </Typography> */}
          </div>
        </div>
      </Dialog>
      {/* Confirmation Modal for show interest*/}
      <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)} // Close modal on cancel or outside click
      >
        <DialogContent>
          <Typography variant="h6" className="font-bold my-3 text-center">
            Are you sure you want to submit your interest?
          </Typography>
        </DialogContent>
        <DialogActions className="w-[97%] mb-3">
              <Btn
               onClick={showingInterest}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
               onClick={() => setModalOpen(false)}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
      </Dialog>
      <SuccessfullModal
        open={Open}
        message={message!==""?message:errormessage}
        handleClose={handleClose}
      />
    </>
  );
};

export default InvestmentPage;
