import React, { useState } from "react";
import {
  TableRow,
  IconButton,
  TableCell,
  ListItem,
  MenuItem,
  Divider,
  Menu,
  Fade,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { Button as Btn } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showInterest } from "../../redux/user/userSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import { withdrawStock } from "../../redux/Stocks/StockSlice";
import InvestModal from "../../components/modals/investModalUnlistedStock";
import { colors } from "../../constants/colors";
import { toCamelCase } from "../../utils/ToCamelCase";
import { formatIndianCurrency } from "../../utils/formatIndianNumber";

const TableRowComponent = ({ data }: any) => {
  const isArray = Array.isArray(data);
  const navigate = useNavigate();
  const user = useUserData();
  const checkPMS = user?.preferences?.pms;
  const dispatch = useDispatch<any>();
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const [openRows, setOpenRows] = useState<boolean[]>(
    new Array(data.length).fill(false)
  );
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const [Fund, setFund] = useState({
    id: 0,
    stockName: "",
    stockSector: "",
    currentamount: "",
    redeemID:""
  });
  const [Open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const openMenuMD = Boolean(anchorMD);
  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  //model Open
  const openInvestModal = () => {
    // if (user?.onboardStatus !== 3) {
    //   setOpen(true);
    //   setMessage(
    //     "Please wait for 24 hours. You are not allowed to invest now."
    //   );
    //   return;
    // }
    setIsInvestModalOpen(true);
  };

  //model Close
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
  };

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    stockName: string,
    stockSector: string,
    currentamount: string,
    redeemID:string,
  ) => {
    setAnchorMD(event.currentTarget);
    setFund({
      id: id,
      stockName: stockName,
      stockSector: stockSector,
      currentamount: currentamount,
      redeemID:redeemID
    });
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const isValidAssetName = (stockName: string) => {
    return stockName && stockName !== "-" && stockName.trim() !== "";
  };

  const handleShowInterest = async (stockId: any) => {
    try {
      const response = await dispatch(
        showInterest({ id: stockId, holdingType: 8 })
      );

      if (response?.payload?.statusCode === 200) {
        setOpen(true);
        setMessage(`Your interest has been successfully submitted to your RM. You can also reach out to your RM ${user?.relationManager?.firstName} at ${user?.relationManager?.mobile}.`);
      } else {
        setOpen(true);
        setMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setOpen(true);
      setMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  const handleRedeem = async () => {
    try {
      const response = await dispatch(
        withdrawStock({ holdingId: Fund?.redeemID, amount: 0, isPrivate: 1 })
      );
      if (response?.payload?.data?.statusCode === 200) {
        setOpen(true);
        setMessage("Redeem request submitted successfully");
      } else {
        setOpen(true);
        setMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setOpen(true);
      setMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }finally{
      setModalOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRedeemClick = () => {
    setModalOpen(true);
  };
  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (
            <>
              {item?.stockName && isValidAssetName(item?.stockName) && (
                <React.Fragment key={item.id}>
                  <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                      className="min-w-[150px]"
                    >
                      <div className="flex gap-x-2 items-center">
                        <div className="w-3/5">
                          <span
                            className="cursor-pointer"
                            // onClick={() => handleToggleRow(index)}
                          >
                            {toCamelCase(item?.stockName)}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                      className="min-w-[150px]"
                    >
                      <div className="flex gap-x-2 items-center">
                        <div className="w-3/5">
                          <span
                            className="cursor-pointer"
                            // onClick={() => handleToggleRow(index)}
                          >
                            {toCamelCase(item?.stockSector==="null"?"-":item?.stockSector)}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                      className="min-w-[150px]"
                    >
                      <div className="flex gap-x-2 items-center">
                        <div className="w-3/5">
                          <span
                            className="cursor-pointer"
                            // onClick={() => handleToggleRow(index)}
                          >
                            {item?.lotSize?formatIndianCurrency(item?.lotSize):"-"}
                          </span>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        border: "none",
                      }}
                      className="min-w-[100px] flex justify-center cursor-pointer"
                    >
                      {/* <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleToggleRow(index)}
                  >
                    {openRows[index] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton> */}
                      <IconButton
                        onClick={(e) => {
                          modalMenuClick(
                            e,
                            item?.id,
                            item?.stockName,
                            item?.stockSector,
                            item?.myHoldings[0]?.amount,
                            item?.myHoldings[0]?.id
                          );
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {item?.stockName && isValidAssetName(item?.stockName) && (
                    <TableRow sx={{ height: "5%" }}>
                      <TableCell
                        sx={{ border: "none" }}
                        colSpan={8}
                        align="center"
                      ></TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )}
            </>
          );
        })}

      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        disableScrollLock={true}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "12%",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem
            button
            sx={{ marginLeft: "-25px" }}
            onClick={() => {
              openInvestModal();
              handleCloseMD(); // Close the dropdown menu
            }}
          >
            <MenuItem className="cursor-pointer hover:bg-slate-500">
              Invest
            </MenuItem>
          </ListItem>
          <Divider
            sx={{
              backgroundColor: "#fff",
            }}
          />
          <ListItem button sx={{ marginLeft: "-25px" }}>
            <MenuItem
              className="cursor-pointer hover:bg-slate-500"
              disabled={!Fund?.currentamount}
              onClick={() => {
                if (Fund?.currentamount !=="") {
                  handleRedeemClick();
                  handleCloseMD(); // Close the dropdown menu
                }
              }}
            >
              Redeem
            </MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
      <SuccessfullModal
        open={Open}
        message={message}
        handleClose={handleClose}
      />
      <InvestModal
        open={isInvestModalOpen}
        onClose={closeInvestModal}
        fundInfo={Fund}
      />
             <Dialog
        open={isModalOpen}
        onClose={() => setModalOpen(false)} // Close modal on cancel or outside click
      >
        <DialogContent>
          <Typography variant="h6" className="font-bold my-3 text-center">
            Are you sure you want to submit redeem request?
          </Typography>
        </DialogContent>
        <DialogActions className="w-[97%] mb-3">
              <Btn
               onClick={handleRedeem}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
               onClick={() => setModalOpen(false)}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
      </Dialog>
    </>
  );
};

export default TableRowComponent;
