import React from 'react';
import { motion } from 'framer-motion';
import sanjeev from '../../assets/images/team/sanjeevkumar.jpg';
import sujata from '../../assets/images/team/sujata agarwal.jpg';
import alisha from '../../assets/images/team/alisha.jpg';
import ashis from '../../assets/images/team/ashis.jpg';
import lalit from '../../assets/images/team/lalitgupta.jpg';

const teamMembers = [
  {
    name: 'Sanjeev Kumar',
    position: 'At AUM Sampann, Sanjeev will be focusing on building and expanding the distribution network. He has previously worked with Bajaj Capital, Chola Mutual Fund, and Tata Mutual Fund as Head of Retail Sales. Sanjeev holds a BSc (Hons) in Physics, an MBA in Finance from Aligarh Muslim University (AMU), and has completed a Senior Management Programme from IIM Calcutta.',
    image: sanjeev
  },
  {
    name: 'Dr. Sujata Agarwal',
    position: 'As a Certified PR professional with excellent PR and strong communication skills Dr Sujata Agarwal, looks after the Corporate Communications Channel of AUM Sampann. She is also certified and experienced coach and consultant with wide experience of working with top notch senior executives and entrepreneurs to identify and implement strategies that help in growth and profitability.',
    image: sujata
  },
  {
    name: 'Alisha Patni',
    position: 'With 6+ years of experience, Alisha works alongside the product team to build our product roadmap, having a keen insight into what improvements & new updates users are actively looking for. Prior to AUM Sampann, she was a product manager at AUM Capital focusing on wealth research and client investments. Alisha has completed her CFP, FRM and is a CFA level 2 candidate.',
    image: alisha
  },
  {
    name: 'Ashis Kumar Das',
    position: 'Ashis takes care of the development of AUM Sampann Cloud as well as iOS & Android mobile initiatives. He has prior 10 years of experience from various organizations.',
    image: ashis
  },
  {
    name: 'Dr. Lalit Gupta',
    position: 'Chief Technology Officer, Dr. Lalit Gupta heads Sampann’s AI and software development and brings 18 years of technical experience, having co-founded DeepNeura with Ahinsa. Lalit holds a PhD from Masstricht University, The Netherlands and Master’s degree from IIT Madras in Computer Science and AI.',
    image: lalit
  }
];

const AboutUs = () => {
  return (
    <section className="py-16 bg-white" id="team">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16 text-[#255288]">Our Team</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="h-64 overflow-hidden">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-full object-contain transform hover:scale-110 transition-transform duration-500"
                />
              </div>
              <div className="p-6 text-center">
                <h3 className="text-xl font-bold mb-2 text-[#255288]">{member.name}</h3>
                <p className="text-gray-600">{member.position}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;